export const rtScores = (score: number) => {
    return score <= 24
        ? {
            title: "You prefer lower risk investments",
            body: "You risked low amounts of your investment in the activity. This tells us that you probably don't like to take too much risk when seeking investment opportunities."
        }
        : score > 24 && score <= 59
            ? {
                title: "You’re okay with some investment risk",
                body: "You risked a measured amount of your investment in the activity. The precise amount that you risked tells us that you are reasonably comfortable with taking risk when seeking investment opportunities."
            }
            : {
                title: "You’re okay with investment risk",
                body: "You risked large amounts of your investment in the activity. This tells us that you are comfortable with risk when seeking investment opportunities."
            }
}

export const sensitvityToLossText = (score: number) => {
    return score <= 19
        ? {
            title: "You understand that markets can be volatile",
            body: "While nobody likes short-term losses, your decisions suggest that you’ll stay composed when markets are volatile."
        }
        : score > 19 && score <= 49
            ? {
                title: "You may feel somewhat unsettled when markets are volatile",
                body: "You risked different amounts of money as the gains changed between rounds in the activity. This suggests you might feel somewhat unsettled during periods of market volatility."
            }
            : {
                title: "You may feel unsettled when markets are volatile",
                body: "You put less of your money at risk when the gain that was offered was low. This suggests you might feel unsettled during periods of market volatility."
            }
}

export const portfolioModelSecondaryText = (portfolioModelName: string, growthAssetVal: number) => {
    const portfolioText = [
        { min: 0, max: 15, text: "investors prefer to focus on protecting their money." },
        { min: 15, max: 30, text: "investors prefer to focus on capital preservation." },
        { min: 30, max: 45, text: "investors prefer to limit their short-term volatility and focus on capital preservation." },
        { min: 45, max: 60, text: "investors prefer to limit their short-term volatility while still growing their wealth over the long-term." },
        { min: 60, max: 75, text: "investors are willing to accept some short-term volatility to grow their wealth over the long-term." },
        { min: 75, max: 90, text: "investors are willing to accept short-term volatility to grow their wealth over the long-term." },
        { min: 90, max: 100, text: "investors are comfortable with short-term volatility to grow their wealth over the long-term." },
    ];

    const match = portfolioText.find(({ min, max }) => growthAssetVal >= min && growthAssetVal <= max)
    return match ? `${portfolioModelName} ${match.text}` : ""
};