export const getThresholdRule = (scaleValue: number, step: number) => (threshold: number[]) => {
  return threshold.map((value: number) => scaleValue * (step - 1 + value))
}

export const getStepLevel = (value: number, threshold: number[]): number => {
  return threshold.reduce((step: number, maxValue: number, index: number) => {
    return value > maxValue ? index + 1 : step
  }, 0)
}

export const toFixedNumberByDigit =
  (digit: number = 2) =>
  (input: number) => {
    const baseNumber = Math.pow(10, digit)
    return Math.round(input * baseNumber) / baseNumber
  }

export const rectOptions = {
  default: {
    r: 0,
    fill: "#DCDFF3",
    opacity: 0.7
  },
  goalMatch: {
    r: 0,
    fill: "#E2B640",
    color: "#886811",
    opacity: 1
  },
  comfortMatch: {
    r: 0,
    fill: "#4B9DC8",
    color: "#E2B640",
    opacity: 1
  },
  otherSource: {
    r: 0,
    fill: "#C1C5E4",
    opacity: 1
  }
}

export const getPositions = ({
  value,
  minValue = 0,
  maxValue = 0,
  startPos,
  endPos
}: {
  value: number
  minValue: number
  maxValue: number
  startPos: number
  endPos: number
}): number => {
  const result = ((value - minValue) * (endPos - startPos)) / (maxValue - minValue) + startPos
  return isNaN(result) ? 0 : result
}

export const getComfortOrGoal = (list: any[]) => {
  return list.reduce(
    (
      res: any,
      {
        goalMatch,
        comfortMatch,
        income // merge value + otherSourcesIncome = income
      }: // otherSourcesIncome: otherValue,
      any
    ) => {
      if (goalMatch) {
        res.goal = income
      }
      if (comfortMatch) {
        res.comfort = income
      }
      if (goalMatch && comfortMatch) {
        res.both = true
      }
      return res
    },
    {
      comfort: 0,
      goal: 0,
      both: false
    }
  )
}

export const getXAxisPositions = (startPos: number, currentIndex: number, list: any[]) => {
  return list.reduce((x: number, _: any, index: number, origin: any[]) => {
    if (index && index <= currentIndex) {
      return x + origin[index - 1].width
    }
    return x
  }, startPos)
}

export const getSvg = (props: any, opts?: any) => {
  return opts
    ? { ...props, ...opts }
    : {
        ...props,
        viewBox: `0, 0, ${props.width}, ${props.height}`
      }
}

export const getChart = (props: any, options: any) => {
  const { gutter: { top, left, right, bottom } = {} as any } = options
  const { width, height } = props
  return getSvg(
    {
      width: width - left - right,
      height: height - top - bottom
    },
    {
      x: left,
      // x: 0,
      y: top
    }
  )
}

export const getXAxisPositions1 = ({ index, number, startPos, endPos, boundaryGap, firstBoundaryGap, lastBoundaryGap }: any) => {
  if (endPos > startPos) {
    const w = (endPos - startPos - firstBoundaryGap - lastBoundaryGap - boundaryGap * (number - 1)) / number
    const x = index * (w + boundaryGap)
    return {
      width: w,
      x
    }
  }
  return 0
}