import { format, parse } from "date-fns"
import { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import Loading from "../../components/ClientProfile/Loading/Loading"
import LoadingError from "../../components/ClientProfile/LoadingError/LoadingError"
import { ClientHouseholdCacheContext } from "../../contexts/ClientHouseholdCacheContext"
import useClient from "../../hooks/useClient"
import useHousehold from "../../hooks/useHousehold"
import { validateDob } from "../../lib/clients"
import { DATE_FORMAT } from "../../lib/date"
import { InvestmentGoal } from "../../models/Client"
import errorImage from "../../pages/rmjourney/portfolioComfort/RMJInvestmentGoalModal/assets/error.svg"
import InvestmentGoalChooser from "../clients/components/InvestmentGoal/InvestmentGoalChooser"

type ClientDOB = {
  _id: string
  dob: Date | null
  dobRaw?: string
  name: string
}

const ChooseGoalPage = () => {
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client, isClientLoading, clientLoadError } = useClient(clientId)
  const { household, isHouseholdLoading, householdLoadError } = useHousehold(householdId)
  const loadingError = clientLoadError ?? householdLoadError
  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const navigate = useNavigate()
  const [status, setStatus] = useState<"updating" | "error">()
  const [goalType, setGoalType] = useState<InvestmentGoal>()
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [clientDobs, setClientDobs] = useState<ClientDOB[] | null>(null)

  useEffect(() => {
    setGoalType((client ?? household)?.primaryInvestmentGoal)
  }, [client, household])

  useEffect(() => {
    if (client) {
      setClientDobs([{ _id: client._id, dob: client.dob ? new Date(client.dob) : null, name: client.firstName }])
    } else if (household) {
      setClientDobs(household.members.map(({ client }) => ({ _id: client._id, dob: client.dob ? new Date(client.dob) : null, name: client.firstName })))
    }
  }, [client, household])

  const validate = useCallback(() => {
    if (goalType === "retirementIncome") {
      return clientDobs?.map((c) => {
        if (!c.dob && !c.dobRaw) {
          return "Please select a date"
        } else {
          const parsed = c.dobRaw ? parse(c.dobRaw, DATE_FORMAT, new Date()) : c.dob
          return validateDob(parsed!)
        }
      })
    }
  }, [clientDobs, goalType])

  const onContinue = () => {
    setShouldValidate(true)
    const errs = validate()
    if (goalType && (!errs || Object.values(errs).every((x) => !x))) {
      setStatus("updating")
      const updatePromise = client
        ? updateClient(client._id, {
            primaryInvestmentGoal: goalType,
            ...(goalType === "retirementIncome" ? { dob: format(clientDobs![0].dob!, "yyyy-MM-dd") } : {})
          })
        : updateHousehold(household!._id, goalType === "retirementIncome" ? clientDobs!.map((c) => ({ dob: format(c.dob!, "yyyy-MM-dd") })) : [], {
            primaryInvestmentGoal: goalType
          })
      updatePromise
        .then(() => {
          setStatus(undefined)
          const goalTypeParam =
            goalType === "retirementIncome" ? "retirement-income" : goalType === "retirementDrawdown" ? "retirement-drawdown" : "wealth-accumulation"
          if (client) {
            navigate(`/clients/${client?._id}/explore/goal?goal=${goalTypeParam}`)
          } else {
            navigate(`/households/${household?._id}/explore/goal?goal=${goalTypeParam}`)
          }
        })
        .catch(() => {
          console.error("error updating the goal type")
          setStatus("error")
        })
    }
  }

  return (
    <div className="pg-ctr pg-ctr-py-lg w-full h-full overflow-y-auto no-scrollbar absolute pb-12 flex justify-center items-center">
      {isClientLoading || isHouseholdLoading ? (
        <Loading />
      ) : (
        <>
          {client ?? household ? (
            <div className="flex flex-col items-center max-w-md">
              <h2 className="text-h2 text-main-600 font-semibold text-left mb-6">Let’s take a look at your primary investment goal</h2>
              <p className="text-sec text-main-600 mb-2 w-full text-left">Please select a goal you’d like to start with</p>
              <InvestmentGoalChooser
                client={client}
                household={household}
                goalType={goalType}
                cDobs={clientDobs}
                onGoalChange={(goalType) => {
                  setGoalType(goalType)
                }}
                onDobChange={(clientId, value) => {
                  setClientDobs(clientDobs ? clientDobs.map((item) => (item._id === clientId ? { ...item, dob: value, dobRaw: undefined } : item)) : null)
                }}
                onDobRawChange={(clientId, value) => {
                  setClientDobs(clientDobs ? clientDobs.map((item) => (item._id === clientId ? { ...item, dobRaw: value } : item)) : null)
                }}
                shouldValidate={shouldValidate}
              />
              <div role="alert">
                {((shouldValidate && !goalType) || status === "error") && (
                  <p className="flex items-center gap-1 text-sm text-error mt-2">
                    <img alt="" src={errorImage} aria-hidden />
                    <span className="mt-0.5">{status === "error" ? "Error updating the goal type, please try again" : "Please select a goal"}</span>
                  </p>
                )}
              </div>

              <div className="flex justify-center gap-4 mt-6">
                <button className="btn btn-primary btn-medium w-44" onClick={onContinue} disabled={status === "updating"}>
                  {status === "updating" ? <Loading type="dots" /> : "Continue"}
                </button>
              </div>
            </div>
          ) : (
            <LoadingError message={(loadingError as any)?.response?.data ?? loadingError?.message ?? "Could not load data"} />
          )}
        </>
      )}
    </div>
  )
}

export default ChooseGoalPage
