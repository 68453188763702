import React from "react"
import { useNavigate } from "react-router"
import Modal from "../../components/Modal/Modal"
import { AnimatePresence } from "framer-motion"
import CPLogo from "../../layout/images/CPLogo.svg"
import { useTheme } from "../../contexts/ThemeContext"
import mountain from "./images/mountain.png"

const AuthLayout: React.FC<React.PropsWithChildren<{ title?: string; modal?: JSX.Element }>> = ({ children, modal }) => {
  const navigate = useNavigate()
  const theme = useTheme()
  return (
    <>
      <div className="h-full w-full bg-surface-100 relative">
        <div className="h-full w-full flex items-center justify-end flex-col-reverse lg:flex-row">
          <div className="lg:h-full w-full lg:w-5/12 flex flex-col items-start lg:justify-center justify-start px-16 lg:px-0">
            <main className="h-full w-full flex flex-col items-center lg:justify-center">
              <div className="w-full relative">
                {theme.pages.login?.firmLogo && <img className="absolute left-10 top-7.5 hidden lg:block" src={theme.pages.login?.firmLogo.image} alt={theme.pages.login?.firmLogo.alt ?? "Logo"} />}
              </div>
              {children}
            </main>
            <footer className="px-5 flex gap-[7px] text-sm font-normal pb-5 absolute bottom-0 left-0">
              <img className="h-3.5" src={CPLogo} alt="Powered by Capital Preferences" />
            </footer>
          </div>
          <div className="w-full lg:w-7/12 lg:h-full">
            <img className="h-86 lg:h-full w-full object-center object-cover" src={theme.pages.login?.heroImage ?? mountain} alt="" aria-hidden />
          </div>
        </div>
      </div>
      <AnimatePresence>{modal && <Modal handleClose={() => navigate(-1)}>{modal}</Modal>}</AnimatePresence>
    </>
  )
}

export default AuthLayout
