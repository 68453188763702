import { AxiosError } from "axios"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router"
import { Link } from "react-router-dom"
import axiosInstance from "../api/axiosInstance"
import Loading from "../components/ClientProfile/Loading/Loading"

const RedirectExternalClient = () => {
  const { provider, externalId } = useParams()
  const navigate = useNavigate()
  
  const resolve = useQuery<{ clientId?: string, householdId?: string }, AxiosError<string>>(
    ["client:external", provider, externalId],
    () => axiosInstance.get(`${import.meta.env.VITE_APP_API_BASE}/api/crm/${provider}/client/${externalId}`).then(res => res.data),
    {
      enabled: true,
      retry: false,
      onSuccess: ({ clientId, householdId }) => {
        if (clientId) {
          navigate("/clients/" + clientId)
        } else if (householdId) {
          navigate("/households/" + householdId)
        }
      }
    }
  )
  
  
  return (
    <div className="full-flex-content-center">
      {resolve.isLoading && <Loading />}
      {resolve.isError && (
        <div className="flex flex-col gap-y-12 items-center">
          <div className="text-error">
            {resolve.error.response?.data}
          </div>
          <Link to="/" className="btn btn-primary btn-large">Back To Portal</Link>
        </div>
      )}
    </div>
  )
}

export default RedirectExternalClient