import clsx from "clsx"
import { useState, useEffect, useRef, useCallback, ReactNode } from "react"
import styled from "styled-components"
import leftArrow from "./assets/arrow-left.svg"
import rightArrow from "./assets/arrow-right.svg"

const CarouselDiv = styled.div`
  > div {
    scroll-snap-align: center;
  }
`

type Direction = "left" | "right"

interface CarouselProps {
  children: ReactNode
  disableNav?: boolean
  cardsAlignment?: string
}

const Carousel = ({ children, disableNav = false, cardsAlignment = "left" }: CarouselProps) => {
  const [isScrollable, setIsScrollable] = useState<boolean>(true)
  const [isFarLeft, setIsFarLeft] = useState<boolean>(true)
  const [isFarRight, setIsFarRight] = useState<boolean>(false)

  const carouselRef = useRef<HTMLDivElement>(null)

  const handleScroll = useCallback(() => {
    if (carouselRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current
      const isAtFarLeft = scrollLeft < 1
      const isAtFarRight = scrollWidth - scrollLeft <= clientWidth + 1
      setIsFarLeft(isAtFarLeft)
      setIsFarRight(isAtFarRight)
    }
  }, [])

  const handleResize = useCallback(() => {
    if (carouselRef.current) {
      setIsScrollable((carouselRef.current.scrollWidth - 8) > carouselRef.current.clientWidth)
    }
  }, [])

  const handleNavClick = (direction: Direction) => {
    if (carouselRef.current) {
      const card = carouselRef.current.firstChild as HTMLElement
      const remainder = Math.floor((window.innerWidth - card.offsetWidth - 32) / 2) // how far teaser cards come in
      if (direction === "right") {
        carouselRef.current.scrollLeft += card.offsetWidth + 16 + (isFarLeft && window.innerWidth < 600 ? -remainder : 0)
      } else if (direction === "left") {
        carouselRef.current.scrollLeft -= card.offsetWidth + 16 + (isFarRight && window.innerWidth < 600 ? -remainder : 0)
      }
    }
  }

  useEffect(() => {
    carouselRef.current && isScrollable === null && setIsScrollable(carouselRef.current.scrollWidth > carouselRef.current.clientWidth)
    handleScroll()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [isScrollable, handleResize, handleScroll])

  return (
    <div className="carousel relative h-auto w-full no-scrollbar pr-4" role="region" aria-label="Carousel">
      <CarouselDiv
        ref={carouselRef}
        onScroll={handleScroll}
        className={clsx(
          cardsAlignment === "center" ? "lg:justify-center" : "lg:justify-start",
          "carousel-container w-full p-1 mr-2 relative flex flex-grow overflow-x-auto overscroll-x-contain gap-4 no-scrollbar scroll-snap-x lg:scroll-snap-none scroll-smooth"
        )}
      >
        {children}
      </CarouselDiv>
      {isScrollable && !isFarLeft && (
        <button
          tabIndex={disableNav ? -1 : 0}
          className="carousel-left-btn absolute inset-y-0 left-0"
          onClick={() => handleNavClick("left")}
          aria-label="Scroll left"
        >
          <img src={leftArrow} alt="" aria-hidden />
        </button>
      )}
      {isScrollable && !isFarRight && (
        <button
          tabIndex={disableNav ? -1 : 0}
          className="carousel-right-btn absolute inset-y-0 right-0"
          onClick={() => handleNavClick("right")}
          aria-label="Scroll right"
        >
          <img src={rightArrow} alt="" aria-hidden />
        </button>
      )}
    </div>
  )
}

export default Carousel
