import { useContext, useState } from "react"
import { useNavigate } from "react-router"
import { unlinkHousehold } from "../../../../api/clients"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import { Household } from "../../../../models/Household"
import { AuthContext } from "../../../../views/auth/AuthContext"

const UnlinkHouseholdModal = ({ household, onClose }: { household: Household; onClose: () => void }) => {
  const { sessionInfo } = useContext(AuthContext)
  const navigate = useNavigate()
  const [isUnlinking, setIsUnlinking] = useState<boolean>(false)

  const unlink = () => {
    setIsUnlinking(true)
    unlinkHousehold(sessionInfo!, household._id).then(() => {
      setIsUnlinking(false)
      navigate("/", { replace: true })
    }).catch(err => {
      setIsUnlinking(false)
      console.error("error unlinking accounts", err)
    })
  }

  return (
    <div className="modal-container w-[500px]">
      {isUnlinking ? (
        <div className="flex flex-col justify-center items-center">
          <Loading />
          <p className="mt-6 text-main-500">Unlinking accounts...</p>
        </div>
      ) : (
        <>
          <h2 className="modal-title font-semibold text-h2 text-main-500">Are you sure you want to unlink the {household.name} household?</h2>
          <p className="text-center text-main-400">This cannot be undone</p>
          <div className="flex gap-4 w-full m-auto pt-10">
            <button className="btn btn-secondary btn-medium text-sec flex-1" onClick={onClose}>
              Cancel
            </button>
            <button className="btn btn-primary btn-medium text-sec flex-1 hover:bg-interactive-600 hover:cursor-pointer" onClick={unlink}>
              Yes, continue
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default UnlinkHouseholdModal
