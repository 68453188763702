import { ConfigSet, Firm } from "../models/Firm"
import { AssetClass } from "../models/InvestmentUniverse"
import { ModelPortfolio } from "../models/PortfolioModel"
import { SessionInfo } from "../views/auth/AuthContext"
import axiosInstance from "./axiosInstance"

export type FirmUpdateRequest = {
  logo?: string,
  inflationRate?: number
  assetClasses?: AssetClass[]
  modelPortfolios?: ModelPortfolio[]
  uiConfig?: Partial<ConfigSet>
  clientSupportEmail?: string
}

export const get = (firmId: string) => {
  return axiosInstance
    .get(`${import.meta.env.VITE_APP_API_BASE || ""}/api/firm/${firmId}`)
    .then((res) => res.data as Firm)
}

export const update = async (
  sessionInfo: SessionInfo,
  firmId: string,
  fields: FirmUpdateRequest
) => {
  console.log("firmID", firmId)
  const res = await axiosInstance
    .put(`${import.meta.env.VITE_APP_API_BASE || ""}/api/firm/${firmId}`, fields)
    .then((res) => res.data)
  console.log("res in ts", res)
  return res as Firm
}

