import { format, isValid, parse } from "date-fns"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { deactivate } from "../../../api/userProfiles"
import AlertIcon from "../../../assets/icons/AlertIcon"
import DatePicker from "../../../components/DatePicker/DatePicker"
import SelectAdvisor from "../../../components/SelectAdvisor/SelectAdvisor"
import { AppContext } from "../../../contexts/AppContext"
import { FirmContext } from "../../../contexts/FirmContext"
import useFirmAdvisors from "../../../hooks/useFirmAdvisors"
import useTeamMembersSearch, { TeamMember } from "../../../hooks/useTeamMemberSearch"
import { validate } from "../../../lib/clients"
import { DATE_FORMAT } from "../../../lib/date"
import { Advisor } from "../../../models/Advisor"
import { AuthContext } from "../../../views/auth/AuthContext"
import { ClientErrors } from "../../advisor/CreateIndividualClientPage"
import vector from "../images/Vector.svg"
import Loading from "../../../components/ClientProfile/Loading/Loading"

export interface DeactivateTeamMember {
  email: string
  newAdvisorId?: string
}

const DeactivateTeamMemberModal: React.FunctionComponent<{
  handleClose: () => void
  member: TeamMember
  noOfClients: number | "-"
}> = ({ handleClose, member, noOfClients }) => {
  const { data: advisors } = useFirmAdvisors()
  const { sessionInfo, authStatus } = useContext(AuthContext)
  const { userProfile } = useContext(AppContext)
  const { firm } = useContext(FirmContext)
  const [updatedAdvisor, setUpdatedAdvisor] = useState<Advisor>()
  const [clientUpdateState, setClientUpdateState] = useState<"updating" | "success" | "error" | undefined>()
  const [errors, setErrors] = useState<ClientErrors>({})
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [deactivateUser, setDeactivateUser] = useState<DeactivateTeamMember>()
  const [deactivationDate, setDeactivationDate] = useState<Date | null>(new Date())
  const [deactivationDateRaw, setDeactivationDateRaw] = useState<string | null>(null)
  const { refetch } = useTeamMembersSearch(sessionInfo, authStatus!)
  const isAssociate = member.isAssociate
  const activeAdvisors = useMemo(
    () => (!isAssociate ? advisors?.filter((adv) => adv._id !== member.advisor?._id).filter((adv) => !adv.deletedBy) : []),
    [isAssociate, advisors, member.advisor?._id]
  )
  const hasClients = noOfClients !== 0 && (activeAdvisors?.length ?? 0) > 0

  const deactivateTeamMember = () => {
    setShouldValidate(true)
    deactivate(deactivateUser!, format(deactivationDate!, "yyyy-MM-dd"))
      .then(() => refetch())
      .then(() => setClientUpdateState("success"))
      .then(handleClose)
      .catch((error) => {
        console.error("error de-activating advisor", error)
        setClientUpdateState("error")
      })
  }

  useEffect(() => {
    setDeactivateUser({
      newAdvisorId: !isAssociate ? updatedAdvisor?._id : undefined,
      email: isAssociate ? member.userProfile.email : member.advisor!.email!
    })
  }, [isAssociate, member, updatedAdvisor, userProfile])

  const validateForm = useCallback(() => validate({ advisor: updatedAdvisor }, firm!, member.advisor?._id), [updatedAdvisor, firm, member])

  useEffect(() => {
    if (shouldValidate) {
      const nextErrors = validateForm()
      setErrors(nextErrors)
    }
  }, [updatedAdvisor, shouldValidate, validateForm])

  const isDeactivationDateValid = useMemo(() => {
    const parsed = deactivationDateRaw ? parse(deactivationDateRaw, DATE_FORMAT, new Date()) : deactivationDate
    return parsed ? isValid(parsed) : false
  }, [deactivationDate, deactivationDateRaw])

  return (
    <div className="modal-container w-[600px]" style={{ cursor: "auto" }}>
      <div className="m-auto pb-10 gap-5">
        <img src={vector} alt="warning" aria-hidden />
      </div>
      <h1 className="modal-title">{`Are you sure you want to de-activate ${member.firstName} ${member.lastName}?`}</h1>
      {hasClients && (
        <>
          <div className="flex-1 pt-5 pb-5">
            <div className="flex max-w-75 text-center bg-white border border-interactive-200 shadow-sm p-1 items-center text-sm mb-2">
              <AlertIcon alertType="warning" />
              <p className="pl-2">{`${member.firstName + " " + member.lastName} currently has ${noOfClients} clients`}</p>
            </div>
          </div>
          <SelectAdvisor
            error={errors.advisor}
            label={<span>Select a new primary adviser for these clients</span>}
            onSelect={(value) => setUpdatedAdvisor(value)}
            selectedAdvisor={updatedAdvisor}
            verticalAlign="top"
            advisors={activeAdvisors!}
          />
        </>
      )}
      <div className="mt-4 w-full">
        <DatePicker
          id="deactivate-date"
          label="Confirm de-activation date"
          value={deactivationDate}
          onChange={(value) => {
            setDeactivationDate(value)
          }}
          onChangeRaw={(value) => {
            setDeactivationDateRaw(value)
          }}
          error={!isDeactivationDateValid ? "Please enter a valid de-activation date" : undefined}
        />
      </div>
      <div role="alert">{clientUpdateState === "error" && <div className="text-error">Error de-activating the user</div>}</div>
      <div className="flex pt-5 gap-x-4 w-full max-w-[340px] m-auto">
        <button className="btn btn-secondary btn-medium text-sec flex-1" onClick={handleClose}>
          Cancel
        </button>
        <button
          disabled={clientUpdateState === "updating" || (hasClients && !updatedAdvisor) || !isDeactivationDateValid}
          className="btn btn-primary btn-medium text-sec flex-1 hover:bg-interactive-600 hover:cursor-pointer"
          onClick={() => {
            setClientUpdateState("updating")
            deactivateTeamMember()
          }}
        >
          {clientUpdateState === "updating" ? <Loading type="dots" /> : "Confirm"}
        </button>
      </div>
    </div>
  )
}

export default DeactivateTeamMemberModal
