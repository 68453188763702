import { useCallback, useContext, useEffect, useState } from "react"
import { useMutation } from "react-query"
import checkmark from "../../advisor/assets/images/checkmark-brown.svg"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import TextInput from "../../../components/TextInput/TextInput"
import { ClientHouseholdCacheContext } from "../../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../../contexts/FirmContext"
import { supportedGames } from "../../../lib/clients"
import { validateEmail } from "../../../lib/email"
import { tt } from "../../../lib/translations"
import { Client, GameType } from "../../../models/Client"
import { Household } from "../../../models/Household"
import { ClientUpdate } from "./ProfileOverview/components/EditProfileModal/EditProfileModal"
import { useTheme } from "../../../contexts/ThemeContext"

const SendInvitesModal = ({
  client,
  household,
  onClose,
  onConfirm
}: {
  client?: Client
  household?: Household
  onClose: () => void
  onConfirm: () => void
}) => {
  const { updateClient, updateHousehold, replace } = useContext(ClientHouseholdCacheContext)
  const { firm } = useContext(FirmContext)
  const theme = useTheme()
  const [data, setData] = useState<{ [clientId: string]: ClientUpdate }>({})
  const [error, setError] = useState<string>("")
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [screen, setScreen] = useState<"confirm" | "add-email">("confirm")

  useEffect(() => {
    if (household) {
      setData(
        household.members.reduce((acc, { client }) => {
          acc[client._id] = { email: client.email }
          return acc
        }, {} as { [clientId: string]: ClientUpdate })
      )
    } else if (client) {
      setData({ [client._id]: { email: client.email } })
    }
  }, [client, household])

  useEffect(() => {
    setScreen(client?.email || household?.members.every(({ client }) => client.email) ? "confirm" : "add-email")
  }, [client, household])

  const { mutate: updateInviteEmails, status: updateState } = useMutation({
    mutationFn: () =>
      client
        ? (updateClient(client._id, {
            email: data[client._id].email
          }) as Promise<Client>)
        : (updateHousehold(household!._id, Object.values(data).map((d) => ({ email: d.email })) ?? [], {}) as Promise<Household>),
    onSuccess: (updated: Client | Household) => {
      replace(updated!)
      setScreen("confirm")
    },
    onError: (err) => {
      console.error("Error updating the emails", err)
    }
  })

  const handleSaveChanges = () => {
    if (screen === "add-email") {
      setShouldValidate(true)
      const errs = validateForm()
      if (!errs) {
        updateInviteEmails()
      }
    } else {
      onConfirm()
      onClose()
    }
  }

  const validateForm = useCallback(() => {
    if (Object.values(data).some((d) => !d.email || (d.email?.trim() && !validateEmail(d.email.trim())))) {
      return "Please enter a valid email address"
    }
  }, [data])

  useEffect(() => {
    if (shouldValidate) {
      const nextErrors = validateForm()
      setError(nextErrors ?? "")
    }
  }, [data, shouldValidate, validateForm])

  const areArraysEqual = (arr1: GameType[], arr2: GameType[]) => {
    if (arr1.length !== arr2.length) return false
    return arr1.every((value) => arr2.includes(value))
  }

  const haveSameGameTypes = (household: Household) => {
    return household.members.every(({ client }) => areArraysEqual(supportedGames(client, firm), supportedGames(household.members[0].client, firm)))
  }

  const gameOrder = theme.games.map((game) => game.type)
  const sortGamesByOrder = (games: GameType[]) => {
    return games.sort((a, b) => {
      return gameOrder.indexOf(a) - gameOrder.indexOf(b)
    })
  }

  return (
    <div className="send-invites-modal-wrapper flex flex-col items-start gap-y-6 w-full text-main-500">
      {screen === "add-email" ? (
        <>
          <h2 className="text-h2 text-left font-semibold text-main-600">
            {household
              ? `Please provide ${household.members.map(({ client }) => client.firstName).join(" and ")}'s email addresses`
              : `Please provide ${client!.firstName}'s email address`}
          </h2>
          <p>
            {household
              ? "Each household member will be sent an invite to complete their Economic Fingerprint."
              : `${client!.firstName} will be sent an invite to complete their Economic Fingerprint.`}
          </p>

          {household ? (
            household.members.map(({ client }) => {
              return (
                <TextInput
                  key={client._id}
                  label={`${client.firstName}'s email address`}
                  name="email"
                  onChange={(val) => setData((prev) => ({ ...prev, [client._id]: { email: val } }))}
                  value={data?.[client!._id!].email ?? client?.email}
                  error={error}
                  placeholder="email address"
                />
              )
            })
          ) : (
            <TextInput
              label={`${client!.firstName}'s email address`}
              name="email"
              onChange={(val) => setData((prev) => ({ ...prev, [client!._id]: { email: val } }))}
              value={data?.[client!._id!].email ?? client?.email}
              error={error}
              placeholder="email address"
            />
          )}
        </>
      ) : (
        <>
          <h2 className="text-h2 text-center font-semibold text-main-600">{household ? "Confirm invites" : "Confirm invite"}</h2>
          <p>
            {household
              ? "Each household member will be sent an invite to complete their Economic Fingerprint."
              : `${client!.firstName} will be sent an invite to complete their Economic Fingerprint.`}
          </p>
          <div className="w-full">
            {household && !haveSameGameTypes(household) ? (
              <div className="flex justify-between">
                {household.members.map(({ client }) => (
                  <div className="flex flex-col" key={client._id}>
                    <h3 className="text-sec font-semibold text-main-600">{client.firstName}'s activities</h3>
                    <ul key={client._id}>
                      {sortGamesByOrder(supportedGames(client, firm)).map((game) => {
                        return (
                          <li className="flex items-center justify-between text-main-500 text-p hover:bg-interactive-100 p-1" key={game}>
                            <div className="flex gap-x-3 items-center">
                              <img alt="" className="w-4 h-4" src={checkmark} aria-hidden />
                              {tt({ id: `game-${game}-title` })}
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <h3 className="text-sec font-semibold text-main-600">Activities included</h3>
                <ul>
                  {sortGamesByOrder(household ? supportedGames(household.members[0].client, firm) : supportedGames(client!, firm)).map((game) => {
                    return (
                      <li className="flex items-center justify-between text-main-500 text-p p-1" key={game}>
                        <div className="flex gap-x-3 items-center">
                          <img alt="" className="w-4 h-4" src={checkmark} aria-hidden />
                          {tt({ id: `game-${game}-title` })}
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </>
            )}
          </div>
        </>
      )}
      {updateState === "error" && <div className="text-error text-right">Error updating the client details</div>}
      <div className="modal-foote w-full flex flex-shrink-0 flex-wrap items-center justify-center pt-4">
        <div className="flex gap-5">
          <button onClick={onClose} className="btn btn-secondary btn-medium w-44">
            Cancel
          </button>
          <button
            onClick={handleSaveChanges}
            disabled={updateState === "loading" || (screen === "add-email" && Object.values(data).some((d) => !d.email))}
            className="btn btn-primary btn-medium w-44"
          >
            {updateState === "loading" ? <Loading type="dots" /> : <span className="whitespace-nowrap">{household ? "Send invites" : "Send invite"}</span>}
          </button>
        </div>
      </div>
    </div>
  )
}

export default SendInvitesModal
