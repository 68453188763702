import React, { useContext, useEffect, useMemo, useState } from "react"
import plus from "../images/plus-circle.svg"
import { AssetClass, SubAssetClass } from "../../../models/InvestmentUniverse"
import RadioButton from "../../../components/RadioButton/RadioButton"
import TextInput from "../../../components/TextInput/TextInput"
import trash from "../images/Trash.svg"
import { FirmContext } from "../../../contexts/FirmContext"
import { AnimatePresence } from "framer-motion"
import ErrorDeleteAssetClassModal from "./ErrorDeleteAssetClassModal"
import Modal from "../../../components/Modal/Modal"

const AssetClassModal: React.FunctionComponent<{
  handleClose: () => void
  onCreate: (create: AssetClass) => void
  onEdit: (edit: AssetClass) => void
  currentAssetClass?: AssetClass
  isEdit?: boolean
}> = ({ handleClose, onCreate, onEdit, currentAssetClass, isEdit }) => {
  const { firm } = useContext(FirmContext)
  const [assetClass, setAssetClass] = useState<AssetClass>(isEdit && currentAssetClass ? currentAssetClass! : { name: "", subAssetClasses: [] })
  const [assetClassName, setAssetClassName] = useState<string>(isEdit ? currentAssetClass?.name ?? "" : "")
  const [assetClassType, setAssetClassType] = useState<"growth" | "income" | "cash" | "mixed" | undefined>(isEdit ? currentAssetClass?.type : undefined)
  const [subAssetClasses, setSubAssetClasses] = useState<SubAssetClass[]>(isEdit ? currentAssetClass?.subAssetClasses || [] : [])
  const [errorDeleteAssetClassModal, setErrorDeleteAssetClassModal] = useState<boolean>(false)

  const disable = useMemo(
    () => !assetClassName || (subAssetClasses?.length === 0 ? !assetClassType : subAssetClasses.some((sac) => !sac.name || !sac.type)),
    [subAssetClasses, assetClassName, assetClassType]
  )
  useEffect(() => setAssetClass({
    name: assetClassName,
    type: subAssetClasses?.length ? undefined : assetClassType,
    subAssetClasses: subAssetClasses || []
  }), [assetClassType, assetClassName, subAssetClasses])

  const addSubAssetClass = () => setSubAssetClasses([...subAssetClasses, { name: "" }])

  const submit = (assetClass: AssetClass) => {
    isEdit ? onEdit(assetClass) : onCreate(assetClass)
    handleClose()
  }

  const removeSubAssetClass = (index: number) => {
    setSubAssetClasses(subAssetClasses.filter((_, i) => i !== index))
  }

  const updateSubAssetClass = (subAssetClass: SubAssetClass, index: number) => {
    setSubAssetClasses(subAssetClasses.map((_, i) => i === index ? subAssetClass: _))
  }

  return (
    <div className="asset-class-modal w-full overflow-y-auto no-scrollbar p-2">
      <h1 className="pb-3">{isEdit ? "Edit asset class" : "Create asset class"}</h1>
      <fieldset>
        <TextInput name="assetClassName" disableAutoComplete value={assetClassName} onChange={setAssetClassName} label="Asset class name" focusOnLoad={true} />
        {!subAssetClasses?.length && (
          <div className="pb-5 pt-5">
            <h2>Type</h2>
            <div className="flex gap-8 pt-2">
              <RadioButton
                checked={assetClassType === "growth"}
                id="growth"
                label="Growth"
                name="assetClassType"
                onChange={() => setAssetClassType("growth")}
                value="growth"
              />
              <RadioButton
                checked={assetClassType === "income"}
                id="income"
                label="Income"
                name="assetClassType"
                onChange={() => setAssetClassType("income")}
                value="income"
              />
              <RadioButton
                checked={assetClassType === "cash"}
                id="cash"
                label="Cash"
                name="assetClassType"
                onChange={() => setAssetClassType("cash")}
                value="cash"
              />
            </div>
          </div>
        )}
      </fieldset>
      {subAssetClasses?.length !== 0 && <h2 className="pt-5 pb-3">Sub-asset class</h2>}
      {subAssetClasses.map((sac, index) => {
        const inUse = firm!.modelPortfolios?.some((pf) => pf.components?.some((comp) => comp.id === sac.id && comp.weight !== 0))
        return (
          <div key={index}>
            <div className="pb-2">
              <div className="bg-surface-100 flex px-4 py-2 gap-5">
                <div className="pt-3 w-full">
                  <TextInput
                    name={`name-${index}`}
                    value={sac.name}
                    onChange={(value) => updateSubAssetClass({ ...sac, name: value }, index)}
                    label="Sub-asset name"
                  />
                </div>
                <div className="flex-col pt-3">
                  <fieldset>
                    <h3 className="pb-2">Type</h3>
                    <div className="flex w-75 gap-5">
                      <RadioButton
                        checked={sac.type === "growth"}
                        id={`${index}-growth`}
                        label="Growth"
                        name={`${index}-subAssetClassType`}
                        onChange={() => updateSubAssetClass({ ...sac, type: "growth" }, index)}
                        value="growth"
                      />
                      <RadioButton
                        checked={sac.type === "income"}
                        id={`${index}-income`}
                        label="Income"
                        name={`${index}-subAssetClassType`}
                        onChange={() => updateSubAssetClass({ ...sac, type: "income" }, index)}
                        value="income"
                      />
                      <RadioButton
                        checked={sac.type === "cash"}
                        id={`${index}-cash`}
                        label="Cash"
                        name={`${index}-subAssetClassType`}
                        onChange={() => updateSubAssetClass({ ...sac, type: "cash" }, index)}
                        value="cash"
                      />
                    </div>
                  </fieldset>
                </div>
                <button
                  className="pt-4"
                  onClick={() => (inUse ? setErrorDeleteAssetClassModal(true) : removeSubAssetClass(index))}
                  aria-label="Delete sub asset class"
                >
                  <img className="w-6" alt="delete" src={trash} aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        )
      })}
      <button className="btn btn-text flex pb-5 pt-5 items-center" onClick={addSubAssetClass}>
        <img className="pr-1" src={plus} alt="" aria-hidden="true" />
        Add another sub-asset class
      </button>
      <div className="flex gap-x-4 pt-5 w-full max-w-[340px]">
        <button className="btn btn-secondary btn-medium text-sec flex-1" onClick={handleClose}>
          Cancel
        </button>
        <button
          className="btn btn-primary btn-medium text-sec flex-1 hover:bg-interactive-600 cursor-pointer"
          disabled={disable}
          onClick={() => submit(assetClass)}
        >
          {isEdit ? "Save" : "Create"}
        </button>
      </div>
      <AnimatePresence>
        {errorDeleteAssetClassModal && (
          <Modal handleClose={() => setErrorDeleteAssetClassModal(false)}>
            <ErrorDeleteAssetClassModal handleClose={() => setErrorDeleteAssetClassModal(false)} />
          </Modal>
        )}
      </AnimatePresence>
    </div>
  )
}

export default AssetClassModal
