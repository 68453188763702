import { useMemo } from "react"
import LineChart, { NodeData, XAxisConfig, YAxisConfig } from "../LineChart/LineChart"
import { customDateFormat, formatDate } from "../../../../../../lib/date"
import { Household } from "../../../../../../models/Household"
import add from "date-fns/add"
import eachYearOfInterval from "date-fns/eachYearOfInterval"
import endOfYear from "date-fns/endOfYear"
import min from "date-fns/min"
import startOfYear from "date-fns/startOfYear"
import { differenceInDays } from "date-fns"

interface ChartPoint {
  attitudeToRisk: number
  date: Date
  sensitivityToLoss: number
}

const toolTipFormatter = ({ raw, value }: NodeData) => {
  return (
    <table className="text-xs font-semibold uppercase">
      <tbody>
        <tr>
          <td className="pr-2">Date</td>
          <td>{customDateFormat(raw.date, "MMM yy")}</td>
        </tr>
        <tr>
          <td className="pr-2">Score</td>
          <td>{value}</td>
        </tr>
      </tbody>
    </table>
  )
}

export const xAxisConfig: XAxisConfig = {
  minLabels: 5,
  labels: [],
  title: "Date"
}

export const yAxis: YAxisConfig = {
  labels: [
    {
      id: "label-0",
      text: "0"
    },
    {
      id: "label-1",
      text: "20"
    },
    {
      id: "label-2",
      text: "40"
    },
    {
      id: "label-3",
      text: "60"
    },
    {
      id: "label-4",
      text: "80"
    },
    {
      id: "label-5",
      text: "100"
    }
  ],
  title: "Score"
}

const memberSeriesConfigs = [
  {
    color: "#694589",
    id: "chart-series-1",
    toolTipFormatter
  },
  {
    color: "#CA5CDF",
    id: "chart-series-2",
    toolTipFormatter
  }
]

const HouseholdScoreTrends = ({ household }: { household: Household }) => {
  const data = useMemo(() => {
    const householdCreatedDate = new Date(household.createdAt)
    // get games for each member and filter out incomplete games
    const riskResults = household.members.map(({ client: { games } }) => {
      return (games ?? [])
        .filter(({ played, gameType }) => gameType === "risk" && played && new Date(played) >= householdCreatedDate) // only include games played after household was created
        .map(
          (game) =>
            ({
              attitudeToRisk: game.risk.results?.R?.riskTolerance,
              date: new Date(game.played),
              sensitivityToLoss: game.risk.results?.R?.lossAversion
            } as ChartPoint)
        )
    })

    // create array of all game played dates
    const dates = riskResults.reduce<Date[]>((prev, current) => {
      const dates = current.map(({ date }) => date)
      return [...prev, ...dates]
    }, [])

    // get start of year of the earliest played game (Jan 1)
    const startDate = dates.length ? startOfYear(min(dates)) : startOfYear(new Date())

    // add 4 years and then get the end of the year (Dec 31)
    const endDate = endOfYear(
      add(startDate, {
        years: 4
      })
    )
    const years = eachYearOfInterval({
      start: startDate,
      end: endDate
    })

    const numDays = differenceInDays(startDate, endDate)

    const xAxis = {
      ...xAxisConfig,
      labels: years.map((year, i) => {
        return {
          id: `label-${i}`,
          text: formatDate(year.getTime(), "yyyy")
        }
      }),
      title: "Date"
    }

    return {
      attitude: {
        series: household.members.map(({ client: { _id, firstName } }, i) => {
          const results = riskResults[i]

          return {
            ...memberSeriesConfigs[i],
            title: firstName,
            values: results.map((data: ChartPoint, j) => {
              const { date } = data

              const index = differenceInDays(startDate, date)

              return {
                code: "risk-attitude",
                id: `series-${i}-value-${j}`,
                raw: data,
                x: (100 / numDays) * index,
                y: data.attitudeToRisk
              }
            })
          }
        }),
        xAxis
      },
      lossSensitivity: {
        series: household.members.map(({ client: { _id, firstName } }, i) => {
          const results = riskResults[i]

          return {
            ...memberSeriesConfigs[i],
            title: firstName,
            values: results.map((data: ChartPoint, j) => {
              const { date } = data

              const index = differenceInDays(startDate, date)

              return {
                code: "loss-aversion",
                id: `series-${i}-value-${j}`,
                raw: data,
                x: (100 / numDays) * index,
                y: data.sensitivityToLoss
              }
            })
          }
        }),
        xAxis
      }
    }
  }, [household.createdAt, household.members])

  return (
    <div className="flex flex-col gap-8 items-center mt-6">
      <div className="w-full h-[440px]">
        <LineChart series={data.attitude.series} title="Attitude to Risk" xAxis={data.attitude.xAxis} yAxis={yAxis} />
      </div>
      <div className="w-full h-[440px]">
        <LineChart series={data.lossSensitivity.series} title="Sensitivity to Loss" xAxis={data.lossSensitivity.xAxis} yAxis={yAxis} />
      </div>
    </div>
  )
}

export default HouseholdScoreTrends
