export const toFixedNumberByDigit =
  (digit = 2) =>
    (input: number) => {
      const baseNumber = Math.pow(10, digit)
      return Math.round(input * baseNumber) / baseNumber
    }

export interface ShortNumRule {
  min: number
  max?: number
  step: number
  decimal: number
  places: number
  unit: string
  value?: number
  handler: (amount: number, rule: ShortNumRule) => any | undefined
}

interface LongNumRule {
  min: number
  max?: number
  step: number
  handler: (amount: number, rule: LongNumRule) => number
}

const longNumbersHandler = (amount: number, { min, max, step }: LongNumRule) => {
  const val = Math.round(amount / step) * step

  return (max === undefined && min <= amount) || (max && min <= amount && amount < max) ? val : 0
}

const shortNumbersHandler = (amount: number, rule: ShortNumRule) => {
  const { min, max, step, decimal, places = 1 } = rule

  if (min || max) {
    const num = (Math.round(amount / step) * step) / places
    const value = decimal > 0 ? toFixedNumberByDigit(decimal)(num) : num
    if ((max === undefined && min <= amount) || (max && min <= amount && amount < max)) {
      return { value, ...rule }
    }
  }
}

const longNumbersRules: LongNumRule[] = [
  {
    min: 0,
    max: 10000,
    step: 10,
    handler: longNumbersHandler
  },
  {
    min: 10000,
    max: 25000,
    step: 50,
    handler: longNumbersHandler
  },
  {
    min: 25000,
    max: 100000,
    step: 100,
    handler: longNumbersHandler
  },
  {
    min: 100000,
    max: 250000,
    step: 500,
    handler: longNumbersHandler
  },
  {
    min: 250000,
    max: 1000000,
    step: 1000,
    handler: longNumbersHandler
  },
  {
    min: 1000000,
    max: 2500000,
    step: 5000,
    handler: longNumbersHandler
  },
  {
    min: 2500000,
    max: 10000000,
    step: 10000,
    handler: longNumbersHandler
  },
  {
    min: 10000000,
    step: 50000,
    handler: longNumbersHandler
  }
]

const shortNumbersRules: ShortNumRule[] = [
  {
    min: 0,
    max: 1000,
    step: 10,
    handler: shortNumbersHandler,
    decimal: 0,
    places: 1,
    unit: ""
  },
  {
    min: 1000,
    max: 10000,
    step: 10,
    decimal: 2,
    places: 1000,
    unit: "K",
    handler: shortNumbersHandler
  },
  {
    min: 10000,
    max: 25000,
    step: 50,
    decimal: 1,
    places: 1000,
    unit: "K",
    handler: shortNumbersHandler
  },
  {
    min: 25000,
    max: 100000,
    step: 100,
    decimal: 1,
    places: 1000,
    unit: "K",
    handler: shortNumbersHandler
  },
  {
    min: 100000,
    max: 250000,
    step: 500,
    decimal: 1,
    places: 1000,
    unit: "K",
    handler: shortNumbersHandler
  },
  {
    min: 250000,
    max: 1000000,
    step: 1000,
    decimal: 0,
    places: 1000,
    unit: "K",
    handler: shortNumbersHandler
  },
  {
    min: 1000000,
    max: 10000000,
    step: 10000,
    decimal: 2,
    places: 1000000,
    unit: "M",
    handler: shortNumbersHandler
  },
  {
    min: 10000000,
    max: 100000000,
    step: 100000,
    decimal: 1,
    places: 1000000,
    unit: "M",
    handler: shortNumbersHandler
  },
  {
    min: 100000000,
    max: 1000000000,
    step: 1000000,
    decimal: 0,
    places: 1000000,
    unit: "M",
    handler: shortNumbersHandler
  },
  {
    min: 1000000000,
    max: 25000000000,
    step: 100000000,
    decimal: 1,
    places: 1000000000,
    unit: "B",
    handler: shortNumbersHandler
  },
  {
    min: 25000000000,
    max: 1000000000000,
    step: 1000000000,
    decimal: 0,
    places: 1000000000,
    unit: "B",
    handler: shortNumbersHandler
  },
  {
    min: 1000000000000,
    step: 1000000000000,
    decimal: 1,
    places: 1000000000000,
    unit: "T",
    handler: shortNumbersHandler
  }
]

// TODO? this may be a candidate to be exposed if different rules are req'd
const longRoundByDefaults = (rules: LongNumRule[]) => (amount: number) =>
  rules.reduce((result, rule) => {
    const num = rule.handler(amount, rule)
    return Math.abs(num) > 0 ? num : result
  }, 0)

export const longRound = longRoundByDefaults(longNumbersRules)

// TODO? this may be a candidate to be exposed if different rules are req'd
const shortRoundByDefaults = (rules: ShortNumRule[]) => (amount: number) =>
  rules.reduce<ShortNumRule>(
    (result, rule) => {
      const val = rule.handler(amount, rule)
      return val ? val : result
    },
    { value: 0 } as ShortNumRule
  )

export const shortRound = shortRoundByDefaults(shortNumbersRules)

export const periodToAnnual: { [key: string]: number } = {
  annually: 1,
  quarterly: 4,
  monthly: 12,
  fortnightly: 26,
  weekly: 52
}
