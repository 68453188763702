import { useParams } from "react-router"
import Loading from "../../components/ClientProfile/Loading/Loading"
import LoadingError from "../../components/ClientProfile/LoadingError/LoadingError"
import { Helmet } from "react-helmet-async"
import useHousehold from "../../hooks/useHousehold"
import HouseholdProfile from "../households/components/HouseholdProfile/HouseholdProfile"

const HouseholdStatusPage = () => {
  const { householdId } = useParams<{ householdId: string }>()
  const { household, isHouseholdLoading: isLoading, householdLoadError: error } = useHousehold(householdId!)
  return (
    <>
      <Helmet>
        <title>
          {import.meta.env.VITE_APP_TITLE ?? "Adviser Portal"}
          {household ? " - " + household.name : ""}
        </title>
      </Helmet>
      <div className="pg-ctr pg-ctr-py-lg w-full h-full overflow-y-auto no-scrollbar absolute pb-12">
        {isLoading
          ? <Loading />
          : household
            ? <HouseholdProfile household={household} />
            : error
              ? <LoadingError message={error?.toString()}/>
              : <>Oops.</>
        }
      </div>
    </>
  )
}

export default HouseholdStatusPage
