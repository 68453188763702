import React, { UIEventHandler, useContext } from "react"
import { AuthContext } from "../../../views/auth/AuthContext"

const Signout: React.FunctionComponent<{ handleClose: UIEventHandler }> = ({ handleClose }) => {
  const { signOut } = useContext(AuthContext)
  return (
    <div className="modal-container">
      <h2 className="modal-title font-semibold text-h2 text-main-500">Are you sure you want to log out?</h2>
      <div className="text-main-400 text-center">You will be returned to the login screen.</div>
      <div className="flex gap-4 w-full max-w-[340px] m-auto pt-10">
        <button className="btn btn-secondary btn-medium text-sec flex-1" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn btn-primary btn-medium text-sec flex-1 hover:bg-interactive-600 hover:cursor-pointer" onClick={signOut}>
          Yes, log out
        </button>
      </div>
    </div>
  )
}

export default Signout
