import hero from "../../../../assets/icons/info.svg"

const ImportReminderModal: React.FunctionComponent<{ handleClose: () => void; handleConfirm: () => void }> = ({ handleClose, handleConfirm }) => {
  return (
    <div className="import-reminder flex flex-col items-center text-center gap-6">
      <img className="w-25 h-25" src={hero} alt="" aria-hidden />
      <h2 className="text-h3 font-semibold">It looks like you're not fully set up</h2>
      <p>Would you like to import your clients to streamline risk profiling for your book of business?</p>
      <div className="flex gap-5">
        <button className="btn btn-secondary btn-medium w-44" onClick={handleClose}>Not now</button>
        <button className="btn btn-primary btn-medium w-44" onClick={handleConfirm}>Import clients</button>
      </div>
    </div>
  )
}

export default ImportReminderModal
