import { AxiosError } from "axios"
import { useState } from "react"
import { useMutation, useQuery } from "react-query"
import axiosInstance from "../../../../api/axiosInstance"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import SelectNew from "../../../../components/Select/SelectNew"
import { Firm } from "../../../../models/Firm"
import Licensee from "../../../../models/Licensee"
import { FirmStats } from "./ListFirms"
import PortfolioTableDisplay from "../../../rmjourney/components/PortfolioTableDisplay/PortfolioTableDisplay"

type FirmUpdate = Partial<Omit<Firm, "licensee"> & { licensee: Licensee }>

const CopyPortfolios = ({ firm, whenDone = () => {} }: { firm: Firm; whenDone?: () => any }) => {
  const [copyFrom, setCopyFrom] = useState<FirmUpdate>()

  const updateFirm = useMutation<unknown, AxiosError<{ message?: string } | string | undefined>, FirmUpdate>(
    () =>
      axiosInstance.put(`${import.meta.env.VITE_APP_API_BASE || ""}/api/firm/${firm._id}/copy-portfolios?from=${copyFrom!._id}`, {}).then((res) => res.data),
    {
      onSuccess: () => {
        setCopyFrom(undefined)
        whenDone()
      }
    }
  )

  const firms = useQuery(
    ["firms"],
    () =>
      axiosInstance
        .get<(Omit<Firm, "licensee"> & FirmStats & { licensee: Licensee })[]>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/firms`)
        .then((res) => res.data),
    {
      staleTime: 0
    }
  )

  return (
    <div className="pl-10 flex flex-col gap-y-6">
      <SelectNew
        className="w-full max-w-md place-self-start"
        label="Choose the firm you want to copy the portfolios from"
        data={firms.data}
        selected={firms.data?.find((opt) => opt._id === copyFrom?._id)}
        match={(o, s) => o?._id.toLowerCase().indexOf(s) >= 0}
        onSelect={(firm) => setCopyFrom(firm!)}
        isSelected={(opt) => opt._id === copyFrom?._id}
        autoFocusSearch
        renderLabel={(code, sel) => {
          const selected = sel ? firms.data?.find((opt) => opt._id === sel._id) : undefined
          return code ? (
            <div className="grid gap-x-2 grid-cols-[6rem,1fr] -mx-4 px-4">
              <div className="text-sec h-full flex items-center justify-stretch">{code._id}</div>
              <div className="text-p h-full flex items-center justify-stretch">{code.name}</div>
            </div>
          ) : selected ? (
            <div className="flex gap-x-2 items-baseline">
              <div className="text-sm text-neutral-500">{selected._id}</div>
              <div>{selected.name}</div>
            </div>
          ) : (
            <></>
          )
        }}
      />

      <button
        className="btn btn-primary btn-medium w-48"
        disabled={updateFirm.isLoading || !copyFrom}
        onClick={() => {
          if (copyFrom) {
            updateFirm.mutate(copyFrom)
          }
        }}
      >
        {updateFirm.isLoading ? <Loading type="dots" /> : <>Copy</>}
      </button>
      {updateFirm.isLoading && <Loading />}
      <div role="alert">
        {updateFirm.isError && <p className="text-error">Error copying the portfolios</p>}
        {updateFirm.isSuccess && <p className="text-positive-600">Successfully copied the portfolios</p>}
      </div>
      {!updateFirm.isLoading && !updateFirm.isError && firm.assetClasses && firm.modelPortfolios && (
        <div className="w-full overflow-auto">
          <PortfolioTableDisplay assetClasses={firm.assetClasses} portfolioMappings={firm.modelPortfolios.map((mp) => ({ portfolio: mp }))} />
        </div>
      )}
    </div>
  )
}

export default CopyPortfolios
