import React, { createContext, FunctionComponent, useContext } from 'react'
import { useQuery } from 'react-query'
import axiosInstance from "../api/axiosInstance"
import Loading from '../components/ClientProfile/Loading/Loading'
import { ConfigSet, Firm } from '../models/Firm'
import { AuthContext, AuthStatus } from '../views/auth/AuthContext'
import { AppContext } from './AppContext'
import { AxiosError } from "axios"

interface FirmContextType {
  firm?: Firm
  reloadFirm: () => void
  isLoading: boolean
  isSuccess?: boolean
  error?: { message?: string } | null
}

export const defaults: FirmContextType = {
  isLoading: false,
  isSuccess: false,
  reloadFirm: () => {}
}

export const FirmContext = createContext(defaults)


const FirmContextProvider: React.FunctionComponent<{ children: any }> = ({ children }) => {
  const { authStatus, sessionInfo } = useContext(AuthContext)
  const { firmId } = useContext(AppContext)

  const {
    isLoading,
    isSuccess,
    error,
    data: firm,
    refetch: reloadFirm
  } = useQuery<Firm | undefined, AxiosError>(
    ["firm", firmId],
    () =>
      axiosInstance.get<Firm>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/firm/${firmId}`)
        .then((res) => res.data)
        .then((firm) => ({
          ...firm,
          uiConfig: {
            ...(firm.uiConfig ?? {} as ConfigSet)
          }
        })),
    {
      enabled: authStatus === AuthStatus.SignedIn && !!sessionInfo?.accessToken && !!firmId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 900
    }
  )

  return (
    <FirmContext.Provider
      value={{
        firm,
        reloadFirm,
        isLoading,
        isSuccess,
        error
      }}
    >
      {children}
    </FirmContext.Provider>
  )
}

export const WaitForFirm: FunctionComponent<{ children: JSX.Element }> = ({ children }) => {
  const { firmId } = useContext(AppContext)
  const { isLoading, isSuccess, error } = useContext(FirmContext)
  const { signOut } = useContext(AuthContext)

  return firmId
    ? isLoading
      ? <Loading />
      : error
        ? <div className="full-flex-content-center gap-y-5">
            <div>Error loading the firm: {error.message}</div>
            <div className="flex items-center justify-center gap-x-4">
              <a href="mailto:customer-support@capitalpreferences.com" className="btn btn-primary btn-large">Contact support</a>
              <button className="btn btn-secondary btn-large" onClick={() => signOut()}>Sign Out</button>
            </div>
          </div>
        : isSuccess
          ? <>{children}</>
          : <div className="full-flex-content-center">No firm</div>
    
    : <>{ children }</>
    
}

export default FirmContextProvider