import { useContext, useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import axiosInstance from "../api/axiosInstance"
import { AppContext } from "../contexts/AppContext"
import { ClientHouseholdCacheContext } from "../contexts/ClientHouseholdCacheContext"
import { Household } from "../models/Household"
import { AuthContext, AuthStatus } from "../views/auth/AuthContext"

const useHousehold = (desiredHouseholdId?: string) => {

  const { authStatus, sessionInfo } = useContext(AuthContext)
  const { firmId } = useContext(AppContext)
  const { cache, replace } = useContext(ClientHouseholdCacheContext)
  const [householdId, setHouseholdId] = useState<string|undefined>(desiredHouseholdId)

  const {
    isLoading,
    isSuccess,
    error,
    refetch: reloadHousehold
  } = useQuery<Household, { message?: string }>(
    ["household", householdId],
    () =>
      axiosInstance
        .get<Household>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/household/${householdId}`)
        .then(res => res.data),
    {
      enabled: authStatus === AuthStatus.SignedIn && !!sessionInfo?.accessToken && !!firmId && !!householdId && !cache[householdId],
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 900,
      onSuccess: (data) => {
        replace(data)
      }
    }
  )


  useEffect(() => {
    if (householdId !== desiredHouseholdId) {
      setHouseholdId(desiredHouseholdId)
    }
  }, [householdId, desiredHouseholdId, setHouseholdId])
  

  const merged = useMemo(() => householdId ? cache[householdId] as Household : undefined, [cache, householdId])

  return {
    householdId,
    setHouseholdId,
    household: merged,
    isHouseholdLoading: isLoading,
    isHouseholdLoadedSuccessfully: isSuccess,
    householdLoadError: error,
    reloadHousehold
  }
}

export default useHousehold
