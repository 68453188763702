import { useState, useRef, ChangeEventHandler, DragEventHandler } from "react"

export function useImportModal() {
  const [selectedFile, setSelectedFile] = useState<File | null>()
  const [dragActive, setDragActive] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const [importStatus, setImportStatus] = useState<"importing" | "success" | "partiallySuccess" | "error" | "validationError">()
  const [isInvalidFile, setIsInvalidFile] = useState(false)

  const allowedExtensions = /(\.xlsx|\.xls|\.csv)$/i
  const handleFile = (files: FileList) => {
    if (files && files.length > 0) {
      setIsInvalidFile(false)
      if (!allowedExtensions.exec(files.item(0)!.name)) {
        setImportStatus("validationError")
        setIsInvalidFile(true)
        setSelectedFile(null)
      } else {
        setImportStatus(undefined)
        setSelectedFile(files.item(0))
      }
    }
  }

  const handleDrag: DragEventHandler<HTMLElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave") {
      setDragActive(false)
    }
  }

  const handleDrop: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer?.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files)
    }
  }

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files)
    }
  }

  const onButtonClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click()
    }
  }

  return {
    selectedFile,
    setSelectedFile,
    dragActive,
    setDragActive,
    inputRef,
    importStatus,
    setImportStatus,
    isInvalidFile,
    setIsInvalidFile,
    handleFile,
    handleDrag,
    handleDrop,
    handleChange,
    onButtonClick,
  }
}