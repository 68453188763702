import { useEffect, useMemo, useState } from "react"
import LineChart, { NodeData, Series, XAxisConfig, YAxisConfig } from "../../../../../../components/ClientProfile/LineChart/LineChart"
import { customDateFormat, formatDate } from "../../../../../../lib/date"
import { ScoreTrend } from "../../../../../../models/ScoreTrend"

const toolTipFormatter = ({ value, code }: NodeData) => {
  let label
  if (code === "risk-attitude") {
    label = value < 33 ? "Low Risk" : (value < 60 ? "Medium Risk" : "High Risk")
  } else if (code === "loss-aversion") {
    label = value < 20 ? "Avg Sensitivity" : (value < 50 ? "High Sensitivity" : "Very High Sensitivity")
  }
  return `${value} - ${label}`
}

export const xAxisConfig: XAxisConfig = {
  minLabels: 10,
  labels: [],
  title: "Date"
}

export const yAxis: YAxisConfig = {
  labels: [
    {
      id: "label-0",
      text: "0"
    },
    {
      id: "label-1",
      text: "20"
    },
    {
      id: "label-2",
      text: "40"
    },
    {
      id: "label-3",
      text: "60"
    },
    {
      id: "label-4",
      text: "80"
    },
    {
      id: "label-5",
      text: "100"
    }
  ],
  title: "Score"
}

const series1Config = {
  color: "#6DA2DA",
  id: "chart-series-1",
  title: "Attitude to Risk",
  toolTipFormatter
}

const series2Config = {
  color: "#E26969",
  id: "chart-series-2",
  title: "Sensitivity to Loss",
  toolTipFormatter
}

const ScoreTrends = ({ riskResults }: { riskResults: ScoreTrend[] }) => {
  const results = useMemo(() => riskResults?.slice(-xAxisConfig.minLabels), [riskResults])

  const [series, setSeries] = useState<Series[]>(() => {
    return [
      {
        ...series1Config,
        values: results.map(({ attitudeToRisk }, i) => ({
          id: `series-0-value-${i}`,
          code: 'risk-attitude',
          value: attitudeToRisk
        }))
      },
      {
        ...series2Config,
        values: results.map(({ sensitivityToLoss }, i) => ({
          id: `series-0-value-${i}`,
          code: 'loss-attitude',
          value: sensitivityToLoss
        }))
      }
    ]
  })
  const [xAxis, setXAxis] = useState<XAxisConfig>(() => {
    return {
      ...xAxisConfig,
      values: results.map(({ date }, i) => ({
        id: `label-${i}`,
        text: date && customDateFormat(date)
      }))
    }
  })

  useEffect(() => {
    setSeries((prev) => {
      return [
        {
          ...prev[0],
          values: results.map(({ attitudeToRisk }, i) => ({
            id: `series-0-value-${i}`,
            code: 'risk-attitude',
            value: attitudeToRisk
          }))
        },
        {
          ...prev[1],
          values: results.map(({ sensitivityToLoss }, i) => ({
            id: `series-0-value-${i}`,
            code: 'loss-aversion',
            value: sensitivityToLoss
          }))
        }
      ]
    })
    setXAxis((prev) => {
      return {
        ...prev,
        labels: results.map(({ date }, i) => ({
          id: `label-${i}`,
          text: date && formatDate(date, "dd MMM yy")
        }))
      }
    })
  }, [results])

  return (
    <div className="h-[500px] flex flex-col items-center">
      <LineChart series={series} title="Timeseries score for Attitude to Risk and Sensitivity to Loss" xAxis={xAxis} yAxis={yAxis} />
    </div>
  )
}

export default ScoreTrends
