import { useNavigate, useParams } from "react-router"
import useClient from "../../../hooks/useClient"
import useHousehold from "../../../hooks/useHousehold"
import arrowLeft from "../assets/images/arrow-left.svg"
import SidebarHeader from "../components/SidebarHeader/SidebarHeader"

const RMDecisionsSideBar = () => {
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client } = useClient(clientId)
  const { household } = useHousehold(householdId)
  const navigate = useNavigate()

  return (
    <div className="w-80 h-full bg-interactive-600 flex flex-1 flex-col justify-between">
      {(client || household) && (
        <>
          <div className="flex gap-x-2.5 overflow-y-auto no-scrollbar">
            <div className="flex flex-col">
              {client && <SidebarHeader client={client} />}
              {household && <SidebarHeader household={household} />}
              <div className="sidebar-section">
                <h3 className="sidebar-section-title text-sec">Your risk decisions</h3>
                <p className="sidebar-section-text text-sec">
                  Your decisions in the activity provide us with a good understanding of how you think about taking investment risk.
                </p>
              </div>
              <div className="sidebar-section">
                <h3 className="sidebar-section-title">Discuss</h3>
                {client && <p className="sidebar-section-text text-sec">What were you thinking about as you made your decisions in the activity?</p>}
                {household && <p className="sidebar-section-text text-sec">What were you each thinking about as you made your decisions in the activity?</p>}
              </div>
            </div>
          </div>
          <div className="px-4 pb-4 w-full flex justify-between items-center">
            <div onClick={() => navigate(-1)} className="cursor-pointer w-10">
              <img className="mt-3" src={arrowLeft} alt="arrow left icon" />
            </div>
            <button className="btn btn-medium btn-primary-alt mt-4 h-14 grow" onClick={() => navigate("../risk-attitude")}>
              Next
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default RMDecisionsSideBar
