import styled from "styled-components"
import { useTheme } from "../../../../contexts/ThemeContext"
import { ReactNode } from "react"

const Wrapper = styled.div<{ waterMark: string }>`
  height: 1122px;
  position: relative;
  @media print {
    page-break-before: always;
    -webkit-print-color-adjust: exact;
  }
  ${({ waterMark }) => {
    return (
      waterMark &&
      `&::before {
      content: '';
      position: absolute;
      top: 0%;
      left: 0%;
      transform-origin: 0 0;
      transform: translate(50%, 55%);
      width: 50%;
      height: 50%;
      background-image: url(${waterMark});
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 0.5;
      z-index: 5;
    }`
    )
  }}
`

const Container = ({ children, className, id }: { children: ReactNode; className: string; id?: string }) => {
  const theme = useTheme()

  const waterMark = theme.reports?.waterMark ?? ""

  return (
    <Wrapper id={id} className={className} waterMark={waterMark}>
      {children}
    </Wrapper>
  )
}

export default Container
