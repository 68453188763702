import clsx from "clsx"
import { ReactNode } from "react"

const Popup = ({
  alignVertical = "bottom",
  children,
  overlayClassName,
  align = "right"
}: {
  alignVertical?: "bottom" | "top"
  children: ReactNode
  overlayClassName?: string
  align?: "left" | "right"
}) => {
  return (
    <div
      className={clsx(
        "absolute left-0 z-10 shadow bg-white transition-[height] duration-1000 delay-100 ease-in-out transform",
        {
          "left-auto right-0": align === "left",
          "top-full": alignVertical === "bottom",
          "top-0 -translate-y-full": alignVertical === "top"
        },
        overlayClassName
      )}
    >
      {children}
    </div>
  )
}

export default Popup
