import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

let isRefreshing = false;
const failedQueue: Array<{config: any, resolve: (token: string) => void, reject: (error: Error) => void}> = [];

const processQueue = async (token?: string | null, error?: Error) => {
  while (failedQueue.length > 0) {
    const { config, resolve, reject } = failedQueue.shift()!;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      try {
        const response = await axiosInstance(config);
        resolve(response as any);
      } catch (err) {
        reject(err as any);
      }
    } else {
      reject(error as any);
    }
  }
};

const refreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await axios.post(`${import.meta.env.VITE_APP_API_BASE}/api/auth/refresh`, { refreshToken });
    if (response.status === 200) {
      const { accessToken } = response.data;
      localStorage.setItem("accessToken", accessToken);
      return accessToken;
    }
  } catch (error) {
    console.error("Failed to refresh token:", error);
    window.dispatchEvent(new CustomEvent('logout'));
    throw error;
  }
};

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401) {
      if (originalRequest._retry) {
        window.dispatchEvent(new CustomEvent('logout'));
        return Promise.reject(error);
      }

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({config: originalRequest, resolve, reject});
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const newToken = await refreshToken();
        await processQueue(newToken);
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        await processQueue(undefined, refreshError as Error);
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
