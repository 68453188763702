import { i18n } from "@lingui/core"
import { useTheme } from "../../../../contexts/ThemeContext"
import { Client } from "../../../../models/Client"
import { Firm } from "../../../../models/Firm"
import { Household } from "../../../../models/Household"
import { clientTopCause } from "../../components/Profile/causeScores"
import Container from "../components/Container"
import Footer from "../components/Footer"
import SustainabilityValues from "../components/SustainabilityValues/SustainabilityValues"
import ValuesMindset from "../components/ValuesMindset/ValuesMindset"
import clsx from "clsx"
import { useMemo } from "react"
import { tt } from "../../../../lib/translations"
import { Trans } from "@lingui/react"
import { latest } from "../../../../lib/clients"

const SustainabilityValuesPage = ({ page, firm, client, household }: { page: number; firm: Firm; client: Client; household: Household }) => {
  const theme = useTheme()
  const topCause = clientTopCause(client, theme)
  const game = latest(client, "esg")
  const altruismScore = Object.values(game?.esg?.results?.S2 ?? {})?.[1]?.score
  const altruismLevel = altruismScore < 33 ? "low" : altruismScore < 67 ? "medium" : "high"
  const cause = useMemo(() => (altruismLevel === "low" ? theme.causes?.S2?.cause0 : topCause), [altruismLevel, theme.causes?.S2?.cause0, topCause])
  const highlightPrimaryCause = Object.values(theme.causes?.S1 ?? {}).some((c) => c.id === cause?.id)
  const showValuesSection = theme.reports?.valuesMindset?.show
  const hasSdgItemsIcon = topCause?.learn?.devGoals?.items.find((item) => !!item.icon)

  return (
    <Container id="sustainability-values-page" className="flex flex-col justify-between relative">
      <div className="relative flex items-start">
        <div className={clsx("w-full", { "bg-neutral-50": highlightPrimaryCause })}>
          {showValuesSection && (
            <div className="flex gap-x-10 py-11 px-10">
              {highlightPrimaryCause ? (
                <ValuesMindset game={game!} altruismLevel={altruismLevel} />
              ) : (
                <SustainabilityValues client={client} altruismLevel={altruismLevel} />
              )}
            </div>
          )}
        </div>
      </div>

      <div className="flex-auto mx-10">
        <div className="mt-8">
          <h2 className="mb-2 text-main-600 text-sec tracking-wide uppercase">
            {
              /* lingui-extract-ignore */
              i18n._({ id: `report-sustainability-values-investing-${topCause?.id}-title` })
            }
          </h2>
          <h3 className="font-semibold text-main-600 text-h4 mb-6">Here are some themes you might look for in your investments</h3>
          <ul className="list-disc leading-6 ml-5 mb-12">
            {topCause?.items?.map((item, i) => (
              <li key={`${topCause.id}-item-${i}`}>{item.title}</li>
            ))}
          </ul>
          {(topCause?.learn?.devGoals?.items ?? [])?.length > 0 && (
            <>
              <p className="mb-6">
                {tt({
                  id: `report-sustainability-values-${topCause?.id}-sdg-title`,
                  message: "This area of interest aligns with the following United Nations Sustainable Development Goals (UN SDG):"
                })}
              </p>
              <ul className={clsx("mb-2 leading-6", hasSdgItemsIcon ? "list-none flex flex-col gap-y-2" : "list-disc ml-5")}>
                {topCause?.learn?.devGoals?.items.map((item, i) => (
                  <li className={item.icon && "flex p-0"} key={i}>
                    {item.icon && <img alt="" src={item.icon} className="h-5 w-5 mr-2" aria-hidden />}
                    {item.description}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
      {theme.reports?.sustainabilityValues?.selectedInterests?.show && (client.interests && client.interests.length > 0) && (
        <div className="absolute bottom-86 w-full">
          <div className="selected-interests flex justify-between items-start mx-10 mb-9 border border-highlight-300/100 bg-highlight-300/10 rounded-2 px-10 py-9">
            <div className="flex flex-col gap-6">
              <h3 className="text-h4">You told us you're interested in the following...</h3>
              <ul className="list-disc pl-6">
                {client.interests.map((interest) => (
                  <li key={interest}>
                    <Trans id={`selected-interests-${interest}`} />
                  </li>
                ))}
              </ul>
            </div>
            {theme.reports?.sustainabilityValues?.selectedInterests?.image && (
              <img src={theme.reports?.sustainabilityValues?.selectedInterests.image} alt="" aria-hidden />
            )}
          </div>
        </div>
      )}
      <div className="h-full flex items-end">
        {highlightPrimaryCause ? (
          <div className="w-full h-86 bg-cover" style={{ backgroundImage: `url(${topCause?.report?.mindsetHeroImage})` }}>
            <div className="mx-9 h-full flex items-end">
              <Footer pageNum={page} client={client} household={household} firm={firm} className="relative !text-white" borderClassName="!border-white" />
            </div>
          </div>
        ) : (
          <div className="w-full h-20 relative mx-10">
            <Footer pageNum={page} client={client} household={household} firm={firm} />
          </div>
        )}
      </div>
    </Container>
  )
}

export default SustainabilityValuesPage
