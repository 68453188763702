import { useContext } from "react"
import { useQuery } from "react-query"
import axiosInstance from "../api/axiosInstance"
import { AppContext } from "../contexts/AppContext"
import { AdvisorInfo } from "../pages/firm/AdvisorsListPage"
import { AuthContext, AuthStatus } from "../views/auth/AuthContext"

const useFirmAdvisors = () => {
  const { firmId } = useContext(AppContext)
  const { sessionInfo, authStatus } = useContext(AuthContext)

  const { isLoading, isSuccess, error, data } = useQuery<AdvisorInfo[], { message?: string }>(
    ["firmAdvisors", firmId, sessionInfo?.accessToken],
    () =>
      axiosInstance
        .get<AdvisorInfo[]>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/firm/${firmId}/advisors`)
        .then((res) => res.data),
    {
      enabled: authStatus === AuthStatus.SignedIn && !!sessionInfo?.accessToken && !!firmId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  )

  return {
    isLoading,
    isSuccess,
    error,
    data
  }
}

export default useFirmAdvisors
