import { useMemo } from "react"
import { useTheme } from "../../../../../../contexts/ThemeContext"
import { Client, Score } from "../../../../../../models/Client"
import CauseScores, { BarScore } from "./CauseScores"
import { causeScoresForProfile, clientTopCause } from "../../causeScores"

export interface Results {
  S1: {
    [key: string]: Score
  }
  impactScore: number
  rho: number
  topCause: string
}

const PersonaSummary = ({ client }: { client: Client }) => {
  const theme = useTheme()
  const topCause = useMemo(() => clientTopCause(client, theme), [client, theme])
  const persona = topCause?.persona
  const scores: BarScore[] = useMemo(() => causeScoresForProfile(client, theme), [client, theme])

  return (
    <>
      <div className="flex items-center justify-between pb-6">
        <div className="w-2/3 flex gap-2 items-center">
          {persona?.icon && <img className="w-12" src={persona.icon} alt="" aria-hidden />}
          <h2 className="text-h3 font-semibold">{client.firstName} is a {persona?.name}</h2>
        </div>
      </div>
      <div className="flex justify-between gap-10">
        <div className="w-2/3 flex flex-col gap-2">
          <p className="font-normal text-p leading-5">
            {persona?.description}
            {persona?.link && <a target="_blank" rel="noreferrer" href={persona.link} className="link-text ml-1" aria-label={`Read more about ${client.firstName}'s persona`}>Read more</a>}
          </p>
          <CauseScores scores={scores} />
        </div>
        <div className="w-1/3">
          <img className="w-full" src={persona?.image} alt="" aria-hidden />
        </div>
      </div>
    </>
  )
}

export default PersonaSummary