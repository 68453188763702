import { ChangeEvent, useState } from "react"

const RMJNextMeetingSelection = ({ onSelect }: {onSelect: (selected: number) => void}) => {
  const [nextMeetingDate, setNextMeetingDate] = useState<number>(12)

  const onSelectMeeting = (evt: ChangeEvent<HTMLInputElement>) => {
    const nextMeeting = parseInt(evt.target.value)
    setNextMeetingDate(nextMeeting)
    onSelect(nextMeeting)
  }

  return (
    <div className="flex gap-1">
      {[3, 6, 12].map((value) => (
        <label className="relative h-7 flex items-center px-5 z-0" htmlFor={`${value}-months`} key={`${value}-months`}>
          <input
            checked={value === nextMeetingDate}
            className="appearance-none absolute left-0 top-0 w-full h-full cursor-pointer outline-none border border-interactive-400 rounded-full z-0 focus-visible:shadow-focus checked:bg-interactive-100 checked:hover:bg-interactive-100 hover:bg-interactive-100"
            id={`${value}-months`}
            name="next-meeting"
            onChange={onSelectMeeting}
            type="radio"
            value={value}
          />
          <span className="text-sec text-interactive-500 font-semibold pointer-events-none z-10">{value} months</span>
        </label>
      ))}
    </div>
  )
}

export default RMJNextMeetingSelection
