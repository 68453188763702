import { Trans } from "@lingui/macro"
import clsx from "clsx"
import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import chevronDown from "../../../../assets/icons/chevron-down.svg"
import { useTheme } from "../../../../contexts/ThemeContext"
import DecimalInput from "../../../firm/components/DecimalInput"
import remove from "../../assets/images/delete.svg"
import undo from "../../assets/images/undo.svg"
import Tooltip from "./Tooltip"
import Dropdown from "../../../../components/Dropdown/Dropdown"
import Checkbox from "../../../../components/Checkbox/Checkbox"
import { FirmContext } from "../../../../contexts/FirmContext"
export interface FundData {
  id?: string
  ticker?: string
  fundName?: string
  assetClass?: string
  sustainabilityMatchScore?: number
  currentAllocation?: number
  proposedAllocation?: number
  status?: string
}

type SorterFunction = (members: FundData[]) => FundData[]

type Sorters = {
  [key: string]: SorterFunction
}

const FundsTable = ({
  fundTableData,
  isEditing,
  isLoading,
  setFundData,
  forReport = false,
  onFilter,
  assetClassFilters = [],
  statusFilters = [],
  tabRef,
  parentRef,
  showDisclaimer
}: {
  fundTableData: FundData[] | undefined
  isEditing?: boolean
  isLoading?: boolean
  setFundData?: React.Dispatch<React.SetStateAction<FundData[]>>
  forReport?: boolean
  onFilter?: (filter: { assetClasses?: string[]; statuses?: string[] }) => void
  assetClassFilters?: string[]
  statusFilters?: string[]
  tabRef?: React.RefObject<HTMLDivElement>
  parentRef?: React.RefObject<HTMLDivElement>
  showDisclaimer?: boolean
}) => {
  const theme = useTheme()
  const { firm } = useContext(FirmContext)
  const [screenType, setScreenType] = useState<"laptop" | "tablet" | "desktop">("laptop")
  const fundtableRef = useRef<HTMLDivElement>(null)
  const [isColumnSticky, setIsColumnSticky] = useState(false)
  const chevronDownIcon = theme.pages.portfolioAnalyser?.fundAllocation.icons.chevronDown ?? chevronDown
  const percentageIcon = theme.pages.portfolioAnalyser?.fundAllocation.icons.percentageIcon
  const filterIcon = theme.pages.portfolioAnalyser?.fundAllocation.icons.filterIcon ?? chevronDown
  const deleteIcon = remove ?? theme.pages.portfolioAnalyser?.fundAllocation.icons.deleteIcon
  const undoIcon = undo ?? theme.pages.portfolioAnalyser?.fundAllocation.icons.undoIcon

  const onChangeHandler = useCallback(
    (id: string, val?: number) => {
      const updateFundData = fundTableData?.map((funds) =>
        id === funds.id
          ? !funds.currentAllocation
            ? { ...funds, proposedAllocation: val, status: "Added" }
            : val === funds.currentAllocation
            ? { ...funds, proposedAllocation: val, status: "" }
            : { ...funds, proposedAllocation: val, status: val === 0 ? "Removed" : "Updated" }
          : funds
      )

      setFundData?.(updateFundData!)
    },
    [fundTableData, setFundData]
  )

  const removeFunds = useCallback(
    (id: string) => {
      setFundData?.(fundTableData!.filter((funds) => id !== funds.id))
    },
    [fundTableData, setFundData]
  )

  const undoFunds = useCallback(
    (id: string) => {
      const undoFund = fundTableData?.map((funds) => (id === funds.id ? { ...funds, status: "", proposedAllocation: funds.currentAllocation } : funds))
      setFundData?.(undoFund!)
    },
    [fundTableData, setFundData]
  )

  useEffect(() => {
    const localRef = fundtableRef.current
    const divResizeObserver = new ResizeObserver((e) => {
      setScreenType(e[0].contentRect.width <= 650 ? "tablet" : e[0].contentRect.width <= 1050 ? "laptop" : "desktop")
    })
    divResizeObserver.observe(localRef!)
    return () => {
      divResizeObserver.unobserve(localRef!)
    }
  }, [])

  const stickyColumnHeader = useCallback(() => {
    if (!fundtableRef.current || !tabRef?.current || !parentRef?.current) return

    const tabsPosition = tabRef?.current.getBoundingClientRect().top
    const tablePosition = fundtableRef.current.getBoundingClientRect().top
    const scrollThreshold = tabsPosition + tablePosition - 200

    setIsColumnSticky(parentRef.current.scrollTop >= scrollThreshold)
  }, [parentRef, tabRef])

  useEffect(() => {
    const localRef = parentRef?.current
    localRef?.addEventListener("scroll", stickyColumnHeader)
    return () => {
      localRef?.removeEventListener("scroll", stickyColumnHeader)
    }
  }, [parentRef, stickyColumnHeader, tabRef])

  const [sort, setSort] = useState<string>()
  const [sortDirection, setSortDirection] = useState<"DESC" | "ASC">("DESC")
  const sortingFunction = useMemo(() => {
    const sorter: Sorters = {
      ticker: (funds: FundData[]) =>
        [...(funds ?? [])].sort((a, b) => {
          const tickerA = a.ticker ?? ""
          const tickerB = b.ticker ?? ""
          if (!tickerA || !tickerB) {
            return sortDirection === "DESC" ? (tickerA ? 1 : -1) : tickerA ? -1 : 1
          }
          return sortDirection === "DESC" ? tickerB.localeCompare(tickerA) : tickerA.localeCompare(tickerB)
        }),
      name: (funds: FundData[]) =>
        [...(funds ?? [])].sort((a, b) =>
          sortDirection === "DESC" ? (a.fundName ?? "").localeCompare(b.fundName ?? "") : (b.fundName ?? "").localeCompare(a.fundName ?? "")
        ),
      match: (funds: FundData[]) =>
        [...(funds ?? [])].sort((a, b) =>
          sortDirection === "DESC"
            ? (a.sustainabilityMatchScore ?? 0) - (b.sustainabilityMatchScore ?? 0)
            : (b.sustainabilityMatchScore ?? 0) - (a.sustainabilityMatchScore ?? 0)
        ),
      currentAllocation: (funds: FundData[]) =>
        [...(funds ?? [])].sort((a, b) =>
          sortDirection === "DESC" ? (a.currentAllocation ?? 0) - (b.currentAllocation ?? 0) : (b.currentAllocation ?? 0) - (a.currentAllocation ?? 0)
        ),
      proposedAllocation: (funds: FundData[]) =>
        [...(funds ?? [])].sort((a, b) =>
          sortDirection === "DESC" ? (a.proposedAllocation ?? 0) - (b.proposedAllocation ?? 0) : (b.proposedAllocation ?? 0) - (a.proposedAllocation ?? 0)
        )
    }
    return sorter[sort!] || ((funds: FundData[]) => funds)
  }, [sort, sortDirection])

  const [isAssetMenuOpen, setIsAssetMenuOpen] = useState<boolean>(false)
  const assetBtn = useRef<HTMLButtonElement>(null)
  const assetClasses = firm?.assetClasses?.map((assetClass) => assetClass.name) ?? []

  const [isStatusMenuOpen, setIsStatusMenuOpen] = useState<boolean>(false)
  const statusBtn = useRef<HTMLButtonElement>(null)
  const statuses = ["Added", "Removed", "Updated"]

  useEffect(() => {
    setFundData?.((prevFunds: FundData[]) => {
      const sortedFunds = sortingFunction(prevFunds || [])
      return sortedFunds
    })
  }, [sortingFunction, setFundData])

  const onStatusChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target
    if (checked) {
      onFilter?.({ statuses: [...(statusFilters ?? []), value], assetClasses: assetClassFilters })
    } else {
      onFilter?.({ statuses: [...(statusFilters ?? []).filter((a) => !(a === value))], assetClasses: assetClassFilters })
    }
  }

  const onAssetClassChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target
    if (checked) {
      onFilter?.({ assetClasses: [...(assetClassFilters ?? []), value], statuses: statusFilters })
    } else {
      onFilter?.({ assetClasses: [...(assetClassFilters ?? []).filter((a) => !(a === value))], statuses: statusFilters })
    }
  }

  const filteredFunds = useMemo(() => {
    if (!fundTableData) return []
    return fundTableData.filter((fund) => {
      const passesAssetFilter = assetClassFilters?.length === 0 || assetClassFilters?.includes(fund.assetClass || "")
      const passesStatusFilter = statusFilters?.length === 0 || statusFilters?.includes(fund.status || "")
      return passesStatusFilter && passesAssetFilter
    })
  }, [assetClassFilters, fundTableData, statusFilters])

  const clearAllFilters = () => {
    onFilter?.({ assetClasses: [], statuses: [] })
  }

  return (
    <div
      ref={fundtableRef}
      className={clsx("text-sec relative pr-3 flex flex-col-reverse", {
        "overflow-x-scroll": screenType === "tablet",
        "pb-10": !forReport
      })}
      role="grid"
    >
      <div
        className={clsx("h-full", screenType === "tablet" && "min-w-[1000px]", {
          "overflow-y-auto overflow-x-hidden no-scrollbar": isColumnSticky && !forReport
        })}
      >
        {filteredFunds?.length === 0 && (
          <div className="flex items-center justify-center my-10">
            <p className="font-medium">
              No funds meet the specified filter(s).
              <button className="btn-text" onClick={() => clearAllFilters()}>
                Clear filter items.
              </button>
            </p>
          </div>
        )}
        {filteredFunds?.map(({ ticker, fundName, assetClass, sustainabilityMatchScore, currentAllocation, proposedAllocation, status, id }) => {
          return (
            <div
              role="row"
              key={id}
              className={clsx("grid grid-cols-[repeat(18,minmax(0,1fr))] gap-4 border-b border-b-neutral-100 font-normal", {
                "[&>*:not(:last-child)]:line-through": status === "Removed", // No decoration for the last column.
                "[&>*:nth-child(n+6)]:no-underline": isEditing
              })}
            >
              <p role="gridcell" className="py-5 col-start-1">
                {!ticker ? "-" : ticker}
              </p>
              <p
                role="gridcell"
                className={clsx("py-5 ml-4 truncate lg-fluid-max:col-span-5", {
                  "col-span-5": screenType === "laptop" || screenType === "tablet",
                  "col-span-6": screenType === "desktop",
                  "italic": !fundName
                })}
              >
                {!fundName ? "undefined" : fundName}
              </p>
              <p role="gridcell" className={clsx("py-5 col-span-2 ml-0 lg-fluid-max:text-center", forReport && "w-max")}>
                {!fundName ? "-" : assetClass}
              </p>
              <p
                role="gridcell"
                className={clsx("py-5 col-span-3 bg-data-olive-100 lg-fluid-max:text-center xl:col-span-3 xl:mx-0", {
                  "-ml-0": screenType === "laptop",
                  "mx-4": screenType === "desktop",
                  "ml-4.5 w-full pl-2.5": forReport,
                  "px-4.5": !forReport
                })}
              >
                {!fundName || sustainabilityMatchScore === 0 ? "-" : `${sustainabilityMatchScore}%`}
              </p>
              <p role="gridcell" className={clsx("py-5 col-span-2 lg-fluid-max:text-center", forReport ? "ml-3" : "ml-2")}>
                {!currentAllocation ? "-" : `${currentAllocation?.toFixed(2)}%`}
              </p>
              <div
                role="gridcell"
                className={clsx("proposed-allocation-column col-span-2 lg-fluid-max:col-span-3 lg-fluid-max:text-center", {
                  "-mx-2 col-span-2": screenType === "tablet",
                  "col-span-3 w-4/5": screenType === "laptop",
                  "ml-1 px-2.5": forReport,
                  "px-4": !forReport
                })}
              >
                {!isEditing || !fundName ? (
                  <p className="py-5">{proposedAllocation === 0 ? "-" : proposedAllocation ? `${proposedAllocation?.toFixed(2)}%` : currentAllocation}</p>
                ) : (
                  <div className="flex h-full items-center justify-center">
                    <DecimalInput
                      inputFieldClassName="px-3 py-2"
                      disableAutoComplete
                      error={
                        isEditing && !!fundName && status !== "Removed" && proposedAllocation !== 0 && (proposedAllocation! > 25 || proposedAllocation! < 0.25)
                      }
                      name={`proposed-allocation-${id}`}
                      value={proposedAllocation ? parseFloat(proposedAllocation!.toFixed(2)) : !fundName ? currentAllocation : proposedAllocation}
                      suffix={
                        percentageIcon ? <img src={percentageIcon} alt="" aria-hidden /> : <label className="text-sec font-bold text-black">%</label>
                      }
                      onChange={(val) => onChangeHandler(id!, val)}
                      isDisabled={isLoading || !fundName}
                    />
                  </div>
                )}
              </div>
              <div
                role="gridcell"
                className={clsx("self-center flex justify-between gap-4 relative col-span-2 w-32", {
                  "-ml-8": screenType === "laptop",
                  "-ml-5": forReport
                })}
              >
                <div className="absolute -left-4 -top-1 lg:-left-5" role="alert">
                  {isEditing &&
                    !!fundName &&
                    status !== "Removed" &&
                    proposedAllocation !== 0 &&
                    proposedAllocation !== null &&
                    (proposedAllocation! > 25 ? (
                      <Tooltip text="Too much allocated" />
                    ) : proposedAllocation! < 0.25 || proposedAllocation === 0 ? (
                      <Tooltip text="Too little allocated" />
                    ) : (
                      <></>
                    ))}
                </div>
                <p
                  className={clsx("py-1 px-2 text-sm font-medium", {
                    "ml-3": forReport,
                    "bg-data-olive-400 rounded-xl py-1 px-2 w-min": status === "Added",
                    "bg-red-400 rounded-xl py-1 px-2 w-min": status === "Removed",
                    "fund-status-updated rounded-xl py-1 px-2 w-min": status === "Updated"
                  })}
                >
                  {status}
                </p>
                {isEditing &&
                  (status === "Added" ? (
                    <img onClick={() => removeFunds(id!)} className="w-4 mr-4 cursor-pointer" src={deleteIcon} alt="" aria-hidden />
                  ) : status === "Removed" ? (
                    <img onClick={() => undoFunds(id!)} className="w-4 mr-4 cursor-pointer" src={undoIcon} alt="" aria-hidden />
                  ) : (
                    <></>
                  ))}
              </div>
            </div>
          )
        })}
        {showDisclaimer &&
          <div className="mt-2 text-xxs">
            <span><Trans id="funds-match-source-citation">Source: Calculated on MSCI Analytics</Trans></span>
          </div>
        }
      </div>
      <div
        role="row"
        className={clsx("w-full bg-white grid grid-cols-[repeat(18,minmax(0,1fr))] gap-4 font-medium", {
          "min-w-[1000px]": screenType === "tablet",
          "sticky top-0": isColumnSticky
        })}
      >
        <div role="columnheader" className="w-min flex items-center">
          <button
            aria-label="Sort by ticker"
            className="w-full pr-4 py-3 flex gap-1 items-center"
            onClick={() => {
              setSort("ticker")
              setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC")
            }}
          >
            <Trans id="portfolio-analyser-fund-allocation-table-column-heading-1">Ticker</Trans>
            {!forReport && (
              <img className={clsx("w-3 h-3", { "rotate-180": sort === "ticker" && sortDirection === "DESC" })} src={filterIcon} alt="" aria-hidden />
            )}
          </button>
        </div>
        <div
          role="columnheader"
          className={clsx("flex items-center ml-4 lg-fluid-max:col-span-5", {
            "col-span-5": screenType === "laptop" || screenType === "tablet",
            "col-span-6": screenType === "desktop"
          })}
        >
          <button
            aria-label="Sort by fund name"
            className="w-full py-3 flex gap-1 items-center"
            onClick={() => {
              setSort("name")
              setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC")
            }}
          >
            <Trans id="portfolio-analyser-fund-allocation-table-column-heading-2">Funds</Trans>
            {!forReport && (
              <img className={clsx("w-3 h-3", { "rotate-180": sort === "name" && sortDirection === "DESC" })} src={filterIcon} alt="" aria-hidden />
            )}
          </button>
        </div>
        <div
          role="columnheader"
          className={clsx("relative flex items-center lg-fluid-max:justify-center col-span-2", screenType === "laptop" || forReport ? "w-max" : "w-auto")}
        >
          <button
            className="w-full py-3 flex gap-1 items-center lg-fluid-max:justify-center"
            onClick={() => setIsAssetMenuOpen(!isAssetMenuOpen)}
            ref={assetBtn}
          >
            <div className="flex items-center">
              <Trans id="portfolio-analyser-fund-allocation-table-column-heading-3">Asset class</Trans>
              {!forReport && (assetClassFilters?.length ?? 0) > 0 && (
                <div className="funds-filter-badge relative ml-1 inline-block w-3.5 h-3.5 px-1.5 text-center rounded-full bg-highlight-600 items-center justify-center text-white">
                  <span className="absolute top-0 left-1 text-[9px] font-bold">{assetClassFilters?.length}</span>
                </div>
              )}
            </div>
            {!forReport && <img className="w-2 h-2" src={chevronDownIcon} alt="" aria-hidden />}
          </button>
          {isAssetMenuOpen && (
            <Dropdown className="m-4" trigger={assetBtn} overlayClassName="w-[150px]" handleClose={() => setIsAssetMenuOpen(false)}>
              <ul className="list-none mx-4 mt-4">
                {assetClasses.map((assetClass) => (
                  <li className="w-full hover:bg-interactive-100 cursor-pointer" key={assetClass}>
                    <Checkbox
                      name={assetClass}
                      label={assetClass}
                      onChange={onAssetClassChange}
                      className="filter-box__checkbox w-full py-1.5"
                      value={assetClass}
                      checked={assetClassFilters && assetClassFilters.includes(assetClass)}
                    />
                  </li>
                ))}
              </ul>
              <button
                className="w-full text-left btn-text p-4 mb-4"
                onClick={() => {
                  onFilter?.({ assetClasses: [], statuses: statusFilters })
                  setIsAssetMenuOpen(false)
                }}
              >
                Clear filters
              </button>
            </Dropdown>
          )}
        </div>
        <div
          role="columnheader"
          className={clsx("flex items-center bg-data-olive-100 xl:col-span-3 xl:mx-0 gap-2 col-span-3", {
            "-ml-0": screenType === "laptop",
            "mx-4": screenType === "desktop",
            "ml-4.5 w-full": forReport
          })}
        >
          <button
            aria-label="Sort by sustainability match"
            className={clsx("w-full py-3 flex gap-1 items-center text-left lg-fluid-max:justify-center", !forReport ? "px-4" : "px-2")}
            onClick={() => {
              setSort("match")
              setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC")
            }}
          >
            <Trans id="portfolio-analyser-fund-allocation-table-column-heading-4">Sustainability match</Trans>
            {!forReport && (
              <img className={clsx("w-3 h-3", { "rotate-180": sort === "match" && sortDirection === "DESC" })} src={filterIcon} alt="" aria-hidden />
            )}
          </button>
        </div>
        <div role="columnheader" className={clsx("w-full ml-1 flex items-center lg-fluid-max:justify-center col-span-2", forReport && "ml-3")}>
          <button
            aria-label="Sort by current allocation"
            className="py-3 w-full flex gap-1 items-center text-left lg-fluid-max:justify-center"
            onClick={() => {
              setSort("currentAllocation")
              setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC")
            }}
          >
            <Trans id="portfolio-analyser-fund-allocation-table-column-heading-5">Current allocation</Trans>
            {!forReport && (
              <img
                className={clsx("w-3 h-3", { "rotate-180": sort === "currentAllocation" && sortDirection === "DESC" })}
                src={filterIcon}
                alt=""
                aria-hidden
              />
            )}
          </button>
        </div>
        <div
          role="columnheader"
          className={clsx("proposed-allocation-column col-span-2 flex items-center lg-fluid-max:justify-center lg-fluid-max:col-span-3 lg-fluid-max:px-0", {
            "-mx-2 col-span-2": screenType === "tablet",
            "col-span-3 w-4/5": screenType === "laptop",
            "ml-1": forReport
          })}
        >
          <button
            aria-label="Sort by proposed allocation"
            className={clsx("py-3 w-full flex gap-1 items-center text-left lg-fluid-max:justify-center", !forReport ? "px-4" : "px-2")}
            onClick={() => {
              setSort("proposedAllocation")
              setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC")
            }}
          >
            <Trans id="portfolio-analyser-fund-allocation-table-column-heading-6">Proposed allocation</Trans>
            {!forReport && (
              <img
                className={clsx("w-3 h-3", { "rotate-180": sort === "proposedAllocation" && sortDirection === "DESC" })}
                src={filterIcon}
                alt=""
                aria-hidden
              />
            )}
          </button>
        </div>
        <div
          role="columnheader"
          className={clsx("relative flex gap-2 items-center", {
            "-ml-8": screenType === "laptop",
            "auto-cols-max": forReport
          })}
        >
          <button
            ref={statusBtn}
            className={clsx("w-full py-3 flex items-center gap-1", forReport && "pl-3")}
            onClick={() => setIsStatusMenuOpen(!isStatusMenuOpen)}
          >
            <Trans id="portfolio-analyser-fund-allocation-table-column-heading-7">Status</Trans>
            {!forReport && (statusFilters?.length ?? 0) > 0 && (
              <div className="funds-filter-badge relative ml-1 inline-block w-3.5 h-3.5 px-1.5 text-center rounded-full bg-highlight-600 items-center justify-center text-white">
                <span className="absolute top-0 left-1 text-[9px] font-bold">{statusFilters?.length}</span>
              </div>
            )}
            {!forReport && <img className="w-2 h-2" src={chevronDownIcon} alt="" />}
          </button>
          {isStatusMenuOpen && (
            <Dropdown className="m-4 overflow-visible" trigger={statusBtn} overlayClassName="w-[150px]" handleClose={() => setIsStatusMenuOpen(false)}>
              <ul className="list-none mx-4 mt-4">
                {statuses.map((status) => (
                  <li className="w-full hover:bg-interactive-100 cursor-pointer" key={status}>
                    <Checkbox
                      name={status}
                      label={status}
                      onChange={onStatusChange}
                      className="filter-box__checkbox w-full py-1.5"
                      value={status}
                      checked={statusFilters && statusFilters.includes(status)}
                    />
                  </li>
                ))}
              </ul>
              <button
                className="w-full text-left btn-text p-4 mb-4"
                onClick={() => {
                  onFilter?.({ statuses: [], assetClasses: assetClassFilters })
                  setIsStatusMenuOpen(false)
                }}
              >
                Clear filters
              </button>
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  )
}

export default FundsTable
