import { NavLink, Outlet, Route, Routes } from "react-router-dom"
// import UpdateLicensee from "./UpdateLicensee"
import clsx from "clsx"
import ViewTopic from "./ViewTopic"



const KafkaBrowser = () => {

  const linkStyle = ({ isActive }: { isActive: boolean }) => clsx("px-4 border-b-2 transition-all hover:bg-interactive-100 py-2", isActive ? "border-interactive-600" : "border-white hover:border-interactive-200")
  
  return (<div className="pg-ctr pg-ctr-py-lg w-full h-full flex flex-col gap-y-4 pt-10 pl-4" >
    <h1 className="px-10 text-h1">Browse Kafka Topics</h1>
    <nav className="w-full -ml-4 px-10 flex text-h5 text-interactive-600">
      <NavLink to="matlab_calc_req"         end className={linkStyle}>matlab_calc_req</NavLink>
      <NavLink to="matlab_calc_res"         end className={linkStyle}>matlab_calc_res</NavLink>
      <NavLink to="client_game_events"      end className={linkStyle}>client_game_events</NavLink>
      <NavLink to="client_pdf_gen_events"   end className={linkStyle}>client_pdf_gen_events</NavLink>
      <NavLink to="auth_events"             end className={linkStyle}>auth_events</NavLink>
      <NavLink to="client_updates"          end className={linkStyle}>client_updates</NavLink>
      <NavLink to="household_updates"       end className={linkStyle}>household_updates</NavLink>
      <NavLink to="help"                    end className={_ => clsx(linkStyle(_), "ml-auto text-alt-critical")}>Help</NavLink>
    </nav>
    <div className="w-full h-full overflow-y-hidden">
      <Outlet />
      <Routes>
        <Route path="matlab_calc_req"       element={<ViewTopic topic="matlab_calc_req" />}/>
        <Route path="matlab_calc_res"       element={<ViewTopic topic="matlab_calc_res" />}/>
        <Route path="client_game_events"    element={<ViewTopic topic="client_game_events" />}/>
        <Route path="client_pdf_gen_events" element={<ViewTopic topic="client_pdf_gen_events" />}/>
        <Route path="auth_events"           element={<ViewTopic topic="auth_events" />}/>
        <Route path="client_updates"        element={<ViewTopic topic="client_updates" />}/>
        <Route path="household_updates"     element={<ViewTopic topic="household_updates" />}/>
        <Route path="help"                  element={<div className="full-flex-content-center">Browse topics and replay messages.</div>}/>
      </Routes>
    </div>
  </div>
  )
}

export default KafkaBrowser