import clsx from "clsx"
import { PropsWithChildren, useEffect, useMemo, useState } from "react"
import { UseQueryResult, useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"
import axiosInstance from "../../../../api/axiosInstance"
import checkmarkCircle from "../../../../assets/icons/checkmark-circle.svg"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import TextInput from "../../../../components/TextInput/TextInput"

type LicenseeData = {
  name: string
}

const UpdateLicensee: React.FC<PropsWithChildren<{ licensee: UseQueryResult<{_id: string, name: string } | undefined> }>> = ({ licensee }) => {

  const { licenseeId } = useParams()
  const [name, setName] = useState<string>("")
  const isValid = useMemo(() => !!name, [name])

  const licensees = useQuery(["licensees"], () => axiosInstance.get<{_id: string, name: string}[]>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/licensees`)
    .then(res => res.data))

  const updateLicensee = useMutation<any, any, { licenseeData: LicenseeData }>(["licensee", licenseeId], async ({ licenseeData }) => {
    return axiosInstance.put(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/licensees/${licenseeId}`, licenseeData)
      .then(res => {
        licensees.refetch()
        licensee.refetch()
        return res.data
      })
  })

  useEffect(() => {
    
    if (updateLicensee.isSuccess) {
      const t = setTimeout(() => {
        updateLicensee.reset()
      }, 2000)
    
      return () => clearTimeout(t)
    }
  }, [updateLicensee.isSuccess, updateLicensee])
  

  useEffect(() => {
    if (licensees.isLoading) {
      setName("")
    }
    if (licensees.isSuccess) {
      const licensee = licensees.data.find(l => l._id === licenseeId)
      setName(licensee?.name || "")
    }
  }, [licensees.data, licensees.isLoading, licensees.isSuccess, licenseeId])
  
  useEffect(() => {
    if (licensees.isLoading || updateLicensee.isSuccess) setName("")
  }, [licenseeId, licensees.isLoading, updateLicensee.isSuccess])


  const isNameDuplicate = useMemo(
    () => !licensees.isLoading && licensees.data?.find(l => l.name === name && l._id !== licenseeId),
    [licenseeId, licensees.data, licensees.isLoading, name]
  )
  const isIllegalName = useMemo(() => !/^[\wA-Za-z0-9'"._\-$@&, ]*$/.test(name), [name])
    

  return (<div className="w-full h-full overflow-y-auto flex flex-col gap-y-4 pt-1 pl-10">
    <div className="w-full lg:w-2/3">
      <div className="mt-4 pr-8 flex gap-x-8">
        <div className="w-full flex flex-col items-start gap-y-8">
          <label className="font-bold w-full">
            Name:
            <TextInput name="name" value={name} onChange={setName}
              error={isNameDuplicate ? "Duplicate name" : isIllegalName ? "Illegal character(s) in name" : null}
            />
          </label>
        </div>
      </div>
      <p className="mt-12 text-sec">
        This will change the name of the licensee with ID <span className="font-semibold">{licenseeId}</span><br/>
        from <span className="font-semibold">{licensees.data?.find(l => l._id === licenseeId)?.name}</span> to <span className="font-semibold">{name}</span>.
      </p>
      <div className="w-full flex mt-8 gap-x-2">
        <button
          className={clsx("btn w-64 px-4 py-2", isValid && !updateLicensee.isLoading ? "btn-primary" : "btn-secondary")}
          disabled={!(name && !isNameDuplicate && !isIllegalName && licensees.data?.find(l => l._id === licenseeId)?.name !== name?.trim())}
          onClick={isValid && !updateLicensee.isLoading
            ? () => updateLicensee.mutate({ licenseeData: { name } })
            : () => {}
          }>{updateLicensee.isLoading ? <>Updating...</> : <>Update</>}</button>
          <span>
            {updateLicensee.isLoading && <Loading />}
            {updateLicensee.isSuccess && <span className="flex gap-x-1 text-positive-600"><img src={checkmarkCircle} /> Updated.</span>}
          </span>
      </div>
      {updateLicensee.isError && <p className="mt-4 text-error">{updateLicensee.error?.toString()} ({updateLicensee.error?.response?.data?.message})</p>}
    </div>
  </div>)
}

export default UpdateLicensee