import clsx from "clsx"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ClientUpdateRequest } from "../../../../api/clients"
import { HouseholdUpdateRequest } from "../../../../api/households"
import { computeGoalAchievability } from "../../../../api/rm/goals"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../../../contexts/FirmContext"
import { Client, InvestmentGoal } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import { AuthContext, SessionInfo } from "../../../../views/auth/AuthContext"
import switchImage from "../assets/switch.svg"
import SelectPortfolio, { createOptions, Options } from "../components/SelectPortfolio/SelectPortfolio"
import {
  createFormValues,
  createWealthAccumulationRequest,
  validateWealthAccumulationForm,
  WealthAccumulationFormErrors,
  WealthAccumulationFormValues
} from "./WealthAccumulationUtils"
import WealthAccumulationForm from "./WealthAccumulationForm"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import { GoalExplorerStatuses } from "../utils/validation"
import Checkbox from "../../../../components/Checkbox/Checkbox"
import Dropdown from "../../../../components/Dropdown/Dropdown"
import chevronDown from "../../../../assets/icons/chevron-down.svg"
import { FEATURE_WEALTH_AND_INCOME_RANGE } from "../../../../config/features"
import { AnimatePresence } from "framer-motion"
import RMJInvestmentGoalModal from "../../portfolioComfort/RMJInvestmentGoalModal/RMJInvestmentGoalModal"
import { latest } from "../../../../lib/clients"

interface Props {
  client?: Client
  household?: Household
  outsideIM?: boolean
}

const WealthAccumulation = ({ client, household, outsideIM }: Props) => {
  const { sessionInfo } = useContext(AuthContext)
  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const goalType = "wealthAccumulation"
  const { firm } = useContext(FirmContext)
  const [status, setStatus] = useState<GoalExplorerStatuses>("init")
  const [values, setValues] = useState<WealthAccumulationFormValues>(() => createFormValues({ client, household }))
  const [errors, setErrors] = useState<WealthAccumulationFormErrors>({
    annualInvestmentContribution: undefined,
    investmentAmount: undefined,
    wealthAccumulationGoal: undefined,
    wealthAccumulationYear: undefined
  })
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const clientOrHousehold = client ?? household
  const [showRiskComfort, setShowRiskComfort] = useState(true)
  const [showWealthRange, setShowWealthRange] = useState(false)
  const [options, setOptions] = useState<Options | undefined>(() => createOptions(goalType, clientOrHousehold))
  const [optionsWithRange, setOptionsWithRange] = useState<Options | undefined>(() => createOptions(goalType, clientOrHousehold))
  const [displayType, setDisplayType] = useState<"chart" | "list">("chart")
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isInvestmentGoalModalOpen, setIsInvestmentGoalModalOpen] = useState(false)
  const navigate = useNavigate()
  const dropDownTrigger = useRef<HTMLDivElement>(null)

  const calculateResults = useCallback(
    (goalType: InvestmentGoal, sessionInfo?: SessionInfo, clientOrHousehold?: Client | Household) => {
      if (clientOrHousehold) {
        computeGoalAchievability(goalType, clientOrHousehold.advisorId, client && clientOrHousehold._id, household && clientOrHousehold._id)
          .then((res) => {
            setOptions(createOptions(goalType, clientOrHousehold ?? undefined, res.results, false))
            setOptionsWithRange(createOptions(goalType, clientOrHousehold ?? undefined, res.results, true))
            setErrorMessage(undefined)
            setStatus("success")
          })
          .catch((error) => {
            setStatus("error")
            setErrorMessage("An error occurred. Please try again.")
            console.error("error calculating goal achievability", error)
          })
      }
    },
    [client, household]
  )

  const update = useCallback(() => {
    if (client) {
      const updateRequest: ClientUpdateRequest = createWealthAccumulationRequest({ client, values, goalType }) as ClientUpdateRequest
      return updateClient(client._id, updateRequest)
        .then((updatedClient) => {
          setErrorMessage(undefined)
          calculateResults(goalType, sessionInfo, updatedClient!)
        })
        .catch((error) => {
          setStatus("error")
          setErrorMessage("An error occurred. Please try again.")
          console.error("error updating client details", error)
        })
    } else if (household) {
      const updateRequest: HouseholdUpdateRequest = createWealthAccumulationRequest({ values, goalType, household }) as HouseholdUpdateRequest

      return updateHousehold(household._id, [{}, {}], updateRequest)
        .then((updatedHousehold) => {
          setErrorMessage(undefined)
          calculateResults(goalType, sessionInfo, updatedHousehold!)
        })
        .catch((error) => {
          setStatus("error")
          setErrorMessage("An error occurred. Please try again.")
          console.error("error updating client details", error)
        })
    }
  }, [calculateResults, client, household, sessionInfo, updateClient, updateHousehold, values])

  const onSubmit = useCallback(() => {
    setShouldValidate(true)
    const { isValid } = validateWealthAccumulationForm({ values: values, firm })
    if (isValid) {
      setStatus("updating")
      update()
    }
  }, [firm, values, update])

  const onChange = (values: Partial<WealthAccumulationFormValues>) => {
    setValues((prev) => ({
      ...prev,
      ...values
    }))
  }

  const onChangeYear = (value: string) => {
    // test for numbers or blank
    if (/^\d{0,4}$/.test(value)) {
      setValues((prev) => ({
        ...prev,
        wealthAccumulationYear: value
      }))
    }
  }

  useEffect(() => {
    if (status === "init") {
      const nextValues = createFormValues({ client, household })
      setValues(nextValues)
      const { isValid } = validateWealthAccumulationForm({ values: nextValues, firm })
      if (isValid) {
        setStatus("updating")
        calculateResults(goalType, sessionInfo, clientOrHousehold)
      }
    }
  }, [calculateResults, client, clientOrHousehold, firm, household, sessionInfo, status])

  useEffect(() => {
    if (shouldValidate) {
      const { errors } = validateWealthAccumulationForm({ values: values, firm })
      setErrors(errors)
    }
  }, [firm, values, shouldValidate])

  const switchGoal = () => {
    if ((client && !client.dob) || (household && household.members.find(({ client }) => !client.dob))) {
      if (outsideIM) {
        navigate(`/${client ? "clients" : "households"}/${clientOrHousehold?._id}/explore/set-goal`)
      } else {
        setIsInvestmentGoalModalOpen(true)
      }
    } else if (clientOrHousehold?.alreadyRetired) {
      navigate(`../${ outsideIM ? "goal" : "goal-explorer"}?goal=retirement-drawdown`, { relative: "path" })
    } else {
      navigate(`../${ outsideIM ? "goal" : "goal-explorer"}?goal=retirement-income`, { relative: "path" })
    }
  }

  const game = latest(clientOrHousehold!, "risk")

  return (
    <div className="absolute w-full h-full flex flex-row">
      <div className="w-75 h-full shrink-0 bg-surface-200 flex flex-col overflow-hidden">
        <div className="grow flex flex-col overflow-hidden">
          <div className="flex justify-end p-1.5">
            <button
              className={clsx("flex items-center gap-2 hover:bg-surface-300 px-2 py-1.5", {
                "pointer-events-none": status === "updating"
              })}
              onClick={switchGoal}
            >
              <img alt="" src={switchImage} />
              <span className="text-interactive-500 text-sm font-bold">Switch goal</span>
            </button>
          </div>
          <WealthAccumulationForm errors={errors} onChange={onChange} onChangeYear={onChangeYear} onSubmit={onSubmit} values={values} status={status} />
        </div>
      </div>
      <div className="w-full relative grow overflow-auto no-scrollbar bg-white">
        <div className="w-full h-full flex flex-col items-center justify-stretch">
          <SelectPortfolio
            errorMessage={errorMessage}
            goalType={goalType}
            message={!options && status === "init" ? "Please update your plan details" : undefined}
            options={showWealthRange ? optionsWithRange : options}
            client={client}
            household={household}
            displayType={displayType}
            displayTypes={(type) => setDisplayType(type)}
            isRiskComfort={showRiskComfort}
            isRange={showWealthRange}
            isUpdating={status === "updating"}
          >
            {FEATURE_WEALTH_AND_INCOME_RANGE && displayType === "chart" && (
              <div className="relative w-max">
                <div className="flex gap-x-1 cursor-pointer" onClick={() => setIsMenuOpen(!isMenuOpen)} ref={dropDownTrigger}>
                  <p className="text-xs text-interactive-500 leading-1 font-bold">Show</p>
                  <img className="px-1" src={chevronDown} alt="chevron-down" />
                </div>

                {isMenuOpen && (
                  <Dropdown className="w-full" overlayClassName="w-max p-3 mt-2 z-50" handleClose={() => setIsMenuOpen(false)} trigger={dropDownTrigger}>
                    {game?.risk?.results && <Checkbox
                      className="mb-1 p-0"
                      name="risk-comfort"
                      label="Risk comfort"
                      checked={showRiskComfort}
                      onChange={(e) => setShowRiskComfort(e.target.checked)}
                    />}
                    <Checkbox
                      className="p-0"
                      name="wealth-range"
                      label="Wealth range"
                      checked={showWealthRange}
                      onChange={(e) => setShowWealthRange(e.target.checked)}
                    />
                  </Dropdown>
                )}
              </div>
            )}
          </SelectPortfolio>
          {!options && status === "updating" && <Loading />}
        </div>
      </div>
      <AnimatePresence>
        {isInvestmentGoalModalOpen && (
          <RMJInvestmentGoalModal client={client} household={household} onClose={() => setIsInvestmentGoalModalOpen(false)} defaultGoal="retirementIncome" />
        )}
      </AnimatePresence>
    </div>
  )
}

export default WealthAccumulation
