import { useMemo } from "react"
import { useParams } from "react-router"
import useClient from "../../../hooks/useClient"
import useRMDetail from "../../../hooks/useRMDetail"
import goalsIcon from "../assets/images/icon-goals-hnw.png"
import { longRound } from "../../../lib/numbers"
import { formatCurrencyShort } from "../../../lib/currency"
import { differenceInCalendarYears, isValid, parse } from "date-fns"
import { DATE_FORMAT } from "../../../lib/date"
import PortfolioChartDetails from "../../clients/components/Profile/components/PortfolioOverview/PortfolioChartDetails"
import ComfortScoresGraph from "../../clients/components/Profile/components/PortfolioOverview/ComfortScoresGraph"

export type Errors = {
  incomeGoal?: string
  investmentAmount?: string
  annualContribution?: string
  otherSourcesIncome?: string
  retirementAge?: string
}

const RMJSummaryPage = () => {
  const { clientId } = useParams<{ clientId: string }>()
  const { client } = useClient(clientId)
  const { rmDetail } = useRMDetail(clientId)

  const totalAmount = useMemo(() => {
    const dobDate = client?.dob ? parse(client.dob, DATE_FORMAT, new Date()) : null
    if (rmDetail?.plan && dobDate && isValid(dobDate)) {
      const age = differenceInCalendarYears(new Date(), dobDate)
      return `${formatCurrencyShort(rmDetail.plan.investmentAmount! * (rmDetail.plan.retirementAge! - age))}`
    }
    return 0
  }, [client?.dob, rmDetail?.plan])

  const age = useMemo(() => {
    const dobDate = client?.dob ? parse(client.dob, DATE_FORMAT, new Date()) : null
    return dobDate && isValid(dobDate) ? differenceInCalendarYears(new Date(), dobDate) : null
  }, [client?.dob])

  return (
    <div className="flex flex-col text-main-500 mx-6">
      <p className="text-h2 font-semibold mb-2">Here’s a summary of your profile</p>
      <div className="flex flex-row my-4 gap-x-4">
        <div className="w-1/3 flex flex-col mr-4 px-4 py-6" style={{ backgroundColor: "#f2f0ed" }}>
          <div className="flex flex-col items-center mb-4 py-4">
            <div className="flex flex-col items-center pb-6 border-b border-b-main-500">
              <img src={goalsIcon} alt="goals" className="h-12" />
              <p className="text-h3 font-bold text-main-500">{formatCurrencyShort(rmDetail?.plan?.incomeGoal)}</p>
              <p>Retirement income goal</p>
              {rmDetail && <p>{`Total (${totalAmount})`}</p>}
            </div>
            {rmDetail && rmDetail.selectedModel && (
              <div className="flex flex-col pt-6">
                <p className="font-semibold mb-1">Projected retirement income</p>
                <p>Income from new investment</p>
                <p className="font-semibold">{`$${longRound(rmDetail.selectedModel.investmentIncome).toLocaleString()}/yr**`}</p>
                <p>Income from other sources</p>
                <p className="font-semibold">{`$${longRound(rmDetail.selectedModel.otherSourcesIncome).toLocaleString()}/yr`}</p>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-y-4"></div>
        </div>
        <div className="w-2/3">
          <p className="font-semibold mb-2">Your profile...</p>
          <p>You selected the Aggressive portfolio to maximise your comfort and you committed to a plan that will help you to be successful.</p>
          {rmDetail && rmDetail.plan && rmDetail.selectedModel && (
            <div className="my-4 flex flex-row pb-4 border-b border-b-main-400">
              <PortfolioChartDetails
                portfolioType={rmDetail.selectedModel.portfolioModelName}
                portfolioResults={rmDetail.selectedModel.modelWeightGroupList.map((val:any) => ({
                  assetName: val.assetClass.name,
                  totalWeight: val.totalWeight,
                  totalWeightPercentage: val.totalWeightPercentage
                }))}
              />
              <div>
                <p>Investment contribution</p>
                <p className="font-semibold mb-1">${longRound(rmDetail.plan.investmentAmount!).toLocaleString()}</p>
                <p>Annual contribution</p>
                <p className="font-semibold mb-1">${longRound(rmDetail.plan.annualContribution!).toLocaleString()}</p>
                <p>Duration of contribution</p>
                <p>{age ? `${age}yrs(Retire at age ${client?.retirementAge} years)` : `-`}</p>
              </div>
            </div>
          )}
          <p className="font-semibold">How does this profile align with your risk comfort and goal achievability…</p>
          {rmDetail && rmDetail.selectedModel && (
            <div className="my-4">
              <ComfortScoresGraph goalAchievability={rmDetail.selectedModel.goalAchievability} riskComfort={rmDetail.selectedModel.comfortLevel} />
            </div>
          )}
        </div>
      </div>
      <div className="text-xs">
        <p>
          For each portfolio we run 10,000 Monte Carlo simulations to see how much money you are likely to have at retirement, given your investment amount,
          annual contributions, and time horizon. When you retire, we assume that you withdraw 4% of your total investment amount in the first year of
          retirement, and 4% each year thereafter (adjusted for inflation) throughout your retirement.
        </p>

        <p>* Goal achievability is the percentage of Monte Carlo simulations where you exceed your retirement income goal</p>

        <p>** Income reflects the 30th percentile of Monte Carlo simulations, with the range reflecting the 15th-85th percentiles of simulations</p>
      </div>
    </div>
  )
}

export default RMJSummaryPage
