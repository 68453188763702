import { t } from "@lingui/macro"
import { useTheme } from "../../../contexts/ThemeContext"
import Page from "./Page"
import { PrintCauseDetail } from "./PrintCauseDetail"

const AppendixPage2 = ({ page }: { page: number }) => {
  const theme = useTheme()
  return (
    <Page page={page} title={t({ id: "print-report-page-header-title" })}>
      <div className="mx-9">
        {Object.values(theme.causes!.S1!).slice(4).map((cause) => (
          <PrintCauseDetail cause={cause} key={cause.id} />
        ))}
      </div>
    </Page>
  )
}

export default AppendixPage2
