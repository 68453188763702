import { latest } from "../../../lib/clients"
import { Client, PortfolioMapping } from "../../../models/Client"
import { Household } from "../../../models/Household"

export const portfolioComfortMatch = (clientOrHousehold?: Client | Household): PortfolioMapping => {
  const portfolioMappings = clientOrHousehold ? latest(clientOrHousehold, "risk")?.portfolioMappings : []
  const comfortMatches = [...(portfolioMappings || [])].sort((a, b) => {
    const d = b.riskComfort! - a.riskComfort!
    return d ? d : (a.portfolio!.sd || 0) - (b.portfolio!.sd || 0)
  })

  return comfortMatches[0]
}
