import clsx from "clsx"
import { useEffect, useMemo, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { Link } from "react-router-dom"
import axiosInstance from "../../../../api/axiosInstance"
import checkmarkCircle from "../../../../assets/icons/checkmark-circle.svg"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import TextInput from "../../../../components/TextInput/TextInput"
import RenderQueryResults from "../RenderQueryResults"

type FirmData = {
  _id: string,
  name: string
}

const CreateFirm = () => {

  const licensees = useQuery(["licensees"], () => axiosInstance.get<{_id: string, name: string}[]>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/licensees`)
    .then(res => res.data))

  const [licensee, setLicensee] = useState<{_id:string, name:string}>()
  const [name, setName] = useState<string>("")
  const [id, setId] = useState<string>("")
  const [idChanged, setIdChanged] = useState(false)
  const isValid = useMemo(() => !!id && !!name && licensee, [id, name, licensee])

  useEffect(() => {
    if (name && !idChanged) {
      setId(name.replaceAll(" ", "-").toLowerCase())
    }
  }, [name, id, setId, idChanged])
  
  const createFirm = useMutation<any, any, { licenseeId: string, firmData: FirmData }>({
    mutationFn: ({ licenseeId, firmData }) => {
      return axiosInstance.post(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/licensees/${licenseeId}/firms`, firmData)
        .then(res => res.data)
    },
  })

  if (0.01 * Math.exp(0)) return (
    <div className="pg-ctr pg-ctr-py-lg h-full px-10 flex flex-col">
      <h2 className="w-full px-4 text-h2">Create a new firm</h2>
      <div className="w-full h-full flex justify-stretch gap-x-4">
        <div className="w-96 overflow-hidden flex flex-col p-4">
          <h4 className="text-h4 mb-2 border-b border-neutral-200">1. Select Licensee</h4>
          <RenderQueryResults queryResult={licensees} renderData={(data) =>
            <ul className="flex flex-col gap-y-2 h-full overflow-y-auto">
              {data.map((l, i) => (
                <li key={i} className={clsx("px-4 py-2 rounded cursor-pointer", l._id === licensee?._id ? "text-white bg-surface-500" : "text-neutral-500 bg-surface-200")}
                  onClick={() => setLicensee(l)}>
                  <div className="text-xs">{l._id}</div>
                  <div className="font-semibold">{l.name}</div>
                </li>
              ))}
              <li><Link to="/admin/licensees/create" className="btn btn-small btn-text">+ Add new licensee</Link></li>
            </ul>}
          />
        </div>
        <div className="w-full overflow-y-hidden flex flex-col">
          <div className="p-4 flex flex-col items-start gap-y-8 overflow-y-hidden">
            <h4 className="text-h4 border-b border-neutral-200 w-full">2. Enter details</h4>
            <div className="w-full flex flex-col items-center gap-y-6 overflow-y-auto">
              <label className="w-96 font-bold">
                Firm Name:
                <TextInput name="name" value={name} onChange={setName} />
              </label>
              <label className="w-96 font-bold">
                Firm ID: {!id && <span className="text-xs">(will be computed from name if not set)</span>}
                <TextInput name="id" value={id} onChange={(val) => {
                  setId(val)
                  setIdChanged(!!val)
                }}/>
              </label>
                <p className="w-1/2 text-center mt-12 text-sec">
                  Create a new firm with ID <span className="font-semibold">{id}</span><br/>
                  and the name <span className="font-semibold">{name}</span><br/>
                  and make it belong to licensee {licensee?.name} (id: {licensee?._id}).
                </p>
                <div className="w-full flex items-center justify-center mt-8 gap-x-2">
                  <button
                    disabled={!isValid}
                    className={clsx("btn w-64 px-4 py-2", isValid && !createFirm.isLoading ? "btn-primary" : "btn-secondary")}
                    onClick={isValid && !createFirm.isLoading
                      ? () => createFirm.mutate({ licenseeId: licensee!._id, firmData: { _id: id, name } })
                      : () => {}
                    }>{createFirm.isLoading ? <>Creating...</> : <>Create</>}</button>
                    <span>
                      {createFirm.isLoading && <Loading />}
                      {createFirm.isSuccess && <span className="flex gap-x-1 text-positive-600"><img src={checkmarkCircle} /> Created.</span>}
                    </span>
                </div>
                {createFirm.isError && <p className="mt-4 text-center text-error">{createFirm.error?.toString()} ({createFirm.error?.response?.data?.message})</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className="pg-ctr pg-ctr-py-lg h-full px-10">
      <h2 className="w-full bg-neutral-100x px-4 text-h2">Create a new firm</h2>
      <div className="w-full h-full px-4">
        <div className="h-full mt-4 pr-8 flex gap-x-12">
          <div className="flex-0 w-2/5 h-full">
            <h4 className="text-h4 mb-2 border-b border-neutral-200">1. Select Licensee</h4>
            <RenderQueryResults queryResult={licensees} renderData={(data) =>
              <ul className="flex flex-col gap-y-2 h-full overflow-y-auto">
                {data.map((l, i) => (
                  <li key={i} className={clsx("px-4 py-2 rounded cursor-pointer", l._id === licensee?._id ? "text-white bg-surface-500" : "text-neutral-500 bg-surface-200")}
                    onClick={() => setLicensee(l)}>
                    <div className="text-xs">{l._id}</div>
                    <div className="font-semibold">{l.name}</div>
                  </li>
                ))}
                <li><Link to="/admin/licensees/create" className="btn btn-small btn-text">+ Add new licensee</Link></li>
              </ul>}
            />
          </div>
          <div className="px-4 w-full flex flex-col items-start gap-y-8">
            <h4 className="text-h4 border-b border-neutral-200 w-full">2. Enter details</h4>
            <label className="w-1/2 font-bold">
              Firm Name:
              <TextInput name="name" value={name} onChange={setName} />
            </label>
            <label className="w-1/2 font-bold">
              Firm ID: {!id && <span className="text-xs">(will be computed from name if not set)</span>}
              <TextInput name="id" value={id} onChange={(val) => {
                setId(val)
                setIdChanged(!!val)
              }}/>
            </label>
          </div>
        </div>
        <p className="w-1/2 mx-auto text-center mt-12 text-sec">
          Create a new firm with ID <span className="font-semibold">{id}</span><br/>
          and the name <span className="font-semibold">{name}</span><br/>
          and make it belong to licensee {licensee?.name} (id: {licensee?._id}).
        </p>
        <div className="w-full flex items-center justify-center mt-8 gap-x-2">
          <button
            disabled={!isValid}
            className={clsx("btn w-64 px-4 py-2", isValid && !createFirm.isLoading ? "btn-primary" : "btn-secondary")}
            onClick={isValid && !createFirm.isLoading
              ? () => createFirm.mutate({ licenseeId: licensee!._id, firmData: { _id: id, name } })
              : () => {}
            }>{createFirm.isLoading ? <>Creating...</> : <>Create</>}</button>
            <span>
              {createFirm.isLoading && <Loading />}
              {createFirm.isSuccess && <span className="flex gap-x-1 text-positive-600"><img src={checkmarkCircle} /> Created.</span>}
            </span>
        </div>
        {createFirm.isError && <p className="mt-4 text-center text-error">{createFirm.error?.toString()} ({createFirm.error?.response?.data?.message})</p>}
      </div>
    </div>
  )
}

export default CreateFirm