export const ATTITUDE_TO_RISK_SCORES = [
    { id: "0", value: 6 },
    { id: "1", value: 6.52032992 },
    { id: "2", value: 6.97617382 },
    { id: "3", value: 7.37185276 },
    { id: "4", value: 7.7116878 },
    { id: "5", value: 8 },
    { id: "6", value: 8.24111043 },
    { id: "7", value: 8.43934015 },
    { id: "8", value: 8.59901023 },
    { id: "9", value: 8.72444172 },
    { id: "10", value: 8.8199557 },
    { id: "11", value: 8.88987322 },
    { id: "12", value: 8.93851535 },
    { id: "13", value: 8.97020314 },
    { id: "14", value: 8.98925767 },
    { id: "15", value: 9 },
    { id: "16", value: 9.0061503 },
    { id: "17", value: 9.00902521 },
    { id: "18", value: 9.00934047 },
    { id: "19", value: 9.00781184 },
    { id: "20", value: 9.00515505 },
    { id: "21", value: 9.00208586 },
    { id: "22", value: 8.99932001 },
    { id: "23", value: 8.99757326 },
    { id: "24", value: 8.99756134 },
    { id: "25", value: 9 },
    { id: "26", value: 9.00728836 },
    { id: "27", value: 9.02855901 },
    { id: "28", value: 9.07462788 },
    { id: "29", value: 9.15631093 },
    { id: "30", value: 9.2844241 },
    { id: "31", value: 9.46978334 },
    { id: "32", value: 9.7232046 },
    { id: "33", value: 10.05550384 },
    { id: "34", value: 10.47749699 },
    { id: "35", value: 11 },
    { id: "36", value: 11.62869624 },
    { id: "37", value: 12.34873876 },
    { id: "38", value: 13.14014801 },
    { id: "39", value: 13.98294446 },
    { id: "40", value: 14.85714856 },
    { id: "41", value: 15.74278078 },
    { id: "42", value: 16.61986157 },
    { id: "43", value: 17.4684114 },
    { id: "44", value: 18.26845072 },
    { id: "45", value: 19 },
    { id: "46", value: 19.64492666 },
    { id: "47", value: 20.19248594 },
    { id: "48", value: 20.63378006 },
    { id: "49", value: 20.95991123 },
    { id: "50", value: 21.16198166 },
    { id: "51", value: 21.23109355 },
    { id: "52", value: 21.15834912 },
    { id: "53", value: 20.93485058 },
    { id: "54", value: 20.55170014 },
    { id: "55", value: 20 },
    { id: "56", value: 19.27759713 },
    { id: "57", value: 18.40931747 },
    { id: "58", value: 17.42673173 },
    { id: "59", value: 16.36141061 },
    { id: "60", value: 15.2449248 },
    { id: "61", value: 14.10884501 },
    { id: "62", value: 12.98474194 },
    { id: "63", value: 11.90418628 },
    { id: "64", value: 10.89874873 },
    { id: "65", value: 10 },
    { id: "66", value: 9.23168483 },
    { id: "67", value: 8.58624417 },
    { id: "68", value: 8.048293 },
    { id: "69", value: 7.60244632 },
    { id: "70", value: 7.23331912 },
    { id: "71", value: 6.9255264 },
    { id: "72", value: 6.66368313 },
    { id: "73", value: 6.43240431 },
    { id: "74", value: 6.21630494 },
    { id: "75", value: 6 },
    { id: "76", value: 5.77166355 },
    { id: "77", value: 5.53370585 },
    { id: "78", value: 5.29209626 },
    { id: "79", value: 5.05280409 },
    { id: "80", value: 4.8217987 },
    { id: "81", value: 4.60504941 },
    { id: "82", value: 4.40852556 },
    { id: "83", value: 4.23819648 },
    { id: "84", value: 4.10003152 },
    { id: "85", value: 4 },
    { id: "86", value: 3.94166099 },
    { id: "87", value: 3.91893242 },
    { id: "88", value: 3.92332198 },
    { id: "89", value: 3.94633731 },
    { id: "90", value: 3.97948609 },
    { id: "91", value: 4.01427598 },
    { id: "92", value: 4.04221465 },
    { id: "93", value: 4.05480977 },
    { id: "94", value: 4.04356899 },
    { id: "95", value: 4 },
    { id: "96", value: 3.91561045 },
    { id: "97", value: 3.78190801 },
    { id: "98", value: 3.59040034 },
    { id: "99", value: 3.33259512 },
    { id: "100", value: 3 }
]
