import { useRef, useState } from "react"
import moreIcon from "../../../../../assets/images/icons/dots-vertical.svg"
import Dropdown from "../../../../../components/Dropdown/Dropdown"
import { AnimatePresence } from "framer-motion"
import ImportPortfolio from "../../../../advisor/components/ImportModal/ImportPortfolio"
import Modal from "../../../../../components/Modal/Modal"

interface Props {
  type: "current" | "proposed"
}

const PortfolioMatchCardMenu = ({ type }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [showImportModal, setShowImportModal] = useState<boolean>(false)
  const menuBtn = useRef<HTMLButtonElement>(null)

  return (
    <div className="relative flex items-center">
      <button
        aria-label="Actions"
        className="p-1 border-2 border-transparent hover:border-interactive-200"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsMenuOpen(true)
        }}
        ref={menuBtn}>
        <img alt="" src={moreIcon} aria-hidden />
      </button>
      {isMenuOpen && (
        <Dropdown
          overlayClassName="w-[200px]"
          align="left"
          alignVertical={"top"}
          handleClose={() => {
            setIsMenuOpen(false)
          }}
          trigger={menuBtn}>
          <ul className="list-none p-0 text-main-500">
            {type === "current" ? (
              <>
                <li>
                  <button
                    className="btn text-sec p-3 hover:bg-interactive-100 cursor-pointer border-0 block w-full text-left font-normal"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setIsMenuOpen(false)
                      setShowImportModal(true)
                    }}
                  >
                    Upload new portfolio
                  </button>
                </li>
              </>
            ) : type === "proposed" ? (
              <li>
                <button
                  className="btn text-sec p-3 hover:bg-interactive-100 cursor-pointer border-0 flex justify-between items-center w-full text-left font-normal"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setIsMenuOpen(false)
                  }}
                >
                  Edit in portfolio analyser
                </button>
              </li>
            ) : null
            }
          </ul>
        </Dropdown>
      )}
      <AnimatePresence>
        {showImportModal && (
          <Modal handleClose={() => setShowImportModal(false)}>
            <ImportPortfolio handleClose={() => setShowImportModal(false)} />
          </Modal>
        )}
      </AnimatePresence>
    </div>
  )
}

export default PortfolioMatchCardMenu