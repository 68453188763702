import Modal from "../../../../components/Modal/Modal"
import { PortfolioMapping } from "../../../../models/Client"
import { AssetClass } from "../../../../models/InvestmentUniverse"
import PortfolioTableDisplay from "../../components/PortfolioTableDisplay/PortfolioTableDisplay"

interface Props {
  assetClasses?: AssetClass[]
  comfortMatch?: PortfolioMapping
  onClose: () => void
  portfolioMappings?: PortfolioMapping[]
}

const RMJPortfolioComfortMoreInfoModal = ({ assetClasses, comfortMatch, onClose, portfolioMappings }: Props) => {
  return (
    <Modal className="w-[880px]" handleClose={onClose}>
      <div className="w-full flex flex-col overflow-y-scroll no-scrollbar" style={{ maxHeight: "calc(100vh - 100px)" }}>
        <h2 className="text-h2 text-main-600 font-semibold mb-2 xl-max:mb-6">Strategic asset allocation</h2>
        <div className="w-full overflow-auto">
          {assetClasses && portfolioMappings && (
            <PortfolioTableDisplay assetClasses={assetClasses} portfolioMappings={portfolioMappings} comfortMatch={comfortMatch} />
          )}
        </div>
      </div>
    </Modal>
  )
}

export default RMJPortfolioComfortMoreInfoModal
