import clsx from "clsx"
import DollarIcon from "../../../../components/DolarIcon/DollarIcon"
import NumberInput from "../../../../components/NumberInput/NumberInput"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import TextInput from "../../../../components/TextInput/TextInput"
import { WealthAccumulationFormErrors, WealthAccumulationFormValues } from "./WealthAccumulationUtils"
import { GoalExplorerStatuses } from "../utils/validation"
import infoIcon from "../../assets/images/info-icon.svg"
import { useState } from "react"
import RMJTextTooltip from "../components/RMJTextTooltip/RMJTextTooltip"
import { FEATURE_TOOLTIP_WEALTH_AND_INCOME_FORMS } from "../../../../config/features"

interface Props {
  errors: WealthAccumulationFormErrors
  onChange: (values: Partial<WealthAccumulationFormValues>) => void
  onChangeYear: (value: string) => void
  onSubmit: () => void
  status?: GoalExplorerStatuses
  values: WealthAccumulationFormValues
}

const WealthAccumulationForm = ({ errors, onChange, onChangeYear, onSubmit, status, values }: Props) => {
  const [showInfoTooltip, setShowInfoTooltip] = useState(false)
  return (
    <div className="flex flex-col grow overflow-hidden">
      <div
        className={clsx("grow overflow-auto no-scrollbar", {
          "pointer-events-none": status === "updating"
        })}
      >
        <div className="bg-surface-300 px-5 pt-2 pb-7 mb-4">
          <label className="block text-p text-main-600 text-center font-semibold mb-1" htmlFor="wealthGoal">
            Wealth accumulation goal
          </label>
          <NumberInput
            className="text-sec"
            inputFieldClassName="h-14"
            disableAutoComplete
            name="wealthGoal"
            value={values.wealthAccumulationGoal}
            prefix={
              <div className="pl-3">
                <DollarIcon />
              </div>
            }
            onChange={(value) => onChange({ wealthAccumulationGoal: value })}
            error={errors.wealthAccumulationGoal}
          />
        </div>

        <div className="px-5 mb-6">
          <NumberInput
            className="text-sec"
            inputFieldClassName="h-9"
            label="Investment amount"
            disableAutoComplete
            name="investmentAmount"
            value={values.investmentAmount}
            prefix={
              <div className="pl-3">
                <DollarIcon />
              </div>
            }
            onChange={(value) => onChange({ investmentAmount: value })}
            error={errors.investmentAmount}
          />
        </div>
        <div className="px-5 mb-6">
          <NumberInput
            className="text-sec"
            inputFieldClassName="h-9"
            label="Annual investment contribution"
            disableAutoComplete
            name="annualInvestmentContribution"
            value={values.annualInvestmentContribution}
            suffix={<span>p/y</span>}
            prefix={
              <div className="pl-3">
                <DollarIcon />
              </div>
            }
            onChange={(value) => onChange({ annualInvestmentContribution: value })}
            error={errors.annualInvestmentContribution}
          />
        </div>
        <div className="px-5 mb-3">
          <TextInput
            className="text-sec h-9"
            placeholder="yyyy"
            label="Expected withdrawal year"
            disableAutoComplete
            maxLength={4}
            name="wealthAccumulationYear"
            value={values.wealthAccumulationYear?.toString()}
            onChange={(value) => onChangeYear(value)}
            error={errors.wealthAccumulationYear}
          />
        </div>
      </div>
      <div className="px-5 py-4 flex gap-x-4 items-center shadow-sm">
        <button
          className={clsx("btn btn-secondary btn-medium w-full h-10 flex items-center justify-center p-0", {
            "pointer-events-none": status === "updating"
          })}
          onClick={onSubmit}
        >
          {status === "updating" ? <Loading className="h-8 w-full" /> : "Update"}
        </button>
        {FEATURE_TOOLTIP_WEALTH_AND_INCOME_FORMS && (
          <div className="w-5 h-5" onMouseOver={() => setShowInfoTooltip(true)} onMouseOut={() => setShowInfoTooltip(false)}>
            <img className="cursor-pointer" src={infoIcon} alt="information icon" />
          </div>
        )}
        {showInfoTooltip && (
          <div className="absolute bottom-0 w-75 left-70 z-10">
            <RMJTextTooltip>
              <p className="text-sm leading-3">
                This projection assumes that you will stay invested in your current investment for at least the next 10 years, and then you will switch to our
                most conservative investment option
              </p>
            </RMJTextTooltip>
          </div>
        )}
      </div>
    </div>
  )
}

export default WealthAccumulationForm
