import edit from "../images/Edit.svg"
import trash from "../images/Trash.svg"
import Popup from "../../../components/Popup/Popup";

const ModelPortfolioMenuPopup = ({ onEdit, onDelete }: { onEdit: () => void; onDelete: () => void }) => {
  return (
    <Popup overlayClassName="border border-interactive-300 bg-interactive-100" align="right">
      <ul className="inline-flex items-center">
        <li>
          <button className="p-1 btn hover:bg-interactive-100 cursor-pointer inset-0 border-0 block" onClick={onEdit}>
            <img src={edit} alt="edit" />
          </button>
        </li>
        <li>
          <button className="p-1 btn hover:bg-interactive-100 cursor-pointer inset-0 border-0 block" onClick={onDelete}>
            <img src={trash} alt="delete" />
          </button>
        </li>
      </ul>
    </Popup>
  )
}
export default ModelPortfolioMenuPopup
