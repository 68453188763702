import React from "react"
import { useParams } from "react-router"
import Loading from "../../components/ClientProfile/Loading/Loading"
import LoadingError from "../../components/ClientProfile/LoadingError/LoadingError"
import useClient from "../../hooks/useClient"
import Profile from "../clients/components/Profile/Profile"
import { Helmet } from "react-helmet-async"

const ClientStatusPage = () => {
  const { clientId } = useParams<{ clientId: string }>()
  const { client, isClientLoading: isLoading, clientLoadError } = useClient(clientId)

  return (
    <>
      <Helmet>
        <title>
          {import.meta.env.VITE_APP_TITLE ?? "Adviser Portal"}
          {client ? " - " + client.firstName + " " + client.lastName : ""}
        </title>
      </Helmet>
      <div className="pg-ctr pg-ctr-py-lg w-full h-full overflow-y-auto no-scrollbar absolute pb-12">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {client ? (
              <Profile client={client} />
            ) : (
              <LoadingError message={(clientLoadError as any)?.response?.data ?? clientLoadError?.message ?? "Could not load client"} />
            )}
          </>
        )}
      </div>
    </>
  )
}

export default ClientStatusPage
