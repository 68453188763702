import { ActionItem } from "../models/ActionItem"
import { Client } from "../models/Client"
import { Household } from "../models/Household"
import { SessionInfo } from "../views/auth/AuthContext"
import axiosInstance from "./axiosInstance"
import { ClientUpdateRequest, NewClientRequest } from "./clients"

export type NewHouseholdRequest = {
  name?: string
  investmentAmount?: number
  members?: NewClientRequest[]
  clientIds?: string[]
}

export type HouseholdUpdateRequest = {
  name?: string
  currentPortfolio?: string | null
  annualContribution?: number | null
  otherSourcesIncome?: number | null

  investmentAmount?: number | null
  primaryInvestmentGoal?: "retirementIncome" | "wealthAccumulation" | "retirementDrawdown" | null
  annualInvestmentContribution?: number | null

  retirementAge?: number | null
  retirementIncomeGoal?: number | null
  otherSourcesRetirementIncome?: number | null
  pensionInRetirement?: number | null
  alreadyRetired?: boolean
  planningHorizon?: number
  estateGoal?: number | null
  wealthAccumulationGoal?: number | null
  wealthAccumulationYear?: number | null // 4 digit, static year

  nextActivityDue?: string | null

  timeHorizon?: string
  actionItems?: ActionItem[]
}

export const createHousehold = async (advisorId: string, household: NewHouseholdRequest) => {
  const res = await axiosInstance
    .post(`${import.meta.env.VITE_APP_API_BASE || ""}/api/advisor/${advisorId}/households`, household)
    .then((res) => res.data)
  console.log("res in ts", res)
  return res as Household
}

export const updateHousehold = async (
  _: SessionInfo,
  household: Household,
  memberUpdates: ClientUpdateRequest[],
  householdUpdate?: HouseholdUpdateRequest
) => {
  const members = await Promise.all(
    memberUpdates.map((memberUpdate, index) => {
      if (Object.keys(memberUpdate).filter((key) => memberUpdate[key as keyof ClientUpdateRequest] !== undefined).length > 0) {
        return axiosInstance
          .patch(`${import.meta.env.VITE_APP_API_BASE || ""}/api/client/${household.members[index].id}`, memberUpdate)
          .then((res) => res.data as Client)
      } else {
        return Promise.resolve(household.members[index].client)
      }
    })
  )

  return householdUpdate && Object.keys(householdUpdate).filter((k) => householdUpdate[k as keyof HouseholdUpdateRequest] !== undefined).length > 0
    ? axiosInstance
      .patch(`${import.meta.env.VITE_APP_API_BASE || ""}/api/household/${household._id}`, householdUpdate)
      .then((res) => res.data as Household)
    : Promise.resolve({ ...household, members: household.members.map((m, i) => ({ ...m, client: members[i] })) })
}

export const archiveHousehold = (sessionInfo: SessionInfo, householdId: string): Promise<Household> => {
  return axiosInstance
    .delete(
      `${import.meta.env.VITE_APP_API_BASE || ""}/api/household/${householdId}/archive`,
      {}
    )
    .then((res) => res.data)
}

export const unArchiveHousehold = (sessionInfo: SessionInfo, householdId: string): Promise<Household> => {
  return axiosInstance
    .post(
      `${import.meta.env.VITE_APP_API_BASE || ""}/api/household/${householdId}/unarchive`,
      {}
    )
    .then((res) => res.data)
}
