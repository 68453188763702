import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { ScatterData } from "../../pages/advisor/AnalyticsPage"
import clsx from "clsx"
import Loading from "../ClientProfile/Loading/Loading"
import infoIcon from "../../assets/icons/info-alert.svg"

const QUADRANT_KEYS = ["q1", "q2", "q3", "q4"] as const

interface Props {
  chartData: ScatterData[]
  handleQuadrantSelection: (quadrant: (typeof QUADRANT_KEYS)[number]) => void
  activeArea?: "q1" | "q2" | "q3" | "q4"
  isLoading?: boolean
}

const ScatterPlot = ({ chartData, handleQuadrantSelection, activeArea, isLoading }: Props) => {
  const [chartSize, setChartSize] = useState({ width: 50, height: 20 })
  const [showTooltip, setShowTooltip] = useState<number | undefined>()
  const chartArea = useRef<HTMLDivElement>(null)
  const axisTicks = [0, 20, 40, 60, 80, 100]

  const axisFontSize = 12
  const dataPointRadius = 5
  const tooltipPoint = {
    r: 8.5,
    strokeWidth: 5
  }
  const offsetX = 50
  const offsetY = 20

  const xAxisLine = useMemo(() => (chartSize.height - offsetY) * (20 / 100), [chartSize.height, offsetY]) //place x axis 20% of the way down the chart

  const yAxisLine = useMemo(() => (chartSize.width - offsetX) * (50 / 100) + offsetX, [chartSize.width, offsetX]) // place y axis 50% of the way across the chart

  useEffect(() => {
    const localRef = chartArea.current
    if (localRef) {
      const resizeObserver = new ResizeObserver(() => {
        setChartSize({ width: localRef.clientWidth, height: localRef.clientHeight })
      })
      resizeObserver.observe(localRef)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [])

  const YAxisLabel = () => {
    return (
      <div className="flex -rotate-180 justify-between text-sm pt-20 pb-2 text-main-400 font-semibold" style={{ writingMode: "vertical-lr" }}>
        <p>More unsettled</p>
        <p>More composed</p>
      </div>
    )
  }

  const XAxisLabel = () => {
    return (
      <div className="flex justify-between text-sm pt-6 text-main-400 font-semibold" style={{ paddingLeft: offsetX }}>
        <label>Lower Attitude to Risk</label>
        <label>Higher Attitude to Risk</label>
      </div>
    )
  }

  const YAxisTickLabel = () => {
    const yAxisTickOffset = 5
    return (
      <g transform={`translate(${offsetX - axisFontSize}, ${yAxisTickOffset})`}>
        {axisTicks.map((y, i) => (
          <text
            className="text-main-400"
            textAnchor="end"
            fontSize={axisFontSize}
            x="0"
            y={
              i === 0
                ? yAxisTickOffset / 2
                : i === axisTicks.length - 1
                ? chartSize.height - offsetY - yAxisTickOffset / 2
                : (chartSize.height - offsetY) * (y / 100)
            }
            key={i}
          >
            {y}
          </text>
        ))}
      </g>
    )
  }

  const XAxisTickLabel = () => {
    const tickSpacing = (chartSize.width - offsetX) / (axisTicks.length - 1)
    const offset = 6
    return (
      <g transform={`translate(0, ${chartSize.height - offsetY + axisFontSize + offset})`}>
        {axisTicks.map((x, i) => (
          <text
            className="text-main-400"
            textAnchor="middle"
            fontSize={axisFontSize}
            x={i === axisTicks.length - 1 ? chartSize.width - axisFontSize / 2 - 3 : offsetX + i * tickSpacing}
            y="0"
            key={i}
          >
            {x}
          </text>
        ))}
      </g>
    )
  }

  const XAxis = () => {
    return (
      <g>
        <line className="stroke-surface-300" x1={offsetX} y1={xAxisLine} x2="100%" y2={xAxisLine} strokeDasharray="5 2" strokeWidth="2" />
      </g>
    )
  }

  const YAxis = () => {
    return (
      <g>
        <line className="stroke-surface-300" x1={yAxisLine} y1={0} x2={yAxisLine} y2={chartSize.height - offsetY} strokeDasharray="5 2" strokeWidth="2" />
      </g>
    )
  }

  const Tooltip = ({
    x,
    y,
    firstName,
    lastName,
    riskTolerance,
    lossAversion,
    cceiScore
  }: {
    x: number
    y: number
    firstName: string
    lastName: string
    riskTolerance: number
    lossAversion: number
    cceiScore: number
  }) => {
    const atrStlLabels = calcAtrStlArea(lossAversion, riskTolerance)
    const inconsistent = cceiScore < 75
    const width = inconsistent ? 170 : 140
    const height = inconsistent ? 90 : 70

    const offsetXpos = width / 2
    const offsetYpos = dataPointRadius + tooltipPoint.r / 2 + tooltipPoint.strokeWidth
    const adjustTooltipPositioning = useMemo(
      () =>
        x + offsetXpos > chartSize.width
          ? { x: x - width - offsetYpos, y: y - height / 2 }
          : offsetX + x < width
          ? {
              x: x + offsetYpos,
              y: y - height / 2
            }
          : { x: x - offsetXpos, y: y + offsetYpos },
      [height, offsetXpos, offsetYpos, width, x, y]
    )

    return (
      <foreignObject
        x={adjustTooltipPositioning.x}
        y={adjustTooltipPositioning.y}
        width={width}
        height={height}
        className="border border-highlight-200 bg-white rounded-1 transition-all duration-300 delay-100 ease-in relative"
      >
        <div className="text-sm py-2 px-1 text-main-500">
          <p className="font-semibold truncate mb-1">
            {firstName} {lastName}
          </p>
          <p>{atrStlLabels.attitudeToRisk}</p>
          <p>{atrStlLabels.stability}</p>
          {inconsistent && (
            <div className="w-full h-5 flex items-center bg-red-200 mt-1">
              <img className="mx-1" src={infoIcon} alt="" aria-hidden />
              <p className="uppercase font-bold text-red-500 text-xs">Inconsistent decisions</p>
            </div>
          )}
        </div>
      </foreignObject>
    )
  }

  const scatterPoints = useMemo(
    () =>
      chartData.map(({ riskTolerance, lossAversion, firstName, lastName, cceiScore }) => {
        const xCoord = (chartSize.width - offsetX) * ((riskTolerance ?? 0) / 100) + offsetX
        const yCoord = (chartSize.height - offsetY) * ((lossAversion ?? 0) / 100)

        return {
          x: xCoord,
          y: yCoord,
          firstName,
          lastName,
          riskTolerance,
          lossAversion,
          cceiScore
        }
      }),
    [chartData, chartSize.height, chartSize.width, offsetX, offsetY]
  )

  const calcAtrStlArea = useCallback(
    (stlScore: number, atrScore: number) : { attitudeToRisk: string, stability: string } =>
      stlScore < 20 && atrScore < 50
        ? { attitudeToRisk: "Lower Attitude to Risk", stability: "More composed" }
        : stlScore >= 20 && atrScore < 50
        ? { attitudeToRisk: "Lower Attitude to Risk", stability: "More unsettled" }
        : stlScore >= 20 && atrScore >= 50
        ? { attitudeToRisk: "Higher Attitude to Risk", stability: "More unsettled" }
        : { attitudeToRisk: "Higher Attitude to Risk", stability: "More composed" },
    []
  )

  return (
    <div className="w-full h-full flex">
      <div className="-rotate-180 flex justify-center text-sm pt-12 pb-2 text-main-400 font-semibold mr-5" style={{ writingMode: "vertical-lr" }}>
        Sensitivity to Loss
      </div>
      <YAxisLabel />

      <div className="w-full h-full flex flex-col">
        <div className="relative w-full h-full" ref={chartArea}>
          <svg height="100%" width="100%" className="overflow-visible" viewBox={`0 0 ${chartSize.width} ${chartSize.height - offsetY}`}>
            <rect className="stroke-surface-300" x={offsetX} width={chartSize.width - offsetX} height="100%" fill="none" strokeWidth="1" />

            {/* Quadrant fill area */}
            <rect
              className={clsx("cursor-pointer", activeArea === "q1" ? "fill-interactive-200" : "fill-transparent")}
              x={offsetX}
              width={chartSize.width / 2 - offsetX / 2}
              height={xAxisLine}
              onClick={() => handleQuadrantSelection("q1")}
            />

            <rect
              className={clsx("cursor-pointer", activeArea === "q2" ? "fill-interactive-200" : "fill-transparent")}
              x={offsetX}
              y={xAxisLine}
              width={chartSize.width / 2 - offsetX / 2}
              height={chartSize.height - offsetY - xAxisLine}
              onClick={() => handleQuadrantSelection("q2")}
            />

            <rect
              className={clsx("cursor-pointer", activeArea === "q3" ? "fill-interactive-200" : "fill-transparent")}
              x={chartSize.width / 2 + offsetX / 2}
              width={chartSize.width / 2 - offsetX / 2}
              height={xAxisLine}
              onClick={() => handleQuadrantSelection("q3")}
            />

            <rect
              className={clsx("cursor-pointer", activeArea === "q4" ? "fill-interactive-200" : "fill-transparent")}
              x={chartSize.width / 2 + offsetX / 2}
              y={xAxisLine}
              width={chartSize.width / 2 - offsetX / 2}
              height={chartSize.height - offsetY - xAxisLine}
              onClick={() => handleQuadrantSelection("q4")}
            />

            {/* X and Y axis lines */}
            {!isLoading && (
              <>
                <XAxis />
                <YAxis />
              </>
            )}

            {/* Scatter plot points */}
            {scatterPoints.map(({ x, y, cceiScore }, i) => (
              <g key={i}>
                <circle
                  className={clsx("cursor-pointer", (cceiScore ?? 0) < 75 ? "fill-red-500" : "fill-avatar-0-500")}
                  cx={x}
                  cy={y}
                  r={dataPointRadius}
                  onMouseEnter={() => setShowTooltip(i)}
                  onMouseLeave={() => setShowTooltip(undefined)}
                />
              </g>
            ))}
            <YAxisTickLabel />
            <XAxisTickLabel />

            {scatterPoints.map(({ x, y, firstName, lastName, lossAversion, riskTolerance, cceiScore }, i) => (
              <g key={i}>
                {showTooltip === i && (
                  <g>
                    <circle
                      className="stroke-focuscolor"
                      fill="none"
                      cx={x}
                      cy={y}
                      r={tooltipPoint.r}
                      strokeOpacity="50%"
                      strokeWidth={tooltipPoint.strokeWidth}
                    />
                    <Tooltip
                      x={x}
                      y={y}
                      firstName={firstName ?? ""}
                      lastName={lastName ?? ""}
                      riskTolerance={riskTolerance ?? 0}
                      lossAversion={lossAversion ?? 0}
                      cceiScore={cceiScore ?? 0}
                    />
                  </g>
                )}
              </g>
            ))}
            {isLoading && (
              <foreignObject
                x={chartSize.width / 2 - offsetX / 2 + 6}
                y={chartSize.height / 2 - offsetY}
                width="5%"
                height="10%"
                className="flex items-center justify-center"
              >
                <Loading />
              </foreignObject>
            )}
          </svg>
        </div>

        <XAxisLabel />
        <div className="flex justify-center text-sm pt-5 text-main-400 font-semibold mr-5">Attitude to Risk</div>
      </div>
    </div>
  )
}

export default ScatterPlot
