import { useMemo } from "react"
import { Client } from "../../../models/Client"
import { Trans } from "@lingui/macro"
import { useTheme } from "../../../contexts/ThemeContext"
import { DoughnutChart } from "../../rmjourney/components/DoughnutChart/DoughnutChart"
import Page from "./Page"
import { shortRound } from "../../../lib/numbers"
import { comfortMatchPortfolio, findRecommendedPortfolio } from "./SelectedPortfolio"
import { tt } from "../../../lib/translations"
import { AssetClass, SubAssetClass } from "../../../models/InvestmentUniverse"
import { ASSET_TYPES } from "../../../config/assetClasses"
import { allAssetClasses } from "./ComfortMatchPage"
import { latest } from "../../../lib/clients"

const GoalSummary = ({ page, client }: { page: number; client: Client }) => {
  const theme = useTheme()
  const goalAmount = shortRound(client.goalAmount ?? 0)
  const initialAmount = shortRound(client.investmentAmount)
  const game = latest(client, "risk")
  const portfolioMappings = game?.portfolioMappings
  const assetClasses = useMemo(
    () => allAssetClasses(game?.assetClasses ?? []) as { [key: string]: AssetClass | SubAssetClass },
    [game?.assetClasses]
  )
  const portfolioList = useMemo(() => {
    return [...(portfolioMappings || [])]
      .sort((a, b) => a.portfolio.sd! - b.portfolio.sd!)
      .map(({ portfolio, riskComfort }) => ({
        id: portfolio.id,
        translationKey: portfolio.translationKey,
        riskComfortScore: riskComfort,
        expectedVolatility: portfolio.sd,
        minRecommendedTimeframe: portfolio.aux?.minRecommendedTimeframe,
        assetClass: ASSET_TYPES.map((ac, jj) => ({
          assetClass: ac,
          colorIndex: jj,
          total: Math.round(portfolio.components.filter((c) => assetClasses![c.id].type === ac).reduce((sum, x) => sum + x.weight, 0) * 100) / 100
        })).filter(({ total }) => total > 0)
      }))
  }, [assetClasses, portfolioMappings])
  const comfortMatch = comfortMatchPortfolio(portfolioMappings ?? [])
  const recommendedPortfolio = findRecommendedPortfolio(client)
  const currentPortfolio = useMemo(
    () => game?.portfolioMappings?.find(({ portfolio }) => portfolio.id === client.currentPortfolio),
    [game?.portfolioMappings, client.currentPortfolio]
  )
  const selectedPortfolio = currentPortfolio ?? recommendedPortfolio
  const selectedPortfolioInfo = useMemo(() => {
    const index = portfolioList.findIndex(({ id }) => id === selectedPortfolio?.portfolio.id)
    const portfolio = index !== -1 ? portfolioList[index] : null
    return { index, portfolio }
  }, [portfolioList, selectedPortfolio?.portfolio.id])
  const shouldHideWealthGoalDetails = client.primaryInvestmentGoal === "wealthAccumulation" && !client.goalAmount

  return (
    <Page className="report-goal-summary bg-interactive-400" page={page} title={tt({ id: "report-header-title" })}>
      <div className="ml-9 text-main-400">
        <div className="mt-15">
          <h2 className="font-semibold text-h2 leading-7">
            <Trans id="report-goal-summary-statement-title">Your Why statement</Trans>
          </h2>
          <div className="report-goal-summary-why-statement mt-10">
            <p className="font-semibold text-display">
              <Trans
                id={`report-goal-summary-why-statement-text-1-${client.primaryInvestmentGoal?.toLowerCase()}${
                  shouldHideWealthGoalDetails ? "-no-specific-goal" : ""
                }`}
              >
                I want to <span className="bg-main-400 rounded-2 px-1 text-white box-decoration-clone">grow my wealth</span> by{" "}
                <span className="bg-main-400 rounded-2 px-1 text-white">{client.goalTargetYear}</span>
              </Trans>
            </p>
            <p className="font-semibold text-display mt-6 h-auto">
              {client?.aux?.goalReasonOptionsSelected?.length ? (
                <>
                  So I can{" "}
                  {(client.aux.goalReasonOptionsSelected as string[]).map((option, index) => (
                    <>
                      <span key={index} className="bg-main-400 rounded-2 px-1 text-white box-decoration-clone">
                        {option.toLowerCase()}
                      </span>
                      {index < client.aux?.goalReasonOptionsSelected.length - 1 && " & "}
                    </>
                  ))}
                </>
              ) : (
                <Trans id="report-goal-summary-why-statement-custom-text">So I can {client?.aux?.goalReason}</Trans>
              )}
            </p>
          </div>
        </div>
        <div className="mt-20 w-2/3">
          <h2 className="font-semibold leading-7">
            <Trans id="report-goal-summary-goal-summary-title">Your goal plan</Trans>
          </h2>
          <div className="report-goal-summary mt-10 rounded-3 bg-interactive-500 flex flex-col pt-10 gap-x-3 text-white">
            <div className="flex gap-x-3">
              <div className="bg-main-400 relative rounded-3 ml-16 py-4 w-28 text-center">
                <h3 className="title text-white font-semibold text-sm leading-3 text-center">
                  <Trans id="report-goal-summary-card-1-title">Goal</Trans>
                </h3>
                <div className="h-21 my-4 flex items-center justify-center">
                  {!shouldHideWealthGoalDetails ? (
                    <div className="text-white mx-auto w-24 rounded-3 relative overflow-hidden text-display bg-interactive-500">
                      <div className="h-24 w-24 bg-interactive-400 absolute rounded-full -right-8 -bottom-8 opacity-30" />
                      <h4 className="report-goal-amount relative py-2.5">{goalAmount.value + goalAmount.unit}</h4>
                    </div>
                  ) : (
                    <>
                      {theme.reports?.goalSummary?.images?.noWealthGoal && (
                        <img className="mx-auto" src={theme.reports?.goalSummary?.images?.noWealthGoal} alt="" aria-hidden />
                      )}
                    </>
                  )}
                </div>
                <p className="font-semibold text-sec px-3 text-center">
                  <Trans id={`report-goal-plan-goal-type-${client.primaryInvestmentGoal?.toLowerCase()}`}>Wealth</Trans>
                </p>
              </div>
              <div className="text-center bg-main-400 rounded-3 py-4 w-28">
                <h3 className="title text-white font-semibold text-sm leading-3">
                  <Trans id="report-goal-summary-card-2-title">Personality</Trans>
                </h3>
                <div className="h-21 my-4 flex items-center justify-center">
                  {theme.reports?.investorPersonality?.portfolioThemes.map(
                    ({ id, heroImage }) =>
                      id === comfortMatch?.portfolio.translationKey && <img key={id} className="my-5 mx-auto h-18" src={heroImage} alt="" aria-hidden />
                  )}
                </div>
                <p className="text-center font-semibold text-sec leading-5">{tt({ id: `report-persona-title-${comfortMatch?.portfolio.translationKey}` })}</p>
              </div>
              {selectedPortfolio && (
                <div className="bg-main-400 rounded-3 py-4 px-2.5 text-center w-28">
                  <h3 className="title text-white font-semibold text-sm leading-3">
                    <Trans id="report-goal-summary-card-3-title">Portfolio</Trans>
                  </h3>
                  <div className="w-16 h-21 my-4 mx-auto flex items-center">
                    {selectedPortfolioInfo.portfolio && (
                      <DoughnutChart
                        data={selectedPortfolioInfo.portfolio?.assetClass.map(({ total }) => total)}
                        colors={selectedPortfolioInfo.portfolio?.assetClass.map(
                          ({ assetClass }) =>
                            theme.reports?.goalSummary?.doughnutChartColor[assetClass as keyof typeof theme.reports.goalSummary.doughnutChartColor] ?? "#FFFFFF"
                        )}
                        strokeWidth={10}
                      />
                    )}
                  </div>
                  <p className="text-center font-semibold text-sec leading-5 m-auto">
                    {tt({ id: `report-portfolio-title-risk-level-${selectedPortfolioInfo.index + 1}-text` })}
                  </p>
                  <p className="flex items-center gap-x-1 justify-center font-semibold text-xs leading-5 m-auto">
                    <Trans id="report-goal-summary-risk-level-text">
                      Risk level{" "}
                      <span className="flex items-center justify-center w-3.5 h-3.5 rounded-full bg-white text-main-400">
                        {selectedPortfolioInfo.index + 1}
                      </span>
                    </Trans>
                  </p>
                </div>
              )}
            </div>

            <hr className="w-full my-8 border-main-300 opacity-20" />
            <div className="mb-10 text-sec font-normal mx-16">
              <h3 className="text-main-400">
                <Trans id="report-goal-summary-investment-summary-title">Investment details</Trans>
              </h3>
              <p className="my-3 flex justify-between">
                <Trans id="report-goal-summary-investment-summary-initial-investment">
                  Initial investment <span className="font-semibold">${initialAmount.value + initialAmount.unit}</span>
                </Trans>
              </p>
              <p className="flex justify-between">
                <Trans id={`report-goal-summary-investment-summary-${client?.aux?.investmentContributionFrequency}-contribution`}>
                  Monthly contribution <span className="font-semibold">${client?.aux?.investmentContributionAmount}</span>
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default GoalSummary
