import { i18n } from "@lingui/core"
import { Trans } from "@lingui/react"
import { useContext, useMemo } from "react"
import { ASSET_TYPES } from "../../../../config/assetClasses"
import { FirmContext } from "../../../../contexts/FirmContext"
import { useTheme } from "../../../../contexts/ThemeContext"
import { areDecisionsIncoherent, latest } from "../../../../lib/clients"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import { AssetClass, SubAssetClass } from "../../../../models/InvestmentUniverse"
import { DoughnutChart } from "../../../rmjourney/components/DoughnutChart/DoughnutChart"
import Container from "../components/Container"
import Footer from "../components/Footer"
import alert from "./assets/alert-triangle.svg"
import fingerprint from "./assets/fingerprint.svg"
import riskDimensions from "./assets/risk-dimensions-beige.png"

const SummaryPage = ({ page, client, household }: { page: number; client: Client; household: Household }) => {
  const theme = useTheme()
  const { firm } = useContext(FirmContext)
  const game = latest((client ?? household), "risk")
  const portfolio = useMemo(() => {
    const portfolioMappings = game?.portfolioMappings
    return [...(portfolioMappings || [])].sort((a, b) => b.riskComfort! - a.riskComfort!)[0]?.portfolio
  }, [game?.portfolioMappings])

  const assetTypeWeights =
    useMemo(() => {
      const allAssetClasses = firm?.assetClasses
        .flatMap((ac) => [ac, ...(ac.subAssetClasses || []).map((sac) => ({ ...sac, type: sac.type ?? ac.type }))] as (AssetClass | SubAssetClass)[])
        .reduce(
          (acc, cur) => ({
            ...acc,
            [cur.id!]: cur
          }),
          {} as { [key: string]: AssetClass | SubAssetClass }
        )
      return ASSET_TYPES.map((ac, jj) => ({
        assetClass: ac,
        colorIndex: jj,
        total: portfolio?.components.filter((c) => allAssetClasses![c.id]?.type === ac).reduce((sum, x) => sum + x.weight, 0) ?? 0
      })).filter(({ total }) => total ?? 0 > 0)
    }, [firm?.assetClasses, portfolio?.components]) || {}

  const portfolioMessages = useMemo(
    () => ({
      /* lingui-extract-ignore */
      title: i18n._({ id: `report-summary-page-${portfolio?.translationKey}-title` }),
      /* lingui-extract-ignore */
      description: i18n._({ id: `report-summary-page-${portfolio?.translationKey}-description` })
    }),
    [portfolio?.translationKey]
  )

  return (
    <Container className="flex flex-col justify-between pt-10 relative">
      <div className="flex-auto mx-10">
        <h2 className="report-summary-page-header mb-2 text-main-600 text-sec tracking-wide uppercase">
          <Trans id="report-summary-page-header">Summary</Trans>
        </h2>
        <h3 className="text-main-600 text-h4">
          <Trans id="report-summary-page-title">There are three dimensions of risk that will shape your financial plan</Trans>
        </h3>
        <div className="my-6">
          <p className="mb-3 leading-6">
            <Trans id="report-summary-page-description-1">
              We consider your risk comfort, risk required and risk capacity when looking at your financial plan. Our aim is to help you achieve your financial
              goals while keeping you comfortable with risk and acknowledging your financial constraints.
            </Trans>
          </p>
          <p>
            <Trans id="report-summary-page-description-2">
              Throughout the journey toward achieving your goals, it is important to periodically review how your preferences may evolve and incorporate any
              changes into your plan.
            </Trans>
          </p>
        </div>
        <div className="flex justify-center items-center grow pb-10">
          <img className="w-86" src={theme.reports?.riskSummary?.heroImage ?? riskDimensions} alt="Risk dimensions" aria-hidden />
        </div>

        <h2 className="report-summary-page-subheader mb-2 text-main-600 text-sec tracking-wide uppercase">Your Risk Profile</h2>
        <h3 className={`text-main-600 text-h4 ${areDecisionsIncoherent(game) ? "mb-6" : "mb-8"}`}>
          {theme.reports?.riskSummary?.usePersonalisedProfileTitle ? (
            <span>{portfolioMessages.title}</span>
          ) : (
            <Trans id="report-summary-page-risk-profile-title">What your risk preference results tell us about you</Trans>
          )}
        </h3>
        {areDecisionsIncoherent(game) && (
          <div className="p-3 mb-6 bg-surface-100">
            <div className="flex gap-x-3">
              <img src={theme.reports?.riskSummary?.inconsistentIcon ?? alert} alt="warning" />
              <p className="text-sm">
                <Trans id="report-summary-page-inconsistent-note">
                  We noticed some inconsistencies in how you took risk between the six scenarios. Please consider retaking the risk activity to ensure we get
                  the best read of your preferences. Your adviser will assist you with this.
                </Trans>
              </p>
            </div>
          </div>
        )}
        <div className="grid grid-cols-2 grid-flow-col gap-x-8">
          <div className="flex flex-col">
            {!theme.reports?.riskSummary?.hidePiechart && (
              <div className="flex gap-x-3 mb-3 items-center">
                <div className="w-13 h-13 flex items-center">
                  <DoughnutChart
                    forReport={true}
                    data={assetTypeWeights.map(({ total }) => total)}
                    colors={assetTypeWeights.map(({ assetClass }) => theme.colors.assetClassConfigurations[assetClass].color)}
                  />
                </div>
                <p className="font-medium">{portfolioMessages.title}</p>
              </div>
            )}
            {theme.reports?.riskSummary?.showDescription1 && (
              <p className="mb-3">
                <Trans id="report-summary-page-behaviour-description-1">
                  Thank-you for completing your risk check-in. You’ve given us valuable insights into how you’re currently thinking about investment risk.
                </Trans>
              </p>
            )}
            <p className="mb-3">{portfolioMessages.description}</p>
            <p>
              <Trans id="report-summary-page-behaviour-description-3">
                Along with your goals and constraints, your comfort with risk is an important piece of creating the right financial plan for you.
              </Trans>
            </p>
          </div>
          <div className="flex flex-col p-5 pb-8 bg-surface-100">
            <div className="flex gap-x-2 mb-2 items-center">
              <img src={theme.reports?.riskSummary?.noteImage ?? fingerprint} alt="fingerprint" />
              <p className="font-600">Your decisions showed us that...</p>
            </div>
            {game?.risk?.results?.R?.riskTolerance !== undefined && (
              <div className="border-solid border-b border-b-neutral-500">
                <p className="mb-3">
                  {game.risk.results?.R.riskTolerance <= 24 ? (
                    <Trans id="report-summary-page-risk-tolerance-low">
                      You probably don’t like to take too much risk when seeking investment opportunities.
                    </Trans>
                  ) : game.risk.results?.R.riskTolerance <= 59 ? (
                    <Trans id="report-summary-page-risk-tolerance-medium">
                      You’re still reasonably comfortable with taking risk when seeking investment opportunities.
                    </Trans>
                  ) : (
                    <Trans id="report-summary-page-risk-tolerance-high">You’re still comfortable with taking risk when seeking investment opportunities.</Trans>
                  )}
                </p>
              </div>
            )}
            {game?.risk?.results?.R?.lossAversion !== undefined && (
              <p className="mt-3">
                {game.risk.results?.R.lossAversion <= 19 ? (
                  <Trans id="report-summary-page-loss-aversion-low">You have low sensitivity to losses.</Trans>
                ) : game.risk.results?.R.lossAversion <= 49 ? (
                  <Trans id="report-summary-page-loss-aversion-medium">
                    During periods of market volatility you may want to mitigate risk. We should discuss our plans for volatile times so we can best support
                    you.
                  </Trans>
                ) : (
                  <Trans id="report-summary-page-loss-aversion-high">
                    During periods of market volatility you may want to de-risk. We should discuss our plans for volatile times so we can best support you."
                  </Trans>
                )}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="relative mx-10">
        <Footer pageNum={page} client={client} household={household} firm={firm} />
      </div>
    </Container>
  )
}

export default SummaryPage
