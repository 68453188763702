import { t } from "@lingui/macro"
import clsx from "clsx"
import React, { PropsWithChildren } from "react"
import { useLocation } from "react-router"
import useAppVersionCheck from "../hooks/useAppVersionCheck"
import CPLogo from "./images/CPLogo.svg"

const MainLayout: React.FC<PropsWithChildren<{ sidebar?: JSX.Element; navbar?: JSX.Element; footerDisclaimer?: JSX.Element }>> = ({
  children,
  sidebar,
  navbar,
  footerDisclaimer
}) => {
  const location = useLocation()
  useAppVersionCheck()

  const footer = footerDisclaimer ? (
    <footer className={clsx("w-full py-4 pr-8 flex justify-between")}>
      {footerDisclaimer}
      <div className="flex items-center">
        <div className="flex gap-x-2 items-center border-r pr-2 mr-2">
          <img className="h-3.5" src={CPLogo} alt="Powered by Capital Preferences" />
        </div>
        <a href={t({ id: "terms-of-service-link", message: "https://capitalpreferences.com.au/terms-of-service" })} className="text-sm border-r pr-2 mr-2" target="_blank" rel="noreferrer">
          Terms of Service
        </a>
        <a href={t({ id: "privacy-policy-link", message: "https://capitalpreferences.com.au/privacy-policy" })} className="text-sm" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
      </div>
    </footer>
  ) : (
    <></>
  )

  return (
    <>
      {!location.pathname.match(/^\/reports\//) &&
        (location.pathname.includes("/rm/") ? (
          <div className="w-full h-full flex items-center">
            <div className="h-full w-full flex flex-col items-center justify-stretch overflow-hidden">
              {navbar}
              <div className="pt-8 h-full w-full overflow-hidden px-14 flex flex-col items-center justify-between gap-y-2">
                <main className="w-full h-full overflow-y-hidden relative">
                  <div className="w-full h-full">{children}</div>
                </main>
                {footer}
              </div>
            </div>
            <aside className="rm-sidebar h-full">{sidebar}</aside>
          </div>
        ) : (
          <div className="w-full h-full flex flex-col items-center justify-stretch">
            <div className="h-full w-full flex flex-col items-center justify-stretch overflow-hidden">
              {navbar}
              <div className="main-layout-wrapper flex flex-row-reverse w-full h-full overflow-hidden">
                <div className="h-full w-full flex flex-col items-center justify-between overflow-hidden ">
                  <div className="pt-5 xl-max:pt-10 px-6 xl-max:px-8 w-full h-full overflow-hidden">
                    <main className="main-layout w-full h-full overflow-x-hidden relative shadow-100 overflow-y-hidden">
                      <div className="w-full h-full">{children}</div>
                    </main>
                  </div>
                  {footer}
                </div>
                <aside className="main-layout-sidebar h-full">{sidebar}</aside>
              </div>
            </div>
          </div>
        ))}
    </>
  )
}

export default MainLayout
