import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react"
import { useQuery } from "react-query"
import axiosInstance from "../api/axiosInstance"
import { RMDetail, RMPortfolioModel, RMSummaryAction } from "../models/RMDetail"
import { AuthContext, AuthStatus } from "../views/auth/AuthContext"

interface RMDetailContextType {
  setClientId: Dispatch<SetStateAction<string | undefined>>
  rmDetail?: RMDetail
  isRMDetailLoading: boolean
  isRMDetailLoadedSuccessfully?: boolean
  rmDetailLoadError?: { message?: string } | null
  reloadRMDetail: () => void
  selectedPortfolioModel?: RMPortfolioModel
  projectedPortfolioModels?: RMPortfolioModel[]
  setSelectedPortfolioModel: Dispatch<SetStateAction<RMPortfolioModel | undefined>>
  setProjectedPortfolioModels: Dispatch<SetStateAction<RMPortfolioModel[] | undefined>>
  summaryActionList?: RMSummaryAction[]
  setSummaryActionList: Dispatch<SetStateAction<RMSummaryAction[] | undefined>>
}

export const defaults: RMDetailContextType = {
  setClientId: () => {},
  isRMDetailLoading: false,
  reloadRMDetail: () => {},
  setSelectedPortfolioModel: () => {},
  setProjectedPortfolioModels: () => {},
  setSummaryActionList: () => {}
}

export const RMDetailContext = createContext(defaults)

const RMDetailContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { authStatus, sessionInfo } = useContext(AuthContext)
  const [clientId, setClientId] = useState<string>()
  const [selectedPortfolioModel, setSelectedPortfolioModel] = useState<RMPortfolioModel>()
  const [projectedPortfolioModels, setProjectedPortfolioModels] = useState<RMPortfolioModel[]>()
  const [summaryActionList, setSummaryActionList] = useState<RMSummaryAction[]>()

  const { isLoading, isSuccess, error, data, ...rest } = useQuery<RMDetail, { message?: string }>(
    ["rmdetail", clientId],
    () =>
      axiosInstance
        .get<RMDetail>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/clients/${clientId}/rmdetail`)
        .then((res) => {
          console.log("rmdetail", res)
          return res.data
        }),
    {
      enabled: authStatus === AuthStatus.SignedIn && !!sessionInfo?.accessToken && !!clientId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 900
    }
  )

  return (
    <RMDetailContext.Provider
      value={{
        setClientId,
        rmDetail: data,
        isRMDetailLoading: isLoading,
        isRMDetailLoadedSuccessfully: isSuccess,
        rmDetailLoadError: error,
        reloadRMDetail: rest.refetch,
        selectedPortfolioModel,
        setSelectedPortfolioModel,
        projectedPortfolioModels,
        setProjectedPortfolioModels,
        summaryActionList,
        setSummaryActionList
      }}
    >
      {children}
    </RMDetailContext.Provider>
  )
}

export default RMDetailContextProvider
