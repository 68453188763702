import { useCallback, useContext, useEffect, useState } from "react"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import Modal from "../../../../components/Modal/Modal"
import NumberInput from "../../../../components/NumberInput/NumberInput"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"

interface Props {
  value: number
  onClose: () => void
  client?: Client
  household?: Household
}

const EditPlanningHorizonModal = ({ value, onClose, client, household }: Props) => {
  const [planningHorizon, setPlanningHorizon] = useState<number | undefined>(value)
  const [errors, setErrors] = useState<string>("")
  const [shouldValidate, setShouldValidate] = useState(false)
  const [status, setStatus] = useState<"updating" | "success" | "error">()
  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const clientOrHousehold = client ?? household

  const validate = useCallback(() => {
    if (planningHorizon) {
      if (planningHorizon <= (clientOrHousehold?.retirementAge ?? 0) || planningHorizon > 120) {
        return "The planning horizon must be greater than your retirement age and at most 120 years"
      } else {
        return ""
      }
    } else {
      return "Please enter the planning horizon value"
    }
  }, [clientOrHousehold?.retirementAge, planningHorizon])

  const save = useCallback(() => {
    setStatus("updating")
    return (client ? updateClient(client._id, { planningHorizon }) : updateHousehold(household!._id, [], { planningHorizon })).then(onClose).catch(() => {
      setStatus("error")
    })
  }, [client, household, onClose, planningHorizon, updateClient, updateHousehold])

  const onApply = useCallback(() => {
    setShouldValidate(true)
    const errors = validate()
    setErrors(errors)
    if (!errors) {
      save()
    }
  }, [validate, save])

  // this is to validate on every change after "Apply" has been clicked the first time
  useEffect(() => {
    if (shouldValidate) {
      setErrors(validate)
    }
  }, [shouldValidate, planningHorizon, validate])

  return (
    <Modal handleClose={onClose} className="max-w-xl w-full">
      <form
        className="w-full"
        onSubmit={(e) => {
          e.preventDefault()
          onApply()
        }}
      >
        <h2 className="text-h2 pb-6">Edit planning horizon</h2>
        <NumberInput
          label={<span className="text-sec">Planning horizon</span>}
          name="planningHorizon"
          suffix="years"
          error={errors}
          value={planningHorizon}
          onChange={setPlanningHorizon}
        />
        {status === "error" && (
          <div className="text-error" role="alert">
            <span>Error updating the value</span>
          </div>
        )}
        <div className="flex justify-center gap-5 mt-6">
          <button type="button" onClick={onClose} className="btn btn-secondary btn-medium w-44">
            Cancel
          </button>
          <button className="btn btn-primary btn-medium w-44" disabled={!planningHorizon}>
            {status === "updating" ? <Loading type="dots" /> : "Apply"}
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default EditPlanningHorizonModal
