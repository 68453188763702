import { AnimatePresence } from "framer-motion"
import { useContext, useRef, useState } from "react"
import Dropdown from "../components/Dropdown/Dropdown"
import Modal from "../components/Modal/Modal"
import AccountSettings from "../pages/advisor/components/AccountSettings"
import ChangePassword from "../pages/advisor/components/ChangePassword"
import Signout from "../pages/advisor/components/Signout"
import burger from "./images/burger.svg"
import { useNavigate } from "react-router"
import share from "./images/share.svg"
import { AuthContext } from "../views/auth/AuthContext"
import { useTheme } from "../contexts/ThemeContext"
import UserPolicies from "../components/UserPolicies/UserPolicies"
import { UserPoliciesContext } from "../contexts/UserPoliciesContext"
import TwoFactorAuthentication from "../views/auth/TwoFactorAuthentication"

const MainMenu = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { isSuperAdmin } = useContext(AuthContext)
  const [billingModal, setBillingModal] = useState<boolean>(false)
  const [signOutModal, setSignoutModal] = useState<boolean>(false)
  const [twoFactorAuthenticationModal, setTwoFactorAuthenticationModal] = useState<boolean>(false)
  const [accountSettingsModal, setAccountSettingsModal] = useState<boolean>(false)
  const [changePasswordModal, setChangePasswordModal] = useState<boolean>(false)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const menuBtn = useRef<HTMLButtonElement>(null)
  const { firmHasBilling } = useContext(UserPoliciesContext)

  return (
    <>
      <div className="hamburger-menu-wrapper relative inline-block z-40 flex-shrink-0">
        <button type="button" className="btn border-transparent hover:bg-interactive-100 py-5 px-5 cursor-pointer" ref={menuBtn} onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <img src={burger} alt="menu" />
        </button>
        {isMenuOpen && (
          <Dropdown overlayClassName="w-[270px]" align="left" handleClose={() => setIsMenuOpen(false)} trigger={menuBtn}>
            <ul className="hamburger-menu list-none p-0 text-main-500">
              {theme.navbar.hamburgerMenu.links.personalDetails &&
                <li>
                  <button
                    className="btn p-4 hover:bg-interactive-100 cursor-pointer border-0 block w-full text-left font-normal"
                    onClick={() => {
                      setAccountSettingsModal(true)
                      setIsMenuOpen(false)
                    }}
                  >
                    Personal details
                  </button>
                </li>
              }
              {theme.navbar.hamburgerMenu.links.twoFactorAuth &&
                <li>
                  <button
                    className="btn p-4 hover:bg-interactive-100 cursor-pointer border-0 block w-full text-left font-normal"
                    onClick={() => {
                      setTwoFactorAuthenticationModal(true)
                      setIsMenuOpen(false)
                    }}
                  >
                    Two-factor authentication
                  </button>
                </li>
              }
              {theme.navbar.hamburgerMenu.links.changePassword &&
                <li>
                  <button
                    className="btn p-4 hover:bg-interactive-100 cursor-pointer border-0 block w-full text-left font-normal"
                    onClick={() => {
                      setChangePasswordModal(true)
                      setIsMenuOpen(false)
                    }}
                  >
                    Change password
                  </button>
                </li>
              }
              {theme.navbar.hamburgerMenu.links.contactSupport &&
                <li>
                  <a
                    href="mailto:customer-support@capitalpreferences.com?subject=Capital Preferences support request"
                    className="btn border-transparent hover:bg-interactive-100 p-4 block font-normal"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Contact support
                  </a>
                </li>
              }
              {theme.navbar.hamburgerMenu.links.knowledgeHub &&
                <li className="w-full">
                  <a href="https://capitalpreferences.helpscoutdocs.com/" className="btn font-normal border-transparent hover:bg-interactive-100 p-4 flex" target="_blank" rel="noreferrer">
                    Visit our Knowledge Hub <img className="ml-auto pr-3" src={share} alt="" aria-hidden="true" />
                  </a>
                </li>
              }
              {(theme.navbar.hamburgerMenu.links.billing && firmHasBilling) &&
                <li className="w-full">
                  <button
                    className="btn p-4 hover:bg-interactive-100 cursor-pointer border-0 block w-full text-left font-normal"
                    onClick={() => {
                      setBillingModal(true)
                      setIsMenuOpen(false)
                    }}
                  >
                    Subscription details
                  </button>
                </li>
              }
              {isSuperAdmin && (
                <li>
                  <button
                    className="btn border-transparent p-4 hover:bg-interactive-100 cursor-pointer border-0 font-normal block w-full text-left"
                    onClick={() => {
                      setIsMenuOpen(false)
                      navigate("/select")
                    }}
                  >
                    Switch firm
                  </button>
                </li>
              )}
              {theme.navbar.hamburgerMenu.links.logout &&
                <li className="hamburger-menu-logout text-interactive-500 font-bold">
                  <button
                    className="btn p-4 hover:bg-interactive-100 cursor-pointer border-0 block w-full text-left"
                    onClick={() => {
                      setSignoutModal(true)
                      setIsMenuOpen(false)
                    }}
                  >
                    Log out
                  </button>
                </li>
              }
            </ul>
          </Dropdown>
        )}
      </div>
      <AnimatePresence>
        {billingModal && (
          <Modal className="max-w-screen-md" contentClassName="!px-0 !py-0" handleClose={() => setBillingModal(false)}>
            <UserPolicies handleClose={() => setBillingModal(false)} interactive={false} modalType="billing" />
          </Modal>
        )}
        {signOutModal && (
          <Modal handleClose={() => setSignoutModal(false)}>
            <Signout handleClose={() => setSignoutModal(false)} />
          </Modal>
        )}
        {twoFactorAuthenticationModal && (
          <Modal handleClose={() => setTwoFactorAuthenticationModal(false)}>
            <TwoFactorAuthentication handleClose={() => setTwoFactorAuthenticationModal(false)} />
          </Modal>
        )}
        {accountSettingsModal && (
          <Modal handleClose={() => setAccountSettingsModal(false)}>
            <AccountSettings handleClose={() => setAccountSettingsModal(false)} />
          </Modal>
        )}
        {changePasswordModal && (
          <Modal handleClose={() => setChangePasswordModal(false)}>
            <ChangePassword handleClose={() => setChangePasswordModal(false)} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  )
}

export default MainMenu
