import { useMemo } from "react"

const useNumberFormatter = (locale:string) => {
  const formatter = useMemo(() => {
    const options = {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    }
    return new Intl.NumberFormat(locale, options)
  }, [locale])

  return useMemo(() => {
    return {
      format: (value: number) => {
        return formatter.format(value).replace("NaN", "")
      },
      parse: (value: string) => {
        if (value === "") {
          return
        }
        // get the different parts of formatted value
        // use 1,000,000 to make sure we get a separator (, . or space)
        const parts = formatter.formatToParts(1000000)
        // second value is the separator
        const separator = parts[1].value

        // default to space as separator
        let pattern = /[^0-9\s]/
        if (separator === ",") {
          // only contains numbers and commas
          pattern = /[^0-9,]/
        } else if (separator === ".") {
          // only contains numbers and dots
          pattern = /[^0-9.]/
        }

        if (pattern.test(value) === false) {
          return parseFloat(value.replaceAll(separator, ""))
        } else {
          return NaN
        }
      }
    }
  }, [formatter])
}

export default useNumberFormatter
