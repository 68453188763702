import { UIEventHandler, useContext } from "react"
import warning from "./assets/warning.svg"
import { FirmContext } from "../../../contexts/FirmContext"
import { tt } from "../../../lib/translations"

interface Props {
  handleClose: UIEventHandler
  handleConfirm: UIEventHandler
}

const ImportConfirm: React.FunctionComponent<Props> = ({ handleClose, handleConfirm }) => {
  const { firm } = useContext(FirmContext)
  const integrationTrans = tt({ id: `${Object.keys(firm?.integrations ?? {})?.[0]}-label`})

  return (
    <div className="import-confirm-modal w-modal flex flex-col items-center gap-6">
      <img className="w-25 h-25" src={warning} alt="" aria-hidden />
      <h2 className="text-h2 font-semibold text-center">Are you sure you want to import from Excel?</h2>
      <p>{tt({ id: "import-confirm-modal-description", values: { integration: integrationTrans } })}</p>
      <div className="flex items-center gap-5">
        <button className="btn btn-secondary btn-medium w-44" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn btn-primary btn-medium w-44" onClick={handleConfirm}>
          Import from Excel
        </button>
      </div>
    </div>
  )
}

export default ImportConfirm
