import clsx from "clsx"
import { ReactNode } from "react"

interface Props {
  button?: ReactNode
  children?: ReactNode
  showBottomBorder?: boolean
  title?: ReactNode | string
  titleElement?: "h1" | "h2" | "h3"
  className?: string
}

const Section = ({ button, children, showBottomBorder = true, title, titleElement = "h2", className }: Props) => {
  return (
    <div
      className={clsx({ "border-b-0.5 border-surface-300": showBottomBorder },
        className
      )}
    >
      <div className="flex flex-col md:flex-row justify-between">
        {title && (
          <div>
            {titleElement === "h1" && <h1 className="text-h1 text-main-600 font-semibold">{title}</h1>}
            {titleElement === "h2" && <h2 className="text-h2 text-main-600 font-semibold">{title}</h2>}
            {titleElement === "h3" && <h3 className="text-h3 text-main-600 font-semibold">{title}</h3>}
          </div>
        )}
        {button && (
          <div>
            {button}
          </div>
        )}
      </div>
      <div className="flex flex-col">{children}</div>
    </div>
  )
}

export default Section
