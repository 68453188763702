import { createContext, PropsWithChildren, useCallback } from 'react'
// import { useLocation } from 'react-router'
import GA4 from "react-ga4"

import.meta.env.VITE_APP_GA_TRACKING_ID && GA4.initialize(import.meta.env.VITE_APP_GA_TRACKING_ID!)

export interface TrackingEvent {
  action: string,
  category: string
  label?: string,
  value?: number
}

interface TrackingContextType {
  trackViewEvent: (data:TrackingEvent) => void
}

export const defaults:TrackingContextType = {
  trackViewEvent: () => {}
}
// interface ViewportSize {
//   width: number,
//   height: number,
// }
// const viewportSize: ViewportSize = { width: window.innerWidth, height: window.innerHeight }

export const TrackingContext = createContext(defaults)
  const TrackerGA4:React.FC<PropsWithChildren<{}>> = ({ children }) => {
  // const location = useLocation()
  // const [viewportSize, setViewportSize] = useState<ViewportSize>({ width: window.innerWidth, height: window.innerHeight })
  // eslint-disable-next-line
  const trackViewEvent = useCallback(import.meta.env.VITE_APP_GA_TRACKING_ID ? (data:TrackingEvent) => GA4.event(data) : () => {}, [])

  return (
    <TrackingContext.Provider value={{trackViewEvent}}>
      {children}
    </TrackingContext.Provider>
  )
}

export default TrackerGA4
