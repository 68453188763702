import clsx from "clsx"
import { useEffect, useMemo, useState } from "react"
import { useMutation, useQuery } from "react-query"
import axiosInstance from "../../../../api/axiosInstance"
import checkmarkCircle from "../../../../assets/icons/checkmark-circle.svg"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import TextInput from "../../../../components/TextInput/TextInput"
import RenderQueryResults from "../RenderQueryResults"

type LicenseeData = {
  _id: string,
  name: string
}

const CreateLicensee = () => {

  const licensees = useQuery(["licensees"], () => axiosInstance.get<{_id: string, name: string}[]>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/licensees`)
    .then(res => res.data))

  const [name, setName] = useState<string>("")
  const [id, setId] = useState<string>("")
  const [idChanged, setIdChanged] = useState(false)
  const isValid = useMemo(() => !!id && !!name, [id, name])

  useEffect(() => {
    if (name && !idChanged) {
      setId(name.replaceAll(" ", "-").toLowerCase())
    }
  }, [name, id, setId, idChanged])
  
  const createLicensee = useMutation<any, any, { licenseeData: LicenseeData }>({
    mutationFn: ({ licenseeData }) => {
      return axiosInstance.post(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/licensees`, licenseeData)
        .then(res => {
          licensees.refetch()
          return res.data
        })
    },
  })
  const isNameDuplicate = useMemo(() => licensees.data?.find(l => l.name === name), [licensees, name])
  const isIdDuplicate = useMemo(() => licensees.data?.find(l => l._id === id), [licensees, id])
  const isIllegalName = useMemo(() => !/^[\wA-Za-z0-9'"._\-$@&, ]*$/.test(name), [name])
  const isIllegalId = useMemo(() => !/^[A-Za-z0-9-]*$/.test(id), [id])
  
  useEffect(() => {
    if (createLicensee.isSuccess) {
      setId("")
      setName("")
    }
  }, [createLicensee])
  

  return (<div className="pg-ctr pg-ctr-py-lg w-full h-full overflow-y-auto grid grid-cols-[1fr_300px] auto-rows-min">

    <div className="flex flex-col gap-y-4 pt-1 pl-10 h-full">
      <h2 className="w-full bg-neutral-100x text-h2">Create a new licensee</h2>
      <div className="w-full lg:w-2/3">
        <div className="mt-4 pr-8 flex gap-x-8">
          <div className="w-full flex flex-col items-start gap-y-8">
            <label className="font-bold w-full">
              Licensee Name:
              <TextInput name="name" value={name} onChange={setName}
                error={isNameDuplicate ? "Duplicate name" : isIllegalName ? "Illegal character(s) in name" : null}
              />
            </label>
            <label className="font-bold w-full">
              Licensee ID: {!id && <span className="text-xs">(will be computed from name if not set)</span>}
              <TextInput name="id" value={id} onChange={(val) => {
                setId(val)
                setIdChanged(!!val)
              }} error={isIdDuplicate ? "Duplicate ID" : isIllegalId ? "Illegal character(s) in ID" : null}/>
            </label>
          </div>
        </div>
        <p className="mt-12 text-sec">
          This will create a new licensee with ID <span className="font-semibold">{id}</span><br/>
          and name <span className="font-semibold">{name}</span>.
        </p>
        <div className="w-full flex mt-8 gap-x-2">
          <button
            className={clsx("btn w-64 px-4 py-2", isValid && !createLicensee.isLoading ? "btn-primary" : "btn-secondary")}
            disabled={!(name && id && !isIdDuplicate && !isIllegalId && !isNameDuplicate && !isIllegalName)}
            onClick={isValid && !createLicensee.isLoading
              ? () => createLicensee.mutate({ licenseeData: { _id: id, name } })
              : () => {}
            }>{createLicensee.isLoading ? <>Creating...</> : <>Create</>}</button>
            <span>
              {createLicensee.isLoading && <Loading />}
              {createLicensee.isSuccess && <span className="flex gap-x-1 text-positive-600"><img src={checkmarkCircle} /> Created.</span>}
            </span>
        </div>
        {createLicensee.isError && <p className="mt-4 text-error">{createLicensee.error?.toString()} ({createLicensee.error?.response?.data?.message})</p>}
      </div>
    </div>
    <div className="pr-10 border-l border-neutral-200 pl-4 h-full">
      <h4 className="text-h4">Existing Licensees</h4>
        <RenderQueryResults queryResult={licensees} renderData={(data) =>
          <ul className="flex flex-wrap gap-x-2 mt-2 gap-y-2">
            {data.map((l, i) => (
              <li key={i} className={clsx("px-4 py-2 rounded-3 border", l._id === id || l.name === name ? "border-error bg-red-400" : "border-interactive-400 bg-neutral-50")}>
                <div className="text-xs">{l._id}</div>
                <div className="text-sec">{l.name}</div>
              </li>
            ))}
          </ul>}
        />
      </div> 
  </div>)
}

export default CreateLicensee