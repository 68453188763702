import { Link } from "react-router-dom"


const SiteNavigatorPage = () => {

  return (
    <div className="pg-ctr pg-ctr-py-lg h-full w-full flex flex-col items-center justify-center">
      <div className="grid grid-cols-3 gap-8 text-center">
        <Link to="/admin/licensees" className="px-20 btn btn-secondary btn-large">Licensees</Link>
        <Link to="/admin/firms" className="px-20 btn btn-secondary btn-large">Firms</Link>
        <Link to="/admin/billing/codes" className="px-20 btn btn-secondary btn-large">Billing Setup</Link>
      </div>
    </div>
  )
}

export default SiteNavigatorPage