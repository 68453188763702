import { Theme } from "../../../config/theme"
import { Client } from "../../../models/Client"

interface CreateClientDataPoints {
  clients?: Client[]
  theme: Theme
  values: {
    id: string
    value: number
  }[]
}

export const createClientDataPoints = ({ clients = [], values, theme }: CreateClientDataPoints) => {
  return clients.map((client, i) => {
    const colorIndex = i % 2

    return {
      id: `data-point-${client?.firstName}`,
      radius: 10,
      textSize: 14,
      color: theme.colors.avatarColors[colorIndex],
      textColor: theme.colors.avatarTextColor,
      offSetX: 3,
      offSetY: 3,
      text: client.firstName.charAt(0),
      x: values[i]?.id,
      y: values[i]?.value
    }
  })
}
