import React from 'react';
import { UseQueryResult } from 'react-query'
import Loading from "../../../components/ClientProfile/Loading/Loading";

interface ErrorLoadingStuffProps<T> {
  queryResult: UseQueryResult<T>
  renderData: (data: T) => React.ReactNode
}

function RenderQueryResults<T>({ queryResult, renderData }: ErrorLoadingStuffProps<T>) {
  const { isLoading, isError, data, error } = queryResult;

  if (isLoading) return <Loading />
  if (isError) return <div>Error: {(error as any)?.message}</div>
  if (data) return <>{renderData(data)}</>

  return null
}

export default RenderQueryResults;
