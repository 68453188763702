import { useContext, useMemo } from "react"
import { FirmContext } from "../../../contexts/FirmContext"
import { AssetClass, SubAssetClass } from "../../../models/InvestmentUniverse"
import { ModelPortfolio } from "../../../models/PortfolioModel"
import { useTheme } from "../../../contexts/ThemeContext"
import { DoughnutChart } from "../../rmjourney/components/DoughnutChart/DoughnutChart"
import { ASSET_TYPES } from "../../../config/assetClasses"

const PortfolioDetail = ({ portfolio, forReport = false }: { portfolio: ModelPortfolio; forReport?: boolean }) => {
  const { firm } = useContext(FirmContext)
  const theme = useTheme()
  const assetTypeWeights =
    useMemo(() => {
      const allAssetClasses = firm?.assetClasses
        .flatMap((ac) => [ac, ...(ac.subAssetClasses || []).map((sac) => ({ ...sac, type: sac.type ?? ac.type }))] as (AssetClass | SubAssetClass)[])
        .reduce(
          (acc, cur) => ({
            ...acc,
            [cur.id!]: cur
          }),
          {} as { [key: string]: AssetClass | SubAssetClass }
        )
      return ASSET_TYPES.map((ac, jj) => ({
        assetClass: ac,
        colorIndex: jj,
        total: Math.round(portfolio.components.filter((c) => allAssetClasses![c.id].type === ac).reduce((sum, x) => sum + x.weight, 0) * 100) / 100
      })).filter(({ total }) => total > 0)
    }, [firm?.assetClasses, portfolio.components]) || {}

  return (
    <div className="flex gap-x-2">
      <div className="w-21">
        <DoughnutChart
          forReport={forReport}
          data={assetTypeWeights.map(({ total }) => total)}
          colors={assetTypeWeights.map(({ assetClass }) => theme.colors.assetClassConfigurations[assetClass].color)}
        />
      </div>
      <div className="flex flex-col ml-2">
        <h4 className="text-main-600 font-semibold text-sec mb-1">{`${portfolio.name} investment`}</h4>
        <div className="w-full flex flex-col">
          {assetTypeWeights.map(({ assetClass, total }, kk) => (
            <div className="flex flex-row justify-start mb-1" key={kk}>
              <div className="rounded-full h-1 w-1 my-auto mr-1" style={{ backgroundColor: theme.colors.assetClassConfigurations[assetClass].color }}></div>
              <label className="text-sm mr-4">
                {total}% {theme.colors.assetClassConfigurations[assetClass].label}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PortfolioDetail
