import React from 'react'
import { JSONTree } from 'react-json-tree'
import { Link } from "react-router-dom"
import { Message } from './ViewTopic'

interface CollapsibleMessageProps {
  message: Message
}

const theme2 = {
  scheme: 'custom',
  author: 'me',
  base00: 'var(--color-surface-100, #F8F8F8)',  // Background
  base01: 'var(--color-surface-200, #E8E8E8)',  // Light background
  base02: 'var(--color-surface-300, #D8D8D8)',  // Lighter background
  base03: 'var(--color-neutral-200, #C5C7C9)',  // Lighter foreground
  base04: 'var(--color-neutral-400, #8C8E90)',  // Light foreground
  base05: 'var(--color-main-400, #4D4F63)',     // Foreground
  base06: 'var(--color-main-500, #272A43)',     // Dark foreground
  base07: 'var(--color-main-600, #1A1C2F)',     // Dark background
  base08: 'var(--color-negative-500, #C76B4E)', // Red
  base09: 'var(--color-goal-500, #D49358)',     // Orange
  base0A: 'var(--color-highlight-500, #7681DE)',// Yellow
  base0B: 'var(--color-positive-500, #73AB69)', // Green
  base0C: 'var(--color-data-olive-500, #849E42)',// Aqua/Cyan
  base0D: 'var(--color-link-600, #694589)',     // Blue
  base0E: 'var(--color-avatar-600, #562483)',   // Purple
  base0F: 'var(--color-negative-600, #B8541C)'  // Brown
}

const CollapsibleMessage: React.FC<CollapsibleMessageProps> = ({ message }) => {

  return (
    <div className="flex items-stretch overflow-ellipsis gap-x-1 px-1">
      <div className="json-view w-1/5 flex flex-col grow rounded-2">
        <JSONTree
          data={message.headers}
          theme={theme2}
          invertTheme={false}
          hideRoot={true}
        />
      </div>
      <div className="json-view w-full flex flex-col grow rounded-2">
        <JSONTree
          data={message.json ?? JSON.parse(message.value)}
          theme={theme2}
          invertTheme={false}
          hideRoot={true}
          shouldExpandNodeInitially={(keyPath, data, level) => level < 2}
          // labelRenderer={(path, type, exanded, expandable) => {
          //   return path[0]
          // }}
          valueRenderer={(valueAsString, value, keyPath) => {
            return keyPath === "clientId"
              ? <Link className="underline text-interactive-400" to={`/clients/${value}`}>"{value?.toString()}"</Link>
              : keyPath === "firmId"
              ? <Link className="underline text-interactive-400" to={`/admin/firm/${value}`}>"{value?.toString()}"</Link>
              : keyPath === "advisorId"
              ? <Link className="underline text-interactive-400" to={`/?advisorId=${value}`}>"{value?.toString()}"</Link>
              : <>{valueAsString}</>
          }}

        />
      </div>
    </div>
  )
}

export default CollapsibleMessage
