import { useRef, useState } from "react"
import Dropdown from "../../../components/Dropdown/Dropdown"
import moreIcon from "../images/more-vertical.svg"
import trash from "../images/Trash.svg"

interface Props {
  alignVertical?: "bottom" | "top"
  onDeleteClick: () => void
  onEditClick: () => void
}

const ClientItemMenu = ({ alignVertical, onDeleteClick, onEditClick }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const menuBtn = useRef<HTMLButtonElement>(null)

  return (
    <div className="relative flex items-center">
      <button className="p-1 border-2 border-transparent hover:border-interactive-200" onClick={() => isMenuOpen ? setIsMenuOpen(false) : setIsMenuOpen(true)} ref={menuBtn}>
        <img alt="Actions" src={moreIcon} />
      </button>
      {isMenuOpen && (
        <Dropdown overlayClassName="w-[200px]" align="left" alignVertical={alignVertical} handleClose={() => setIsMenuOpen(false)} trigger={menuBtn}>
          <ul className="list-none p-0 text-main-500">
            <li>
              <button
                className="btn text-p p-4 hover:bg-interactive-100 cursor-pointer border-0 block w-full text-left font-normal"
                onClick={() => {
                  setIsMenuOpen(false)
                  onEditClick()
                }}
              >
                Edit asset class
              </button>
            </li>
            <li>
              <button
                className="btn text-p p-4 hover:bg-interactive-100 cursor-pointer border-0 flex justify-between items-center w-full text-left font-normal"
                onClick={() => {
                  setIsMenuOpen(false)
                  onDeleteClick()
                }}
              >
                Delete asset class
                <img alt="delete" src={trash} aria-hidden />
              </button>
            </li>
          </ul>
        </Dropdown>
      )}
    </div>
  )
}

export default ClientItemMenu
