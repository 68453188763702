import { Advisor } from "../../models/Advisor"
import { SessionInfo } from "../../views/auth/AuthContext"
import axiosInstance from "../axiosInstance"

export type NewAdvisor = {
  firstName?: string
  lastName?: string
  email?: string
  notifyUser?: boolean
  manageAllClients?: boolean
  isAdmin?: boolean
}

export const createAdvisor = async (sessionInfo: SessionInfo, firmId: string, advisor: NewAdvisor) => {
  const res = await axiosInstance
    .post<Advisor>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/firm/${firmId}/advisors`, advisor)
    .then((res) => res.data)
  console.log("res in ts", res)
  return res
}
