import { useTheme } from "../../../../../../contexts/ThemeContext"
import { AssetClass } from "../../../../../../models/InvestmentUniverse"
import { WeightedComponent } from "../../../../../../models/PortfolioModel"
import { allAssetClasses, assetTypeWeights } from "../../../../../clients/reports/ComfortMatchPage"
import { DoughnutChart } from "../../../../components/DoughnutChart/DoughnutChart"
import { Options } from "../../SelectPortfolio/SelectPortfolio"

interface ChartTooltipProps {
  x?: number
  y?: number
  index: number
  portfolioComponents?: WeightedComponent[]
  assetClasses: AssetClass[]
  chartWidth: number
  props?: any
  options: Options
}
const LineTooltip = ({ portfolioComponents, assetClasses, x, y, index, chartWidth, options }: ChartTooltipProps) => {
  const width = 200
  const height = 70
  const offsetX = x! + width / 2 >= chartWidth ? 200 : 100
  const offsetY = 5
  const theme = useTheme()

  const allClasses = allAssetClasses(assetClasses) || {}
  const assetWeights = portfolioComponents && allClasses ? assetTypeWeights(portfolioComponents, allClasses) : undefined

  return (
    <g>
      <foreignObject width={width} height={height} x={x! - offsetX} y={y! + offsetY}>
        <div className="flex w-full gap-x-2 bg-white px-2 py-3 text-sm text-main-500 shadow border border-interactive-200 items-center">
          <div className="h-10 w-10">
            {assetWeights && (
              <DoughnutChart
                data={assetWeights.map(({ total }) => total)}
                colors={assetWeights.map(({ assetClass }) => theme.colors.assetClassConfigurations[assetClass].color)}
              />
            )}
          </div>
          {options.drawdownChartOptions && (
            <div className="flex flex-col">
              <p className="text-p mb-1">{options.drawdownChartOptions[index].name}</p>
              {options.drawdownChartOptions[index].riskComfort !== undefined && <p className="text-xs uppercase">{options.drawdownChartOptions[index].riskComfort}% Risk Comfort</p>}
            </div>
          )}
        </div>
      </foreignObject>
    </g>
  )
}

export default LineTooltip
