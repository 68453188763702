import { useMemo } from "react"
import { Household } from "../../../../../../models/Household"
import { clientReports } from "../../../../../../lib/regulatoryReports"
import ReportsList, { ReportsListItem } from "../../../../../../components/ReportsList/ReportsList"

export interface ReportItem {
  csvContent: string
  fileName: string
  date: string
  id: string
  message: string
}

const HouseholdReporting = ({ household }: { household: Household }) => {
  const reports: ReportsListItem[] = useMemo(() => {
    const nextReports: ReportItem[] = []

    household.members.forEach(({ client }) => {
      const memberReports: ReportItem[] = clientReports(client, `Risk activity data (${client.firstName} ${client.lastName})`)
      nextReports.push(...memberReports)
    })

    return nextReports.map(({ date, csvContent, fileName, id, message }) => ({
      date,
      id,
      link: (
        <a className="text-riskComfort-500 underline" href={csvContent} download={fileName}>
          Export data
        </a>
      ),
      title: message
    }))
  }, [household.members])

  return <ReportsList reports={reports} />
}

export default HouseholdReporting
