import { useNavigate, useParams } from "react-router"
import Checkbox from "../components/Checkbox/Checkbox"
import useClient from "../../../hooks/useClient"
import arrowLeft from "../assets/images/arrow-left.svg"
import useHousehold from "../../../hooks/useHousehold"
import SidebarHeader from "../components/SidebarHeader/SidebarHeader"
import { useContext, useEffect, useMemo, useState } from "react"
import { ClientHouseholdCacheContext } from "../../../contexts/ClientHouseholdCacheContext"
import { ClientUpdateRequest } from "../../../api/clients"
import { HouseholdUpdateRequest } from "../../../api/households"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import { latest } from "../../../lib/clients"

const RMJLossSensitivitySideBar = () => {
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client } = useClient(clientId)
  const { household } = useHousehold(householdId)
  const navigate = useNavigate()

  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const [createStrategy, setCreateStrategy] = useState<boolean>(false)
  const [status, setStatus] = useState<"updating" | "error">()
  const game = latest((client ?? household)!, "risk")
  const existing = useMemo(() => {
    const actionItems = game?.actionItems
    return actionItems?.find((item) => item.key === "create-strategy-for-volatile-times")
  }, [game?.actionItems])

  useEffect(() => {
    setCreateStrategy(!!(existing && Boolean(existing.value)))
  }, [existing])
  const onNext = () => {
    if (createStrategy !== !!(existing && Boolean(existing.value))) {
      const actionItems = game?.actionItems
      const req: ClientUpdateRequest | HouseholdUpdateRequest = {
        actionItems: existing
          ? actionItems!.map((x) => (x.key === "create-strategy-for-volatile-times" ? { ...x, value: createStrategy } : x))
          : [
              ...(actionItems || []),
              {
                key: "create-strategy-for-volatile-times",
                value: createStrategy
              }
            ]
      }
      setStatus("updating")
      const updatePromise = client ? updateClient(client._id, req) : updateHousehold(household!._id, [], req)
      updatePromise
        .then(() => {
          navigate("../portfolio-comfort")
        })
        .catch(() => {
          setStatus("error")
          console.error("error updating action items")
        })
    } else {
      navigate("../portfolio-comfort")
    }
  }

  return (
    <div className="w-80 h-full bg-interactive-600 flex flex-1 flex-col justify-between">
      {(client || household) && (
        <>
          <div className="flex gap-x-2.5 overflow-y-auto no-scrollbar">
            <div className="flex flex-col">
              {client && <SidebarHeader client={client} />}
              {household && <SidebarHeader household={household} />}
              <div className="sidebar-section">
                <h3 className="sidebar-section-title text-sec">Sensitivity to Loss score</h3>
                <p className="sidebar-section-text text-sec">
                  Your Sensitivity to Loss score reflects how unsettled you may become when markets are down, which happens from time to time.
                </p>
              </div>
              {client && (
                <div className="sidebar-section">
                  <h3 className="sidebar-section-title text-sec">Discuss</h3>
                  {client.existingClient ? (
                    <p className="sidebar-section-text text-sec">
                      How are you feeling about the level of volatility that you're seeing in the market today?
                    </p>
                  ) : (
                    <p className="sidebar-section-text text-sec">Describe how you felt the last time markets were volatile?</p>
                  )}
                </div>
              )}
              {household && (
                <div className="sidebar-section">
                  <h3 className="sidebar-section-title text-sec">Discuss</h3>
                  {household.existingClient ? (
                    <p className="sidebar-section-text text-sec">
                      How are you feeling about the level of volatility that you're seeing in the market today?
                    </p>
                  ) : (
                    <p className="sidebar-section-text text-sec">How did each of you feel the last time markets were volatile?</p>
                  )}
                </div>
              )}
              <div className="sidebar-section">
                <h3 className="sidebar-section-title text-sec">Actions</h3>
                <Checkbox
                  name="action-plan"
                  className=""
                  label={<span className="text-sec">Create a strategy for volatile times</span>}
                  checked={createStrategy}
                  onChange={() => setCreateStrategy(!createStrategy)}
                />
              </div>
            </div>
          </div>
          {status === "error" && <p className="text-error">Error updating the details</p>}
          <div className="px-4 pb-4 w-full flex justify-between items-center">
            <div onClick={() => navigate(-1)} className="cursor-pointer w-10">
              <img className="mt-3" src={arrowLeft} alt="arrow left icon" />
            </div>
            <button
              type="button"
              className="flex items-center justify-center btn btn-medium btn-primary-alt text-center mt-4 h-14 grow"
              onClick={onNext}
              disabled={status === "updating"}
            >
              {status === "updating" ? <Loading type="dots" /> : "Next"}
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default RMJLossSensitivitySideBar
