import clsx from "clsx"
import { ReactNode } from "react"

export interface Props {
  isActive?: boolean
  isDisabled?: boolean
  label: string
  leftElement: ReactNode
  link?: string
  onClick?: () => void
  shouldOpenInNewTab?: boolean
}

const SidebarMenuItem = ({ isDisabled = false, isActive = false, label, leftElement, link, onClick, shouldOpenInNewTab = false }: Props) => {
  if (link) {
    return (
      <a
        className={clsx("btn btn-alt-text whitespace-nowrap", {
          "is-disabled": isDisabled,
          "is-active": isActive,
        })}
        href={link}
        onClick={onClick}
        rel="noreferrer"
        target={shouldOpenInNewTab ? "_blank" : undefined}
      >
        <div className="mr-2">{leftElement}</div>
        <p className="">{label}</p>
      </a>
    )
  } else {
    return (
      <button className={clsx("btn btn-alt-text whitespace-nowrap", { "is-active": isActive })} disabled={isDisabled} onClick={onClick}>
        <div className="mr-2">{leftElement}</div>
        <p className="">{label}</p>
      </button>
    )
  }
}

export default SidebarMenuItem
