import { useState } from "react"
import TextInput from "../TextInput/TextInput"
import Loading from "../ClientProfile/Loading/Loading"
import ListItem from "./components/ListItem/ListItem"
import addNoteIcon from "../../assets/icons/add-note.svg"

export interface NotesListItem {
  date: Date
  id: string
  message: string
  userName?: string
  warningAlert?: {
    message: string
  }
}

interface Props {
  isDisabled: boolean
  isLoading?: boolean
  isNoteSaving: boolean
  items: NotesListItem[]
  onAddNote: (note: string) => void
}

const NotesList = ({ isDisabled, isLoading = false, isNoteSaving, items, onAddNote }: Props) => {
  const [showAddNote, setShowAddNote] = useState(false)
  const [isNotValid, setIsNotValid] = useState(false)
  const [noteText, setNoteText] = useState<string>()

  const onInputChange = (value: string) => {
    setNoteText(value)
  }

  const addNote = () => {
    if (!noteText) {
      setIsNotValid(true)
    } else {
      onAddNote(noteText)
      setIsNotValid(false)
      setNoteText("")
    }
  }

  return (
    <div className="flex flex-col mt-6">
      <div className="notes-list max-h-[29rem] bg-surface-100 overflow-auto rounded-lg py-2 text-main-500">
        {items.length > 0 ? (
          <div className="flex flex-col divide-y-0.5 divide-surface-400 border-surface-400">
            {items.map(({ date, id, message, userName, warningAlert }, i) => (
              <ListItem date={date} key={`${id}-${i}`} message={message} userName={userName} warningAlert={warningAlert} />
            ))}
          </div>
        ) : (
          <p className="text-p text-main-400 text-center py-3">No notes to display</p>
        )}
      </div>
      <div className="py-3">
        {showAddNote ? (
          <div className="min-h-[8rem] flex items-center justify-center px-1">
            <div className="w-full flex flex-col">
              <div className="mb-4">
                <TextInput
                  label={<span className="text-main-500 font-semibold ml-1 text-p">Add note</span>}
                  error={isNotValid && "Please enter a note"}
                  disableAutoComplete={true}
                  name="note"
                  focusOnLoad
                  onChange={onInputChange}
                  value={noteText}
                />
              </div>
              <div className="flex gap-3 items-center">
                <button className="btn btn-primary btn-medium w-44" onClick={addNote}>
                  {isNoteSaving || isLoading ? <Loading type="dots" /> : <span>Add note</span>}
                </button>
                <button className="btn btn-text btn-text-md" onClick={() => setShowAddNote(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        ) : (
          <button
            className="add-note-btn btn btn-text btn-text-md flex items-center justify-center align-middle gap-2 disabled:opacity-50 disabled:pointer-events-none"
            disabled={isDisabled}
            onClick={() => setShowAddNote(true)}
          >
            <img className="add-note-btn__img mr-1 w-4 h-4" src={addNoteIcon} alt="" aria-hidden />
            <span className="text-interactive-500 font-bold">Add note</span>
          </button>
        )}
      </div>
    </div>
  )
}

export default NotesList
