import { useContext } from "react"
import { FirmContext } from "../../../../contexts/FirmContext"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import riskDimensions from "../../../rmjourney/adviceOverview/assets/risk-dimensions-beige.png"
import DecisionsTable from "../../../rmjourney/yourDecisions/components/DecisionsTable/DecisionsTable"
import Container from "../components/Container"
import Footer from "../components/Footer"

const RiskDimensionsPage = ({ page, client, household }: { page: number; client: Client; household: Household }) => {
  const { firm } = useContext(FirmContext)

  return (
    <Container className="flex flex-col justify-between pt-10 relative">
      <div className="flex-auto mx-10">
        <h2 className="mb-2 text-main-600 text-sec tracking-wide uppercase">Risk dimensions</h2>
        <h3 className="text-main-600 font-semibold text-h4">There are three dimensions of risk that will shape your financial strategy</h3>
        <div className="grid grid-cols-2 gap-6 mt-4 items-center">
          <div className="flex flex-col gap-y-6">
            <div className="text-sec text-main-600">
              <h4 className="font-bold">Preferences</h4>
              <p className="mt-2 leading-tight">Your risk preferences are important as they determine how comfortable you’ll be with our investment options.</p>
            </div>
            <div className="text-sec text-main-600">
              <h4 className="font-bold">Goals</h4>
              <p className="mt-2 leading-tight">Your goals are central to your investment strategy and will guide the risk required to meet your goals.</p>
            </div>
            <div className="text-sec text-main-600">
              <h4 className="font-bold">Constraints</h4>
              <p className="mt-2 leading-tight">
                Your constraints may include your income, timeframe, debt levels, and other factors that may influence your capacity to take risk.
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center grow">
            <img src={riskDimensions} alt="Risk dimensions" aria-hidden />
          </div>
        </div>
        <p className="mt-6 leading-tight text-sec text-main-600">
          Our planning process balances these three factors to come to a recommendation that offers the best path toward achieving your lifestyle goals.
        </p>

        <div className="mt-6">
          <h2 className="mb-2 text-main-600 text-sec tracking-wide uppercase">Your decision pattern</h2>
          <h3 className="text-main-600 font-semibold text-h4">Here are the decisions you made in the activity</h3>
          <div className="flex flex-col w-full text-main-600 mt-5">
            {household && <DecisionsTable enableAnimations={false} forReport={true} clients={household.members.map(({ client }) => client)} />}
            {client && <DecisionsTable enableAnimations={false} forReport={true} clients={[client]} />}
          </div>
        </div>
      </div>

      <div className="relative mx-10">
        <Footer pageNum={page} client={client} household={household} firm={firm} />
      </div>
    </Container>
  )
}

export default RiskDimensionsPage
