import React, { useEffect, useMemo } from "react"
import { shortRound } from "../../../../../lib/numbers"
interface DataPointProps {
  forReport?: boolean
  style?: {
    fill: string
  }
  x?: number
  y?: number
  chartWidth: number
  paddingRight: number
  datum?: { x: number; y: number }
  setTargetDateLine: React.Dispatch<
    React.SetStateAction<{
      x: number
      y: number
    }>
  >
}
const DataPoint = ({ x, y, chartWidth, paddingRight, datum, setTargetDateLine }: DataPointProps) => {
  const width = 50
  const height = 17
  const offSet = height / 2
  const gap = 2
  const { value, unit } = useMemo(() => shortRound(datum?.y ?? 0), [datum?.y])

  const tooltipXpos = x! + width + paddingRight
  const orientation = tooltipXpos > chartWidth ? x! - (width + offSet + gap) : x! + offSet + gap

  useEffect(() => {
    setTargetDateLine({ x: x!, y: y! })
  }, [setTargetDateLine, x, y])

  return (
    <g>
      <circle className="stroke-interactive-500 fill-none" strokeWidth={2} cx={x!} cy={y!} r={8} />
      <circle className="fill-interactive-500" cx={x!} cy={y!} r={4} />
      <rect className="fill-interactive-500" x={orientation} y={y! - offSet} width={width} height={height} rx={8} />
      <text
        className="fill-white font-semibold"
        dominantBaseline="middle"
        textAnchor="middle"
        x={orientation + width / 2}
        y={y! + 1}
        width={width}
        height={height}
        fontSize={10}
      >
        ${value}
        {unit}
      </text>
    </g>
  )
}

export default DataPoint
