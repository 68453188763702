import { useEffect, useState } from "react"
import { useValidEmail } from "../../hooks/useAuthHooks"
import { Email } from "./AuthComponents"

import axios, { AxiosError } from "axios"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import AlertIcon from "../../assets/icons/AlertIcon"
import Loading from "../../components/ClientProfile/Loading/Loading"


const RequestCode = (props: { isSettingUp?: boolean, onNextClick: (username: string, token: string) => void }) => {
  const { email, setEmail, emailIsValid } = useValidEmail("")
  const [errorMessage, setErrorMessage] = useState("")
  const navigate = useNavigate()

  const {
    isLoading, error,
    mutate: requestCode, data: requestCodeResponse
  } = useMutation<{ token: string }, AxiosError, string>(
    (email: string) => axios(`${import.meta.env.VITE_APP_API_BASE}/api/auth/send-otp?email=${encodeURIComponent(email)}`).then(res => res.data)
  )

  const sendCodeClicked = () => {
    const formattedEmail = email.trim().toLowerCase()
    if (!emailIsValid || !email) {
      setErrorMessage("Please enter a valid email")
    } else {
      requestCode(formattedEmail)
    }
  }

  useEffect(() => {
    if (requestCodeResponse) {
      props.onNextClick(email, requestCodeResponse.token)
      // navigate("", {state: { token: requestCodeResponse.token } })
    }
  }, [requestCodeResponse, navigate, props, email])

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message)
    }
  }, [error])

  return (
    <div className="reset-password-modal modal-container">
      <h1 className="modal-title text-left">{props.isSettingUp ? "Set up account" : "Reset password" }</h1>
      <p className="modal-description text-left">Please enter your email address to receive instructions on how to {props.isSettingUp ? "set" : "change"} your password.</p>
      <div className="flex flex-col gap-2 w-full m-auto pb-11">
        <Email label="Email address" error={errorMessage} emailIsValid={emailIsValid} setEmail={setEmail} email={email} />
        <div className="flex items-center gap-2 text-error text-sm" role="alert">
          {errorMessage && (
            <>
              <AlertIcon />
              <p>{errorMessage}</p>
            </>
          )}
        </div>
      </div>
      <div className="flex gap-5 items-center justify-center">
        <button onClick={() => navigate("/")} className="btn btn-secondary btn-medium w-44">
          Cancel
        </button>
        <button onClick={sendCodeClicked} className="btn btn-primary btn-medium text-sec w-44 flex flex-col items-center">
          {isLoading ? <Loading type="dots" /> : "Send email"}
        </button>
      </div>
    </div>
  )
}

export default RequestCode