import clsx from "clsx"
import { ReactNode } from "react"
import styled from "styled-components"
import checked from "./assets/checkmark-alt.svg"

export type Props = {
  className?: string
  label?: string | ReactNode
  name: string
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
  checked?: boolean
  [x: string]: any
}

const Input = styled.input`
  appearance: none;
  height: 20px;
  width: 20px;

  &:checked {
    background-color: #31366B;
    background-image: url(${checked});
    background-position: center;
    background-repeat: no-repeat;
  }
`

const Checkbox = ({ className, label, name, onChange, checked, value, ...rest }: Props) => {
  return (
    <div className={clsx("checkbox flex-no-wrap flex items-center gap-x-2 select-none", className)} {...rest}>
      <Input
        checked={checked}
        value={value}
        className="checkbox-input flex-shrink-0 border border-interactive-600 checked:border-interactive-500 rounded-1 cursor-pointer"
        id={name}
        name={name}
        onChange={onChange}
        type="checkbox"
      />
      {label && (
        <label className="checkbox-label flex-1 font-normal -mx-2 px-2 -my-3 py-3 text-main-500 cursor-pointer" htmlFor={name}>
          {label}
        </label>
      )}
    </div>
  )
}

export default Checkbox
