import { useMemo } from "react"
import { latest } from "../../../lib/clients"
import { Client } from "../../../models/Client"
import { Household } from "../../../models/Household"
import { customDateFormat } from "../../../lib/date"
import downloadIcon from "../../advisor/assets/images/download.svg"
import RMJYourDecisions from "../../rmjourney/yourDecisions/RMJYourDecisions"
import RMJRiskAttitude from "../../rmjourney/riskAttitude/RMJRiskAttitude"
import RMJLossSensitivity from "../../rmjourney/lossSensitivity/RMJLossSensitivity"
import RMJPortfolioComfort from "../../rmjourney/portfolioComfort/RMJPortfolioComfort"
import { clsx } from "clsx"

const RiskResults = ({ client, household }: { client?: Client; household?: Household }) => {
  const latestClientRiskGame = latest(client!, "risk")
  const latestHouseholdRiskGames = useMemo(() => {
    return household?.members.map((member) => latest(member.client!, "risk")) ?? []
  }, [household?.members])

  const completedMembers = household && household.members.filter((_, index) => latestHouseholdRiskGames[index]?.played)
  const incompleteMembers = household && household.members.filter((_, index) => !latestHouseholdRiskGames[index]?.played)

  const householdStatus = useMemo(() => {
    if (!household) return null
    if (completedMembers!.length === household.members.length) {
      const lastPlayedGame = latestHouseholdRiskGames
        .filter((game) => game!.played)
        .sort((a, b) => new Date(b!.played).getTime() - new Date(a!.played).getTime())[0]
      return `Household profile completed on ${customDateFormat(lastPlayedGame!.played, "d MMM yyyy")}`
    } else {
      const completedText = completedMembers! // If we're on this tab, at least one member has completed the risk game
        .map((member) => {
          const playedDate = latestHouseholdRiskGames.find((game) => game?.played)?.played
          return `${member.client.firstName} completed the risk activity on ${customDateFormat(playedDate!, "d MMM yyyy")}`
        })
        .join(". ")
      const pendingText = incompleteMembers!.map((member) => `${member.client.firstName}'s risk activity is still pending.`).join(" ") // If completed members are less than 2, then at least one is incomplete
      return `${completedText}.${incompleteMembers!.length > 0 ? ` ${pendingText}` : ""}`
    }
  }, [completedMembers, household, incompleteMembers, latestHouseholdRiskGames])

  return (
    <div className="pt-10">
      <div className="px-16 flex justify-between items-center">
        <p>
          {client && `${client?.firstName}'s profile completed on ${customDateFormat(latestClientRiskGame!.played)}`}
          {household && householdStatus}
        </p>
        <a
          href={household && completedMembers?.length === household.members.length ? latest(household, "risk")?.gameReportUrl : latestClientRiskGame?.gameReportUrl}
          target="_blank"                                                                                                                                                                                                                                                            
          rel="noreferrer"
          aria-disabled={household && (completedMembers?.length ?? 0) < household.members.length}
          className={clsx(
            "btn btn-text btn-text-medium flex gap-2 items-center text-sec font-bold",
            household && (completedMembers?.length ?? 0) < household.members.length && "opacity-50 hover:bg-transparent"
          )}
        >
          <span>Generate report</span>
          <img src={downloadIcon} alt="" aria-hidden />
        </a>
      </div>
      <div className="py-10 px-12">
        <RMJYourDecisions translationContext="advisor" />
      </div>
      <hr className="border-surface-300" />
      <div className="py-10 px-12">
        <RMJRiskAttitude translationContext="advisor" />
      </div>
      <hr className="border-surface-300" />
      <div className="py-10 px-12">
        <RMJLossSensitivity translationContext="advisor" />
      </div>
      <hr className="border-surface-300" />
      <div className="py-10 px-12">
        <RMJPortfolioComfort translationContext="advisor" />
      </div>
    </div>
  )
}

export default RiskResults
