import React, { ReactNode, useContext, useState } from "react"
import { useTheme } from "../../../contexts/ThemeContext"
import { AppContext } from "../../../contexts/AppContext"
import StepDisplay from "./StepDisplay"

interface Screens {
  [key: number]: ReactNode
}

const Onboarding: React.FunctionComponent<{ handleClose: () => void }> = ({ handleClose }) => {
  const { updateUserProfile } = useContext(AppContext)
  const [screen, setScreen] = useState<keyof Screens>(1)
  const [activeIndex, setActiveIndex] = useState<number>(1)
  const theme = useTheme()
  const totalSteps = Object.keys(theme.onboarding.screens).length

  const onNext = () => {
    setActiveIndex(activeIndex + 1)
    setScreen(screen + 1)
  }
  const onClose = async () => {
    updateUserProfile({ tourState: { welcome: new Date() } })
    handleClose()
  }

  const screenEl = (screen: keyof Screens) => {
    const { title, image, description } = theme.onboarding.screens[screen]
    return (
      <div className="p-0">
        <div className="onboarding-screen-modal mt-6 flex flex-col relative">
          <h2 className="modal-title text-h4 md:text-h2 py-0 px-20">{title}</h2>
          <img className="onboarding-screen__img py-6" src={image} alt="" aria-hidden />
          <div className="text-main-500 text-center px-14 text-p">{description}</div>
          <div className="py-4">
            <StepDisplay activeIndex={activeIndex} numSteps={totalSteps} />
          </div>
          <div className="flex justify-center gap-4 pt-4 pb-6 shadow-md">
            <button className="w-44 btn btn-primary btn-medium" onClick={activeIndex < totalSteps ? onNext : onClose}>
              Continue
            </button>
          </div>
        </div>
      </div>
    )
  }

  return <div className="p-0">{screenEl(screen)}</div>
}

export default Onboarding
