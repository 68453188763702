import { AxiosError } from "axios"
import clsx from "clsx"
import React, { ReactNode, UIEventHandler, useContext, useEffect, useMemo, useState } from "react"
import { useMutation, useQuery } from "react-query"
import axiosInstance from "../../api/axiosInstance"
import Loading from "../../components/ClientProfile/Loading/Loading"
import InputCode from "../../components/InputCode/InputCode"
import { AppContext } from "../../contexts/AppContext"
import { SessionInfo } from "./AuthContext"
import union from "./images/Union.png"
import vector from "./images/Vector.png"
import { Password } from "./AuthComponents"



const CodeChallenge = ({ title, to, email, onComplete }: { title: string, to: string, email?: string | null, onComplete: (otp: string) => void }) => (
  <div className="flex flex-col justify-center items-center mx-auto my-auto">
    <div className="modal-container">
      <h1 className="modal-title">{title}</h1>
      <p className="modal-description">
        A verification code has been sent to {' '}
        {email}
        . Please enter the six-digit code below to {to}.
      </p>
      <div className="pb-[41px] pt-[25px] input-wrapper">
        <InputCode
          length={6}
          onComplete={onComplete} // Passing the handler as a whole
        />
      </div>
      <p className="text-main-500 text-center">It may take a minute to receive your code.</p>
    </div>
  </div>
)


const DisableEnterPassword = ({ handleClose, onNext, enableNext }: { enableNext: boolean, handleClose?: UIEventHandler, onNext: (password: string) => void }) => {
  const [password, setPassword] = useState("")
  return <>
    <h1 className="modal-title">Enter your password to disable 2FA</h1>
    <div className="mt-8 mb-2">
      <Password password={password} setPassword={setPassword} passwordIsValid={true} />
    </div>
    <div className="flex gap-4 w-full max-w-[340px] m-auto pt-10">
      <button className="btn btn-secondary btn-medium text-sec flex-1" onClick={handleClose}>
        Cancel
      </button>
      <button
        disabled={!password || !enableNext}
        className={clsx("btn btn-primary btn-medium text-sec flex-1 hover:bg-interactive-600 hover:cursor-pointer")}
        onClick={() => onNext(password)}
      >
        {enableNext ? <>Disable 2FA</> : <Loading type="dots" />}
      </button>
    </div>
  </>
}

const TwoFactorAuthentication: React.FunctionComponent<{ handleClose: UIEventHandler }> = ({ handleClose }) => {
  const { userProfile } = useContext(AppContext)
  const [token, setToken] = useState<string | null>()

  type Screens = "loading" | "notEnabled" | "disableEnterPassword" | "enterCodeEnable" | "enterCodeDisable"| "enableSuccess" | "alreadyEnabled" | "disableSuccess" | "error"

  const [screen, setScreen] = useState<Screens>("loading")

  
  const mfaStatus = useQuery(
    ["mfaStatus", userProfile?.email],
    () => axiosInstance.get<{ email: boolean }>(`${import.meta.env.VITE_APP_API_BASE}/api/auth/2fa/status`).then(res => res.data),
    {
      enabled: !!userProfile,
      staleTime: 0,
      onSuccess: (mfa) => {
        setScreen(mfa.email ? "alreadyEnabled" : "notEnabled")
      }
    }
  )
  

  const requestCode = useMutation(
    () => axiosInstance.post<{ token: string }>(
      `${import.meta.env.VITE_APP_API_BASE}/api/auth/2fa/enable/initiate`, { method: "email" }).then(res => res.data), {
        onSuccess: (data) => {
          setToken(data.token)
          setScreen("enterCodeEnable")
        }
      }
  )

  const respondToChallenge = useMutation<{}, AxiosError<any>, { otp: string, token: string, action: "enable" | "disable" }>(
    ({ otp, token, action }) => {
      return axiosInstance.post<SessionInfo>(
        `${import.meta.env.VITE_APP_API_BASE}/api/auth/2fa/${action}/confirm`, {
          otp, token
        }).then(res => res.data)
      }, {
        onSuccess: async () => {
          setScreen(screen === "enterCodeEnable" ? "enableSuccess" : screen === "enterCodeDisable" ? "disableSuccess" : "error")
        }
      }
  )


  const requestToDisable = useMutation(
    ({ password }: { password: string }) => axiosInstance.post<{ token: string }>(
      `${import.meta.env.VITE_APP_API_BASE}/api/auth/2fa/disable/initiate`, { method: "email", password }).then(res => res.data), {
        onSuccess: (data) => {
          setToken(data.token)
          setScreen("enterCodeDisable")
        }
      }
  )





  const error = useMemo(() => mfaStatus.error || requestCode.error || respondToChallenge.error || requestToDisable.error, [mfaStatus.error, requestCode.error, requestToDisable.error, respondToChallenge.error])


  const screens: {[key in Screens]: ReactNode} = {
    loading: (
      <div className="">
        <div className="w-full h-64 m-auto flex flex-col items-center justify-center">
          <Loading />
        </div>
      </div>
    ),
    error: (
      <>
        {!!error && <p className="text-error font-normal mt-4 text-center">{((error as any)?.response?.data || error)?.toString()}</p>}
      </>
    ),
    notEnabled: (
      <>
        <h1 className="modal-title">Activate two-factor authentication to keep your account more secure</h1>
        <div className="w-full m-auto flex flex-col gap-y-5">
          <img className="h-[80px] w-[72.5px] mx-auto" src={vector} alt="vector"></img>
          <p className="text-center">
            Two-factor authentication adds an extra layer of security by sending a verification code to your email each time you log in, ensuring only you can access your account.
          </p>
          <p className="text-center -mt-1">
            We will email you a code to set up two-factor authentication on your account.
          </p>
        </div>
        <div className="flex gap-4 w-full max-w-[340px] m-auto pt-10">
          <button className="btn btn-secondary btn-medium text-sec flex-1" onClick={handleClose}>
            Cancel
          </button>
          <button
            className={clsx("btn btn-primary btn-medium text-sec flex-1 hover:bg-interactive-600 hover:cursor-pointer", {})}
            disabled={requestCode.isLoading || requestCode.isSuccess}
            onClick={() => requestCode.mutate()}
          >
            {requestCode.isLoading ? <Loading type="dots" /> : <>Send code</>}
          </button>
        </div>
      </>
    ),
    enterCodeEnable:  <CodeChallenge title="Complete two-factor authentication set up" to="activate two-factor authentication" email={userProfile?.email} onComplete={(otp) => respondToChallenge.mutate({ otp, token: token!, action: "enable" })} />,
    enterCodeDisable: <CodeChallenge title="Enter code to disable" to="de-activate two-factor authentication" email={userProfile?.email} onComplete={(otp) => respondToChallenge.mutate({ otp, token: token!, action: "disable" })} />,
    enableSuccess: (
      <div className="flex flex-col justify-center items-center mx-auto my-auto">
        <div className="modal-container">
          <h1 className="modal-title pb-10 text-center">Success!</h1>
          <img className="h-[80px] w-[80px] mx-auto" src={union} alt="union"></img>
          <p className="modal-description pt-10 text-center">Two-factor authentication is set up on this account.</p>
        </div>
      </div>
    ),
    alreadyEnabled: (
      <>
        <h1 className="modal-title">Your account is secure</h1>
        <div className="w-full m-auto flex flex-col gap-5">
          <img className="h-[80px] w-[72.5px] mx-auto" src={vector} alt="vector"></img>
        </div>
        <div className="flex gap-4 w-full max-w-[340px] m-auto pt-10">
          <button className="btn btn-secondary btn-medium text-sec flex-1" onClick={handleClose}>
            Cancel
          </button>
          <button
            disabled={requestToDisable.isLoading}
            className={clsx("btn btn-primary btn-medium text-sec flex-1 hover:bg-interactive-600 hover:cursor-pointer")}
            onClick={() => setScreen("disableEnterPassword")}
          >
            Disable 2FA
          </button>
        </div>
      </>
    ),
    disableEnterPassword: <DisableEnterPassword
      onNext={(password) => requestToDisable.mutate({ password })}
      handleClose={handleClose}
      enableNext={!requestToDisable.isLoading}
    />,
    disableSuccess: (
      <div className="flex flex-col justify-center items-center mx-auto my-auto">
        <div className="modal-container">
          <h1 className="modal-title pb-10 text-center">Success!</h1>
          <img className="h-[80px] w-[80px] mx-auto" src={union} alt="union"></img>
          <p className="modal-description pt-10 text-center">Two-factor authentication is disabled on this account.</p>
        </div>
      </div>
    )
  }

  return (
    <div className="modal-container w-[600px]">
      {screens[screen]}
      {!!error && <p className="text-error mt-4 text-center">{((error as any)?.response?.data || error)?.toString()}</p>}
    </div>
  )
}

export default TwoFactorAuthentication
