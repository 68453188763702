import { Trans } from "@lingui/macro"
import clsx from "clsx"

interface Props {
  page: number
  className?: string
}

const Footer = ({ page, className }: Props) => {
  const currentYear = new Date().getFullYear()
  return (
    <div className={clsx("reports-footer text-main-400 h-14 mx-9", className)}>
      <hr className="border-solid border-t-2" />
      <div className="flex flex-row justify-between font-semibold leading-6 text-xxs">
        <p>
          <Trans id="print-footer-text">Capital Preferences</Trans>
        </p>
        <p>
          <Trans id="print-footer-text-copyright">
            {currentYear}{page}
          </Trans>
        </p>
      </div>
    </div>
  )
}

export default Footer
