import { useNavigate, useParams } from "react-router"
import useClient from "../../../hooks/useClient"
import useHousehold from "../../../hooks/useHousehold"
import arrowLeft from "../assets/images/arrow-left.svg"
import SidebarHeader from "../components/SidebarHeader/SidebarHeader"

const RMJAdviceOverviewSideBar = () => {
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client } = useClient(clientId)
  const { household } = useHousehold(householdId)
  const navigate = useNavigate()

  return (
    <div className="w-80 h-full bg-interactive-600 flex flex-1 flex-col justify-between">
      {(client || household) && (
        <>
          <div className="gap-x-2.5 overflow-y-auto no-scrollbar">
            <div className="flex flex-col">
              {client && <SidebarHeader client={client} />}
              {household && <SidebarHeader household={household} />}
              <div className="sidebar-section">
                <h3 className="sidebar-section-title text-sec">Preferences</h3>
                <p className="sidebar-section-text text-sec">
                  Your risk preferences are important as they determine how comfortable you’ll be with our investment options.
                </p>
              </div>
              <div className="sidebar-section">
                <h3 className="sidebar-section-title text-sec">Goals</h3>
                <p className="sidebar-section-text text-sec">
                  Your goals are central to your investment strategy and will guide the risk required to meet your goals.
                </p>
              </div>
              <div className="sidebar-section">
                <h3 className="sidebar-section-title text-sec">Constraints</h3>
                <p className="sidebar-section-text text-sec">
                  Your constraints may include your income, timeframe, debt levels, and other factors that may influence your capacity to take risk.
                </p>
              </div>
            </div>
          </div>
          <div className="px-4 pb-4 w-full flex justify-between items-center">
            <div onClick={() => navigate(-1)} className="cursor-pointer w-10">
              <img className="mt-3" src={arrowLeft} alt="arrow left icon" />
            </div>
            <button className="btn btn-medium btn-primary-alt mt-4 h-14 grow" onClick={() => navigate("../your-decisions")}>
              Next
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default RMJAdviceOverviewSideBar
