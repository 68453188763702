import React, { Fragment, PropsWithChildren } from 'react'
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import axiosInstance from "../../../../api/axiosInstance"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import { Advisor } from "../../../../models/Advisor"
import { Firm } from "../../../../models/Firm"

type Stats = {
  stats: {
    advisors: {
      active: number
      archived: number
    }
    clients: {
      active: number
      archived: number
    }
  }
}

const ViewFirm: React.FC<PropsWithChildren<{ firm: {_id: string, name: string } }>> = ({ firm }) => {

  const view = useQuery(["admin/firm", firm._id, "reports", "overview"], () => axiosInstance.get<{ firm: Firm, advisors: (Advisor & Stats)[] }>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/firm/${firm._id}/report/overview`)
    .then(res => res.data), { staleTime: 1000})

  
  return (<>
    {view.isLoading && <Loading />}
    {view.isError && <div className="text-error">{String(view.error)}</div>}
    {view.isSuccess && <>
      <div className="px-10 w-full h-full flex flex-col gap-y-4 overflow-y-hidden">
        <div className="h-full flex flex-col overflow-y-hidden">
          <div className="grid grid-cols-[2fr_3fr_1fr_1fr_2fr] auto-rows-min">
            <div className="text-h5 font-semibold border-b border-interactive-300">Advisor</div>
            <div className="text-h5 font-semibold border-b border-interactive-300">Email</div>
            <div className="text-h5 font-semibold border-b border-interactive-300 text-right">Clients</div>
            <div className="text-h5 font-semibold border-b border-interactive-300 text-right">Archived</div>
            <div className="text-h5 font-semibold border-b border-interactive-300 text-right">Actions</div>
          </div>
          <div className="grid grid-cols-[2fr_3fr_1fr_1fr_2fr] overflow-y-auto auto-rows-min">
            {view.data.advisors.map(advisor => <Fragment key={advisor._id}>
              <div className="border-b border-neutral-200 py-1 overflow-ellipsis"><Link to={`/?advisorId=${advisor._id}`} className="underline">{advisor.firstName} {advisor.lastName}</Link></div>
              <div className="border-b border-neutral-200 py-1">{advisor.email}</div>
              <div className="border-b border-neutral-200 py-1 text-right">
                {advisor.stats?.clients?.active && <Link to={`/?advisorId=${advisor._id}`} className="underline text-interactive-500">{advisor.stats?.clients?.active}</Link>}
              </div>
              <div className="border-b border-neutral-200 py-1 text-right">
                {advisor.stats?.clients?.archived && <Link to={`/archived?advisorId=${advisor._id}`} className="underline text-interactive-500">{advisor.stats?.clients?.archived}</Link>}
              </div>
              <div className="border-b border-neutral-200 py-1 w-full flex gap-x-1">
                {/* <Link to={`/clients?advisorId=${advisor._id}`} className="ml-auto btn btn-primary px-2 py-1 btn-small">Clients</Link> */}
                <button className="ml-auto btn btn-secondary btn-small px-2 py-1" disabled>X</button>
              </div>
            </Fragment>)}
          </div>
        </div>
      </div>
    </>}
  </>)
}

export default ViewFirm