import { Client } from "../../../models/Client"
import Container from "./components/Container"
import { Household } from "../../../models/Household"
import Footer from "./components/Footer"
import { useContext } from "react"
import { FirmContext } from "../../../contexts/FirmContext"
import { Options } from "../../rmjourney/goalExplorer/components/SelectPortfolio/SelectPortfolio"
import PortfolioTable from "../../rmjourney/goalExplorer/components/PortfolioTable/PortfolioTable"

interface Props {
  page: number
  client: Client
  household: Household
  options: Options
}

const ProjectorTablePage = ({ page, client, household, options }: Props) => {
  const { firm } = useContext(FirmContext)
  const clientOrHousehold = client || household

  return (
    <Container className="flex flex-col justify-between pt-10 relative">
      <div className="flex-auto mx-10 h-1/2">
        <h2 className="mb-2 text-main-600 text-primary pb-5 tracking-wide">
          {clientOrHousehold.primaryInvestmentGoal === "retirementIncome"
            ? "Retirement income projector"
            : clientOrHousehold.primaryInvestmentGoal === "retirementDrawdown"
            ? "Retirement income and legacy projector"
            : "Wealth accumulation projector"}
        </h2>
        {options && (
          <div className="flex flex-col justify-between">
            <div className="mb-2">
              {options && (
                <PortfolioTable
                  options={options}
                  client={client}
                  household={household}
                  clientOrHousehold={clientOrHousehold}
                  goalType={clientOrHousehold.primaryInvestmentGoal}
                  forReport={true}
                />
              )}
              {clientOrHousehold.primaryInvestmentGoal === "retirementIncome" && (
                <div className="text-xs text-main-400 leading-1">
                  <p>
                    <i>Goal achievability</i> is the percentage of Monte Carlo simulations where the withdrawal amount exceeds your retirement income goal.
                  </p>
                  <p>
                    <i>Projected income</i> reflects the withdrawal amount at the 30th percentile of Monte Carlo simulations, with the range reflecting the
                    15th-85th percentiles of simulations.
                  </p>
                </div>
              )}
              {clientOrHousehold.primaryInvestmentGoal === "wealthAccumulation" && (
                <div className="text-xs text-main-400 leading-1">
                  <p>
                    <i>Goal achievability</i> is the percentage of Monte Carlo simulations where you exceed your wealth accumulation goal.
                  </p>
                  <p>
                    <i>Projected wealth</i> reflects the 30th percentile of Monte Carlo simulations, with the range reflecting the 15th-85th percentiles of
                    simulations.
                  </p>
                </div>
              )}
              {clientOrHousehold.primaryInvestmentGoal === "retirementDrawdown" && (
                <div className="text-xs text-main-400 leading-1">
                  {!!clientOrHousehold?.estateGoal && (
                    <p>
                      <i>When legacy money may be required</i> is the year when there’s at least a 30% likelihood that you’ll need to begin using your 'legacy
                      money' to fund your retirement income withdrawals.
                    </p>
                  )}
                  <p className="mt-1">
                    <i>{clientOrHousehold?.estateGoal ? "When legacy money may run out" : "When expected wealth may run out"}</i> is the year when there’s at
                    least a 30% likelihood you’ll have nothing left in your portfolio, with the range reflecting the 15%-85% likelihood range.
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="relative mx-10">
        <Footer pageNum={page} client={client} household={household} firm={firm} />
      </div>
    </Container>
  )
}

export default ProjectorTablePage
