import { ReactNode } from "react"

export interface Props {
  label: ReactNode
  value: ReactNode | undefined
}

const SidebarSectionRow = ({ label, value }: Props) => {
  return (
    <div className="sidebar-section-row w-full flex gap-2 whitespace-nowrap">
      <p className="w-40 text-sec text-white">{label}</p>
      <p className="text-sec font-bold text-white">{value || "-"}</p>
    </div>
  )
}

export default SidebarSectionRow
