import { useParams } from "react-router-dom";
import logo from "../images/cp-logo-light-surface.svg"
import csHero from "../images/cs-hero.png"


const AccountNotFoundForProvider = () => {
  const { provider } = useParams()

  return (
      
      <div className="w-full h-full">
        <div className="relative">
          <img className="object-cover min-h-[320px]" src={csHero} />
          <p className="absolute bottom-0 left-0 right-0 text-center pb-10 text-white text-h2 font-bold px-10">Thank you for your interest in <br/> Capital Preferences</p>
        </div>
        <div className="w-full mx-auto px-10 md:px-10 md:max-w-[680px] py-13 flex flex-col items-start gap-y-2 ">
          <p>We’ve notified our customer success team of your interest and they’ll be in touch shortly to get the solution personalised for your firm.</p>
          <p>In the meantime, if you have any questions, please contact us at:<br/>
            <a href={`mailto:customer-support@capitalpreferences.com?subject=Reaching out regarding ${provider}`} className="underline text-link-500">
            customer-support@capitalpreferences.com
          </a>.</p>
          <p>Regards,</p>
          <p className="mt-4">
            <div className="font-bold">Oliver Lynch</div>
            <div className="uppercase text-sm"> Chief Customer Officer</div>
          </p>
          <img src={logo} className="object-none mt-4" />
        </div>
      </div>    
  );
};

export default AccountNotFoundForProvider;
