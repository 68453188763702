import clsx from "clsx"
import { useCallback, useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useNavigate } from "react-router"
import axiosInstance from "../../../../../../api/axiosInstance"
import { merge } from "../../../../../../api/clients"
import { NewHouseholdRequest } from "../../../../../../api/households"
import infoAlert from "../../../../../../assets/icons/info-alert.svg"
import trash from "../../../../../../assets/icons/trash.svg"
import AvatarBadges from "../../../../../../components/AvatarBadges/AvatarBadges"
import Loading from "../../../../../../components/ClientProfile/Loading/Loading"
import DollarIcon from "../../../../../../components/DolarIcon/DollarIcon"
import NumberInput from "../../../../../../components/NumberInput/NumberInput"
import TextInput from "../../../../../../components/TextInput/TextInput"
import { FirmContext } from "../../../../../../contexts/FirmContext"
import { formatCurrencyLong } from "../../../../../../lib/currency"
import { Client } from "../../../../../../models/Client"
import { AuthContext, AuthStatus } from "../../../../../../views/auth/AuthContext"
import SearchBox from "../../../../../advisor/components/SearchBox"
import link from "./assets/link.svg"
import person from "./assets/person.svg"

type Errors = {
  otherClient?: string
  householdName?: string
  investmentAmount?: string
}

const MergeClientsModal = ({ client, onClose }: { client: Client; onClose: () => void }) => {
  const { authStatus, sessionInfo } = useContext(AuthContext)
  const { firm } = useContext(FirmContext)
  const navigate = useNavigate()
  const [isMerging, setIsMerging] = useState<boolean>(false)
  const [searchString, setSearchString] = useState<string>()
  const [shouldSearch, setShouldSearch] = useState<boolean>(false)
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [errors, setErrors] = useState<Errors>({})
  const [otherClient, setOtherClient] = useState<Client>()
  const [newHousehold, setNewHousehold] = useState<NewHouseholdRequest>()

  const mergeClients = () => {
    setShouldValidate(true)
    const errs = validate()
    if (!errs.householdName && !errs.investmentAmount && !errs.otherClient) {
      setIsMerging(true)
      merge(sessionInfo!, client.advisorId, {
        ...newHousehold,
        clientIds: [client._id, otherClient!._id]
      })
        .then((res) => {
          setIsMerging(false)
          navigate(`/households/${res._id}`, { replace: true })
        })
        .catch(() => {
          setIsMerging(false)
          console.error("error merging accounts")
        })
    }
  }

  const validate = useCallback(() => {
    const errs: Errors = {}
    if (!otherClient) {
      errs.otherClient = "Please choose a client to merge"
    }
    if (!newHousehold?.name?.trim()) {
      errs.householdName = "Please enter a household name"
    }
    const investmentAmountConfig = firm!.uiConfig.investmentAmount
    if (
      newHousehold?.investmentAmount &&
      (newHousehold.investmentAmount < investmentAmountConfig.min || newHousehold.investmentAmount > investmentAmountConfig.max)
    ) {
      errs.investmentAmount = `Please add a value between ${formatCurrencyLong(investmentAmountConfig.min)} and ${formatCurrencyLong(
        investmentAmountConfig.max
      )}`
    }
    return errs
  }, [firm, newHousehold?.investmentAmount, newHousehold?.name, otherClient])

  useEffect(() => {
    if (shouldValidate) {
      setErrors(validate())
    }
  }, [shouldValidate, validate])

  const {
    isLoading: isSearching,
    error: isSearchingError,
    data: searchResults
  } = useQuery<Client[], { message?: string }>(
    ["searchClients", searchString],
    () => {
      return axiosInstance
        .get<Client[]>(
          `${import.meta.env.VITE_APP_API_BASE || ""}/api/advisor/clients?excludeHouseholds=true&limit=10&search=${searchString}`
        )
        .then((res) => res.data.filter((r) => r._id !== client._id && r._id !== otherClient?._id))
    },
    {
      enabled: authStatus === AuthStatus.SignedIn && !!sessionInfo?.accessToken && shouldSearch && !!searchString,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 900
    }
  )

  useEffect(() => {
    if (otherClient) {
      setNewHousehold((prev) => ({ ...prev, investmentAmount: (client.investmentAmount || 0) + (otherClient.investmentAmount || 0) }))
    }
  }, [client.investmentAmount, otherClient])

  return (
    <div className="modal-container px-4">
      <div className="flex flex-col w-full bg-white overflow-y-auto">
        <div className="" style={{ maxHeight: "calc(100vh - 200px)" }}>
          <h2 className="modal-title font-semibold text-h3 text-main-500 text-left mb-2">Create household</h2>
          <div className="grid grid-cols-12 w-full gap-x-4 justify-center items-center mb-8">
            <div className="col-start-1 col-span-5 flex flex-col bg-surface-100 px-6 py-4 items-center overflow-hidden">
              <AvatarBadges clients={[client]} />
              <div className="flex flex-col gap-y-4 justify-center pt-2 w-full">
                <div className="flex flex-col items-center">
                  <p
                    className="text-main-500 overflow-hidden text-ellipsis w-full whitespace-nowrap text-center"
                    title={`${client.firstName} ${client.lastName}`}
                  >
                    {client.firstName} {client.lastName}
                  </p>
                  <p className="text-sec overflow-hidden text-ellipsis w-full text-center" title={client.email}>
                    {client.email || "-"}
                  </p>
                </div>
                <div className="flex flex-col items-center">
                  <p className="text-sec">Investment amount</p>
                  <p className="text-sec font-semibold">{client.investmentAmount ? formatCurrencyLong(client.investmentAmount) : "$"}</p>
                </div>
              </div>
            </div>
            <div className="col-start-6 col-span-2 mx-auto">
              <img src={link} alt="link" />
            </div>
            <div
              className={clsx(
                "col-start-8 col-span-full flex flex-col bg-surface-100 px-6 py-4 items-center overflow-hidden relative",
                !otherClient && "opacity-50"
              )}
            >
              {otherClient && (
                <div className="absolute right-0 top-0 py-2.5 px-3">
                  <div className="cursor-pointer" tabIndex={1}>
                    <img
                      src={trash}
                      alt="remove client"
                      onClick={() => {
                        setOtherClient(undefined)
                        setSearchString("")
                      }}
                    />
                  </div>
                </div>
              )}
              {otherClient ? (
                <AvatarBadges clients={[otherClient]} colorStartIndex={1} />
              ) : (
                <div className="rounded-full flex items-center justify-center bg-avatar-1-500 p-1 w-9 h-9">
                  <img src={person} alt="person" />
                </div>
              )}

              <div className="flex flex-col gap-y-4 justify-center pt-2 w-full">
                <div className="flex flex-col justify-center items-center">
                  <p
                    className="text-main-500 overflow-hidden text-ellipsis w-full whitespace-nowrap text-center"
                    title={otherClient ? `${otherClient.firstName} ${otherClient.lastName}` : "Client 2"}
                  >
                    {otherClient ? `${otherClient.firstName} ${otherClient.lastName}` : "Client 2"}
                  </p>
                  <p className="text-sec overflow-hidden text-ellipsis w-full text-center" title={(otherClient && otherClient.email) || `-`}>
                    {(otherClient && otherClient.email) || "-"}
                  </p>
                </div>
                <div className="flex flex-col items-center">
                  <p className="text-sec">Investment amount</p>
                  <p className="text-sec font-semibold">
                    {otherClient && otherClient.investmentAmount ? formatCurrencyLong(otherClient.investmentAmount) : "$"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col">
              <p className="text-sec text-main-600 mb-1">
                Select a profile to merge with {client.firstName}
                <span className="text-error">*</span>
              </p>
              <div className="flex flex-col">
                <div className="relative">
                  <SearchBox
                    placeholder="Start typing name..."
                    value={searchString}
                    onChange={(val) => {
                      setSearchString(val)
                      setOtherClient(val === "" ? undefined : otherClient)
                      setShouldSearch(true)
                    }}
                    onClear={() => {
                      setSearchString("")
                      setOtherClient(undefined)
                    }}
                    isSearching={isSearching}
                    results={
                      searchResults ? (
                        <ul className="list-none p-0 mx-1 shadow max-h-48 overflow-y-auto relative">
                          {searchResults.length > 0 ? (
                            searchResults.map((result) => (
                              <li key={result._id}>
                                <button
                                  className="w-full px-4 py-3 flex flex-row gap-x-3 hover:bg-interactive-100 cursor-pointer"
                                  onClick={() => {
                                    setOtherClient(result)
                                    setSearchString(`${result.firstName} ${result.lastName}`)
                                    setShouldSearch(false)
                                  }}
                                >
                                  <div className="flex flex-col text-left">
                                    <p>
                                      {result.firstName} {result.lastName}
                                    </p>
                                    <p>{result.email}</p>
                                  </div>
                                </button>
                              </li>
                            ))
                          ) : (
                            <li className="p-3 gap-x-3 bg-interactive-100">No results</li>
                          )}
                        </ul>
                      ) : (
                        isSearchingError && <p className="p-3 gap-x-3 bg-interactive-100 text-error">Error searching the clients, try again.</p>
                      )
                    }
                  />
                </div>
                {errors.otherClient && (
                  <div className="text-input-error flex items-center mt-1">
                    <img alt="Info alert" className="text-input-error-icon mr-1" height="14px" src={infoAlert} width="14px" />
                    <p className="text-input-error-text text-sm text-error font-normal mt-px">{errors.otherClient}</p>
                  </div>
                )}
              </div>
            </div>
            <TextInput
              label="Set a household name"
              type="text"
              mandatory
              name="householdName"
              value={newHousehold?.name}
              onChange={(value) => {
                setNewHousehold((prev) => ({ ...prev, name: value }))
              }}
              error={errors?.householdName}
            />
            <div>
              <NumberInput
                label="Household investment amount"
                name="investmentAmount"
                value={newHousehold?.investmentAmount}
                onChange={(valueAsNumber) => {
                  setNewHousehold((prev) => ({ ...prev, investmentAmount: valueAsNumber }))
                }}
                prefix={
                  <div className="pl-3">
                    <DollarIcon />
                  </div>
                }
                error={errors?.investmentAmount}
              />
              <p className="text-sm mt-1">
                The default household investment amount is the sum of the two individuals investment amounts. You can edit now or choose to update later.
              </p>
            </div>
          </div>
        </div>
        <div className="flex gap-4 m-auto pt-10 text-center">
          <button className="btn btn-secondary btn-medium w-44 flex-1" onClick={onClose}>
            Cancel
          </button>
          <button className="btn btn-primary btn-medium w-44 flex-1" onClick={mergeClients}>
            {isMerging ? <Loading type="dots" /> : <span>Create</span>}
          </button>
        </div>
      </div>
    </div>
  )
}

export default MergeClientsModal
