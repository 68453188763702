import { useState } from "react"
import { customDateFormat } from "../../../../lib/date"
import AlertPopover from "../../../AlertPopover/AlertPopover"
import AlertIcon from "../../../../assets/icons/AlertIcon"

interface Props {
  date: Date
  message: string
  userName?: string
  warningAlert?: {
    message: string
  }
}

const ListItem = ({ date, message, userName, warningAlert }: Props) => {
  const [isWarningAlertOpen, setIsWarningAlertOpen] = useState<boolean>(false)

  return (
    <div className="flex flex-row justify-between px-8 py-4">
      <div className="flex items-center gap-2">
        <p>
          {userName && <span>{userName}</span>}
          {message}
        </p>
        {warningAlert && (
          <span>
            <AlertPopover
              options={{
                alignY: "bottom",
                content: (
                  <div className="min-w-fit h-8 flex items-center justify-center border border-interactive-200 p-3">
                    <p className="text-sec text-interactive-500 whitespace-nowrap">{warningAlert.message}</p>
                  </div>
                ),
                onClose: () => setIsWarningAlertOpen(false),
                timeoutMS: null
              }}
              show={isWarningAlertOpen}
            >
              <button className="pt-0.5" onClick={() => setIsWarningAlertOpen(true)}>
                <AlertIcon sizeProp="md" />
              </button>
            </AlertPopover>
          </span>
        )}
      </div>
      <p>{customDateFormat(date.toString())}</p>
    </div>
  )
}

export default ListItem
