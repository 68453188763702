import addYears from "date-fns/addYears"
import getYear from "date-fns/getYear"
import startOfYear from "date-fns/startOfYear"
import { Firm } from "../../../../models/Firm"
import { Client, InvestmentGoal } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import { validateAnnualContribution, validateInvestmentAmount } from "../utils/validation"
import { ClientUpdateRequest } from "../../../../api/clients"
import { HouseholdUpdateRequest } from "../../../../api/households"

export interface WealthAccumulationFormErrors {
  annualInvestmentContribution?: string
  investmentAmount?: string
  wealthAccumulationGoal?: string
  wealthAccumulationYear?: string
}

export const validateWealthAccumulationForm = ({
  values,
  firm
}: {
  values: WealthAccumulationFormValues
  firm?: Firm
}): { errors: WealthAccumulationFormErrors; isValid: boolean } => {
  let isValid = true
  const { annualInvestmentContribution, investmentAmount, wealthAccumulationGoal, wealthAccumulationYear } = values
  const errors: WealthAccumulationFormErrors = {
    annualInvestmentContribution: undefined,
    investmentAmount: undefined,
    wealthAccumulationGoal: undefined,
    wealthAccumulationYear: undefined
  }

  errors.investmentAmount = validateInvestmentAmount(investmentAmount, firm)
  errors.annualInvestmentContribution = validateAnnualContribution(annualInvestmentContribution)

  if (wealthAccumulationGoal !== null && wealthAccumulationGoal !== undefined) {
    if (wealthAccumulationGoal < 0 || wealthAccumulationGoal > 1000000000000) {
      errors.wealthAccumulationGoal = "Value must be less than $1T"
    }
  } else {
    errors.wealthAccumulationGoal = "Wealth accumulation goal required"
  }

  errors.wealthAccumulationYear = validateYear(wealthAccumulationYear)

  isValid = Object.values(errors).every((x) => x === null || x === undefined || x === "")

  return {
    errors,
    isValid
  }
}

const validateYear = (year?: string) => {
  if (year) {
    const minYear = getYear(addYears(startOfYear(new Date()), 1))
    const maxYear = getYear(addYears(startOfYear(new Date()), 125))
    if (year.length < 4) {
      return "Please enter full year"
    } else if (parseInt(year) < minYear) {
      return `Must be at least ${minYear}`
    } else if (parseInt(year) > maxYear) {
      return `Must be before ${maxYear}`
    }
  } else {
    return "Target date required"
  }
}

export const createFormValues = ({ client, household }: { client?: Client; household?: Household }) => {
  if (client) {
    return {
      annualInvestmentContribution: client.annualInvestmentContribution,
      investmentAmount: client.investmentAmount,
      wealthAccumulationGoal: client.wealthAccumulationGoal,
      wealthAccumulationYear: client.wealthAccumulationYear ? client.wealthAccumulationYear.toString() : undefined
    }
  } else if (household) {
    return {
      annualInvestmentContribution: household.annualInvestmentContribution,
      investmentAmount: household.investmentAmount,
      wealthAccumulationGoal: household.wealthAccumulationGoal,
      wealthAccumulationYear: household.wealthAccumulationYear ? household.wealthAccumulationYear.toString() : undefined
    }
  } else {
    return {
      annualInvestmentContribution: undefined,
      investmentAmount: undefined,
      wealthAccumulationGoal: undefined,
      wealthAccumulationYear: undefined
    }
  }
}

export interface WealthAccumulationFormValues {
  annualInvestmentContribution?: number
  investmentAmount?: number
  wealthAccumulationGoal?: number
  wealthAccumulationYear?: string
}

export const createWealthAccumulationRequest = ({
  client,
  values,
  goalType,
  household
}: {
  client?: Client
  values: WealthAccumulationFormValues
  goalType: InvestmentGoal
  household?: Household
}) => {
  const { annualInvestmentContribution, investmentAmount, wealthAccumulationGoal, wealthAccumulationYear } = values

  const updateRequest: ClientUpdateRequest | HouseholdUpdateRequest = {}
  const clientOrHousehold: Client | Household | undefined = client || household

  if (clientOrHousehold) {
    if (annualInvestmentContribution !== clientOrHousehold.annualInvestmentContribution) {
      updateRequest.annualInvestmentContribution = annualInvestmentContribution
    }

    if (investmentAmount !== clientOrHousehold.investmentAmount) {
      updateRequest.investmentAmount = investmentAmount
    }

    if (wealthAccumulationGoal !== clientOrHousehold.wealthAccumulationGoal) {
      updateRequest.wealthAccumulationGoal = wealthAccumulationGoal
    }

    const nextWealthAccumulationYear = wealthAccumulationYear ? parseInt(wealthAccumulationYear) : undefined

    if (nextWealthAccumulationYear !== clientOrHousehold.wealthAccumulationYear) {
      updateRequest.wealthAccumulationYear = nextWealthAccumulationYear
    }

    if (goalType !== clientOrHousehold.primaryInvestmentGoal) {
      updateRequest.primaryInvestmentGoal = goalType
    }
  }

  return updateRequest
}
