import { ReactNode } from "react"
import { NodeData } from "./LineChart"

export interface Props {
  formatter?: (node: NodeData) => ReactNode
  node: NodeData
}

const ToolTip = ({ formatter, node }: Props) => {
  const { value, x, y } = node
  return (
    <div
      className="absolute text-sm text-main-500 bg-white border-0.5 border-interactive-200 shadow-sm -translate-x-1/2 -translate-y-full -mt-2 ml-2 px-2 py-1"
      style={{ left: x, top: y }}
    >
      {formatter ? formatter(node) : value}
    </div>
  )
}

export default ToolTip
