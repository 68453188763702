import { AxiosError } from "axios"
import { AnimatePresence } from "framer-motion"
import { Fragment, useCallback, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { Link } from "react-router-dom"
import axiosInstance from "../../../../api/axiosInstance"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import Modal from "../../../../components/Modal/Modal"
import { BillingCode } from "../../../../models/Billing"
import { Firm } from "../../../../models/Firm"
import RenderQueryResults from "../RenderQueryResults"

import Switch from "../../../../components/Switch/Switch"
import trash from "../../../firm/images/Trash.svg"
import edit from "../../../firm/images/Edit.svg"
import cancel from "../../../../components/Modal/assets/close.svg"
import TextInput from "../../../../components/TextInput/TextInput"

const BillingCodes = () => {

  const [toDelete, setToDelete] = useState<string>("")
  const [editing, setEditing] = useState("")
  const [changed, setChanged] = useState<{ name?: string, description?: string }>({})

  const codes = useQuery(["billing/codes-with-stats"],
    () => axiosInstance.get<(BillingCode & { firms: Partial<Firm>[] })[]>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/billing/codes-with-stats`)
      .then(res => res.data),
    {
      staleTime: 0,
      refetchOnWindowFocus: false
    }
  )

  const deleteCode = useMutation<any, AxiosError<{ message?: string } | undefined>, string>(
    (code) => axiosInstance.delete(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/billing/codes/${encodeURIComponent(code)}`)
      .then(res => res.data),
      {
        onSuccess: () => {
          setToDelete("")
          codes.refetch()
        }
      }
  )

  const cancelDelete = useCallback(
    () => {
      setToDelete("")
      deleteCode.reset()
    },
    [deleteCode],
  )

  const resetChanged = useCallback(
    () => {
      setEditing("")
      setChanged({})
    },
    [],
  )

  const toggleBillingCode = useMutation<any, AxiosError<{ message?: string }>, { code: string, payload: { name?: string, optIn?: boolean } }>((x: { code: string, payload: { name?: string, optIn?: boolean } }) => axiosInstance.patch(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/billing/codes/${encodeURIComponent(x.code)}`, x.payload)
    .then(res => res.data), {
      onSuccess: () => { codes.refetch(); resetChanged() },
      onError: () => { codes.refetch(); resetChanged() }
    }
  )
  


  return (
    <div className="w-full h-full px-10" onKeyDown={e => e.key === "Escape" && resetChanged()}>
      <RenderQueryResults queryResult={codes} renderData={(data) => <>
        <ul className="grid grid-cols-[1.5fr,1.75fr,2fr,2fr,3rem,4rem] gap-y-2">
          <div className="border-b-2 border-neutral-200 font-bold">Code</div>
          <div className="border-b-2 border-neutral-200 font-bold">Name</div>
          <div className="border-b-2 border-neutral-200 font-bold">Description</div>
          <div className="border-b-2 border-neutral-200 font-bold">Firms using</div>
          <div className="border-b-2 border-neutral-200 font-bold">Show</div>
          <div className="border-b-2 border-neutral-200 font-bold text-right"></div>
          {data?.map(({ _id, name, description, firms, optIn }) => <Fragment key={_id}>
            <div className="pb-2 border-b border-neutral-200 flex items-center"><span className="rounded-2 bg-surface-300 px-3 py-1">{_id}</span></div>
            {editing === _id
              ? <div className="pb-2 border-b border-neutral-200 flex items-center gap-x-1">
                  <div className="w-4/5 flex items-start gap-x-1">
                    <TextInput name="name" value={changed.name !== undefined ? changed.name : name} onChange={(val) => setChanged({ ...changed, name: val })}/>
                  </div>
                  <button className="p-1" onClick={resetChanged}><img src={cancel} className="w-4 h-4"/></button>
                </div>
              : <div className="pb-2 border-b border-neutral-200 flex items-center gap-x-1">
                  <span>{name}</span>
                  <button className="p-1" onClick={() => setEditing(_id)}><img className="cursor-pointer" src={edit}/></button>
                </div>
            }
            {editing === _id
              ? <div className="pb-2 border-b border-neutral-200 flex items-center gap-x-1">
                  <div className="w-4/5 flex items-start gap-x-1">
                    <TextInput type="textarea" name="description" value={changed.description !== undefined ? changed.description : description} onChange={(val) => setChanged({ ...changed, description: val })}/>
                  </div>
                  <button className="p-1" onClick={resetChanged}><img src={cancel} className="w-4 h-4" /></button>
                </div>
              : <div className="pb-2 border-b border-neutral-200 flex items-center gap-x-1">
                  <span>{description}</span>
                  <button className="p-1" onClick={() => setEditing(_id)}><img className="cursor-pointer" src={edit}/></button>
                </div>
            }
            <div className="pb-2 border-b border-neutral-200 flex flex-col gap-y-1">{firms.map(f => <Link className="underline text-interactive-500" key={f._id} to={`/admin/firm/${f._id}`}>{f.name}</Link>)}</div>
            <div className="pb-2 border-b border-neutral-200 flex items-center justify-center">{
              toggleBillingCode.isLoading && toggleBillingCode.variables?.code === _id
                ? <Loading />
                : <Switch checked={Boolean(optIn)} onChange={() => toggleBillingCode.mutate({ code: _id, payload: { optIn: !optIn } })} name={_id} id={_id} />
            }</div>
            <div className="pb-2 border-b border-neutral-200 flex flex-row-reverse items-center gap-x-2">
              {editing !== _id && !firms?.length && <button onClick={() => setToDelete(_id)} className="btn h-8 w-8 flex items-center justify-center" disabled={Boolean(firms?.length)}><img src={trash} /></button>}
              {editing === _id && <button onClick={() => toggleBillingCode.mutate({ code: _id, payload: changed })} className="btn btn-primary btn-xs flex items-center justify-center">Update</button>}
            </div>
          </Fragment>)}
        </ul>
        {toggleBillingCode.error && <div className="mt-10 text-error">{toggleBillingCode.error?.response?.data?.message ?? toggleBillingCode.error?.message ?? toggleBillingCode.error}</div>}
      </>
      } />
      <AnimatePresence>
        {
          toDelete && (
            <Modal handleConfirm={() => deleteCode.mutate(toDelete)} handleClose={cancelDelete}>
              <div className="flex flex-col items-center gap-y-4">
                <p>Are you sure you want to delete this code?</p>
                <div className="flex justify-center gap-x-4">
                  <button className="btn btn-secondary btn-medium" disabled={deleteCode.isLoading} onClick={cancelDelete}>Cancel</button>
                  <button className="btn btn-primary btn-medium" disabled={deleteCode.isLoading} onClick={() => deleteCode.mutate(toDelete)}>{deleteCode.isLoading ? <Loading type="dots" /> : <>Yes, delete</>}</button>
                </div>
                {deleteCode.isError && <div className="text-error">{deleteCode.error.response?.data?.message ?? deleteCode.error.message}</div>}
              </div>
            </Modal>
          )
        }
      </AnimatePresence>
    </div>
  )
}

export default BillingCodes