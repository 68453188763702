import { ReactNode } from "react"
import { customDateFormat } from "../../lib/date"

export interface ReportsListItem {
  date: string
  id: string
  link: ReactNode
  title: string | ReactNode
}

interface Props {
  reports: ReportsListItem[]
}

const ReportsList = ({ reports }: Props) => {
  return (
    <div className="reports-list max-h-[29rem] bg-surface-100 overflow-auto text-main-500 py-3 mt-6">
      {reports && reports.length > 0 ? (
        <table className="w-full table-fixed border-collapse">
          <tbody>
            {[...reports]
              .sort((report1, report2) => Number(new Date(report2.date)) - Number(new Date(report1.date)))
              .map(({ id, link, title, date }) => (
                <tr className="border-b-0.5 border-surface-400 last-of-type:border-0" key={id}>
                  <td className="w-1/2 pl-8 pr-4 py-4">{title}</td>
                  <td className="w-1/3 pl-4 pr-4 py-4">{link}</td>
                  <td className="w-40 text-right pl-4 pr-8 py-4">{date ? customDateFormat(date) : ""}</td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <p className="text-p text-center py-3">There are no reports currently available</p>
      )}
    </div>
  )
}

export default ReportsList
