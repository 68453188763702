import axios from "axios";
import React, { useEffect } from 'react';
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import Loading from "../../components/ClientProfile/Loading/Loading";

type ConfigResponse = {
  authorizeEndpoint: string,
  params: {
    client_id: string,
    redirect_uri: string,
    response_type: string,
    scope: string
  }
}

const RedirectToOAuthProvider = ({ idp }: { idp: string }) => {
  const location = useLocation()

  const provider = useQuery<ConfigResponse>(
    [idp],
    () => axios.get(`${import.meta.env.VITE_APP_API_BASE}/api/auth/provider/${idp}/config`).then(res => res.data),
    { enabled: true, retry: false }
  )

  useEffect(() => {
    if (provider.isSuccess && provider.data) {
      const form = document.createElement('form');
      form.method = 'GET';
      form.action = provider.data.authorizeEndpoint;
  
  
      // Define pwrameters
      const params = {
        state: JSON.stringify({
          protocol: window.location.protocol,
          pathname: location.pathname,
          hostname: window.location.hostname,
          search: location.search,
          port: window.location.port,
          hash: location.hash,
        }),
        client_id: provider.data.params.client_id,
        redirect_uri: provider.data.params.redirect_uri,
        response_type: provider.data.params.response_type,
        scope: provider.data.params.scope
      };
  
      // Append hidden input elements for each parameter
      Object.entries(params).forEach(([key, value]) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = value;
        form.appendChild(input);
      });
  
      // Append form to the body and submit
      document.body.appendChild(form);
      form.submit();
  
      // Clean up by removing the form after submission
      return () => {
        form.remove();
      };

    }
  }, [location.hash, location.pathname, location.search, provider.data, provider.isSuccess]);



  return (
    <>{provider.isLoading && <div>Loading {idp}<Loading /></div>}</>
  );
};

export default RedirectToOAuthProvider;
