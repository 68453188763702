import clsx from "clsx"
import { useContext, useEffect, useState } from "react"
import AlertIcon from "../../../../assets/icons/AlertIcon"
import TextInput from "../../../../components/TextInput/TextInput"
import { AppContext } from "../../../../contexts/AppContext"

const ratingRange = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const FeedbackModal: React.FunctionComponent<{ handleClose: () => void }> = ({ handleClose }) => {
  const { updateUserProfile } = useContext(AppContext)  
  const [rating, setRating] = useState<number>()
  const [error, setError] = useState<boolean>(false)
  const [step, setStep] = useState<number>(1)
  const [comment, setComment] = useState<string>("")

  const onNext = () => {
    if (!rating) {
      setError(true)
      return
    } else {
      setStep(2)
    }
  }
  const onFinish = () => {
    updateUserProfile({
      feedback: {
        timestamp: new Date(),
        status: "success",
        score: rating,
        comment: comment
      }
    })
    handleClose()
  }

  useEffect(() => {
    if (rating) {
      setError(false)
    }
  }, [rating])

  
  return (
    <div className="feedback-modal">
      {step === 1 && (
        <div className="h-full flex flex-col gap-6">
          <h2 className="text-h2">Risk Profiling Suite feedback</h2>
          <p>How likely are you to recommend the Risk Profiling Suite to a peer?</p>
          <div>
            <div className="flex justify-between text-sec mb-1">
              <p>
                <span className="sr-only">0 = </span>Not at all likely
              </p>
              <p>
                <span className="sr-only">10 = </span>Extremely likely
              </p>
            </div>
            <div className={clsx("grid grid-cols-11 items-center border", error ? "border-red-500 shadow-err" : "border-main-500")}>
              {ratingRange.map((value) => (
                <label
                  key={value}
                  className={clsx("w-full h-full text-center cursor-pointer p-4 border-main-500 hover:bg-interactive-100 active:bg-interactive-200", {
                    "border-l": value !== 0,
                    "bg-interactive-200": value === rating,
                    "border-red-500": error
                  })}
                  htmlFor={`${value}`}
                >
                  <input
                    id={`${value}`}
                    className="hidden"
                    name="feedback"
                    value={value}
                    type="radio"
                    onChange={() => {
                      setRating(value)
                      setError(false)
                    }}
                    checked={value === rating}
                  />
                  {value}
                </label>
              ))}
            </div>
            <div role="alert" className="text-red-500 text-sm mt-1">
              {error && (
                <div className="flex gap-1 items-center">
                  <AlertIcon sizeProp="sm" />
                  <span>Please select a number</span>
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-5 justify-center">
            <button
              type="button"
              className="btn btn-secondary btn-medium text-sec w-44"
              onClick={() => {
                handleClose()
                updateUserProfile({
                  feedback: {
                    timestamp: new Date(),
                    status: "snoozed"
                  }
                })
              }}
            >
              Later
            </button>
            <button type="button" className="btn btn-primary btn-medium text-sec w-44" onClick={onNext}>
              Next
            </button>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="h-full flex flex-col gap-6">
          <h2 className="text-h2">Thanks for sharing</h2>
          <p>
            We would love to hear your feedback about how we can improve the Suite. Please let us know below, or book a meeting with our customer success team{" "}
            <a
              className="link"
              href="https://meetings.hubspot.com/claire-van-der-veldt?uuid=604d29ef-65dc-4bf4-ad65-4865221d25c2"
              aria-label="Book a meeting with the customer success team"
            >
              here
            </a>
            .
          </p>
          <TextInput
            label="Is there anything we can improve on?"
            id="feedback"
            type="textarea"
            placeholder="Type your message here"
            name="feedback-text"
            onChange={setComment}
          />
          <div className="text-center">
            <button type="button" className="btn btn-primary btn-medium text-sec w-44" onClick={onFinish}>
              Finish
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default FeedbackModal
