import { Trans } from "@lingui/macro"
import { useMemo } from "react"
import { useTheme } from "../../../../contexts/ThemeContext"
import { Client } from "../../../../models/Client"
import { BarScore } from "../../../clients/components/Profile/components/PersonaSummary/CauseScores"
import { causeScoresForProfile } from "../../../clients/components/Profile/causeScores"
import CauseBar from "../../../clients/components/Profile/components/PersonaSummary/CauseBar"
import { latest } from "../../../../lib/clients"
// import infoIcon from "../../assets/images/info.svg"

const SustainabilityMetrics = ({ client }: { client: Client }) => {
  const theme = useTheme()
  const game = latest(client, "esg")
  const currentThemeRatings = useMemo(
    () =>
      (client.portfolio?.current?.themeRatings ?? []).map((r, i) => ({ id: `cause${i + 1}`, rating: Math.round(r), percentage: (Math.round(r) / 10) * 100 })),
    [client.portfolio]
  )
  const proposedThemeRatings = useMemo(
    () =>
      (client.portfolio?.proposed?.themeRatings ?? game?.esg.results?.portfolioMatch?.themeRatings ?? []).map((r, i) => ({
        id: `cause${i + 1}`,
        rating: Math.round(r),
        percentage: (Math.round(r) / 10) * 100
      })),
    [game?.esg.results?.portfolioMatch?.themeRatings, client.portfolio?.proposed?.themeRatings]
  )
  const scores = useMemo(() => causeScoresForProfile(client!, theme), [client, theme])
  const barScores: BarScore[] = scores
  // const keyMetrics = useMemo(() => {
  //   const numberFormatter = new Intl.NumberFormat(theme.defaultLocale, { signDisplay: "exceptZero" })
  //   const causes = theme.causes ?? {}
  //   return Object.keys(causes)
  //     .filter((key) => !causes[key].isDefault)
  //     .map((causeId) => {
  //       const cause = causes[causeId]
  //       return {
  //         id: causeId,
  //         data: {
  //           title: cause.themeIndicatorTitle,
  //           portfolioDiff: `${numberFormatter.format(
  //             (currentThemeRatings.find((r) => r.id === causeId)?.percentage ?? 0) - (proposedThemeRatings.find((r) => r.id === causeId)?.percentage ?? 0)
  //           )}%`
  //         }
  //       }
  //     })
  // }, [currentThemeRatings, proposedThemeRatings, theme.causes, theme.defaultLocale])

  return (
    <>
      <h2 className="text-p font-medium">
        <Trans id="portfolio-analyser-sustainability-metrics-heading">Theme ratings</Trans>
      </h2>
      <div className="grid grid-cols-12" role="table">
        <div className="sr-only" role="columnheader">
          <span>Theme</span>
        </div>
        <div className="col-start-7 col-span-3 text-center py-4 text-sec" role="columnheader">
          <p>
            <Trans id="portfolio-analyser-sustainability-metrics-table-column-2-heading">Current portfolio</Trans>
          </p>
        </div>
        <div className="col-start-10 col-span-3 text-center py-4 bg-data-olive-100 font-semibold text-sec mr-5" role="columnheader">
          <p>
            <Trans id="portfolio-analyser-sustainability-metrics-table-column-3-heading">Proposed portfolio</Trans>
          </p>
        </div>
      </div>
      {barScores.map((data) => (
        <div key={data.causeId} className="grid grid-cols-12 border-dashed border-b border-b-neutral-100 text-sec" role="row">
          <div className="col-start-1 col-span-4 flex gap-x-3 py-4" role="cell">
            <img src={data.icon} alt="" aria-hidden />
            <CauseBar title={data.title} score={data.score} color={data.color} />
          </div>
          <p className="col-start-7 col-span-3 text-center py-4 font-normal" role="cell">
            {currentThemeRatings.find((r) => r.id === data.causeId)?.rating}/10
          </p>
          <p className="col-start-10 col-span-3 text-center font-semibold bg-data-olive-100 py-4 mr-5" role="cell">
            {proposedThemeRatings.find((r) => r.id === data.causeId)?.rating}/10
          </p>
        </div>
      ))}
      <div className="mt-2 text-xxs">
        <span><Trans id="funds-match-source-citation">Source: Calculated on MSCI Analytics</Trans></span>
      </div>
      {/* <div className="grid grid-cols-12">
        <div className="col-start-10 col-span-3 bg-data-olive-100 py-5 mr-5" />
      </div>
      <div className="mt-5">
        <h2 className="text-p text-black font-medium">
          <Trans id="portfolio-analyser-sustainability-metrics-key-metrics-heading">Proposed portfolio theme indicators</Trans>
        </h2>
        <div className="flex gap-x-3 mt-3">
          {barScores.map((data, i) => {
            const metricData = keyMetrics.find((metric) => metric.id === data.causeId)
            return (
              <div key={i} className="relative w-full shadow-sm p-3 text-sm">
                {!forReport && <img className="absolute right-1.5 top-1.5" src={infoIcon} alt="" aria-hidden />}
                <a href="#" className="absolute top-0 left-0 w-full h-full" aria-label={`Read more about ${metricData?.data.title}`} />
                <div className="flex gap-0.5 items-center mb-1">
                  <img className="h-6" src={scores[i].secondaryIcon} alt="" aria-hidden />
                  <p className="text-sec font-medium">{metricData?.data.portfolioDiff}</p>
                </div>
                <p className="mb-3">{metricData?.data.title}</p>
              </div>
            )
          })}
        </div>
      </div> */}
    </>
  )
}

export default SustainabilityMetrics
