import { useTheme } from "../../../../contexts/ThemeContext"
import { Client } from "../../../../models/Client"
import { Firm } from "../../../../models/Firm"
import { Household } from "../../../../models/Household"
import Container from "../components/Container"
import Footer from "../components/Footer"
import banner from "./assets/banner.jpg"

const SustainabilityCommitmentPage = ({ page, firm, client, household }: { page: number; firm: Firm; client: Client; household: Household }) => {
  const theme = useTheme()
  return (
    <Container id="sustainability-commitment-page" className="flex flex-col relative justify-between">
      <div className="flex-grow overflow-hidden">
        <div className="w-full flex h-[364px] items-center" style={{ backgroundImage: `url(${banner})` }}>
          <div className="mx-9 p-8 items-center w-[55%] rounded-3" style={{ background: "rgba(255, 255, 255, 0.5)" }}>
            <h4 className="text-h4 text-black mb-3">Our commitment to environmental sustainability</h4>
            <p>
              Bank of America is committed to improving the environment in how we approach our global business strategy, work with partners, make our operations
              more sustainable, support our employees, manage risks and govern our activities.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 h-full">
          <div className="flex flex-col gap-y-7 pt-9 px-10">
            <div>
              <h4 className="!font-medium mb-2">Business & partners</h4>
              <p className="text-sec">
                Since 2007, we have invested more than $200 billion in financing low-carbon and sustainable business activities across the globe through our
                Environmental Business Initiative. For years, our financing commitment has focused on providing capital that supports innovative solutions to
                climate change and other environmental challenges. Over the next ten years (2020-2030), we have committed an additional $1 trillion to help
                advance these efforts and to work toward achieving the United Nation’s Sustainable Development Goals (SDGs).
              </p>
            </div>
            <div>
              <h4 className="!font-medium mb-2">Operations & employees</h4>
              <p className="text-sec mb-2">
                As a large, global company, we understand the impact our operations have on the environment and the potential we have to make a difference. Our
                scale lets us take measurable action to reduce our impacts by operating with greater efficiency, by employing new technologies and by
                influencing our supply chain.
              </p>
              <p className="text-sec">
                Our employees also play a key role in reducing our environmental impact. We empower them to make a difference through our My Environment®
                program, employee reimbursement programs and environmentally focused volunteer events.
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-y-7 pt-9 px-10 bg-surface-100">
            <div>
              <h4 className="!font-medium mb-2">Governance & policies</h4>
              <p className="text-sec pb-2">
                Our serious commitment to environmental activities starts at the top, with leadership that ensures strong governance and reporting. We set
                industry-leading business and operational goals, partnering across our lines of business to identify and advance solutions. We also have robust
                risk management of environmental issues related to our clients.
              </p>
              <p className="text-sec">
                To give our investors, clients and communities a complete, transparent view of our approach to environmental and social risks, we have a
                dedicated Environmental and Social Risk Policy Framework. That framework sets out how we identify, measure, monitor and control these risks as
                part of our company’s risk framework.
              </p>
            </div>
            <a
              href={theme.reports?.sustainabilityCommitment?.readMoreLink}
              className="btn btn-primary btn-small text-sec w-max"
              target="_blank"
              rel="noreferrer"
            >
              Read more
            </a>
          </div>
        </div>
      </div>
      <div className="relative mx-10">
        <Footer pageNum={page} client={client} household={household} firm={firm} />
      </div>
    </Container>
  )
}

export default SustainabilityCommitmentPage
