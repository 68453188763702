import { AnimatePresence, motion } from "framer-motion"
import { useContext, useMemo, useState } from "react"
import AvatarBadges from "../../../../components/AvatarBadges/AvatarBadges"
import Modal from "../../../../components/Modal/Modal"
import SidebarMenu from "../../../../components/SidebarMenu/SidebarMenu"
import SidebarMenuItem from "../../../../components/SidebarMenuItem/SidebarMenuItem"
import SidebarSection from "../../../../components/SidebarSection/SidebarSection"
import SidebarSectionRow from "../../../../components/SidebarSectionRow/SidebarSectionRow"
import { FirmContext } from "../../../../contexts/FirmContext"
import { formatCurrencyLong } from "../../../../lib/currency"
import { getAge } from "../../../../lib/date"
import { Household } from "../../../../models/Household"
import EditHouseholdProfileModal from "../../../clients/components/ProfileOverview/components/EditProfileModal/EditHouseholdProfileModal"
import UnlinkHouseholdModal from "./UnlinkHouseholdModal"
import { useTheme } from "../../../../contexts/ThemeContext"
import clsx from "clsx"
import { Link, useNavigate } from "react-router-dom"

const HouseholdProfileOverview = ({ household, isExpanded = true, isAnimating }: { household: Household; isExpanded: boolean; isAnimating: boolean }) => {
  const { firm } = useContext(FirmContext)
  const theme = useTheme()
  const navigate = useNavigate()
  const { annualInvestmentContribution, retirementIncomeGoal, otherSourcesRetirementIncome, investmentAmount } = household
  const [isEditModal, setIsEditModal] = useState(false)
  const [isUnlinkModal, setIsUnlinkModal] = useState(false)
  const clientEmails = household.members.map(({ client: { email } }) => email).filter((val) => !!val)
  const clientNames = household.members
    .filter(({ client: { lastName } }) => !!lastName)
    .map(({ client: { firstName, lastName } }) => {
      return `${firstName ? `${firstName.charAt(0).toUpperCase()} ` : ""}${lastName}`
    })

  return (
    <motion.div
      animate={{ width: "100%", transition: { duration: 0.1, ease: "easeInOut" } }}
      className="profile-overview-wrapper w-full h-full flex flex-col overflow-auto no-scrollbar"
    >
      <div
        className={clsx("profile-overview-header sticky top-0 w-full flex items-center bg-interactive-600 border-b border-interactive-500 p-5", {
          "justify-center": !isExpanded
        })}
      >
        <Link
          to={`/households/${household._id}`}
          aria-label="Go to household profile"
          className={clsx("w-full gap-x-3 flex items-center transition ease-in-out duration-500", { "translate-x-1": !isExpanded && !isAnimating })}
        >
          <AvatarBadges clients={household.members.map(({ client }) => client)} size="small" />
          {isExpanded && <h3 className="text-white whitespace-nowrap">{clientNames.join(" & ")}</h3>}
        </Link>
      </div>
      <div className="profile-overview-content grow">
        {isExpanded && (
          <>
            <SidebarSection label="Household details" hideLabel={true}>
              <SidebarSectionRow label="Investment amount" value={formatCurrencyLong(investmentAmount)} />
              {household.members.map(({ client: { _id, dob, firstName } }) => <SidebarSectionRow key={_id} label={`${firstName}'s age`} value={dob ? getAge(dob) : ""} /> )}
            </SidebarSection>
          </>
        )}
      </div>
      <SidebarMenu>
        {theme.pages.profile.sidebar.menu.clientProfile && (
          <SidebarMenuItem
            isActive={window.location.pathname === `/households/${household._id}`}
            isDisabled={household.status === "Archived"}
            label={isExpanded ? `${household.name} profile` : ""}
            leftElement={
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.8334 16.6667C15.8334 17.1267 15.4609 17.5 15.0001 17.5C14.5392 17.5 14.1667 17.1267 14.1667 16.6667C14.1667 14.3692 12.2976 12.5 10.0001 12.5C7.70258 12.5 5.83341 14.3692 5.83341 16.6667C5.83341 17.1267 5.46091 17.5 5.00008 17.5C4.53925 17.5 4.16675 17.1267 4.16675 16.6667C4.16675 13.45 6.78425 10.8333 10.0001 10.8333C13.2159 10.8333 15.8334 13.45 15.8334 16.6667ZM10.0001 4.16667C10.9192 4.16667 11.6667 4.91417 11.6667 5.83333C11.6667 6.7525 10.9192 7.5 10.0001 7.5C9.08091 7.5 8.33341 6.7525 8.33341 5.83333C8.33341 4.91417 9.08091 4.16667 10.0001 4.16667ZM10.0001 9.16667C11.8384 9.16667 13.3334 7.67167 13.3334 5.83333C13.3334 3.995 11.8384 2.5 10.0001 2.5C8.16175 2.5 6.66675 3.995 6.66675 5.83333C6.66675 7.67167 8.16175 9.16667 10.0001 9.16667Z"
                  fill="currentcolor"
                />
              </svg>
            }
            onClick={() => navigate(`/households/${household._id}`)}
          />
        )}
        {theme.pages.profile.sidebar.menu.results && (firm?.config?.supportedGames?.length ?? 0) >= 1 && (
          <SidebarMenuItem
            isActive={window.location.pathname === `/households/${household._id}/results`}
            isDisabled={household.status === "Archived"}
            label={isExpanded ? "Goals and preferences" : ""}
            leftElement={
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.16675 18.3332H6.66675C8.04508 18.3332 9.16675 17.2115 9.16675 15.8332V4.1665C9.16675 2.78817 8.04508 1.6665 6.66675 1.6665H4.16675C2.78841 1.6665 1.66675 2.78817 1.66675 4.1665V15.8332C1.66675 17.2115 2.78841 18.3332 4.16675 18.3332ZM3.33341 4.1665C3.33341 3.70734 3.70758 3.33317 4.16675 3.33317H6.66675C7.12592 3.33317 7.50008 3.70734 7.50008 4.1665V15.8332C7.50008 16.2923 7.12592 16.6665 6.66675 16.6665H4.16675C3.70758 16.6665 3.33341 16.2923 3.33341 15.8332V4.1665Z"
                  fill="currentcolor"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.3334 18.3332H15.8334C17.2117 18.3332 18.3334 17.2115 18.3334 15.8332V8.33317C18.3334 6.95484 17.2117 5.83317 15.8334 5.83317H13.3334C11.9551 5.83317 10.8334 6.95484 10.8334 8.33317V15.8332C10.8334 17.2115 11.9551 18.3332 13.3334 18.3332ZM12.5001 8.33317C12.5001 7.874 12.8742 7.49984 13.3334 7.49984H15.8334C16.2926 7.49984 16.6667 7.874 16.6667 8.33317V15.8332C16.6667 16.2923 16.2926 16.6665 15.8334 16.6665H13.3334C12.8742 16.6665 12.5001 16.2923 12.5001 15.8332V8.33317Z"
                  fill="currentcolor"
                />
              </svg>
            }
            onClick={() => navigate(`/households/${household._id}/results`)}
          />
        )}
        {theme.pages.profile.sidebar.menu.goalProjector && (
          <SidebarMenuItem
            isActive={window.location.pathname.startsWith(`/households/${household._id}/explore`)}
            label={isExpanded ? "Goal Projector" : ""}
            leftElement={
              <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.6736 0.448996C14.5976 0.0218488 14.0147 -0.0491207 13.8385 0.347332L13.2992 1.56073V2.4913C13.2646 2.51327 13.2313 2.538 13.1995 2.5655C11.8701 1.41525 10.138 0.718573 8.2462 0.718573C4.06689 0.718573 0.666748 4.11871 0.666748 8.29802C0.666748 12.4773 4.06689 15.8775 8.2462 15.8775C12.4255 15.8775 15.8256 12.4773 15.8256 8.29802C15.8256 6.74113 15.3538 5.29236 14.5456 4.08722H15.5449L17.0739 3.39919C17.4666 3.22245 17.3977 2.64503 16.9744 2.56566L14.9835 2.19236L14.6736 0.448996ZM8.2462 14.3616C4.9029 14.3616 2.18264 11.6413 2.18264 8.29802C2.18264 4.95473 4.9029 2.23446 8.2462 2.23446C9.71911 2.23446 11.0711 2.76244 12.1228 3.63907L11.4067 4.35522C10.5412 3.66061 9.44225 3.24506 8.2462 3.24506C5.45552 3.24506 3.19323 5.50735 3.19323 8.29802C3.19323 11.0887 5.45552 13.351 8.2462 13.351C11.0369 13.351 13.2992 11.0887 13.2992 8.29802C13.2992 7.31231 13.0169 6.39252 12.5288 5.61506L13.2569 4.88697C13.9211 5.85944 14.3098 7.03422 14.3098 8.29802C14.3098 11.6413 11.5895 14.3616 8.2462 14.3616ZM10.1509 5.61101L8.98026 6.78165C8.75839 6.67404 8.50934 6.6137 8.2462 6.6137C7.31597 6.6137 6.56188 7.3678 6.56188 8.29802C6.56188 9.22825 7.31597 9.98235 8.2462 9.98235C9.17642 9.98235 9.93052 9.22825 9.93052 8.29802C9.93052 8.27031 9.92985 8.24275 9.92853 8.21537L11.2337 6.91018C11.43 7.33194 11.5396 7.80218 11.5396 8.29802C11.5396 10.1169 10.0651 11.5914 8.2462 11.5914C6.42733 11.5914 4.95285 10.1169 4.95285 8.29802C4.95285 6.47916 6.42733 5.00467 8.2462 5.00467C8.95591 5.00467 9.61319 5.22916 10.1509 5.61101Z"
                  fill="#EAD9C3"
                />
              </svg>
            }
            onClick={() => {
              if (household.primaryInvestmentGoal) {
                navigate(
                  `/households/${household?._id}/explore/goal?goal=${
                    household.primaryInvestmentGoal === "retirementIncome"
                      ? "retirement-income"
                      : household.primaryInvestmentGoal === "retirementDrawdown"
                      ? "retirement-drawdown"
                      : "wealth-accumulation"
                  }`
                )
              } else {
                navigate(`/households/${household._id}/explore/set-goal`)
              }
            }}
          />
        )}
        <SidebarMenuItem
          isDisabled={household.status === "Archived"}
          label={isExpanded ? "Edit household details" : ""}
          leftElement={
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.6791 7.1192L8.88243 5.32253L10.1811 4.0232L11.9771 5.8192L10.6791 7.1192ZM6.0531 11.7499L4.06843 11.9305L4.24443 9.95987L7.9891 6.2152L9.78643 8.01253L6.0531 11.7499ZM12.9358 4.89186L12.9351 4.8912L11.1098 3.06586C10.6158 2.5732 9.7671 2.54986 9.2991 3.01986L3.30177 9.0172C3.08443 9.23386 2.94977 9.52187 2.92177 9.82653L2.6691 12.6065C2.65177 12.8032 2.72177 12.9979 2.86177 13.1379C2.98777 13.2639 3.15777 13.3332 3.3331 13.3332C3.35377 13.3332 3.37377 13.3325 3.39377 13.3305L6.17377 13.0779C6.4791 13.0499 6.76643 12.9159 6.9831 12.6992L12.9811 6.7012C13.4664 6.21453 13.4458 5.40253 12.9358 4.89186Z"
                fill="currentcolor"
              />
            </svg>
          }
          onClick={() => setIsEditModal(true)}
        />
        <SidebarMenuItem
          isDisabled={household.status === "Archived" || clientEmails.length === 0}
          label={isExpanded ? "Contact clients" : ""}
          leftElement={
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.6668 12H3.3335C2.96616 12 2.66683 11.7013 2.66683 11.3333V4.83329L7.60016 8.53329C7.71883 8.62263 7.8595 8.66663 8.00016 8.66663C8.14083 8.66663 8.2815 8.62263 8.40016 8.53329L13.3335 4.83329V11.3333C13.3335 11.7013 13.0342 12 12.6668 12ZM12.2222 3.99996L8.00016 7.16663L3.77816 3.99996H12.2222ZM12.6668 2.66663H3.3335C2.23083 2.66663 1.3335 3.56396 1.3335 4.66663V11.3333C1.3335 12.436 2.23083 13.3333 3.3335 13.3333H12.6668C13.7695 13.3333 14.6668 12.436 14.6668 11.3333V4.66663C14.6668 3.56396 13.7695 2.66663 12.6668 2.66663Z"
                fill="currentcolor"
              />
            </svg>
          }
          link={clientEmails.length > 0 ? `mailto:${clientEmails.join(",")}` : "#"}
        />
        <SidebarMenuItem
          isDisabled={household.status === "Archived" || !!household.externalId}
          label={isExpanded ? "Unlink household" : ""}
          leftElement={
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.6668 12.6667C14.6668 13.0347 14.3688 13.3333 14.0002 13.3333C13.6315 13.3333 13.3335 13.0347 13.3335 12.6667C13.3335 11.564 12.4362 10.6667 11.3335 10.6667C10.8782 10.6667 10.4455 10.8227 10.0962 11.0993C10.4588 11.7633 10.6668 12.5247 10.6668 13.3333C10.6668 13.7013 10.3688 14 10.0002 14C9.6315 14 9.3335 13.7013 9.3335 13.3333C9.3335 11.4953 7.83816 10 6.00016 10C4.16216 10 2.66683 11.4953 2.66683 13.3333C2.66683 13.7013 2.36883 14 2.00016 14C1.6315 14 1.3335 13.7013 1.3335 13.3333C1.3335 10.76 3.4275 8.66667 6.00016 8.66667C7.28483 8.66667 8.44883 9.18867 9.2935 10.0307C9.87283 9.58267 10.5862 9.33333 11.3335 9.33333C13.1715 9.33333 14.6668 10.8287 14.6668 12.6667ZM11.3335 6C11.7015 6 12.0002 6.29933 12.0002 6.66667C12.0002 7.034 11.7015 7.33333 11.3335 7.33333C10.9655 7.33333 10.6668 7.034 10.6668 6.66667C10.6668 6.29933 10.9655 6 11.3335 6ZM11.3335 8.66667C12.4362 8.66667 13.3335 7.76933 13.3335 6.66667C13.3335 5.564 12.4362 4.66667 11.3335 4.66667C10.2308 4.66667 9.3335 5.564 9.3335 6.66667C9.3335 7.76933 10.2308 8.66667 11.3335 8.66667ZM6.00016 3.33333C6.7355 3.33333 7.3335 3.93133 7.3335 4.66667C7.3335 5.402 6.7355 6 6.00016 6C5.26483 6 4.66683 5.402 4.66683 4.66667C4.66683 3.93133 5.26483 3.33333 6.00016 3.33333ZM6.00016 7.33333C7.47083 7.33333 8.66683 6.13733 8.66683 4.66667C8.66683 3.196 7.47083 2 6.00016 2C4.5295 2 3.3335 3.196 3.3335 4.66667C3.3335 6.13733 4.5295 7.33333 6.00016 7.33333Z"
                fill="currentcolor"
              />
            </svg>
          }
          onClick={() => setIsUnlinkModal(true)}
        />
      </SidebarMenu>

      <AnimatePresence>
        {isEditModal && (
          <Modal handleClose={() => setIsEditModal(false)}>
            <EditHouseholdProfileModal household={household} onClose={() => setIsEditModal(false)} />
          </Modal>
        )}
        {isUnlinkModal && (
          <Modal handleClose={() => setIsUnlinkModal(false)}>
            <UnlinkHouseholdModal household={household} onClose={() => setIsUnlinkModal(false)} />
          </Modal>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export default HouseholdProfileOverview
