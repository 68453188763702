import React from "react"
import styled from "styled-components"
import { useTheme } from "../../../contexts/ThemeContext"
import { Trans } from "@lingui/macro"
import { Client } from "../../../models/Client"

const CoverPageContainer = styled.div`
  height: 1122px;
  width: 794px;
`

const GoalPlanCoverPage = ({ client }: { client: Client }) => {
  const theme = useTheme()
  const dateCreated = new Date(client.createdAt)

  return (
    <CoverPageContainer className="reports-container cover-page flex relative flex-col w-full h-full">
      <div className="mt-24 ml-auto mr-24">
        <h1 className="title uppercase text-main-400 text-display leading-10">
          {theme.reports?.goalCover?.logo && <img className="mb-3" src={theme.reports.goalCover.logo} alt="" aria-hidden />}
          <Trans id="report-goal-plan-cover-page-title">Your goal plan</Trans>
        </h1>
        <hr className="border-2 my-6 w-1/4" />
        <div className="subtitle text-main-400 font-semibold text-h2 leading-7">
          <p>
            <Trans id={`report-goal-plan-goal-type-${client.primaryInvestmentGoal?.toLowerCase()}`}>Wealth</Trans>
          </p>
          <p>{dateCreated.toLocaleString(theme.defaultLocale, { month: "long" }) + " " + dateCreated.getFullYear()}</p>
        </div>
      </div>

      <div className="absolute bottom-0">{theme.reports?.goalCover?.heroImage && <img src={theme.reports.goalCover.heroImage} alt="" aria-hidden />}</div>
    </CoverPageContainer>
  )
}

export default GoalPlanCoverPage
