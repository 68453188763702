import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"
import axiosInstance from "../api/axiosInstance"
import { ClientHouseholdCacheContext } from "../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../contexts/FirmContext"
import { Client } from "../models/Client"
import { Household } from "../models/Household"
import { AuthContext, AuthStatus } from "../views/auth/AuthContext"
import { AppContext } from "../contexts/AppContext"

const useClientHouseholdSearch = (showArchivedOnly: boolean = false, pageSize: number = 25) => {
  const { authStatus, sessionInfo } = useContext(AuthContext)
  const [params, setParams] = useSearchParams()
  const { firmId } = useContext(AppContext)
  const { firm } = useContext(FirmContext)
  const [page, setPage] = useState(+(params.get("page") || "1"))
  const [search, setSearch] = useState("")
  const [sort, setSort] = useState(showArchivedOnly ? params.get("sort") || "-archivedAt" : params.get("sort") || "-createdAt")
  const [statusFilters, setStatusFilters] = useState<string[]>(params.getAll("status") || [])
  const [advisorFilters, setAdvisorFilters] = useState<string[]>(params.getAll("advisorId") || [])
  const [clients, setClients] = useState<string[]>()
  const { cache, replaceAll } = useContext(ClientHouseholdCacheContext)

  useEffect(() => {
    // TODO: setParams breaks forward navigation (seems to reset history)
    // const x = setTimeout(() => {
    setParams(
      {
        ...(page > 1 && { page: page.toString() }),
        ...(sort && (showArchivedOnly ? sort !== "-archivedAt" : sort !== "-createdAt") && { sort }),
        ...(statusFilters && { status: statusFilters }),
        ...(advisorFilters && { advisorId: advisorFilters })
      },
      { replace: true }
    )
    // }, 100)
    // return () => clearTimeout(x)
  }, [page, sort, setParams, showArchivedOnly, advisorFilters, statusFilters])

  const fetchClients = useCallback(
    (page: number = 1, search: string = "", sort: string = "", filters?: string[], advisorFilters?: string[]) => {
      const params = new URLSearchParams({
        ...(firmId && { firmId }),
        ...(search && { search }),
        ...(sort && { sort }),
        ...(page && { page: page.toString() }),
        limit: pageSize.toString()
      })

      // only show archived status
      if (showArchivedOnly) {
        params.append("status", "Archived")
      }

      filters?.forEach((filter) => {
        params.append("status", filter)
      })

      advisorFilters?.forEach((filter) => {
        params.append("advisorId", filter)
      })
      return axiosInstance
        .get<(Client | Household)[]>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/advisor/clients${params ? `?${params}` : ""}`)
        .then((res) => res.data)
    },
    [firmId, showArchivedOnly, pageSize]
  )

  // pass showArchivedOnly to differentiate queries. Stops the cached data from clients being used
  const { isSuccess, isLoading, isError, error, data, isPreviousData, isFetching, refetch } = useQuery<(Client | Household)[], { message?: string }>(
    ["clients", page, search, statusFilters, advisorFilters, sort, showArchivedOnly, authStatus, firm],
    () => fetchClients(page, search, sort, statusFilters, advisorFilters),
    {
      enabled: authStatus === AuthStatus.SignedIn && !!sessionInfo?.accessToken && !!firm,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 900,
      keepPreviousData: true,
      onSuccess(data) {
        replaceAll(data)
      }
    }
  )

  useEffect(() => {
    if (isSuccess) {
      setClients(data ? data.map((ch) => ch._id) : [])
    }
  }, [isSuccess, data, setClients])

  const merged = useMemo(() => clients?.map((id) => cache[id]).filter((_) => !!_), [cache, clients])

  return {
    isLoading,
    isError,
    isFetching,
    isSuccess,
    clients: merged,
    error,
    isPreviousData,
    sort,
    setSort,
    search,
    setSearch,
    page,
    setPage,
    advisorFilters,
    statusFilters,
    refetch,
    setAdvisorFilters,
    setStatusFilters
  }
}

export default useClientHouseholdSearch
