import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import logo from "../../../layout/images/CPLogo.svg"
import prison from "./images/void.png"
import good from "../../../pages/advisor/assets/images/checkmark-circle.svg"
import checkMissing from "./images/check-grey.svg"

const ProviderInsufficientPrivileges = () => {
  const { provider } = useParams()
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const granted = params.getAll("granted")
  const rejected = params.getAll("rejected")

  return (
    <div className="w-full h-full">
      <div className="relative w-full overflow-hidden">
        <img className="w-full object-cover min-h-[160px] max-h-[160px] md:max-h-[320px]" src={prison} />
        <p className="absolute bottom-0 left-0 right-0 text-center pb-10 text-white text-h2 font-bold px-10 text-balance md:text-wrap">
          We are looking forward to working together,<br className="hidden lg:inline"/>
          {' '} but need additional information
        </p>
      </div>
      <div className="w-full mx-auto px-10 md:px-10 md:max-w-[680px] py-13 flex flex-col items-start gap-y-6">
        {granted.length > 0 && <div className="flex flex-col gap-y-2">
          <p>Permissions to access the following data have been granted:</p>
          <ul className="flex flex-col gap-y-2">
            {granted.map(p => p.split(":")).map(([ scope ]) => (
              <li className="flex items-center gap-x-2" key={scope}><img src={good} /><span>{scope}</span></li>
            ))
          }
          </ul>
        </div>}
        <div className="flex flex-col gap-y-2">
          <p>Capital Preferences still requires the following permissions:</p>
          <ul className="flex flex-col gap-y-2">
            {rejected.map(p => p.split(":")).map(([ scope, explain ]) => (
              <li className="flex items-start gap-x-2" key={scope}>
                <img src={checkMissing} className="mt-px"/>
                <div>
                  <div className="font-semibold">{scope}</div>
                  <div className="text-sec">{explain}</div>
                </div>
              </li>
            ))
          }
          </ul>
        </div>
        <div className="mt-4 flex flex-col gap-y-1">
          <p>
            This data is required to set up and profile your clients in the Capital Preferences Suite, and save results back to {provider?.split("-")[0]}.
          </p>
          <p>Please go back and accept all the permissions listed.</p>
        </div>
        <div className="w-full flex justify-start">
          <button className="btn btn-medium btn-primary px-18" onClick={() => navigate(-1)}>Back</button>
        </div>
      </div>
      <img src={logo} alt="Powered by Capital Preferences" className="h-4 absolute mt-4 bottom-0 left-0 ml-[1.625rem] mb-7" />
    </div>    
  );
};

export default ProviderInsufficientPrivileges;
