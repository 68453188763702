import clsx from "clsx"
import format from "date-fns/format"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { ClientUpdateRequest } from "../../../../api/clients"
import { HouseholdUpdateRequest } from "../../../../api/households"
import { computeGoalAchievability } from "../../../../api/rm/goals"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"
import { Client, InvestmentGoal } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import { AuthContext, SessionInfo } from "../../../../views/auth/AuthContext"
import switchImage from "../assets/switch.svg"
import RetirementIncomeForm from "./RetirementIncomeForm"
import SelectPortfolio, { createOptions, Options } from "../components/SelectPortfolio/SelectPortfolio"
import {
  createFormValues,
  createRetirementIncomeRequest,
  RetirementIncomeDrawdownErrors,
  RetirementIncomeFormErrors,
  RetirementIncomeFormValues,
  validateRetirementDrawdownForm,
  validateRetirementIncomeForm
} from "./RetirementIncomeUtils"
import { FirmContext } from "../../../../contexts/FirmContext"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import { GoalExplorerStatuses } from "../utils/validation"
import Dropdown from "../../../../components/Dropdown/Dropdown"
import Checkbox from "../../../../components/Checkbox/Checkbox"
import chevronDown from "../../../../assets/icons/chevron-down.svg"
import { FEATURE_WEALTH_AND_INCOME_RANGE } from "../../../../config/features"
import { latest } from "../../../../lib/clients"
interface Props {
  client?: Client
  household?: Household
  outsideIM?: boolean
}

const RetirementIncome = ({ client, household, outsideIM }: Props) => {
  const { sessionInfo } = useContext(AuthContext)
  const { firm } = useContext(FirmContext)
  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const [values, setValues] = useState<RetirementIncomeFormValues>(() => createFormValues({ client, household }))
  const [status, setStatus] = useState<GoalExplorerStatuses>("init")
  const [errors, setErrors] = useState<RetirementIncomeFormErrors>({
    annualInvestmentContribution: undefined,
    retirementIncomeGoal: undefined,
    investmentAmount: undefined,
    members: [],
    otherSourcesRetirementIncome: undefined
  })
  const [errorsRetirementDrawdown, setErrorsRetirementDrawdown] = useState<RetirementIncomeDrawdownErrors>({
    pensionInRetirement: undefined,
    retirementIncomeGoal: undefined,
    investmentAmount: undefined,
    otherSourcesRetirementIncome: undefined
  })
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const clientOrHousehold = client ?? household
  const [goalType, setGoalType] = useState<InvestmentGoal>(clientOrHousehold?.alreadyRetired ? "retirementDrawdown" : "retirementIncome")
  const [options, setOptions] = useState<Options | undefined>(() => createOptions(goalType, clientOrHousehold))
  const [optionsWithRange, setOptionsWithRange] = useState<Options | undefined>(() => createOptions(goalType, clientOrHousehold))
  const [showRiskComfort, setShowRiskComfort] = useState(true)
  const [showIncomeRange, setShowIncomeRange] = useState(false)
  const [displayType, setDisplayType] = useState<"chart" | "list">("chart")
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const dropDownTrigger = useRef<HTMLDivElement>(null)

  const calculateResults = useCallback(
    (goalType: InvestmentGoal, sessionInfo?: SessionInfo, clientOrHousehold?: Client | Household) => {
      const values = createFormValues({
        client: client ? (clientOrHousehold as Client) : undefined,
        household: household ? (clientOrHousehold as Household) : undefined,
        goalType
      })
      const { isValid } = goalType === "retirementDrawdown" ? validateRetirementDrawdownForm({ values, firm }) : validateRetirementIncomeForm({ values, firm })
      if (clientOrHousehold && isValid) {
        setStatus("updating")
        computeGoalAchievability(goalType, clientOrHousehold.advisorId, client && clientOrHousehold._id, household && clientOrHousehold._id)
          .then((res) => {
            setOptions(createOptions(goalType, clientOrHousehold ?? undefined, res.results, false, res.disPortfolios, res.legacyPortfolios))
            setOptionsWithRange(createOptions(goalType, clientOrHousehold ?? undefined, res.results, true))
            setErrorMessage(undefined)
            setStatus("success")
          })
          .catch((error) => {
            setStatus("error")
            setErrorMessage("An error occurred. Please try again.")
            console.error("error calculating goal achievability", error)
          })
      }
    },
    [client, firm, household]
  )

  const update = useCallback(() => {
    if (client) {
      const member = values.members[0]
      const updateRequest: ClientUpdateRequest = createRetirementIncomeRequest({ client, values, goalType }) as ClientUpdateRequest
      const dob = member.dob ? format(member.dob, "yyyy-MM-dd") : member.dobRaw ?? undefined
      if (dob !== client.dob) {
        updateRequest.dob = dob
      }
      return updateClient(client._id, updateRequest)
        .then((updatedClient) => {
          setErrorMessage(undefined)
          calculateResults(goalType, sessionInfo, updatedClient!)
        })
        .catch((error) => {
          setStatus("error")
          setErrorMessage("An error occurred. Please try again.")
          console.error("error updating client details", error)
        })
    } else if (household) {
      const updateRequest: HouseholdUpdateRequest = createRetirementIncomeRequest({ values, goalType, household }) as HouseholdUpdateRequest
      const memberUpdates: ClientUpdateRequest[] = values.members.map(({ dob, dobRaw, retirementAge }, i) => {
        const nextMemberUpdates: { dob?: string; retirementAge?: number } = {}
        const nextDob = dob ? format(dob, "yyyy-MM-dd") : dobRaw ?? undefined
        if (nextDob !== household?.members[i].client.dob) {
          nextMemberUpdates.dob = nextDob
        }
        if (retirementAge !== household?.members[i].client.retirementAge) {
          nextMemberUpdates.retirementAge = retirementAge
        }
        return nextMemberUpdates
      })
      return updateHousehold(household._id, memberUpdates, updateRequest)
        .then((updatedHousehold) => {
          setErrorMessage(undefined)
          calculateResults(goalType, sessionInfo, updatedHousehold!)
        })
        .catch((error) => {
          setStatus("error")
          setErrorMessage("An error occurred. Please try again.")
          console.error("error updating client details", error)
        })
    }
  }, [calculateResults, client, goalType, household, sessionInfo, updateClient, updateHousehold, values])

  useEffect(() => {
    if (clientOrHousehold?.alreadyRetired) {
      setGoalType(clientOrHousehold?.alreadyRetired ? "retirementDrawdown" : "retirementIncome")
    }
  }, [clientOrHousehold?.alreadyRetired])

  const onSubmit = useCallback(() => {
    setShouldValidate(true)
    const { isValid } = goalType === "retirementDrawdown" ? validateRetirementDrawdownForm({ values, firm }) : validateRetirementIncomeForm({ values, firm })
    if (isValid) {
      setStatus("updating")
      update()
    }
  }, [firm, goalType, update, values])

  const onChange = (values: Partial<RetirementIncomeFormValues>) => {
    setValues((prev) => ({
      ...prev,
      ...values
    }))
  }

  useEffect(() => {
    if (status === "init") {
      const nextValues = createFormValues({ client, household, goalType })
      setValues(nextValues)
      const { isValid } =
        goalType === "retirementDrawdown"
          ? validateRetirementDrawdownForm({ values: nextValues, firm })
          : validateRetirementIncomeForm({ values: nextValues, firm })
      if (isValid) {
        setStatus("updating")
        calculateResults(goalType, sessionInfo, clientOrHousehold)
      }
    }
  }, [calculateResults, client, clientOrHousehold, firm, goalType, household, sessionInfo, status])

  useEffect(() => {
    if (shouldValidate) {
      if (goalType === "retirementDrawdown") {
        const { errors } = validateRetirementDrawdownForm({ values: values, firm })
        setErrorsRetirementDrawdown(errors)
      } else {
        const { errors } = validateRetirementIncomeForm({ values: values, firm })
        setErrors(errors)
      }
    }
  }, [firm, values, shouldValidate, goalType])

  const game = latest(clientOrHousehold!, "risk")

  return (
    <div className="absolute w-full h-full flex flex-row">
      <div className="w-75 h-full shrink-0 bg-surface-200 flex flex-col overflow-hidden">
        <div className="grow flex flex-col overflow-hidden">
          <div className="flex justify-end p-1.5">
            <Link
              className={clsx("flex items-center gap-2 hover:bg-surface-300 px-2 py-1.5", {
                "pointer-events-none": status === "updating"
              })}
              replace
              to="?goal=wealth-accumulation"
            >
              <img alt="" src={switchImage} />
              <span className="text-interactive-500 text-sm font-bold">Switch goal</span>
            </Link>
          </div>
          <RetirementIncomeForm
            client={client}
            errors={errors}
            errorsDrawdown={errorsRetirementDrawdown}
            household={household}
            onChange={onChange}
            onSubmit={onSubmit}
            setStatus={setStatus}
            status={status}
            values={values}
            goalType={goalType}
            outsideIM={outsideIM}
            calculateResults={(goalType) => {
              setGoalType(goalType)
              calculateResults(goalType, sessionInfo, clientOrHousehold!)
            }}
          />
        </div>
      </div>
      <div className="w-full relative grow overflow-auto no-scrollbar bg-white">
        <div className="w-full h-full flex flex-col items-center justify-stretch">
          <SelectPortfolio
            displayType={displayType}
            displayTypes={(type) => setDisplayType(type)}
            errorMessage={errorMessage}
            goalType={goalType}
            message={!options && status === "init" ? "Please update your plan details" : undefined}
            options={showIncomeRange && goalType !== "retirementDrawdown" ? optionsWithRange : options}
            client={client}
            household={household}
            isRiskComfort={showRiskComfort}
            isRange={showIncomeRange}
            isUpdating={status === "updating"}
          >
            {FEATURE_WEALTH_AND_INCOME_RANGE && displayType === "chart" && !clientOrHousehold?.alreadyRetired && (
              <div className="relative w-max">
                <div className="flex gap-x-1 cursor-pointer" onClick={() => setIsMenuOpen(!isMenuOpen)} ref={dropDownTrigger}>
                  <p className="text-xs text-interactive-500 leading-1 font-bold">Show</p>
                  <img className="px-1" src={chevronDown} alt="chevron-down" />
                </div>

                {isMenuOpen && (
                  <Dropdown className="w-full" overlayClassName="w-max p-3 mt-2 z-50" handleClose={() => setIsMenuOpen(false)} trigger={dropDownTrigger}>
                    {game?.risk?.results && <Checkbox
                      className="mb-1 p-0"
                      name="risk-comfort"
                      label="Risk comfort"
                      checked={showRiskComfort}
                      onChange={(e) => setShowRiskComfort(e.target.checked)}
                    />}
                    <Checkbox
                      className="p-0"
                      name="income-range"
                      label="Income range"
                      checked={showIncomeRange}
                      onChange={(e) => setShowIncomeRange(e.target.checked)}
                    />
                  </Dropdown>
                )}
              </div>
            )}
          </SelectPortfolio>
          {!options && status === "updating" && <Loading />}
        </div>
      </div>
    </div>
  )
}

export default RetirementIncome
