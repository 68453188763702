import clsx from "clsx"
import { ReactNode, useEffect, useRef } from "react"

export interface AlertPopoverOptions {
  alignY?: "bottom" | "top"
  content?: ReactNode
  onClose?: () => void
  timeoutMS?: number | null
}
interface Props {
  children: ReactNode
  options?: AlertPopoverOptions
  show?: boolean
}

const AlertPopover = ({ children, options: { alignY = "top", content, onClose, timeoutMS } = {}, show = false }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (onClose) {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          contentRef.current &&
          !contentRef.current.contains(event.target as Node) &&
          containerRef.current &&
          !containerRef.current.contains(event.target as Node)
        ) {
          onClose()
        }
      }

      window.addEventListener("mousedown", handleClickOutside)

      return () => {
        window.removeEventListener("mousedown", handleClickOutside)
      }
    }
  }, [onClose, containerRef])

  useEffect(() => {
    if (onClose && timeoutMS !== null) {
      const timeoutId: NodeJS.Timeout = setTimeout(
        () => {
          onClose()
        },
        timeoutMS ? timeoutMS : 5000
      )

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [onClose, timeoutMS])

  return (
    <div className="alert-popover-wrapper relative flex w-max items-center gap-3" ref={containerRef}>
      {children}
      {show && (
        <div
          className={clsx("alert-popover absolute left-1/2 w-auto flex transform -translate-x-1/2 mx-auto bg-white shadow-interactive-100 shadow border-interactive-200", {
            "top-0 -translate-y-full -mt-1": alignY === "top",
            "bottom-0 translate-y-full -mb-1": alignY === "bottom"
          })}
          ref={contentRef}
        >
          {content}
        </div>
      )}
    </div>
  )
}
export default AlertPopover
