const PercentageBar = ({ percentage, label }: { percentage: number; label: string }) => {
  return (
    <div className="pt-2">
      <p className="text-sm text-riskComfort-500">{label}</p>
      <div className="bg-surface-400 h-2 w-full">
        <div className="bg-riskComfort-400 h-2" style={{ width: `${percentage}%` }}></div>
      </div>
    </div>
  )
}

export default PercentageBar
