import { useMemo } from "react"
import { useLocation, useParams } from "react-router"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import useClient from "../../../hooks/useClient"
import useHousehold from "../../../hooks/useHousehold"
import { InvestmentGoal } from "../../../models/Client"
import RetirementIncome from "./retirementIncome/RetirementIncome"
import WealthAccumulation from "./wealthAccumulation/WealthAccumulation"

const RMJGoalExplorerPage = () => {
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client } = useClient(clientId)
  const { household } = useHousehold(householdId)
  const location = useLocation()

  const outsideIM = Boolean(clientId && location.pathname.startsWith(`/clients/${clientId}/explore`)
    || householdId && location.pathname.startsWith(`/households/${householdId}/explore`))

    const goalType = useMemo<InvestmentGoal>(() => {
    const query = new URLSearchParams(location.search).get("goal")
    return query === "wealth-accumulation" ? "wealthAccumulation" : query === "retirement-income" ? "retirementIncome" : "retirementDrawdown"
  }, [location.search])

  return (
    <div className="absolute w-full h-full flex flex-row">
      {client || household ? (
        <>
          {(goalType === "retirementIncome" || goalType === "retirementDrawdown") && <RetirementIncome client={client} household={household} outsideIM={outsideIM}/>}
          {goalType === "wealthAccumulation" && <WealthAccumulation client={client} household={household} outsideIM={outsideIM} />}
        </>
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default RMJGoalExplorerPage
