import React, { useEffect, useMemo, useRef, useState } from "react"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import LineChart from "../../../rmjourney/components/LineChart/LineChart"
import { lossSensitivityChartData, lossSensitivityDetails } from "../../../rmjourney/lossSensitivity/lossSensitivityAttributes"
import peopleIcon from "../assets/icon-people.svg"
import { useTheme } from "../../../../contexts/ThemeContext"
import { Trans } from "@lingui/macro"
import { latest } from "../../../../lib/clients"

const SensitivityToLoss = ({ client, household }: { page: number; client: Client; household: Household }) => {
  const lossSensitivityMessages = lossSensitivityDetails(client, household)
  const game = latest(client, "risk")
  const score = game?.risk.results?.R.lossAversion ?? 50

  const theme = useTheme()
  const chartData = useMemo(() => {
    const axisValues = lossSensitivityChartData(theme, client, household)
    if (axisValues) {
      return {
        ...axisValues,
        highlightDataPoints: axisValues.highlightDataPoints.map((dataPoint) => {
          return {
            ...dataPoint,
            text: theme.charts?.sensitivityToLoss?.showScoreAsDataPoint ? dataPoint.x : dataPoint.text,
            textColor: theme.charts?.sensitivityToLoss?.showScoreAsDataPoint ? theme.colors.avatarColors[0] : dataPoint.textColor
          }
        })
      }
    }
    return axisValues
  }, [client, household, theme])

  const calculateLinePosition = (lossAversion: number): number => {
    if (lossAversion <= 19) {
      return 100 - (lossAversion / 19) * 32
    } else if (lossAversion <= 49) {
      return 100 - (((lossAversion - 20) / 29) * 33 + 32)
    } else {
      return 100 - (((lossAversion - 50) / 50) * 33 + 66)
    }
  }

  const [graphWidth, setGraphWidth] = useState(0)
  const graphRef = useRef<HTMLDivElement>(null)
  const offset = (16 / graphWidth) * 100
  const displacement = ((graphWidth * (calculateLinePosition(score) / 100)) / graphWidth) * 100

  useEffect(() => {
    const localRef = graphRef.current
    if (localRef) {
      const divResizeObserver = new ResizeObserver((e) => {
        setGraphWidth(e[0].contentRect.width)
      })
      divResizeObserver.observe(localRef)
      return () => {
        divResizeObserver.unobserve(localRef)
      }
    }
  }, [])

  return (
    <div className="sensitivity-chart-container">
      <div className="w-full flex justify-between items-center gap-x-2">
        <div className="w-full flex flex-col">
          <div className="w-full flex justify-between">
            <div>
              <h2 className="sensitivity-loss-header text-main-600 font-semibold text-h4 tracking-wide">
                <Trans id="sensitivity-loss-header">Sensitivity to Loss</Trans>
              </h2>
              {theme.reports?.riskChartsPage?.sensitivityToLoss?.showSubtitle && lossSensitivityMessages?.title && (
                <h3 className="sensitivity-loss-subtitle text-main-600 text-h4 tracking-wide">{lossSensitivityMessages.title}</h3>
              )}
            </div>
            <div className="flex gap-x-8 score-label-container">
              {client ? (
                <h4 className="sensitivity-score text-h3 text-right text-interactive-400 font-semibold flex flex-row items-center gap-x-2">
                  <span className="text-xs text-interactive-400">Sensitivity score</span>
                  {game?.risk?.results?.R?.lossAversion}
                </h4>
              ) : (
                household?.members.map(({ client }, i) => (
                  <h4 className="text-h3 text-right text-interactive-400 font-semibold flex flex-col items-end w-max" key={client._id}>
                    <span className="flex items-center gap-x-2">
                      {chartData!.legend && <div className="w-2 h-2 rounded-full" style={{ backgroundColor: chartData!.legend[i].color }} />}
                      {latest(client, "risk")?.risk?.results?.R?.lossAversion}
                    </span>
                    <span className="text-xs text-interactive-400">Sensitivity score</span>
                  </h4>
                ))
              )}
            </div>
          </div>
          {!theme.reports?.riskChartsPage?.sensitivityToLoss?.showSubtitle && lossSensitivityMessages?.title && (
            <h3 className="font-bold text-main-600 text-sec">{lossSensitivityMessages.title}</h3>
          )}
        </div>
      </div>
      <p className="sensitivity-loss-description text-sec leading-tight pt-2 text-main-500">
        {theme.reports?.riskChartsPage?.sensitivityToLoss?.showPersonalisedDescription && lossSensitivityMessages?.description ? (
          lossSensitivityMessages.description
        ) : (
          <Trans id="sensitivity-to-loss-description">
            Your Sensitivity to Loss score reflects how unsettled you may become if you experience short-term investment losses. Remaining disciplined during
            periods of market volatility is critical to your long-term financial success.
          </Trans>
        )}
      </p>
      {theme.reports?.riskChartsPage?.sensitivityToLoss?.note?.show && lossSensitivityMessages?.reportNote && (
        <div className="sensitivity-to-loss-note flex items-center gap-1 bg-surface-100 border border-surface-500 mt-6 text-sm p-3">
          {theme.reports?.riskChartsPage?.sensitivityToLoss?.note?.icon && (
            <img src={theme.reports?.riskChartsPage?.sensitivityToLoss?.note?.icon} alt="" aria-hidden />
          )}
          {lossSensitivityMessages.reportNote}
        </div>
      )}

      {theme.reports?.riskChartsPage?.lineChart?.showAsSpectrum ? (
        <div className="w-full relative mt-18">
          <div ref={graphRef} className="risk-sensitivity-chart-line h-1 bg-surface-activity w-full" />
          <div className="flex flex-col items-center relative">
            <div className="absolute top-0 h-12 flex flex-col items-center -translate-y-full w-max" style={{ left: `${displacement - offset}%` }}>
              {theme.reports?.riskChartsPage?.lineChart?.dataPointIcon && (
                <img className="w-8 h-8" src={theme.reports?.riskChartsPage?.lineChart?.dataPointIcon} alt="" aria-hidden />
              )}
              <hr className="h-full border-0 border-l bg-interactive-600" />
              <div className="w-[5px] h-[5px] absolute bottom-0 rounded-full bg-interactive-600" />
            </div>
          </div>
          <div className="risk-sensitivity-chart-labels text-main-400 mt-1 flex justify-between text-sm">
            <p className="text-left w-18">
              <Trans id="risk-sensitivity-chart-high-label">Unsettled</Trans>
            </p>
            <p className="text-center w-32">
              <Trans id="risk-sensitivity-chart-medium-label">Somewhat unsettled</Trans>
            </p>
            <p className="text-right w-18">
              <Trans id="risk-sensitivity-chart-low-label">Composed</Trans>
            </p>
          </div>
        </div>
      ) : (
        <>
          {theme.reports?.riskChartsPage?.lineChart?.showTitle && (
            <p className="text-sec font-bold text-center pt-6">
              <Trans id="report-sensitivity-to-loss-chart-title">Your Sensitivity to Loss compared to other investors</Trans>
            </p>
          )}
          <div className="w-full self-center mt-12 h-75 loss-sensitivity-line-chart">
            {(client || household) && chartData && (
              <LineChart
                enableAnimations={false}
                xAxis={chartData.xAxis}
                yAxis={chartData.yAxis}
                type={"loss"}
                icon={peopleIcon}
                gameType={latest(client ?? household, "risk")?.gameType ?? "risk"}
                series={[
                  {
                    id: "1",
                    title: client?.firstName,
                    values: chartData.sensitivityToLossData,
                    color: theme.colors.scoreChartColor
                  }
                ]}
                highlightDataPoints={chartData.highlightDataPoints}
                legend={theme.reports?.riskChartsPage?.lineChart?.showLegend ? chartData.legend : []}
                highlightAreas={[
                  {
                    endXPercent: 19.5,
                    id: "low",
                    label: "Composed",
                    startXPercent: 0
                  },
                  {
                    endXPercent: 49.5,
                    id: "mid",
                    label: "Somewhat unsettled",
                    startXPercent: 19.5
                  },
                  {
                    endXPercent: 100,
                    id: "high",
                    label: "Unsettled",
                    startXPercent: 49.5
                  }
                ]}
                showScoreAsDataPoint={theme.charts?.sensitivityToLoss?.showScoreAsDataPoint}
                forReport={true}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default SensitivityToLoss
