import React, { useMemo } from "react"
import { Client, PortfolioMapping } from "../../../models/Client"
import Page from "./Page"
import { Trans } from "@lingui/macro"
import { AssetClass, SubAssetClass } from "../../../models/InvestmentUniverse"
import { DoughnutChart } from "../../rmjourney/components/DoughnutChart/DoughnutChart"
import { useTheme } from "../../../contexts/ThemeContext"
import { tt } from "../../../lib/translations"
import { ASSET_TYPES } from "../../../config/assetClasses"
import clsx from "clsx"
import { allAssetClasses } from "./ComfortMatchPage"
import { latest } from "../../../lib/clients"

const comfortMatches = (portfolioMappings: PortfolioMapping[]) => {
  return [...(portfolioMappings || [])].sort((a, b) => {
    const d = b.riskComfort! - a.riskComfort!
    return d ? d : (a.portfolio!.sd || 0) - (b.portfolio!.sd || 0)
  })
}

export const comfortMatchPortfolio = (portfolioMappings: PortfolioMapping[]) => {
  const sortedMappings = comfortMatches(portfolioMappings)
  const highestComfortScorePortfolio = sortedMappings?.reduce(
    (maxPortfolio, currentPortfolio) => (currentPortfolio.riskComfort! > maxPortfolio.riskComfort! ? currentPortfolio : maxPortfolio),
    sortedMappings[0]
  )
  // Filter portfolios within 15 comfortScore units from the highest comfortScore
  const filteredPortfolios = sortedMappings?.filter(
    (portfolio) =>
      portfolio.riskComfort! >= highestComfortScorePortfolio.riskComfort! - 15 && portfolio.riskComfort! <= highestComfortScorePortfolio.riskComfort! + 15
  )
  return filteredPortfolios?.reduce(
    (maxLossScorePortfolio, currentPortfolio) =>
      currentPortfolio.portfolio.sd! > maxLossScorePortfolio.portfolio.sd! ? currentPortfolio : maxLossScorePortfolio,
    filteredPortfolios[0]
  )
}

export const findRecommendedPortfolio = (client: Client) => {
  const game = latest(client, "risk")
  const portfolioMappings = game!.portfolioMappings
  const comfortMatch = comfortMatchPortfolio(portfolioMappings)
  if (client.goalTargetYear && client.goalAmount) {
    const goalTimeframeDiff = client.goalTargetYear - new Date().getFullYear()
    const recommendablePortfolios = portfolioMappings?.filter(
      (portfolioMapping) => (portfolioMapping.portfolio.aux?.minRecommendedTimeframe ?? -1) <= goalTimeframeDiff
    )
    const isHighestRecommendable = recommendablePortfolios?.find((portfolioModelScore) => portfolioModelScore.portfolio.id === comfortMatch.portfolio.id)
    if (isHighestRecommendable) {
      return comfortMatch
    } else {
      return recommendablePortfolios?.reduce((acc: PortfolioMapping | undefined, cur) => (!acc || cur.portfolio.sd! > acc.portfolio.sd! ? cur : acc), undefined)
    }
  } else {
    return comfortMatch
  }
}

const SelectedPortfolio = ({ page, client }: { page: number; client: Client }) => {
  const theme = useTheme()
  const game = latest(client, "risk")
  const portfolioMappings = game?.portfolioMappings
  const assetClasses = useMemo(
    () => allAssetClasses(game!.assetClasses) as { [key: string]: AssetClass | SubAssetClass },
    [game]
  )
  const portfolioList = useMemo(() => {
    return [...(portfolioMappings || [])]
      .sort((a, b) => a.portfolio.sd! - b.portfolio.sd!)
      .map(({ portfolio, riskComfort }) => ({
        id: portfolio.id,
        translationKey: portfolio.translationKey,
        riskComfortScore: riskComfort,
        expectedVolatility: portfolio.sd,
        minRecommendedTimeframe: portfolio.aux?.minRecommendedTimeframe,
        assetClass: ASSET_TYPES.map((ac, jj) => ({
          assetClass: ac,
          colorIndex: jj,
          total: Math.round(portfolio.components.filter((c) => assetClasses![c.id].type === ac).reduce((sum, x) => sum + x.weight, 0) * 100) / 100
        }))
          .filter(({ total }) => total > 0)
      }))
  }, [assetClasses, portfolioMappings])

  const recommendedPortfolio = findRecommendedPortfolio(client)

  const currentPortfolio = useMemo(
    () => game?.portfolioMappings?.find(({ portfolio }) => portfolio.id === client.currentPortfolio),
    [game?.portfolioMappings, client.currentPortfolio]
  )
  const selectedPortfolio = currentPortfolio ?? recommendedPortfolio

  const currentRiskComfortMatchIndex = useMemo(
    () => portfolioList.findIndex((data) => data.id === selectedPortfolio?.portfolio.id),
    [portfolioList, selectedPortfolio?.portfolio.id]
  )

  const recommendablePortfolios = useMemo(() => {
    if (client?.goalTargetYear && client.primaryInvestmentGoal !== "retirementIncome") {
      const goalTimeframeDiff = client.goalTargetYear - new Date().getFullYear()
      return game?.portfolioMappings?.filter(
        (portfolioMapping) => (portfolioMapping.portfolio.aux?.minRecommendedTimeframe ?? -1) <= goalTimeframeDiff
      )
    } else {
      return game?.portfolioMappings
    }
  }, [game?.portfolioMappings, client.goalTargetYear, client.primaryInvestmentGoal])

  return (
    <Page page={page} title={<Trans id="report-header-title">Your Goal Plan</Trans>} footer={<></>}>
      <div className="mx-9 mt-15 text-p">
        <h2 className="portfolio-list-title text-main-600 font-semibold">
          <Trans id="portfolio-list-title">Your portfolio</Trans>
        </h2>
        <div className="flex flex-col mt-4 text-main-600 w-4/5">
          {portfolioList.map(({ id, assetClass }, i) => {
            const isPortfolioRecommendable = !!recommendablePortfolios?.find((portfolioMapping) => portfolioMapping.portfolio.id === id)
            return (
              <div
                className={clsx("flex items-center justify-between py-6", {
                  "border-b-surface-200 border-b": selectedPortfolio?.portfolio.id !== id,
                  "border-b-0":
                    i === (currentRiskComfortMatchIndex > 0 ? currentRiskComfortMatchIndex - 1 : portfolioList.length - 1) ||
                    (currentRiskComfortMatchIndex !== i && i === portfolioList.length - 1),
                  "rounded-3 border-2 border-interactive-500 bg-interactive-300": selectedPortfolio?.portfolio.id === id,
                  "opacity-50": !isPortfolioRecommendable
                })}
                key={id}
              >
                <div className="flex gap-x-3">
                  <div className="w-9 h-9 ml-4">
                    <DoughnutChart
                      data={assetClass.map(({ total }) => total)}
                      colors={assetClass.map(({ assetClass }) => theme.colors.assetClassConfigurations[assetClass].color)}
                      forReport={true}
                      strokeWidth={8.5}
                    />
                  </div>
                  <div>
                    <p className="font-semibold">{tt({ id: `report-portfolio-title-risk-level-${i + 1}-text` })}</p>
                    <div className="flex gap-x-2 text-sm">
                      {assetClass.map(({ total, assetClass }, i) => (
                        <div key={i} className="flex items-center gap-x-1.5">
                          <div className="w-2 h-2 rounded-full" style={{ backgroundColor: theme.colors.assetClassConfigurations[assetClass].color }} />
                          <p>
                            {total}% {theme.colors.assetClassConfigurations[assetClass].label}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <p className="mr-4 text-main-500 text-sm">
                  <Trans id="report-persona-risk-level-text">Risk level {i + 1}</Trans>
                </p>
              </div>
            )
          })}
        </div>
        {recommendablePortfolios?.length !== game?.portfolioMappings?.length && (
          <p className="text-sm text-main-500 mt-6">
            <Trans id="report-portfolio-list-note-text">
              <span className="font-semibold">Please note:</span> We’ve limited your choice of portfolios because of your relatively short investment timeframe.
              This helps protect your investment from losses.
            </Trans>
          </p>
        )}
      </div>
    </Page>
  )
}

export default SelectedPortfolio
