import { Trans } from "@lingui/macro"
import React, { useMemo } from "react"
import { Client } from "../../../../../../models/Client"
import AltruismLineChart from "./AltruismLineChart"
import { altruismChartData, getAltruismLevel } from "./altruismScoreAttributes"
import { useTheme } from "../../../../../../contexts/ThemeContext"

interface Props {
  client: Client
}

const AltruismScore: React.FC<Props> = ({ client }: Props) => {
  const theme = useTheme()
  const altruismScore = useMemo(() => altruismChartData(theme, client), [theme, client])
  const altruismLevel: "low" | "medium" | "high" | null = getAltruismLevel(altruismScore.score[0])

  return (
    <>
      <div className="flex items-center justify-between pb-6">
        <div className="flex gap-2 items-center">
          <h2 className="text-h3 font-semibold">
            <Trans id="altruism-score-heading">Returns vs. Values</Trans>
          </h2>
        </div>
        <p>
          <Trans id="altruism-score-text">
            Score: <span className="font-semibold">{altruismScore.score[0]}</span>
          </Trans>
        </p>
      </div>
      <p className="mb-6">
        <Trans id={`results-altruism-${altruismLevel}-description`}>Reflecting core values is of high importance to {client.firstName}</Trans>
      </p>
      <AltruismLineChart altruismScore={altruismScore} client={client} />
    </>
  )
}

export default AltruismScore
