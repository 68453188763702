import parse from "date-fns/parse"
import differenceInYears from "date-fns/differenceInYears"
import { DATE_FORMAT } from "../../../../lib/date"
import { validateDob } from "../../../../lib/clients"
import { Firm } from "../../../../models/Firm"
import { validateAnnualContribution, validateInvestmentAmount } from "../utils/validation"
import { Client, InvestmentGoal } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import { ClientUpdateRequest } from "../../../../api/clients"
import { HouseholdUpdateRequest } from "../../../../api/households"

export interface RetirementIncomeFormValues {
  annualInvestmentContribution?: number
  retirementIncomeGoal?: number
  investmentAmount?: number
  alreadyRetired?: boolean
  members: {
    dob?: Date
    dobRaw?: string
    retirementAge?: number
  }[]
  otherSourcesRetirementIncome?: number
  pensionInRetirement?: number
  planningHorizon?: number
  estateGoal?: number
}

export interface RetirementIncomeFormErrors {
  annualInvestmentContribution?: string
  retirementIncomeGoal?: string
  investmentAmount?: string
  members: {
    dob?: string
    retirementAge?: string
  }[]
  otherSourcesRetirementIncome?: string
  pensionInRetirement?: string
  planningHorizon?: string
  estateGoal?: string
}

export interface RetirementIncomeDrawdownErrors {
  retirementIncomeGoal?: string
  investmentAmount?: string
  otherSourcesRetirementIncome?: string
  pensionInRetirement?: string
  estateGoal?: string
}



export const validateRetirementIncomeForm = ({
  values,
  firm
}: {
  values: RetirementIncomeFormValues
  firm?: Firm
}): { errors: RetirementIncomeFormErrors; isValid: boolean } => {
  let isValid = true
  const { annualInvestmentContribution, retirementIncomeGoal, investmentAmount, members, otherSourcesRetirementIncome, planningHorizon, pensionInRetirement, estateGoal } = values
  const errors: Partial<RetirementIncomeFormErrors> = {
    annualInvestmentContribution: undefined,
    retirementIncomeGoal: undefined,
    investmentAmount: undefined,
    otherSourcesRetirementIncome: undefined,
    pensionInRetirement: undefined,
    planningHorizon: undefined,
  }

  errors.investmentAmount = validateInvestmentAmount(investmentAmount, firm)
  errors.annualInvestmentContribution = validateAnnualContribution(annualInvestmentContribution)

  if (retirementIncomeGoal) {
    if (retirementIncomeGoal < 0 || retirementIncomeGoal > 1000000000000) {
      errors.retirementIncomeGoal = "Value must be less than $1T"
    }
  } else {
    errors.retirementIncomeGoal = "Retirement income goal required"
  }

  if (otherSourcesRetirementIncome !== null && otherSourcesRetirementIncome !== undefined) {
    if (otherSourcesRetirementIncome < 0 || otherSourcesRetirementIncome > 1000000000000) {
      errors.otherSourcesRetirementIncome = "Value must be less than $1T"
    }
  } else {
    errors.otherSourcesRetirementIncome = "Income from other sources required"
  }

  if (planningHorizon) {
    const retirementAge = Math.max(...members.map(({ retirementAge }) => retirementAge!))
    if (retirementAge > planningHorizon) {
      errors.planningHorizon = "Retirement age must be less than planning horizon"
    }
  }

  if (pensionInRetirement === undefined) {
    errors.pensionInRetirement = "Pension in retirement required"
  }
  if (estateGoal) {
    if (estateGoal > investmentAmount!) {
      errors.estateGoal = "Legacy goal must be less than investment amount"
    }
  }
  const memberErrors = members.map(({ dob, dobRaw, retirementAge }) => {
    const dobError = validateRequiredDob(dob, dobRaw)

    return {
      dob: dobError,
      retirementAge: validateRetirementAge(retirementAge, dobError, dob)
    }
  })

  isValid = Object.values(errors).every((x) => x === null || x === undefined || x === "")

  if (isValid) {
    for (const errs of memberErrors) {
      isValid = Object.values(errs).every((x) => x === null || x === undefined || x === "")

      if (isValid === false) {
        break
      }
    }
  }
  return {
    errors: {
      ...errors,
      members: memberErrors
    },
    isValid
  }
}
export const validateRetirementDrawdownForm = ({
  values,
  firm
}: {
  values: RetirementIncomeFormValues
  firm?: Firm
}): { errors: RetirementIncomeDrawdownErrors; isValid: boolean } => {
  let isValid = true
  const { retirementIncomeGoal, investmentAmount, otherSourcesRetirementIncome, pensionInRetirement, estateGoal } = values
  const errors: Partial<RetirementIncomeDrawdownErrors> = {
    retirementIncomeGoal: undefined,
    investmentAmount: undefined,
    otherSourcesRetirementIncome: undefined,
    pensionInRetirement: undefined,
    estateGoal: undefined
  }
  errors.investmentAmount = validateInvestmentAmount(investmentAmount, firm)
  if (retirementIncomeGoal) {
    if (retirementIncomeGoal < 0 || retirementIncomeGoal > 1000000000000) {
      errors.retirementIncomeGoal = "Value must be less than $1T"
    }
  } else {
    errors.retirementIncomeGoal = "Retirement income goal required"
  }
  if (otherSourcesRetirementIncome !== null && otherSourcesRetirementIncome !== undefined) {
    if (otherSourcesRetirementIncome < 0 || otherSourcesRetirementIncome > 1000000000000) {
      errors.otherSourcesRetirementIncome = "Value must be less than $1T"
    }
  } else {
    errors.otherSourcesRetirementIncome = "Income from other sources required"
  }

  if (pensionInRetirement === undefined) {
    errors.pensionInRetirement = "Pension in retirement required"
  }

  if (estateGoal) {
    if (estateGoal > investmentAmount!) {
      errors.estateGoal = "Legacy goal must be less than investment amount"
    }
  }
  isValid = Object.values(errors).every((x) => x === null || x === undefined || x === "")
  return {
    errors,
    isValid
  }
}

export const validateTopLevelOne = ({ values, firm }: {
  values: RetirementIncomeFormValues
  firm?: Firm
}) => {
  if (values.alreadyRetired) {
    return validateRetirementDrawdownForm({ values: values, firm })
  } else {
    return validateRetirementIncomeForm({ values: values, firm })
  }
  // return {
  //   ...validateRetirementDrawdownForm({ values: values, firm }),
  //   ...validateRetirementIncomeForm({ values: values, firm })
  // }
}

const validateRetirementAge = (retirementAge?: number, dobError?: string, dob?: Date, dobRaw?: string) => {
  if (!dobError && (dob || dobRaw)) {
    const currentAge = getAge(dob, dobRaw)
    if (currentAge) {
      if (retirementAge === null || retirementAge === undefined) {
        return "Required"
      } else if (retirementAge < currentAge + 1) {
        return `At least ${currentAge + 1}yrs`
      } else if (retirementAge > 125) {
        return "Too old"
      }
    }
  }
}

const getAge = (dob?: Date, dobRaw?: string) => {
  const parsed = parseDob(dob, dobRaw)
  return parsed ? differenceInYears(new Date(), parsed) : null
}

const parseDob = (dob?: Date, dobRaw?: string) => {
  return dobRaw ? parse(dobRaw, DATE_FORMAT, new Date()) : !dobRaw && dob ? new Date(dob) : null
}

const validateRequiredDob = (dob?: Date, dobRaw?: string) => {
  if (dobRaw || dob) {
    const parsed = parseDob(dob, dobRaw)
    if (parsed) {
      return validateDob(parsed)
    }
  } else {
    return "Date of birth required"
  }
}

/* const retirementAgeString = (dobRaw?: string, dob?: Date, retirementAge?: number) => {
  if ((dobRaw || dob) && retirementAge) {
    const parsed = dobRaw ? parse(dobRaw, DATE_FORMAT, new Date()) : !dobRaw && dob ? new Date(dob) : null

    if (parsed) {
      const dobError = validateDob(parsed)

      if (!dobError) {
        const difference = differenceInYears(new Date(), parsed)
        const amount = retirementAge - difference
        return amount >= 0 ? `(in ${amount} years)` : ""
      }
    }
  }
} */

export const createFormValues = ({ client, household, goalType }: { client?: Client; household?: Household, goalType?: InvestmentGoal }) => {
  if (client) {
    return {
      annualInvestmentContribution: client.annualInvestmentContribution,
      retirementIncomeGoal: client.retirementIncomeGoal,
      investmentAmount: client.investmentAmount,
      alreadyRetired: goalType ? goalType === "retirementDrawdown" : client.alreadyRetired,
      pensionInRetirement: client.pensionInRetirement,
      planningHorizon: client.planningHorizon,
      estateGoal: client.estateGoal,
      members: [
        {
          dob: client.dob ? new Date(client.dob) : undefined,
          dobRaw: undefined,
          retirementAge: client.retirementAge
        }
      ],
      otherSourcesRetirementIncome: client.otherSourcesRetirementIncome
    }
  } else if (household) {
    return {
      annualInvestmentContribution: household.annualInvestmentContribution,
      retirementIncomeGoal: household.retirementIncomeGoal,
      investmentAmount: household.investmentAmount,
      alreadyRetired: goalType ? goalType === "retirementDrawdown" : household.alreadyRetired,
      pensionInRetirement: household.pensionInRetirement,
      planningHorizon: household.planningHorizon,
      estateGoal: household.estateGoal,
      members: household.members.map(({ client }) => {
        return {
          dob: client.dob ? new Date(client.dob) : undefined,
          dobRaw: undefined,
          retirementAge: client.retirementAge
        }
      }),
      otherSourcesRetirementIncome: household.otherSourcesRetirementIncome
    }
  } else {
    return {
      annualInvestmentContribution: undefined,
      retirementIncomeGoal: undefined,
      investmentAmount: undefined,
      members: [],
      otherSourcesRetirementIncome: undefined
    }
  }
}

export const createRetirementIncomeRequest = ({
  client,
  values,
  goalType,
  household
}: {
  client?: Client
  values: RetirementIncomeFormValues
  goalType: InvestmentGoal
  household?: Household
}) => {
  const { annualInvestmentContribution, investmentAmount, members, otherSourcesRetirementIncome, retirementIncomeGoal, pensionInRetirement, alreadyRetired, estateGoal } = values
  const member = members[0]

  const updateRequest: ClientUpdateRequest | HouseholdUpdateRequest = {}
  const clientOrHousehold: Client | Household | undefined = client || household

  if (clientOrHousehold) {
    if (annualInvestmentContribution !== clientOrHousehold.annualInvestmentContribution) {
      updateRequest.annualInvestmentContribution = annualInvestmentContribution
    }

    if (investmentAmount !== clientOrHousehold.investmentAmount) {
      updateRequest.investmentAmount = investmentAmount
    }

    if (otherSourcesRetirementIncome !== clientOrHousehold.otherSourcesRetirementIncome) {
      updateRequest.otherSourcesRetirementIncome = otherSourcesRetirementIncome
    }

    if (member.retirementAge !== clientOrHousehold.retirementAge) {
      updateRequest.retirementAge = member.retirementAge
    }

    if (retirementIncomeGoal !== clientOrHousehold.retirementIncomeGoal) {
      updateRequest.retirementIncomeGoal = retirementIncomeGoal
    }

    if (alreadyRetired !== clientOrHousehold.alreadyRetired) {
      updateRequest.alreadyRetired = alreadyRetired
    }

    if (pensionInRetirement !== clientOrHousehold.pensionInRetirement) {
      updateRequest.pensionInRetirement = pensionInRetirement
    }

    if (estateGoal !== clientOrHousehold.estateGoal) {
      updateRequest.estateGoal = estateGoal
    }

    if (goalType !== clientOrHousehold.primaryInvestmentGoal) {
      updateRequest.primaryInvestmentGoal = goalType
    }
  }

  return updateRequest
}
