import { useParams } from "react-router-dom"
import useClient from "../../../../hooks/useClient"
import ProfileOverview from "../../../clients/components/ProfileOverview/ProfileOverview"
import { useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import { clsx } from "clsx"
import { useTheme } from "../../../../contexts/ThemeContext"

const ClientStatusSideBar = () => {
  const { clientId } = useParams<{ clientId: string }>()
  const { client } = useClient(clientId)
  const [isExpanded, setIsExpanded] = useState(true)
  const [isAnimating, setIsAnimating] = useState(true) //show hide text based on animation start/complete
  const theme = useTheme()

  return (
    <AnimatePresence>
      <motion.div
        initial={false} //disable initial animation on page load
        animate={{ width: isExpanded ? "320px" : "76px", transition: { duration: 0.3, ease: "easeInOut" } }}
        onAnimationStart={() => setIsAnimating(isAnimating)}
        onAnimationComplete={() => setIsAnimating(!isAnimating)}
        className={clsx("client-sidebar-wrapper relative h-full bg-interactive-600", isExpanded ? "w-80" : "w-20")}
      >
        {theme.pages.profile.sidebar.isCollapsible && (
          <button
            className="absolute w-7 h-7 -right-2 top-13.5 z-10 border border-interactive-400 bg-interactive-500 flex justify-center items-center"
            onClick={() => setIsExpanded(!isExpanded)}
            aria-label={`${isExpanded ? "Collapse" : "Expand"} client sidebar`}
          >
            <img className={clsx("w-3 h-3", { "rotate-180": isExpanded })} src={theme.pages.profile.sidebar.expandCollapseIcon} alt="" aria-hidden />
          </button>
        )}
        {client && (
          <div className="client-sidebar h-full flex items-center">
            <ProfileOverview isExpanded={isExpanded} client={client} isAnimating={isAnimating} />
          </div>
        )}
      </motion.div>
    </AnimatePresence>
  )
}

export default ClientStatusSideBar
