export const sortArrayBy = (array: any[], key: string, dir: "asc" | "desc" = "desc") => {
  return [...array].sort((a: any, b: any) => {
    if (a[key] > b[key]) {
      return dir === "desc" ? -1 : 1
    }
    if (a[key] < b[key]) {
      return dir === "desc" ? 1 : -1
    }
    return 0
  })
}
