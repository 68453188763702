import { i18n } from "@lingui/core"

export const tt = ({ id, message, values }: { id: string; message?: string, values?: Record<string, any> }) => {
  const text = /* lingui-extract-ignore */ i18n._({ id, message, values })
  /**
   * returns empty string if resolved value matches the key (which means no translation present)
   * to be used for cases where an empty string is a permitted value.
   */
  return text !== id ? text : ""
}
