export default `
*Plans are available for teams with more than 20 advisers. Custom application development is also available. Please contact our sales team for details.*

---

## When does billing start?

Your subscription starts on the date you receive the login details. Your monthly billing cycle will run from that date to the same day the following month.
For example, if you received your login details on June 10th, your billing cycle would be June 10th to July 9th.

## When will I receive my invoice?

While your billing cycle is monthly, you will receive quarterly invoices covering the previous 3 months of charges.
The invoice is payable 30 days from the issue date. Additional GST may apply.

We will notify you in advance of any changes as we plan to move to monthly billing in the future.

## What payment methods are accepted?

You can make payments by credit/debit card or set up direct debit payments through GoCardless. The payment link will be provided on your quarterly invoice.

## How do I manage users on my plan?

You can add or remove users by contacting support@capitalpreferences.com.

## What can I expect in my free trial?

Our one-month free trial gives advisors full access to all Suite features. To avoid charges, notify us within 30 days if you don't wish to continue.
Otherwise, your paid subscription begins automatically after the trial ends.

## How do I cancel my subscription?

To cancel your subscription, please email support@capitalpreferences.com.  

Upon cancellation, your access to the Suite will stop at the end of your current monthly billing cycle and you will not be charged for further months.

## What happens if I miss a payment?

If payment is not received within 60 days of the due date and no valid reason is provided, we will be required to cancel your subscription.
`