import clsx from "clsx"
import { useMemo } from "react"
import { useTheme } from "../../../../../contexts/ThemeContext"
import { Client } from "../../../../../models/Client"
import { causeScoresForProfile } from "../../../components/Profile/causeScores"
import CauseScores from "../../../components/Profile/components/PersonaSummary/CauseScores"
import { Trans } from "@lingui/macro"
import { tt } from "../../../../../lib/translations"
import { latest } from "../../../../../lib/clients"

const SustainabilityValues = ({ className, client, altruismLevel }: { className?: string; client: Client, altruismLevel?: string }) => {
  const theme = useTheme()
  const game = latest(client, "esg")
  const scores = useMemo(() => (game?.esg.results ? causeScoresForProfile(client, theme) : []), [client, game?.esg.results, theme])
  return (
    <div className={clsx("sustainability-values", className)}>
      <h2 className="mb-2 text-main-600 text-sec tracking-wide uppercase"><Trans id="sustainability-values-title">Your Values</Trans></h2>
      <h3 className="font-semibold text-main-600 text-h4 mb-6"><Trans id="sustainability-values-subtitle">We can help you reflect your values</Trans></h3>
      <p className="mb-6">
        <Trans id="bar-graph-card-description">Now that we have a clear understanding of your priorities, we’ll watch for any investment opportunities that offer good returns and reflect your areas of interest.</Trans>
        {tt({id: `bar-graph-card-${altruismLevel}-description`})}
      </p>

      {scores && (
        <div className="max-w-[537px]">
          <CauseScores scores={scores} />
        </div>
      )}
    </div>
  )
}

export default SustainabilityValues
