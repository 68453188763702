const ComfortScoresGraph = ({ goalAchievability, riskComfort }: { goalAchievability: number; riskComfort: number }) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col mb-2" tabIndex={0}>
        <p
          className="text-sm mb-1"
          style={{
            color: "#427880"
          }}
        >
          {`Risk comfort ${riskComfort}%`}
        </p>
        <div className="w-40 h-1 bg-surface-100">
          <div className="h-1 rounded-full" style={{ backgroundColor: "#427880", width: `${riskComfort}%` }}></div>
        </div>
      </div>
      <div className="flex flex-col">
        <p
          className="text-sm mb-1"
          style={{
            color: "#A06126"
          }}
          tabIndex={0}
        >
          {`Goal achievability ${goalAchievability}%`}
        </p>
        <div className="w-40 h-1 bg-surface-100">
          <div
            className="h-1 rounded-full"
            style={{
              backgroundColor: "#A06126",
              width: `${goalAchievability}%`
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default ComfortScoresGraph
