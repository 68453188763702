import { useCallback, useContext, useEffect, useState } from "react"
import { useMutation } from "react-query"
import Checkbox from "../../../components/Checkbox/Checkbox"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import { ClientHouseholdCacheContext } from "../../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../../contexts/FirmContext"
import { tt } from "../../../lib/translations"
import { Client, Game, GameType } from "../../../models/Client"
import { Household } from "../../../models/Household"
import { Trans } from "@lingui/macro"
import infoAlert from "../../../assets/icons/info-alert.svg"
import { useTheme } from "../../../contexts/ThemeContext"
import { latestGamesByType } from "../../../lib/clients"
import { AuthContext } from "../../../views/auth/AuthContext"
import { createGame } from "../../../api/createGame"

const ActivitySettingsModal = ({ client, household, onClose }: { client?: Client; household?: Household; onClose: () => void }) => {
  const { firm } = useContext(FirmContext)
  const { sessionInfo } = useContext(AuthContext)
  const theme = useTheme()
  const { updateClient, updateHousehold, replace } = useContext(ClientHouseholdCacheContext)
  const [selectedGames, setSelectedGames] = useState<{ [clientId: string]: GameType[] }>({})
  const [isError, setIsError] = useState<boolean>(false)
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)

  const isValid = useCallback(() => {
    return Object.values(selectedGames).every((gamesArray) => gamesArray.length >= 1)
  }, [selectedGames])

  useEffect(() => {
    if (household) {
      setSelectedGames(
        household.members.reduce((acc, { client }) => {
          acc[client._id] = client.gameTypes ?? firm?.config?.supportedGames?.map((g) => g.type) ?? []
          return acc
        }, {} as { [clientId: string]: GameType[] })
      )
    } else if (client) {
      setSelectedGames({ [client._id]: client.gameTypes ?? firm?.config?.supportedGames?.map((g) => g.type) ?? [] })
    }
  }, [client, firm?.config?.supportedGames, household])

  async function createGames(clientId: string, gameByType: Record<string, Game | undefined>) {
    const anyCreatedGame = Object.values(selectedGames).some((gamesArray) => gamesArray.some((game) => gameByType[game]?.created))
    if (anyCreatedGame) {
      const clientGamesToCreate = Object.entries(selectedGames).flatMap(([_, gamesArray]) => {
        return gamesArray
          .filter(game => !gameByType[game]?.created)
      })
      // looping here instead of Promise.all to ensure last one initiated wins when it hits `replace`
      for (const cg of clientGamesToCreate) {
        await createGame(sessionInfo!, clientId, cg).then(replace)
      }
    }
  }

  const { mutate: updateGameTypes, status: clientUpdateState } = useMutation({
    mutationFn: async () => {
      return (client
        ? (updateClient(client._id, {
            gameTypes: selectedGames[client._id]
          }) as Promise<Client>)
        : (updateHousehold(household!._id, Object.values(selectedGames).map((d) => ({ gameTypes: d })) ?? [], {}) as Promise<Household>)
      ).then(async () => {
        const clients = (client ? [client] : household!.members.map(m => m.client).filter(_ => _))
        await Promise.all(clients.map(c => createGames(c._id, latestGamesByType(c, firm))))
      })
    },
    onSuccess: onClose
  })

  const onChange = (client: Client, gameType: GameType, isSelected: boolean) => {
    setSelectedGames((prevSelectedGames) => {
      const currentGames = prevSelectedGames[client._id] || []
      const updatedGames = isSelected
        ? currentGames.includes(gameType)
          ? currentGames
          : [...currentGames, gameType]
        : currentGames.filter((g) => g !== gameType)
      return {
        ...prevSelectedGames,
        [client._id]: updatedGames
      }
    })
  }

  const onApply = useCallback(() => {
    setShouldValidate(true)
    setIsError(!isValid())
    isValid() && updateGameTypes()
  }, [isValid, updateGameTypes])

  useEffect(() => {
    if (shouldValidate) {
      setIsError(!isValid())
    }
  }, [isValid, shouldValidate])

  const order = theme.games.map((game) => game.type)
  const sortedSupportedGames = firm?.config?.supportedGames?.sort((a, b) => {
    return order.indexOf(a.type) - order.indexOf(b.type)
  })

  return (
    <div className="reprofile-efp-modal-wrapper w-full overflow-y-scroll no-scrollbar max-h-full">
      <div className="flex flex-col items-start gap-y-6">
        <h2 className="text-h2 text-left font-semibold text-main-600">Activity settings</h2>
        <p>
          {household
            ? "Choose which activities you want enabled for your household members."
            : `Choose which activities you want enabled for ${client?.firstName}.`}
        </p>
        <div className="w-full flex flex-col">
          {household ? (
            <div role="grid">
              <div className="grid grid-cols-5" role="rowgroup">
                <div className="col-span-3 pb-1" role="columnheader">
                  <h3 className="text-sec font-semibold text-main-600">Activity</h3>
                </div>
                {household.members.map(({ client }) => (
                  <div key={client._id} className="col-span-1 pl-4 pb-1 text-center" role="columnheader">
                    <h3 className="text-sec font-semibold text-main-600">{client.firstName}</h3>
                  </div>
                ))}
              </div>
              <div className="bg-surface-100 py-3">
                {sortedSupportedGames?.map((game) => (
                  <div key={game.type} className="grid grid-cols-5" role="row">
                    <div className="col-span-3 py-1 px-4" role="rowheader">
                      <p>{tt({ id: `game-${game.type}-title` })}</p>
                    </div>
                    {household.members.map(({ client }) => (
                      <div key={client._id} className="col-span-1 py-1 pl-4 text-center" role="gridcell">
                        <Checkbox
                          name={game.type}
                          className="justify-center"
                          checked={selectedGames?.[client!._id]?.includes(game.type)}
                          onChange={(event) => onChange(client!, game.type, event.target.checked)}
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <>
              <h3 className="text-sec font-semibold text-main-600 mb-1">Activity</h3>
              <div className="w-full bg-surface-100 py-3 px-4">
                {firm?.config?.supportedGames?.map((game) => {
                  return (
                    <Checkbox
                      key={game.type}
                      name={game.type}
                      checked={selectedGames?.[client!._id]?.includes(game.type)}
                      onChange={(event) => onChange(client!, game.type, event.target.checked)}
                      className="flex-row-reverse justify-between w-full p-1"
                      label={tt({ id: `game-${game.type}-title` })}
                    />
                  )
                })}
              </div>
            </>
          )}
        </div>
      </div>
      <div role="alert" className="flex flex-col items-center">
        {clientUpdateState === "error" && <div className="text-error text-right mt-6">Error updating the client details</div>}
        {isError && (
          <div className="text-input-error w-full flex items-center mt-1">
            <img alt="" className="text-input-error-icon mr-1" src={infoAlert} aria-hidden />
            <p className="text-input-error-text text-sm text-left text-error font-normal">
              {household ? (
                <Trans id="activity-settings-error-household-text">You must have at least one activity enabled for each household member</Trans>
              ) : (
                <Trans id="activity-settings-error-client-text">You must have at least one activity enabled</Trans>
              )}
            </p>
          </div>
        )}
      </div>
      <div className="modal-footer w-full flex flex-shrink-0 flex-wrap items-center justify-center mt-6">
        <div className="flex gap-5">
          <button onClick={onClose} className="btn btn-secondary btn-medium w-44">
            Cancel
          </button>
          <button onClick={onApply} disabled={clientUpdateState === "loading"} className="btn btn-primary btn-medium w-44">
            {clientUpdateState === "loading" ? <Loading type="dots" /> : <span className="whitespace-nowrap">Apply</span>}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ActivitySettingsModal
