import { t } from "@lingui/macro"
import { useContext, useMemo } from "react"
import { useQuery } from "react-query"
import axiosInstance from "../../../../../../api/axiosInstance"
import NotesList, { NotesListItem } from "../../../../../../components/NotesList/NotesList"
import { AppContext } from "../../../../../../contexts/AppContext"
import { areDecisionsIncoherent } from "../../../../../../lib/clients"
import { Client, ClientEventType, ClientGameEventType, GameType, Note } from "../../../../../../models/Client"
import { AuthContext, AuthStatus } from "../../../../../../views/auth/AuthContext"

interface NoteItem {
  id: string
  note: Note
  warningAlert?: {
    message: string
  }
}

const clientEventMessages = (name: string): { [key in ClientEventType]: string } => ({
  "created": t({ id: `client-event-created-note`, message: `${name}'s record was created` }),
  "marked as reviewed": t({ id: `client-event-risk-mark-as-reviewed-note`, message: `${name}'s risk profile was marked as reviewed` })
})
const clientGameEventMessages = (name: string): { [key in GameType]: { [key in ClientGameEventType]: string } } => ({
  risk: {
    "created": t({
      id: `client-game-event-risk-created-note`,
      message: `${name} was invited to complete a risk profiling activity`
    }),
    "game completed": t({
      id: `client-game-event-risk-game-completed-note`,
      message: `${name} completed the risk profiling activity`
    }),
    "marked as reviewed": t({ id: `client-game-event-risk-mark-as-reviewed-note`, message: `${name}'s risk profile was marked as reviewed` })
  },
  esg: {
    "created": t({
      id: `client-game-event-esg-created-note`,
      message: `${name} was invited to complete a sustainable investing activity`
    }),
    "game completed": t({
      id: `client-game-event-esg-game-completed-note`,
      message: `${name} completed the sustainable investing activity`
    }),
    "marked as reviewed": t({ id: `client-game-event-esg-mark-as-reviewed-note`, message: `${name}'s values profile was marked as reviewed` })
  },
  liquidity: {
    "created": t({
      id: `client-game-event-liquidity-created-note`,
      message: `${name} was invited to complete a liquidity activity`
    }),
    "game completed": t({
      id: `client-game-event-liquidity-game-completed-note`,
      message: `${name} completed the liquidity activity`
    }),
    "marked as reviewed": t({ id: `client-game-event-liquidity-mark-as-reviewed-note`, message: `${name}'s liquidity profile was marked as reviewed` })
  },
  pfs: {
    "created": t({
      id: `client-game-event-pfs-created-note`,
      message: `${name} was invited to complete a pfs activity`
    }),
    "game completed": t({
      id: `client-game-event-pfs-game-completed-note`,
      message: `${name} completed the pfs activity`
    }),
    "marked as reviewed": t({ id: `client-game-event-pfs-mark-as-reviewed-note`, message: `${name}'s PFS profile was marked as reviewed` })
  },
  goals: {
    "created": t({
      id: `client-game-event-goals-created-note`,
      message: `${name} was invited to complete a goals activity`
    }),
    "game completed": t({
      id: `client-game-event-goals-game-completed-note`,
      message: `${name} completed the goals activity`
    }),
    "marked as reviewed": t({ id: `client-game-event-goals-mark-as-reviewed-note`, message: `${name}'s goals profile was marked as reviewed` })
  },
  retirement: {
    "created": t({
      id: `client-game-event-retirement-created-note`,
      message: `${name} was invited to complete a retirement activity`
    }),
    "game completed": t({
      id: `client-game-event-retirement-game-completed-note`,
      message: `${name} completed the retirement activity`
    }),
    "marked as reviewed": t({ id: `client-game-event-retirement-mark-as-reviewed-note`, message: `${name}'s retirement income profile was marked as reviewed` })
  },
  tax: {
    "created": t({
      id: `client-game-event-tax-created-note`,
      message: `${name} was invited to complete a tax activity`
    }),
    "game completed": t({
      id: `client-game-event-tax-game-completed-note`,
      message: `${name} completed the tax activity`
    }),
    "marked as reviewed": t({ id: `client-game-event-tax-mark-as-reviewed-note`, message: `${name}'s tax profile was marked as reviewed` })
  }
})

export const clientActivityNotes = (client: Client) => {
  const gameEvents =
    client.games
      ?.filter((game) => !!game.events)
      .flatMap((game) => {
        const isIncoherent = game.risk?.results?.R ? areDecisionsIncoherent(game) : false
        return game.events.map(
          (gameEvent) =>
            ({
              id: `note-${gameEvent.created}`,
              note: {
                message: gameEvent.message ?? clientGameEventMessages(client.firstName)[game.gameType ?? "risk"][gameEvent.eventType!],
                created: gameEvent.created
              },
              warningAlert:
                gameEvent.eventType === "game completed" && isIncoherent
                  ? {
                      message: `${client.firstName}’s decisions were inconsistent`
                    }
                  : undefined
            } as NoteItem)
        )
      }) || []
  const notes =
    client.notes?.map((note) => ({
      id: `note-${note.created}`,
      note
    })) ?? []
  const clientEvents =
    client.events?.map((event) => ({
      id: `event-${event.created}`,
      note: {
        message: clientEventMessages(client.firstName)[event.type],
        created: event.created,
        createdBy: event.createdBy,
        custom: false
      }
    })) ?? []
  return [...gameEvents, ...notes, ...clientEvents]
}


const Notes = ({
  profile,
  onAddNote,
  isNoteSaving,
  isDisabled
}: {
  profile: Client
  onAddNote: (note: string) => void
  isNoteSaving: boolean
  isDisabled: boolean
}) => {
  const { authStatus, sessionInfo } = useContext(AuthContext)
  const { userProfile } = useContext(AppContext)
  const notes = useMemo(() => clientActivityNotes(profile), [profile])

  const subs = useMemo(
    () =>
      Array.from(
        new Set(
          notes
            ?.filter((n) => n.note.custom)
            .map((n) => n.note.createdBy?.sub)
            .filter((s) => s)
        )
      ),
    [notes]
  )

  const { isLoading, data: usersBySub } = useQuery<{ [sub: string]: { firstName: string; lastName: string } }>(
    ["usersBySub", ...notes.map((n) => n.note.createdBy?.sub)],
    () => {
      if (subs && subs.length === 1 && subs[0] === userProfile?.sub) {
        return {
          [subs[0]]: { firstName: userProfile.firstName, lastName: userProfile.lastName }
        }
      } else {
        const queryParams = new URLSearchParams({})
        subs?.forEach((sub) => {
          if (sub) queryParams.append("sub", sub)
        })
        return axiosInstance.get(`${import.meta.env.VITE_APP_API_BASE || ""}/api/user/subs?clientId=${profile._id}&${queryParams}`).then((res) => res.data)
      }
    },
    {
      enabled: authStatus === AuthStatus.SignedIn && !!sessionInfo?.accessToken && subs && subs.length > 0,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  )

  const notesListItems: NotesListItem[] = useMemo(() => {
    return [...notes]
      .sort((a, b) => Number(new Date(b.note.created)) - Number(new Date(a.note.created)))
      .map(
        (clientNote: NoteItem) =>
          ({
            date: clientNote.note.created,
            id: clientNote.id,
            message: clientNote.note.message,
            userName:
              clientNote.note.custom && usersBySub && clientNote.note.createdBy?.sub && usersBySub[clientNote.note.createdBy?.sub]
                ? `${usersBySub[clientNote.note.createdBy?.sub].firstName}: `
                : null,
            warningAlert: clientNote.warningAlert
          } as NotesListItem)
      )
  }, [notes, usersBySub])

  return <NotesList isDisabled={isDisabled} isLoading={isLoading} isNoteSaving={isNoteSaving} items={notesListItems} onAddNote={onAddNote} />
}

export default Notes
