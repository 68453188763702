import { useTheme } from "../../../../contexts/ThemeContext"
import { Client } from "../../../../models/Client"
import { Firm } from "../../../../models/Firm"
import { Household } from "../../../../models/Household"
import { PrintCauseDetail } from "../PrintCauseDetail"
import Container from "../components/Container"
import Footer from "../components/Footer"

const ValuesAppendixPage2 = ({ page, client, household, firm }: { page: number; client: Client; household: Household; firm: Firm }) => {
  const theme = useTheme()
  return (
    <Container id="appendix-page-2" className="flex flex-col justify-between pt-10 relative">
      <div className="flex-auto mx-10">
        {Object.values(theme.causes?.S1 ?? {})
          .slice(5, 10)
          .map((cause) => (
            <PrintCauseDetail cause={cause} key={cause.id} className="mb-7" />
          ))}
      </div>

      <div className="relative mx-10">
        <Footer pageNum={page} client={client} household={household} firm={firm} />
      </div>
    </Container>
  )
}

export default ValuesAppendixPage2
