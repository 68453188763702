import clsx from "clsx"
import React from "react"

interface Props {
  id: string
  className?: string
  label: string
  onClick: () => void
  toggleState: boolean
}

const ToggleButton = ({ id, className, label, toggleState, onClick }: Props) => {
  return (
    <div onClick={onClick} className="w-max flex items-center gap-2 cursor-pointer" id={id}>
      <div
        className={clsx(`w-9 h-6  rounded-3 flex items-center my-1 ${className}`, {
          "bg-interactive-500": toggleState,
          "bg-interactive-300": !toggleState
        })}
      >
        <div
          className={clsx("bg-white rounded-full w-4 h-4 mx-1", {
            "translate-x-3 duration-100": toggleState,
            "translate-x-0 duration-100": !toggleState
          })}
        />
      </div>
      <label htmlFor={label} className="text-sec text-main-500 cursor-pointer">
        {label}
      </label>
    </div>
  )
}

export default ToggleButton
