import { Trans, t } from "@lingui/macro"
import { Client } from "../../../models/Client"
import SustainabilityMetrics from "../../advisor/components/PortfolioAnalyser/SustainabilityMetrics"
import Page from "./Page"

const SustainabilityMetricsReportPage = ({ page, client }: { page: number; client: Client }) => {
  return (
    <Page page={page} title={t({ id: "print-report-page-header-title" })}>
      <div id="top-themes-page" className="relative mx-9 h-full">
        <div className="flex items-center justify-between">
          <div className="flex gap-2 items-center">
            <h1 className="font-normal text-[22px]">
              <Trans id="print-sustainability-metrics-title">Sustainability metrics</Trans>
            </h1>
          </div>
        </div>
        <div className="overflow-y-auto no-scrollbar pt-6 pb-10 px-1">
          <SustainabilityMetrics client={client!} />
        </div>
      </div>
    </Page>
  )
}

export default SustainabilityMetricsReportPage
