import { Client } from "../models/Client"
import { SessionInfo } from "../views/auth/AuthContext"
import axiosInstance from "./axiosInstance"

export const sendInvite = async (sessionInfo: SessionInfo, clientId: string, optionalQueryParam?: { skipEmail: boolean }) => {
  const res = await axiosInstance
    .get(`${import.meta.env.VITE_APP_API_BASE || ""}/api/client/${clientId}/game/invite${optionalQueryParam?.skipEmail ? "?skipEmail=true" : ""}`)
    .then((res) => res.data)
  console.log("res in ts", res)
  return res as Client
}
