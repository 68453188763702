import { ImportFundEntry } from "../../../../api/clients"

const FundErrorsDisplay: React.FunctionComponent<{ funds: ImportFundEntry[] }> = ({ funds }) => {
  return (
    <div style={{ maxHeight: "calc(100vh - 300px)" }} className="w-full overflow-y-auto">
      <table className="w-full border-separate border-spacing-y-2">
        <thead className="sr-only">
          <tr>
            <td>Row</td>
            <td>Fund ISIN</td>
            <td>Current allocation</td>
          </tr>
        </thead>
        <tbody>
          {funds?.map((fund) => (
            <tr className="bg-neutral-50 mb-2 text-left" key={fund.id}>
              <td className="p-2">{fund.id + 1}</td>
              <td className="p-2 text-main-500">{fund.fundId ?? "-"}</td>
              <td className="p-2 text-right">{fund.currentAllocation !== undefined ? fund.currentAllocation.toFixed(2) + "%" : "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default FundErrorsDisplay
