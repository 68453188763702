import { useCallback, useContext, useMemo, useState } from "react"
import { createGame } from "../../../../../../api/createGame"
import { sendInvite } from "../../../../../../api/sendInvite"
import linkIcon from "../../../../../../assets/icons/link.svg"
import AlertPopover, { AlertPopoverOptions } from "../../../../../../components/AlertPopover/AlertPopover"
import ClientStatusAlert, { StatusAlert } from "../../../../../../components/ClientProfile/ClientStatusRow/ClientStatusAlert"
import { ClientHouseholdCacheContext } from "../../../../../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../../../../../contexts/FirmContext"
import { useTheme } from "../../../../../../contexts/ThemeContext"
import useTrackViewEvent from "../../../../../../hooks/useTrackViewEvent"
import { gameUrlByType, latest } from "../../../../../../lib/clients"
import { Client } from "../../../../../../models/Client"
import { AuthContext } from "../../../../../../views/auth/AuthContext"
import AddClientEmailModal from "../../../AddClientEmailModal"

const NeedsResultsModal = ({ client, onClose }: { client: Client; onClose: () => void }) => {
  const theme = useTheme()
  const { firm } = useContext(FirmContext)
  const trackViewEvent = useTrackViewEvent()
  const [showAddClientEmailModal, setShowAddClientEmailModal] = useState(false)
  const { status, email } = client
  const { sessionInfo } = useContext(AuthContext)
  const { replace } = useContext(ClientHouseholdCacheContext)
  const [infoAlert, setInfoAlert] = useState<StatusAlert | undefined>(undefined)
  const game = latest(client, "esg")

  const sendEmail = useCallback(() => {
    sendInvite(sessionInfo!, client._id!)
      .then((res) => {
        replace(res)
        setInfoAlert({
          message: "Email has been sent",
          onClose: () => {
            setInfoAlert(undefined)
            onClose()
          },
          type: "success"
        })
      })
      .catch((error) => {
        console.error("Error sending invite", error)
        setInfoAlert(undefined)
      })
  }, [client._id, onClose, replace, sessionInfo])

  const onSendInvite = useCallback(
    (email: string) => {
      trackViewEvent({ action: "click", category: "client_invitation", label: "email_invite" })
      if (email) {
        setInfoAlert({
          message: "Sending...",
          timeout: false,
          type: "activity"
        })
        createGame(sessionInfo!, client._id!, theme.gameType)
          .then(() => {
            sendEmail()
          })
          .catch((error) => {
            console.error("Error creating game:", error)
            setInfoAlert(undefined)
          })
      } else {
        setShowAddClientEmailModal(true)
      }
    },
    [trackViewEvent, sessionInfo, client._id, theme.gameType, sendEmail]
  )

  const onSaveEmailModal = (updatedClient: Client) => {
    onSendInvite(updatedClient.email)
  }

  const alertPopoverOptions: AlertPopoverOptions | undefined = useMemo(() => {
    if (!infoAlert) {
      return
    }
    return {
      content: <ClientStatusAlert type={infoAlert.type} message={infoAlert.message} />,
      onClose: infoAlert.onClose,
      timeoutMS: infoAlert.timeout === false ? null : 5000,
      location: infoAlert.location
    }
  }, [infoAlert])

  const onCopyInviteLink = useCallback(() => {
    trackViewEvent({ action: "click", category: "client_invitation", label: "copy_invite" })
    if (navigator.clipboard) {
      // reminder rather than new game so just copy the link
      if (status === "Activity pending" && game) {
        navigator.clipboard.writeText(gameUrlByType(client, game, firm!))
        setInfoAlert({
          message: "Copying...",
          timeout: false,
          type: "activity"
        })
        sendInvite(sessionInfo!, client._id!, { skipEmail: true })
          .then(async (res) => {
            replace(res)
            setInfoAlert({
              message: "Link copied to clipboard",
              onClose: () => {
                setInfoAlert(undefined)
                onClose()
              },
              type: "success"
            })
            return await new Blob([gameUrlByType(res, latest(res, "esg")!, firm!)], { type: "text/plain" })
          })
          .catch((error) => {
            console.error("error creating game:", error)
            setInfoAlert(undefined)
            return ""
          })
      } else {
        setInfoAlert({
          message: "Copying...",
          timeout: false,
          type: "activity"
        })
        // Safari and Chrome
        if (typeof ClipboardItem && navigator.clipboard.write) {
          const text = new ClipboardItem({
            "text/plain": createGame(sessionInfo!, client._id!, theme.gameType)
              .then(async (res) => {
                replace(res)
                setInfoAlert({
                  message: "Link copied to clipboard",
                  onClose: () => setInfoAlert(undefined),
                  type: "success"
                })
                return await new Blob([gameUrlByType(res, latest(res, "esg")!, firm!)], { type: "text/plain" })
              })
              .catch((error) => {
                console.error("error creating game:", error)
                setInfoAlert(undefined)
                return ""
              })
          })
          navigator.clipboard.write([text])
        } else {
          // Firefox doesn't support ClipboardItem
          createGame(sessionInfo!, client._id!, theme.gameType)
            .then((res) => {
              replace(res)
              navigator.clipboard.writeText(gameUrlByType(res, latest(res, "esg")!, firm!))
              setInfoAlert({
                message: "Link copied to clipboard",
                onClose: () => setInfoAlert(undefined),
                type: "success"
              })
            })
            .catch((error) => {
              console.error("error creating game:", error)
              setInfoAlert(undefined)
            })
        }
      }
    }
  }, [trackViewEvent, status, game, client, firm, sessionInfo, replace, onClose, theme.gameType])

  return (
    <>
      {!showAddClientEmailModal && (
        <div className="flex flex-col gap-6 items-center justify-center text-center">
          {theme.pages.profile.modals?.needsResults?.heroImage && <img src={theme.pages.profile.modals?.needsResults?.heroImage} />}
          <p>In order to use the Portfolio Analyser your client will need to complete the MyESG.id activity first.</p>
          <AlertPopover options={alertPopoverOptions} show={!!infoAlert}>
            <div className="flex flex-col gap-2">
              <button className="btn btn-primary btn-medium w-44 block" onClick={() => onSendInvite(email)}>
                Send invite
              </button>
              <button className="copy-link-btn btn btn-text btn-text-md flex items-center justify-center align-middle gap-2" onClick={onCopyInviteLink}>
                <img className="copy-link-btn__img" src={linkIcon} alt="" aria-hidden />
                Copy link
              </button>
            </div>
          </AlertPopover>
        </div>
      )}
      {showAddClientEmailModal && (
        <AddClientEmailModal onSave={onSaveEmailModal} onClose={() => setShowAddClientEmailModal(false)} client={client} game={game!} />
      )}
    </>
  )
}

export default NeedsResultsModal
