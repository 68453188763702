import { Client } from "../models/Client"
import { Household } from "../models/Household"
import { SessionInfo } from "../views/auth/AuthContext"
import axiosInstance from "./axiosInstance"

export const addNote = async (_: SessionInfo, clientType: string, clientId: string, note: string): Promise<Client | Household> => {
  return axiosInstance
    .post(
      `${import.meta.env.VITE_APP_API_BASE || ""}/api/${clientType}/${clientId}/notes`,
      {
        note
      }
    )
    .then((res) => res.data)
}
