import { clsx } from "clsx"
import { useContext, useLayoutEffect } from "react"
import { NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom"
import { FirmContext } from "../../../contexts/FirmContext"
import useClient from "../../../hooks/useClient"
import useHousehold from "../../../hooks/useHousehold"
import { tt } from "../../../lib/translations"
import RiskResults from "./RiskResults"
import { latest } from "../../../lib/clients"
import { useTheme } from "../../../contexts/ThemeContext"
import comingSoonHero from "../../../assets/images/maintenance.svg"

const ComingSoon = () => (
  <div className="w-full h-full flex flex-col items-center justify-center gap-6">
    <img src={comingSoonHero} alt="Road works sign" />
    <div className="flex flex-col items-center gap-2">
      <h4 className="text-h1 font-semibold">This feature is coming soon</h4>
      <p>Watch this space</p>
    </div>
  </div>
)

const ResultsPage = () => {
  const { firm } = useContext(FirmContext)
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client } = useClient(clientId)
  const { household } = useHousehold(householdId)
  const theme = useTheme()

  const ToFirstTab = () => {
    const navigate = useNavigate()
    useLayoutEffect(() => {
      // Filter theme.games based on firm.config.supportedGames and then find the first tab
      const firstTabType = theme.games
        ?.filter((themeGame) => firm?.config?.supportedGames?.some((sg) => sg.type === themeGame.type))
        ?.find(
          (game) =>
            client?.games?.some((g) => g.gameType === game.type) ||
            household?.members?.some((member) => member.client.games?.some((g) => g.gameType === game.type))
        )?.type
      navigate(firstTabType ?? "pfs", { replace: true }) // Navigate to the found game type or default to "pfs"
    }, [navigate])
    return null
  }

  return (
    <div className="bg-white h-full py-10 text-main-500">
      <nav className="px-16 font-semibold">
        <ul className="flex">
          {theme.games?.map((game) => {
            if (firm?.config?.supportedGames?.some((sg) => sg?.type === game?.type)) {
              const clientGamePlayed = client && latest(client, game.type)?.played
              const householdGamePlayed = household?.members?.some((member) => {
                return latest(member.client, game.type)?.played
              })
              // Disable the tab only if the game is neither "pfs" nor "goals" and hasn't been played by anyone
              const isTabDisabled = game.type !== "pfs" && game.type !== "goals" && (household ? !householdGamePlayed : !clientGamePlayed)
              const linkStyle = ({ isActive }: { isActive: boolean }) =>
                clsx(
                  "w-full h-full px-5 py-3.5 text-sec",
                  isActive ? "border-b-2 border-b-black font-bold" : "font-semibold",
                  isTabDisabled ? "text-main-200 cursor-default" : "text-interactive-500"
                )
              return (
                <li key={game.type}>
                  <NavLink
                    end
                    className={linkStyle}
                    to={game.type}
                    onClick={(e) => isTabDisabled && e.preventDefault()}
                    aria-disabled={isTabDisabled}
                    replace={!!isTabDisabled}
                  >
                    {tt({ id: `game-${game.type}-title` })}
                  </NavLink>
                </li>
              )
            }
          })}
        </ul>
        <div className="mt-3 w-full border-b border-b-surface-300" />
      </nav>
      <div className="h-full overflow-y-auto no-scrollbar">
        <Routes>
          <Route index element={<ToFirstTab />} />
          <Route path="pfs" element={<ComingSoon />} />
          <Route path="goals" element={<ComingSoon />} />
          <Route path="risk" element={<RiskResults client={client} household={household} />} />
          <Route path="liquidity" element={<ComingSoon />} />
          <Route path="esg" element={<ComingSoon />} />
          <Route path="retirement" element={<ComingSoon />} />
          <Route path="tax" element={<ComingSoon />} />
          <Route path="*" element={<div className="full-flex-content-center text-error">We have encountered a configuration error.</div>} />
        </Routes>
      </div>
    </div>
  )
}

export default ResultsPage
