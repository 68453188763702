import { Theme } from "../../../../../../config/theme"
import { latest } from "../../../../../../lib/clients"
import { Client } from "../../../../../../models/Client"
import { createClientDataPoints } from "../../../../../rmjourney/utils/charts"
import { ALTRUISM_DATA } from "./assets/altruismData"
import { t } from "@lingui/macro"

export const getAltruismLevel = (score: number) => {
  if (score !== undefined) {
    if (score >= 0 && score < 30) {
      return "low"
    } else if (score >= 30 && score < 70) {
      return "medium"
    } else if (score >= 70 && score <= 100) {
      return "high"
    } else {
      return null
    }
  } else {
    return null
  }
}

export type AltruismScoreData = {
  altruismData: {
    id: string
    value: number
  }[]
  altruismScoreXYaxisValue: {
    id: string
    value: number
  }[]
  highlightDataPoints: {
    id: string
    radius: number
    textSize: number
    color: string
    textColor: string
    offSetX: number
    offSetY: number
    text: string
    x: string
    y: number
  }[]
  score: number[]
  xAxis: {
    labels: {
      id: string
      text: string
    }[]
    title: string
  }
  yAxis: {
    labels: {
      id: string
      text: string
    }[]
  }
}

export const altruismChartData = (theme: Theme, client: Client): AltruismScoreData => {
  const score = [Object.values(latest(client, "esg")!.esg.results!.S2)[1].score]
  const altruismScoreXYaxisValue = score?.map((s) => ALTRUISM_DATA.find((_, i) => i >= s)!)
  const xAxis = {
    labels: [
      { id: "1", text: "0" },
      { id: "2", text: "10" },
      { id: "3", text: "20" },
      { id: "4", text: "30" },
      { id: "5", text: "40" },
      { id: "6", text: "50" },
      { id: "7", text: "60" },
      { id: "8", text: "70" },
      { id: "9", text: "80" },
      { id: "10", text: "90" },
      { id: "11", text: "100" }
    ],
    title: t({ id: "results-altruism-x-axis-label-title", message: "Distribution of investors’ scores" })
  }
  const yAxis = {
    labels: [
      { id: "1", text: "0%" },
      { id: "2", text: "5%" },
      { id: "3", text: "10%" },
      { id: "4", text: "15%" },
      { id: "5", text: "20%" },
      { id: "6", text: "25%" }
    ]
  }

  const highlightDataPoints = createClientDataPoints({ theme: theme, clients: [client], values: altruismScoreXYaxisValue })

  return {
    altruismData: ALTRUISM_DATA,
    altruismScoreXYaxisValue,
    highlightDataPoints,
    score,
    xAxis,
    yAxis
  }
}
