import { useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import axiosInstance from "../api/axiosInstance"
import { ClientHouseholdCacheContext } from "../contexts/ClientHouseholdCacheContext"
import { AuthContext, AuthStatus } from "../views/auth/AuthContext"
import { FirmContext } from "../contexts/FirmContext"

const useStatusCounts = () => {
  const { sessionInfo, authStatus } = useContext(AuthContext)
  const { lastUpdate } = useContext(ClientHouseholdCacheContext)
  const [previousData, setPreviousData] = useState<{ status: string; count: number }[] | null>(null)
  const { firm } = useContext(FirmContext)

  const { isSuccess, isLoading, data, refetch } = useQuery<{ status: string; count: number }[], { message?: string }>(
    ["status-count", sessionInfo?.accessToken, lastUpdate?._id, lastUpdate?.status, firm?._id],
    () =>
      axiosInstance
        .get<{ status: string; count: number }[]>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/advisor/clients/stats/status-count?firmId=${firm?._id}`)
        .then((res) => res.data),
    {
      enabled: authStatus === AuthStatus.SignedIn && !!sessionInfo?.accessToken,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onSuccess: (newData) => setPreviousData(newData)
    }
  )

  useEffect(() => {
    if (lastUpdate) {
      refetch()
    }
  }, [lastUpdate, refetch])

  return {
    refetch,
    data: data ?? previousData,
    isLoading,
    isSuccess
  }
}

export default useStatusCounts
