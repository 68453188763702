const rules = [
    {
        min: 0,
        max: 10000,
        step: 500,
    },
    {
        min: 10000,
        max: 50000,
        step: 1000,
    },
    {
        min: 50000,
        max: 2500000,
        step: 5000,
    },
    {
        min: 2500000,
        max: 10000000,
        step: 10000,
    },
    {
        min: 10000000,
        step: 50000,
    }
];

const inRange = (amount: number, rule: any) => {
    const { min, max } = rule;
    if (min !== void 0 || max !== void 0) {
        if (min === void 0 && amount < max) {
            return true;
        }
        if (max === void 0 && min <= amount) {
            return true;
        }
        if (min <= amount && amount < max) {
            return true;
        }
    }
}

const rounding = (rules: any[], handler: any) => (amount: number) => {
    return rules.reduce((base: number, rule: any) => {
        return handler(amount, rule) ? rule.step : base;
    }, 0);
};

export default rounding(rules, inRange);