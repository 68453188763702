import clsx from "clsx"
import { ReactNode, useEffect, useMemo, useRef, useState } from "react"
import infoAlert from "../../../src/assets/icons/info-alert.svg"
import chevUp from "../../pages/advisor/assets/images/chevron-up.svg"
import SearchBox from "../../pages/advisor/components/SearchBox"
import Dropdown from "../Dropdown/Dropdown"
import close from "../Modal/assets/close.svg"

type Props<T, Sel> = {
  className?: string
  disableAutoComplete?: boolean
  data?: T[]
  error?: string | ReactNode
  label?: string | ReactNode
  renderLabel: (o?: T, s?: Sel) => ReactNode
  match: (o: T, search: string) => boolean
  onSelect: (v: T | null) => void
  selected?: Sel
  verticalAlign?: "top" | "bottom"
  deselect?: boolean
  isSelected: (o: T, s?: Sel) => boolean
  autoFocusSearch?: boolean // Add this prop
}

function SelectNew<T, Sel>({ deselect, className, label, onSelect, selected, data, error, renderLabel, match, verticalAlign = "bottom", isSelected, autoFocusSearch = true }: Props<T, Sel>) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const menuTriggerEl = useRef<HTMLButtonElement>(null)
  const [searchStr, setSearchStr] = useState<string>("")
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1)
  const listRef = useRef<HTMLUListElement>(null)
  const searchBoxRef = useRef<HTMLInputElement>(null)

  const matches = useMemo(() => data?.filter((x) => match(x, searchStr)) ?? [], [data, match, searchStr])

  useEffect(() => {
    if (!isMenuOpen) {
      setSearchStr("")
      setHighlightedIndex(-1)
    } else if (autoFocusSearch) {
      const t = setTimeout(() => {
        searchBoxRef.current?.focus()
      }, 110)
      return () => clearTimeout(t)
    }
  }, [isMenuOpen, autoFocusSearch])

  const handleSelect = (option: T | null) => {
    onSelect(option)
    setIsMenuOpen(false)
    setHighlightedIndex(-1)
  }

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleDeselect = (e: React.MouseEvent<HTMLButtonElement>) => {
    onSelect(null)
    e.stopPropagation()
    e.preventDefault()
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!isMenuOpen) {
      return
    }

    switch (e.key) {
      case "ArrowDown":
        setHighlightedIndex((prev) => Math.min(prev + 1, matches.length - 1))
        e.preventDefault()
        break
      case "ArrowUp":
        setHighlightedIndex((prev) => Math.max(prev - 1, 0))
        e.preventDefault()
        break
      case "Enter":
        if (highlightedIndex >= 0 && highlightedIndex < matches.length) {
          handleSelect(matches[highlightedIndex])
        }
        e.preventDefault()
        break
      case "Escape":
        setIsMenuOpen(false)
        e.preventDefault()
        break
    }
  }

  useEffect(() => {
    if (listRef.current && highlightedIndex >= 0) {
      const listItem = listRef.current.children[highlightedIndex] as HTMLLIElement
      listItem?.scrollIntoView({ block: "nearest" })
    }
  }, [highlightedIndex])

  return (
    <div className={clsx("flex flex-col", className)}>
      {label && (
        <label className="select-adviser-label text-main-600 flex-shrink-0 text-sec mb-[5px]" htmlFor="select-select">
          {label}
        </label>
      )}
      <div className="relative w-full flex-col inline-block z-40 flex-shrink-0">
        <button
          className={clsx("px-4 py-2 select-adviser-wrapper w-full h-full flex justify-between border bg-white overflow-hidden", {
            "border-error": !!error,
            "border-main-400": !isMenuOpen && !error,
            "border-focuscolor": isMenuOpen && !error
          })}
          onClick={handleToggleMenu}
          ref={menuTriggerEl}
          id="select-select"
          onKeyDown={handleKeyDown}
        >
          {selected
            ? renderLabel(undefined, selected)
            : data ? "Select" : "..."}
          <img src={chevUp} alt="" aria-hidden="true" className={clsx({ "rotate-180": !isMenuOpen })} />
        </button>
        {deselect && selected && (
          <button className="absolute right-0 top-0 p-3 mr-8 w-10 h-10" onClick={handleDeselect}>
            <img src={close} alt="" aria-hidden="true" />
          </button>
        )}
        {isMenuOpen && (
          <Dropdown overlayClassName="w-full h-48" handleClose={() => setIsMenuOpen(false)} trigger={menuTriggerEl} alignVertical={verticalAlign}>
            <ul
              ref={listRef}
              className="list-none p-0 text-main-500 overflow-y-auto border h-48"
              role="listbox"
              tabIndex={-1}
              onKeyDown={handleKeyDown}
            >
              <li key="start-typing">
                <SearchBox
                  ref={searchBoxRef}
                  className="border-none"
                  placeholder="Start typing ..."
                  onChange={setSearchStr}
                  onClear={() => setSearchStr("")}
                />
              </li>
              {matches.map((option, i) => (
                <li key={i}>
                  <button
                    className={clsx("btn p-4 hover:bg-interactive-100 cursor-pointer border-0 block w-full text-left font-normal", {
                      "bg-interactive-100": highlightedIndex === i,
                      "font-bold": isSelected(option, selected)
                    })}
                    onClick={() => handleSelect(option)}
                    role="option"
                    aria-selected={highlightedIndex === i}
                  >
                    {renderLabel(option)}
                  </button>
                </li>
              ))}
            </ul>
          </Dropdown>
        )}
      </div>
      {error && (
        <div className="text-input-error flex items-center mt-1">
          <img alt="" aria-hidden="true" className="text-input-error-icon mr-1" src={infoAlert} />
          <p className="text-input-error-text text-sm text-error font-normal" role="alert">
            {error}
          </p>
        </div>
      )}
    </div>
  )
}

export default SelectNew
