import React, { useMemo, useState } from 'react'
import { Firm } from "../../../../models/Firm"
import TextInput from "../../../../components/TextInput/TextInput"
import { useMutation } from "react-query"
import { Link } from "react-router-dom"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import { AxiosError } from "axios"
import axiosInstance from "../../../../api/axiosInstance"
import Checkbox from "../../../../components/Checkbox/Checkbox"
import { format, isValid, parse } from 'date-fns'
import { DATE_FORMAT } from '../../../../lib/date'
import DatePicker from '../../../../components/DatePicker/DatePicker'


const ArchiveFirm = ({ firm, whenDone = () => {} }: { firm: Firm, whenDone?: () => any }) => {
  const [step, setStep] = useState(1)
  const [firmId, setFirmId] = useState('')
  const [isDryRun, setIsDryRun] = useState(false)
  const [deactivationDate, setDeactivationDate] = useState<Date | null>(new Date())
  const [deactivationDateRaw, setDeactivationDateRaw] = useState<string | null>(null)
  
  const mutation = useMutation<any, AxiosError<{ message?: string } | string | undefined>>({
    mutationFn: () => axiosInstance.delete(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/firm/${firmId}?deactiveDate=${format(deactivationDate!, "yyyy-MM-dd")}`, {
      ...isDryRun ? { params: { dryRun: isDryRun } } : {}
    }).then(res => res.data),
    onSuccess: () => {
      setStep(step + 1)
    }
  })
  
  const handleNext = () => {
    if (step === 3) {
      if (firmId === firm._id) {
        mutation.mutate()
      }
    } else {
      setStep(step + 1)
    }
  }

  const isDeactivationDateValid = useMemo(() => {
    const parsed = deactivationDateRaw ? parse(deactivationDateRaw, DATE_FORMAT, new Date()) : deactivationDate
    return parsed ? isValid(parsed) : false
  }, [deactivationDate, deactivationDateRaw])

  return (
    <div className="w-full h-full flex flex-col items-center pt-8 gap-y-8">
      {!mutation.isSuccess && <>
        <p role="alert" className="text-[36pt] text-red-500 font-semibold animate-pulse">Caution!</p>
        <div className="border max-w-screen-md p-8 border-red-400 flex flex-col gap-y-2">
          <p>You are about to archive a firm. This will:</p>
          <ul>
            <li className="list-disc list-inside">Archive all clients and households</li>
            <li className="list-disc list-inside">Archive all advisors</li>
            <li className="list-disc list-inside">Revoke access from all users (advisor users and associates)</li>
            <li className="list-disc list-inside">De-activate the firm</li>
          </ul>
          <p className="italic">This operation can be undone, but this will require manual intervention and time.</p>
        </div>
        <div className="flex w-full max-w-screen-md">
          <DatePicker
            id="deactivate-date"
            label="Confirm de-activation date"
            value={deactivationDate}
            onChange={(value) => {
              setDeactivationDate(value)
            }}
            onChangeRaw={(value) => {
              setDeactivationDateRaw(value)
            }}
            error={!isDeactivationDateValid ? "Please enter a valid de-activation date" : undefined}
          />
        </div>
      </>}
      {step === 1 && (
        <>
          <p className="text-h1 font-semibold">Are you sure you want to archive this firm?</p>
          <div className="flex gap-x-4">
            <Link to=".." className="btn btn-secondary btn-extra-large">Cancel</Link>
            <button className="btn btn-primary btn-extra-large" onClick={handleNext} disabled={!isDeactivationDateValid}>Yes, confirm in the next step</button>
          </div>
        </>
      )}
      {step === 2 && (
        <div className="flex flex-col gap-y-4">
          <TextInput 
            name="id" 
            label="Enter firm id to confirm" 
            value={firmId} 
            onChange={(e) => setFirmId(e)}
          />
          <button
            className="btn btn-primary btn-extra-large"
            disabled={firmId !== firm._id || !isDeactivationDateValid}
            onClick={handleNext}>Proceed to final confirmation</button>
        </div>
      )}
      {step === 3 && (
        <div className="flex flex-col gap-y-8 text-center">
          <p className="text-h1 text-red-600 font-semibold">This is the last chance to stop. Proceed?</p>
          <div className="flex gap-x-4">
            <Link to=".." className="btn btn-secondary btn-extra-large">Cancel</Link>
            <button
              disabled={firmId !== firm._id || mutation.isLoading || !isDeactivationDateValid}
              className="btn btn-primary btn-extra-large" onClick={handleNext}>
              {mutation.isLoading ? <Loading type="dots" /> : <>Yes, archive the firm</>}
            </button>
            <Checkbox name="dry-run" value="dry-run" checked={isDryRun} onChange={() => setIsDryRun(!isDryRun)} label="Dry run only"/>
          </div>
          {mutation.isError && <p className="text-error">{(mutation.error?.response?.data as any)?.message ?? mutation.error.response?.data ?? mutation.error.message}</p>}
        </div>
      )}
      {step === 4 && (
        <div className="full-flex-content-center gap-y-8">
          {!isDryRun && <p>The firm has been archived.</p>}
          {isDryRun && <p>Here's how many object would be affected:</p>}
          <div className="grid grid-cols-3 gap-x-4 gap-y-1 px-4 py-2 text-center">
            <div className="font-bold">Object</div>
            <div className="font-bold">Matched</div>
            <div className="font-bold">Deleted</div>
            <div className="font-semibold">Advisors</div><div>{mutation.data?.advisors?.matchedCount}</div><div>{mutation.data?.advisors?.modifiedCount}</div>
            <div className="font-semibold">Users</div><div>{mutation.data?.users?.matchedCount}</div><div>{mutation.data?.users?.modifiedCount}</div>
            <div className="font-semibold">Firm</div><div>{mutation.data?.firm?.matchedCount}</div><div>{mutation.data?.firm?.modifiedCount}</div>
          </div>
          {!isDryRun && <Link to="/admin/firms" className="btn btn-text underline" onClick={whenDone}>Back to firms</Link>}
          {isDryRun && <button className="btn btn-text underline" onClick={() => {
            mutation.reset()
            setStep(3)
          }}>Back to previous step</button>}
        </div>
      )}
    </div>
  )
}

export default ArchiveFirm
