import React, { useContext, useEffect, useRef, useState } from "react"
import PortfolioMatchCard from "../clients/components/Profile/components/PortfolioMatchCard"
import { useParams } from "react-router"
import useClient from "../../hooks/useClient"
import { Trans } from "@lingui/macro"
import FundsAllocation from "./components/PortfolioAnalyser/FundAllocation"
import clsx from "clsx"
import SustainabilityMetrics from "./components/PortfolioAnalyser/SustainabilityMetrics"
import PortfolioCompare from "../clients/components/Profile/components/PortfolioCompare/PortfolioCompare"
import Loading from "../../components/ClientProfile/Loading/Loading"
import LoadingError from "../../components/ClientProfile/LoadingError/LoadingError"
import { FirmContext } from "../../contexts/FirmContext"

const PortfolioAnalyserPage = () => {
  const { clientId } = useParams<{ clientId: string }>()
  const { client, isClientLoading: isLoading, clientLoadError } = useClient(clientId)
  const { firm } = useContext(FirmContext)
  const [portfolioUploaded, setPortfolioUploaded] = useState(client?.portfolio ?? false)
  const [isTabActive, setIsTabActive] = useState<boolean>(true)
  const tabRef = useRef<HTMLDivElement>(null)
  const parentRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    setPortfolioUploaded(client?.portfolio ?? false)
  }, [client?.portfolio])

  return (
    <div className="portfolio-analyser-page pg-ctr py-10 w-full h-full px-10 flex flex-col items-stretch absolute">
      <h1 className="text-h2 font-medium mb-10">
        <Trans id="portfolio-analyser-header-text">Portfolio analyser</Trans>
      </h1>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {client ? (
            <>
              {portfolioUploaded ? (
                <>
                  <nav ref={tabRef} className="px-0.5">
                    <ul className="flex">
                      <li className={clsx("text-sec", isTabActive ? "border-b-2 border-b-black font-medium" : "border-b border-b-neutral-200")}>
                        <button className="w-full h-full px-5 py-3" onClick={() => setIsTabActive(!isTabActive ? true : isTabActive)}>
                          <Trans id="portfolio-analyser-fund-allocation-tab">Fund allocation</Trans>
                        </button>
                      </li>
                      <li className={clsx("text-sec font-normal", !isTabActive ? "border-b-2 border-b-black font-medium" : "border-b border-b-neutral-200")}>
                        <button className="px-5 py-3 w-full h-full" onClick={() => setIsTabActive(isTabActive ? !isTabActive : false)}>
                          <Trans id="portfolio-analyser-sustainability-metrics-tab">Sustainability metrics</Trans>
                        </button>
                      </li>
                      <div className="border-b border-b-neutral-200 flex-auto" />
                    </ul>
                  </nav>
                  {isTabActive ? (
                    <>
                      <h2 className="sr-only">Fund allocation</h2>
                      <div ref={parentRef} className="overflow-y-auto no-scrollbar mb-10">
                        <div className="flex flex-col gap-6 mb-6 px-0.5">
                          <div className="flex gap-5 mt-6">
                            <PortfolioMatchCard client={client} type="current" />
                            <PortfolioMatchCard client={client} type="proposed" />
                          </div>
                          <span className="text-xxs">
                            <Trans id="funds-match-source-citation">Source: Calculated on MSCI Analytics</Trans>
                          </span>
                        </div>
                        {client && <FundsAllocation client={client} firm={firm!} tabRef={tabRef} parentRef={parentRef} />}
                      </div>
                    </>
                  ) : (
                    <div className="overflow-y-auto no-scrollbar pt-6 pb-10 px-1">
                      <SustainabilityMetrics client={client!} />
                    </div>
                  )}
                </>
              ) : (
                <PortfolioCompare client={client} />
              )}
            </>
          ) : (
            <LoadingError message={(clientLoadError as any)?.response?.data ?? clientLoadError?.message ?? "Could not load client"} />
          )}
        </>
      )}
    </div>
  )
}

export default PortfolioAnalyserPage
