import { AxiosError } from "axios"
import { useContext } from "react"
import { useQuery } from "react-query"
import axiosInstance from "../api/axiosInstance"
import { FirmContext } from "../contexts/FirmContext"
import { Advisor } from "../models/Advisor"
import { AuthContext, AuthStatus } from "../views/auth/AuthContext"

const useAdvisorSearch = () => {
  const { sessionInfo, authStatus } = useContext(AuthContext)
  const { firm } = useContext(FirmContext)

  const { isLoading, isSuccess, error, data } = useQuery<Advisor[], AxiosError>(
    ["myAdvisors", sessionInfo?.accessToken, firm],
    () =>
      axiosInstance
        .get<Advisor[]>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/user/advisors?firmId=${firm?._id}`)
        .then((res) => res.data),
    {
      enabled: authStatus === AuthStatus.SignedIn && !!sessionInfo?.accessToken,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  )

  return {
    isLoading,
    isSuccess,
    error,
    data
  }
}

export default useAdvisorSearch
