import { Trans } from "@lingui/macro"
import clsx from "clsx"
import { useContext, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import PercentageBar from "../../../components/PercentageBar/PercentageBar"
import { FirmContext } from "../../../contexts/FirmContext"
import { latest } from "../../../lib/clients"
import { ActionItem, ActionItemLabels } from "../../../models/ActionItem"
import { Client } from "../../../models/Client"
import { Household } from "../../../models/Household"
import PortfolioDetail from "../components/PortfolioDetail"
import checkMark from "../reports/RiskProfilePage/assets/checkmark.svg"
import Container from "./components/Container"
import Footer from "./components/Footer"

const PersonalizedAdvice = ({ page, client, household }: { page: number; client: Client; household: Household }) => {
  const [par] = useSearchParams()
  const nextMeetingMonths = par.get("nextMeetingMonths")
  const clientOrHousehold = client || household
  const { firm } = useContext(FirmContext)
  const [actionItems, setActionItems] = useState<ActionItem[]>([])
  const game = latest(clientOrHousehold, "risk")
  useEffect(() => setActionItems(game?.actionItems ?? []), [game?.actionItems])

  const currentPortfolio = useMemo(
    () => firm?.modelPortfolios?.find((modelPortfolio) => modelPortfolio.id === clientOrHousehold.currentPortfolio),
    [firm?.modelPortfolios, clientOrHousehold.currentPortfolio]
  )
  const currentPortfolioScore = game?.portfolioMappings.find((val) => val.portfolio.id === currentPortfolio?.id)

  return (
    <Container className="flex flex-col justify-between pt-10 relative">
      <div className="flex-auto mx-10 h-1/2">
        <h2 className="mb-2 text-main-600 text-sec tracking-wide uppercase">Session summary</h2>
        <h3 className="font-semibold text-main-600 text-h4 mb-8">You selected a {currentPortfolio?.name} investment</h3>
        <div className="flex-col bg-surface-100 p-6 justify-center items-center grow">
          <div
            className={clsx("flex items-start gap-x-21", {
              "pb-3": !game?.timeHorizon
            })}
          >
            <div className="w-auto">{currentPortfolio && <PortfolioDetail portfolio={currentPortfolio} forReport={true} />}</div>
            <div className="w-56 pb-3">
              <p className="font-semibold text-main-600 text-sec">Alignment score</p>
              <PercentageBar percentage={currentPortfolioScore?.riskComfort ?? 0} label={`Risk Comfort ${currentPortfolioScore?.riskComfort ?? 0}%`} />
            </div>
          </div>
          {game?.timeHorizon && (
            <p className="py-4 font-semibold text-sec text-main-600">
              Investment time-horizon <span className="font-normal">{game?.timeHorizon}</span>
            </p>
          )}
          <hr className="border-t-1 p-1 border-surface-400" />
          <h4 className="py-4 text-sec font-semibold text-main-600">Your reason(s) for selecting this investment</h4>
          <div className="flex flex-col text-sec font-normal gap-4 text-main-500">
            <ul className="mb-2 -mx-1">
              {actionItems
                ?.filter(
                  (item) =>
                    item.value &&
                    (item.key === "comfortable-with-investment-risk" ||
                      item.key === "increase-goal-achieving-likelihood" ||
                      item.key === "increase-goal-achieving-likelihood-of-investment-drawdown")
                )
                .map((item, i) => (
                  <li className="flex items-center justify-between text-main-500 hover:bg-interactive-100 p-1" key={`${item.key}-${i}`}>
                    <div className="flex">
                      <img alt="tick" className="mr-1 w-5 h-5" src={checkMark} />
                      {item.key === "freetext" ? item.value : ActionItemLabels[item.key]}
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="h-1/2 w-full mt-10 bg-surface-100 text-main-600 flex flex-col justify-between">
        <div className="flex px-10 mt-10 gap-10">
          <div className="flex flex-col w-full">
            <h3 className="gap-4 font-semibold text-h4">Next Steps</h3>
            <div className="text-sec font-semibold mt-4">
              <Trans id="reports-personalized-advice-page-actions-header">Your adviser will progress the following actions to support your experience.</Trans>
            </div>
          </div>
          <div className="flex flex-col w-full text-sec gap-4 mt-8 text-main-500">
            <ul className="mb-2 -mx-1">
              {nextMeetingMonths && (
                <li className="flex items-center justify-between text-main-500 hover:bg-interactive-100 p-1" key="next-meeting">
                  <div className="flex">
                    <img alt="tick" className="mr-1 w-5 h-5" src={checkMark} />
                    Meet again in {nextMeetingMonths} months
                  </div>
                </li>
              )}
              {actionItems
                ?.filter(
                  (item) =>
                    item.value &&
                    item.key !== "comfortable-with-investment-risk" &&
                    item.key !== "increase-goal-achieving-likelihood" &&
                    item.key !== "increase-goal-achieving-likelihood-of-investment-drawdown"
                )
                .map((item, i) => (
                  <li className="flex items-center justify-between text-main-500 text-sec font-normal hover:bg-interactive-100 p-1" key={`${item.key}-${i}`}>
                    <div className="flex items-center">
                      <img alt="tick" className="mr-1 w-5 h-5" src={checkMark} />
                      {item.key === "freetext" ? item.value : ActionItemLabels[item.key]}
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className="relative mx-10">
          <Footer pageNum={page} client={client} household={household} firm={firm} />
        </div>
      </div>
    </Container>
  )
}

export default PersonalizedAdvice
