import clsx from "clsx"
import { ReactNode, useEffect, useRef } from "react"

const Dropdown = ({
  alignVertical = "bottom",
  children,
  trigger,
  overlayClassName,
  align = "right",
  handleClose
}: {
  alignVertical?: "bottom" | "top"
  children: ReactNode
  className?: string
  trigger: React.RefObject<HTMLElement>
  overlayClassName?: string
  align?: "left" | "right"
  handleClose: () => void
}) => {
  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | FocusEvent) => {
      if (container.current && !container.current.contains(event.target as Node) && trigger.current && !trigger.current.contains(event.target as Node)) {
        event.preventDefault()
        event.stopPropagation()
        handleClose()
      }
    }
    const keyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        e.preventDefault()
        e.stopPropagation()
        handleClose()
      }
    }
    window.addEventListener("mousedown", handleClickOutside)
    window.addEventListener("focusin", handleClickOutside)
    document.addEventListener("keydown", keyDown)
    return () => {
      window.removeEventListener("mousedown", handleClickOutside)
      window.removeEventListener("focusin", handleClickOutside)
      document.removeEventListener("keydown", keyDown)
    }
  }, [handleClose, trigger])
  return (
    <div
      className={clsx(
        "absolute left-0 z-10 shadow bg-white transition-[height] duration-1000 delay-100 ease-in-out transform",
        {
          "left-auto right-0": align === "left",
          "top-full": alignVertical === "bottom",
          "top-0 -translate-y-full": alignVertical === "top"
        },
        overlayClassName
      )}
      ref={container}
    >
      {children}
    </div>
  )
}

export default Dropdown
