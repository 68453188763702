import { Outlet, Route, Routes } from "react-router"
import RMJGoalExplorerPage from "./goalExplorer/RMJGoalExplorerPage"
import ChooseGoalPage from "../advisor/ChooseGoalPage"

const RMJourney = () => (
  <div className="relative pg-ctr w-full h-full">
    <Outlet />
    <Routes>
      <Route path="set-goal" element={<ChooseGoalPage />} />
      <Route path="goal" element={<RMJGoalExplorerPage />} />
    </Routes>
  </div>
)

export default RMJourney
