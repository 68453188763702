import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { AppContext } from "./AppContext"

export type Tour = "createClients" | "gameComplete" | "newClient"

export type CreateClientsTourStep = "Import clients" | "Create clients"
export type NewClientTourStep = "Send invite"
export type GameCompleteTourStep = "Preview report" | "Present results" | "Re-profile"

type TourController = {
  tourSteps: {
    createClients: CreateClientsTourStep[]
    newClient: NewClientTourStep[]
    gameComplete: GameCompleteTourStep[]
  }
  step: {
    createClients?: CreateClientsTourStep | null
    newClient?: NewClientTourStep | null
    gameComplete?: GameCompleteTourStep | null
  }
  progress: {
    createClients: number
    newClient: number
    gameComplete: number
  }
  completeStep: (tours: { createClients?: CreateClientsTourStep; newClient?: NewClientTourStep; gameComplete?: GameCompleteTourStep }) => void
  isLastStep: (tour: Tour) => boolean
}

const defaults: TourController = {
  tourSteps: {
    createClients: [],
    newClient: [],
    gameComplete: []
  },
  step: {},
  progress: { createClients: 0, newClient: 0, gameComplete: 0 },
  completeStep: () => {},
  isLastStep: () => false
}

export const TourControllerContext = createContext<TourController>(defaults)

const TourControllerProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { userProfile, updateUserProfile } = useContext(AppContext)

  const tourSteps = useMemo<{
    createClients: CreateClientsTourStep[]
    newClient: NewClientTourStep[]
    gameComplete: GameCompleteTourStep[]
  }>(
    () => ({
      createClients: ["Import clients", "Create clients"],
      newClient: ["Send invite"],
      gameComplete: userProfile?.isAssociate ? ["Preview report", "Re-profile"] : ["Present results", "Preview report", "Re-profile"]
    }),
    [userProfile?.isAssociate]
  )

  const [step, setStep] = useState(defaults.step)
  const shouldHideImportClientsTour = userProfile?.createdAt && new Date(userProfile.createdAt) < new Date("2024-10-10")

  useEffect(() => {
    setStep((prevStep) => ({
      createClients: prevStep.createClients ?? (userProfile?.tourState?.createClients || shouldHideImportClientsTour ? null : tourSteps.createClients[0]),
      newClient: prevStep.newClient ?? (userProfile?.tourState?.newClient ? null : tourSteps.newClient[0]),
      gameComplete: prevStep.gameComplete ?? (userProfile?.tourState?.gameComplete ? null : tourSteps.gameComplete[0])
    }))
  }, [userProfile, tourSteps, shouldHideImportClientsTour])

  // How to use this controller:
  // {step.gameComplete === "Present results" &&
  //    <button onClick={() => completeStep2({ gameComplete: "Present results" })>
  //      {progress.gameComplete} of {tourSteps.gameComplete.length}
  //    </button>
  // }
  const completeStep = useCallback(
    (tours: { createClients?: CreateClientsTourStep; newClient?: NewClientTourStep; gameComplete?: GameCompleteTourStep }) => {
      setStep((prev) => {
        const tour = Object.keys(tours).reduce((a, c) => {
          const thisStepValue = tours[c as keyof { createClients?: CreateClientsTourStep; newClient?: NewClientTourStep; gameComplete?: GameCompleteTourStep }]
          const stepsInTour: string[] =
            tourSteps[c as keyof { createClients?: CreateClientsTourStep; newClient?: NewClientTourStep; gameComplete?: GameCompleteTourStep }]
          const currentStepIndex = thisStepValue !== undefined ? stepsInTour.indexOf(thisStepValue) : -1
          const nextStepIndex = currentStepIndex < stepsInTour.length - 1 ? currentStepIndex + 1 : -1
          return {
            ...a,
            [c]: nextStepIndex >= 0 ? stepsInTour[nextStepIndex] : null
          }
        }, {})
        return {
          ...prev,
          ...tour
        }
      })
      const { createClients, newClient, gameComplete } = {
        createClients: step.createClients ? tourSteps.createClients.indexOf(step.createClients) : tourSteps.createClients.length,
        newClient: step.newClient ? tourSteps.newClient.indexOf(step.newClient) : tourSteps.newClient.length,
        gameComplete: step.gameComplete ? tourSteps.gameComplete.indexOf(step.gameComplete) : tourSteps.gameComplete.length
      }

      const needSaveCreateClients = !userProfile?.tourState?.createClients && (createClients ?? 0) + 1 >= tourSteps.createClients.length
      const needSaveNewClient = !userProfile?.tourState?.newClient && (newClient ?? 0) + 1 >= tourSteps.newClient.length
      const needSaveGameComplete = (gameComplete ?? 0) + 1 >= tourSteps.gameComplete.length

      if (needSaveCreateClients || needSaveNewClient || needSaveGameComplete) {
        updateUserProfile({
          tourState: {
            ...userProfile?.tourState,
            createClients: userProfile?.tourState?.createClients ?? (needSaveCreateClients ? new Date() : null),
            newClient: userProfile?.tourState?.newClient ?? (needSaveNewClient ? new Date() : null),
            gameComplete: userProfile?.tourState?.gameComplete ?? (needSaveGameComplete ? new Date() : null)
          }
        })
      }
    },
    [step, tourSteps, updateUserProfile, userProfile]
  )

  const progress = useMemo(
    () => ({
      createClients: step.createClients ? tourSteps.createClients.indexOf(step.createClients) : tourSteps.createClients.length,
      newClient: step.newClient ? tourSteps.newClient.indexOf(step.newClient) : tourSteps.newClient.length,
      gameComplete: step.gameComplete ? tourSteps.gameComplete.indexOf(step.gameComplete) : tourSteps.gameComplete.length
    }),
    [step, tourSteps]
  )

  const isLastStep = useCallback<(tour: Tour) => boolean>(
    (tour) => {
      return step[tour] !== undefined && step[tour] !== null && (tourSteps[tour] as string[]).indexOf(step[tour] as string) === tourSteps[tour].length - 1
    },
    [step, tourSteps]
  )

  return (
    <TourControllerContext.Provider
      value={{
        tourSteps,
        progress,
        step,
        completeStep,
        isLastStep
      }}
    >
      {children}
    </TourControllerContext.Provider>
  )
}

export default TourControllerProvider
