import React from 'react'
import { Outlet, Route, Routes } from "react-router"
import BillingCodes from "./BillingCodes"
import { Link, NavLink } from "react-router-dom"
import clsx from "clsx"
import CreateBillingCode from "./CreateBillingCode"

const BillingSetup = () => {
  const linkStyle = ({ isActive }: { isActive: boolean }) => clsx("px-4 border-b-2 transition-all hover:bg-interactive-100 py-2", isActive ? "border-interactive-600" : "border-white hover:border-interactive-200")

  return (
    <div className="pg-ctr pg-ctr-py-lg w-full h-full flex flex-col gap-y-4" >
      <h1 className="px-10 text-h1">Billing management</h1>
      <nav className="w-full -ml-4 px-10 flex text-h5 text-interactive-600">
        <NavLink to="codes" end className={linkStyle}>Codes</NavLink>
        <NavLink to="new" className={linkStyle}>New code</NavLink>
        {/* <NavLink to="archive" className={_ => clsx(linkStyle(_), "ml-auto text-alt-critical")}>Help</NavLink> */}
      </nav>
      <div className="w-full h-full overflow-y-auto">
        <Outlet />
        <Routes>
          <Route path="codes" element={<BillingCodes />}/>
          <Route path="new" element={<CreateBillingCode />}/>
          <Route index element={<div className="full-flex-content-center"><Link to="codes" className="btn btn-large btn-secondary">Manage codes</Link></div>}/>
        </Routes>
      </div>
    </div>
  )
}

export default BillingSetup