import clsx from "clsx"
import { useEffect, useMemo, useState } from "react"
import { ClientStatus } from "../../../models/Client"

import { AnimatePresence, motion } from "framer-motion"
import useStatusCounts from "../../../hooks/useStatusCounts"
import activityPendingIcon from "../assets/images/activity-pending.svg"
import newClientIcon from "../assets/images/new-clients.svg"
import newResultsIcon from "../assets/images/new-results.svg"
import upForReviewIcon from "../assets/images/up-for-review.svg"

export type StatusCounterProps = {
  currentCountFilter?: ClientStatus,
  handleCountFilterClick: (status: ClientStatus) => void,
}

type StatusCount = {
  name: ClientStatus,
  icon: string,
  tooltip: string,
  count?: number
}

const statusCounts: StatusCount[] = [
  { name: "New client", icon: newClientIcon, tooltip: "Invite these clients to complete the risk activity" },
  { name: "Activity pending", icon: activityPendingIcon, tooltip: "You may want to send a reminder to these clients to complete the risk activity" },
  { name: "New results available", icon: newResultsIcon, tooltip: "Schedule a meeting with these clients to walk through their results" },
  { name: "Up for review", icon: upForReviewIcon, tooltip: "Invite these clients to complete a new risk activity" }
]

export default function StatusCounters({ currentCountFilter, handleCountFilterClick }: StatusCounterProps): React.ReactNode {
  
  const [activeTooltip, setActiveTooltip] = useState<number>()
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const [delayElapsed, setDelayElapsed] = useState<boolean>(false)

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null
    if (hoveredIndex !== null) {
      timeout = setTimeout(() => {
        setDelayElapsed(true)
        setActiveTooltip(hoveredIndex)
      }, 300)
    } else {
      setDelayElapsed(false)
      setActiveTooltip(undefined)
    }
    
    return () => { timeout && clearTimeout(timeout) }
  }, [hoveredIndex])

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index)
  }

  const handleMouseLeave = () => {
    setHoveredIndex(null)
    setDelayElapsed(false)
  }

  const { data, isSuccess } = useStatusCounts()

  const countFilterStatuses = useMemo(() => {
    const countByStatus = data?.reduce((acc, s) => ({ ...acc, [s.status]: s.count }), {})
    return statusCounts.map((status) => ({ ...status, count: countByStatus?.[status.name as keyof {}] }))
  }, [data])

  return <div className="w-full flex gap-6 justify-between">
    {countFilterStatuses?.map((status, index) => (
      <div className="w-full relative flex-grow" key={status.name}>
        <button
          onClick={() => handleCountFilterClick(status.name)}
          className={clsx("w-full flex justify-between p-3 border border-interactive-200 hover:bg-interactive-100 active:bg-interactive-200", {
            "border-interactive-600 bg-interactive-200": currentCountFilter === status.name
          })}
          aria-describedby={`${status.name.toLowerCase().replace(/\s+/g, "-")}-tooltip`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          <span className="flex flex-col text-left">
            <span className="text-sm">{status.name}</span>
            {status.count !== undefined || isSuccess ? <span className="font-semibold">{status.count ?? 0}</span> : <div className="mt-1 w-10 h-5 bg-surface-activity animate-pulse" />}
          </span>
          <img src={status.icon} alt="" aria-hidden />
        </button>
        <p id={`${status.name.toLowerCase().replace(/\s+/g, "-")}-tooltip`} className="sr-only">
          {status.tooltip}
        </p>
        <AnimatePresence>
          {activeTooltip === index && delayElapsed && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="absolute mr-0 right-0 top-0 z-10 w-56 bg-white border-0.5 shadow-100 border-neutral-300 drop-shadow-md -translate-y-full -mt-2 p-1.5"
            >
              <p className="text-sm">{status.tooltip}</p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    ))}
  </div>
}
