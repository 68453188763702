import clsx from "clsx"
import { formatISO } from "date-fns"
import { AnimatePresence } from "framer-motion"
import { useCallback, useContext, useMemo, useState } from "react"
import { useMutation } from "react-query"
import { Link } from "react-router-dom"
import axiosInstance from "../../../api/axiosInstance"
import { update } from "../../../api/clients"
import alertIcon from "../../../assets/icons/info-alert.svg"
import refreshIcon from "../../../assets/icons/refresh.svg"
import { ClientHouseholdCacheContext } from "../../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../../contexts/FirmContext"
import useTrackViewEvent from "../../../hooks/useTrackViewEvent"
import { latestGamesByType } from "../../../lib/clients"
import { formatDateLong } from "../../../lib/date"
import { Client, GameType } from "../../../models/Client"
import EFPReprofileModal from "../../../pages/clients/components/EFPReprofileModal"
import SendInvitesModal from "../../../pages/clients/components/SendInvitesModal"
import { AuthContext } from "../../../views/auth/AuthContext"
import AlertPopover, { AlertPopoverOptions } from "../../AlertPopover/AlertPopover"
import DatePicker from "../../DatePicker/DatePicker"
import Modal from "../../Modal/Modal"
import ClientEFPStatusRow from "../ClientEFPStatusRow"
import ClientStatusAlert, { StatusAlert } from "../ClientStatusRow/ClientStatusAlert"
import Loading from "../Loading/Loading"

const ClientEFPSection = ({ client, availableGames }: { client: Client; availableGames: GameType[] }) => {
  const { replace } = useContext(ClientHouseholdCacheContext)
  const trackViewEvent = useTrackViewEvent()
  const { firm } = useContext(FirmContext)
  const { sessionInfo } = useContext(AuthContext)
  const [infoAlert, setInfoAlert] = useState<StatusAlert | undefined>(undefined)
  const [sendInvitesModal, setSendInvitesModal] = useState<{ show: boolean; isReminder?: boolean }>()
  const [showReprofileModal, setShowReprofileModal] = useState(false)
  const nextActivityDueDate = useMemo(() => (client.nextActivityDue ? new Date(client.nextActivityDue) : undefined), [client.nextActivityDue])
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const { mutate: sendInvite, status: sendInviteUpdateState } = useMutation({
    mutationFn: ({ isReminder }: { isReminder: boolean }) =>
      axiosInstance.put(`${import.meta.env.VITE_APP_API_BASE || ""}/api/client/${client._id.toString()}/efp/invite`, {}).then((res) => res.data as Client),
    onSuccess: (updated, { isReminder }) => {
      replace(updated)
      setInfoAlert({
        message: "Email has been sent",
        onClose: () => setInfoAlert(undefined),
        type: "success",
        location: isReminder ? "send_reminder" : "send_invite"
      })
    },
    onError: (err) => {
      console.error("Error sending invite", err)
    }
  })

  const { mutate: createEFP, status: createEFPUpdateState } = useMutation({
    mutationFn: () => axiosInstance.post(`${import.meta.env.VITE_APP_API_BASE || ""}/api/client/${client._id}/efp`, {}).then((res) => res.data as Client),
    onSuccess: (updated) => {
      replace(updated)
      sendInvite({ isReminder: false })
    },
    onError: (err) => {
      console.error("Error creating efp", err)
    }
  })

  const onSendInvite = ({ isReminder }: { isReminder: boolean }) => {
    setInfoAlert({
      message: "Sending...",
      onClose: () => setInfoAlert(undefined),
      type: "activity",
      location: isReminder ? "send_reminder" : "send_invite"
    })
    sendInvite({ isReminder })
  }

  const onConfirmSendInvites = () => {
    setInfoAlert({
      message: "Sending invite...",
      timeout: false,
      type: "activity",
      location: "send_invite"
    })
    createEFP()
  }

  const alertPopoverOptions: AlertPopoverOptions | undefined = useMemo(() => {
    if (!infoAlert) {
      return
    }
    return {
      content: <ClientStatusAlert type={infoAlert.type} message={infoAlert.message} />,
      onClose: infoAlert.onClose,
      timeoutMS: infoAlert.timeout === false ? null : 5000,
      location: infoAlert.location
    }
  }, [infoAlert])

  const gameByType = latestGamesByType(client, firm)

  const anyGamesNotCreated = useMemo(() => availableGames.some((game) => !gameByType[game]), [availableGames, gameByType])

  const anyNotPlayed = useMemo(() => availableGames.some((game) => gameByType[game] && !gameByType[game]?.played), [availableGames, gameByType])

  const anyGamePlayed = useMemo(
    () =>
      availableGames.some(
        (game) => gameByType[game] && gameByType[game]?.played && !gameByType[game]?.summaryDate && gameByType[game].status !== "Up for review"
      ),
    [availableGames, gameByType]
  )

  const allIMDone = useMemo(
    () => availableGames.every((game) => gameByType[game] && gameByType[game]?.played && gameByType[game]?.summaryDate),
    [availableGames, gameByType]
  )

  const anyNeedsToBeReprofiled = useMemo(
    () => availableGames.some((game) => gameByType[game] && gameByType[game]?.status === "Up for review"),
    [availableGames, gameByType]
  )

  const onEditNextActivityDueDate = useCallback(
    (nextActivityDue: string) => {
      setIsTransitioning(true)
      update(sessionInfo!, client._id!, client, { nextActivityDue })
        .then((updatedClient) => {
          replace(updatedClient)
          setIsTransitioning(false)
          setInfoAlert({
            location: "nextActivityDue",
            message: "Date has been successfully updated",
            onClose: () => setInfoAlert(undefined),
            type: "success"
          })
        })
        .catch((error) => {
          console.error("Error setting the activity due date", error)
          setErrorMessage("Error setting the activity due date")
          setIsTransitioning(false)
        })
    },
    [sessionInfo, replace, client]
  )

  return (
    <>
      <div className="flex flex-col gap-x-3 gap-y-10">
        <div className="flex justify-between items-center">
          <div className="flex flex-col col-start-1 col-span-12 px-19">
            <h1 className="text-h2 text-main-600 font-semibold ">{`${client.firstName} ${client.lastName}`}</h1>
            {client.nextActivityDue && nextActivityDueDate && (
              <div className="flex text-sm">
                <span>Re-profile due on</span>
                <DatePicker
                  id="nextActivityDue"
                  yearsInPast={0}
                  isInline={true}
                  customInput={<span className="text-sm text-link-600 hover:text-link-500 underline ml-1">{formatDateLong(client.nextActivityDue)}</span>}
                  onChange={(value: Date | null) => onEditNextActivityDueDate(formatISO(value!.setHours(0, 0, 0, 0)))}
                  value={nextActivityDueDate}
                  popperPlacement="bottom-start"
                />
                <AlertPopover options={alertPopoverOptions} show={!!infoAlert && infoAlert.location === "nextActivityDue"}>
                  {isTransitioning && (
                    <div className="h-4 mx-2">
                      <Loading />
                    </div>
                  )}
                </AlertPopover>
              </div>
            )}
          </div>
          {(sendInviteUpdateState === "error" || createEFPUpdateState === "error") && <div className="text-error">Error sending invite to the client</div>}
          <div className="flex gap-x-5 pr-19">
            {client.status === "New results available" && (
              <Link
                className="btn btn-medium btn-primary !w-44 text-center animate-highlight-ping"
                to={`/clients/${client._id}/rm/overview`}
                target="_blank"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  trackViewEvent({ action: "click", category: "present_results", label: client._id })
                  window.open(`/clients/${client._id}/rm/overview`)
                }}
              >
                Present results
              </Link>
            )}
            {anyGamesNotCreated || anyNeedsToBeReprofiled ? (
              <AlertPopover options={alertPopoverOptions} show={!!infoAlert}>
                <button
                  className={clsx("btn btn-medium w-44", client.status === "New results available" ? "btn-secondary" : "btn-primary")}
                  onClick={() => setSendInvitesModal({ show: true })}
                >
                  Send invite
                </button>
              </AlertPopover>
            ) : anyNotPlayed ? (
              <AlertPopover options={alertPopoverOptions} show={infoAlert?.location === "send_reminder"}>
                <button
                  className={clsx("btn btn-medium w-44", client.status === "New results available" ? "btn-secondary" : "btn-primary")}
                  onClick={() => setSendInvitesModal({ show: true, isReminder: true })}
                >
                  Send reminder
                </button>
              </AlertPopover>
            ) : (
              <></>
            )}
            {(anyGamePlayed || allIMDone) && (
              <button className="btn btn-text btn-text-md flex items-center justify-center align-middle gap-2" onClick={() => setShowReprofileModal(true)}>
                <img src={refreshIcon} alt="" aria-hidden />
                Re-profile
              </button>
            )}
          </div>
        </div>
        <ClientEFPStatusRow client={client} availableGames={availableGames} />

        <AnimatePresence>
          {sendInvitesModal?.show && (
            <Modal className="modal-medium" contentClassName="!px-10 w-full" handleClose={() => setSendInvitesModal({ show: false })}>
              <SendInvitesModal
                onConfirm={() => {
                  if (sendInvitesModal.isReminder) {
                    onSendInvite({ isReminder: true })
                  } else {
                    onConfirmSendInvites()
                  }
                }}
                onClose={() => setSendInvitesModal({ show: false })}
                client={client}
              />
            </Modal>
          )}
          {showReprofileModal && (
            <Modal contentClassName="!px-10 w-full overflow-y-auto" handleClose={() => setShowReprofileModal(false)}>
              <EFPReprofileModal onClose={() => setShowReprofileModal(false)} client={client} />
            </Modal>
          )}
        </AnimatePresence>
      </div>

      <div role="alert">
        {errorMessage && (
          <div className="flex items-center mt-1">
            <img alt="" aria-hidden="true" className="error-icon mr-1" src={alertIcon} />
            <p className="text-input-error-text text-sm text-error font-normal" role="alert">
              {errorMessage}
            </p>
          </div>
        )}
      </div>
    </>
  )
}

export default ClientEFPSection
