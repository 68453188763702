import { useContext, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import axiosInstance from "../../api/axiosInstance"
import { AuthContext } from "../../views/auth/AuthContext"

const ReportRedirect = () => {
  const { sessionInfo } = useContext(AuthContext)
  const [params] = useSearchParams()
  const clientId = params.get("clientId")
  const householdId = params.get("householdId")
  const fileName = params.get("fileName")
  const [message, setMessage] = useState("Redirecting...")

  useEffect(() => {
    if ((clientId || householdId) && fileName) {
      axiosInstance
        .get(`${import.meta.env.VITE_APP_API_BASE || ""}/api/advisor/pdf-report?${params}`)
        .then((res) => res.data)
        .then((reportUrl) => {
          window.location.href = reportUrl
        })
        .catch((err) => {
          console.log(err)
          setMessage("Error opening the report url")
        })
    } else {
      setMessage("Invalid request")
    }
  }, [clientId, fileName, householdId, params, sessionInfo])
  return <div>{message}</div>
}

export default ReportRedirect
