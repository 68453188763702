import { Trans } from "@lingui/macro"
import { useTheme } from "../../../contexts/ThemeContext"
import { latest } from "../../../lib/clients"
import { tt } from "../../../lib/translations"
import { Client } from "../../../models/Client"
import DecisionsTable from "../../rmjourney/yourDecisions/components/DecisionsTable/DecisionsTable"
import Page from "./Page"
import { comfortMatchPortfolio } from "./SelectedPortfolio"

const InvestorPersonality = ({ page, client }: { page: number; client: Client }) => {
  const theme = useTheme()
  const game = latest(client, "risk")
  const portfolioMappings = game?.portfolioMappings
  const comfortMatch = comfortMatchPortfolio(portfolioMappings ?? [])
  return (
    <Page
      className="report-investor-personality"
      page={page}
      title={tt({ id: "report-header-title" })}
      footer={
        <p className="mx-9 text-sm font-normal mt-15 text-main-500">
          <Trans id="report-investor-personality-footer-copyright-text">
            Copyright © {new Date().getFullYear()} Capital Preferences, Ltd. All rights reserved.
          </Trans>
        </p>
      }
    >
      <div className="mx-9">
        <h2 className="report-investor-personality-title font-semibold text-h2 mb-10 mt-15">
          <Trans id="report-investor-personality-title">Your investor personality</Trans>
        </h2>
        <div className="bg-main-400 text-white text-center rounded-3 pt-6 pb-8 w-5/6">
          {theme.reports?.investorPersonality?.portfolioThemes.map(
            ({ id, heroImage }) => id === comfortMatch?.portfolio.translationKey && <img key={id} className="mx-auto" src={heroImage} alt="" aria-hidden />
          )}
          <h3 className="report-investor-personality-portfolio-title uppercase text-display mt-3 mb-6">
            {tt({ id: `report-persona-title-${comfortMatch?.portfolio.translationKey}` })}
          </h3>
          <p className="font-medium px-14">{tt({ id: `report-persona-${comfortMatch?.portfolio.translationKey}-content-2` })}</p>
        </div>
        <h2 className="report-investor-personality-decision-consistency-title font-semibold text-h2 mt-20 mb-10 leading-7">
          {client.games[0].risk?.results && client.games[0].risk.results.R.cceiScore > 75 ? (
            <Trans id="report-investor-personality-high-decision-consistency-title">You have a good understanding of risk and reward</Trans>
          ) : (
            <Trans id="report-investor-personality-low-decision-consistency-title">Here are the decisions you made in the activity</Trans>
          )}
        </h2>
        <div className="w-5/6">
          <DecisionsTable forReport={true} clients={[client]} showNamesInFooter={false} />
        </div>
      </div>
    </Page>
  )
}

export default InvestorPersonality
