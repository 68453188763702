import { ReactNode } from "react"

export interface Props {
  children: ReactNode
}

const SidebarMenu = ({ children }: Props) => {
  return <div className="sidebar-menu sticky bottom-0 w-full flex flex-col bg-interactive-600 border-t border-interactive-500 gap-1.5 p-5">{children}</div>
}

export default SidebarMenu
