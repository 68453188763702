import { useEffect, useState } from "react";

const useAppVersionCheck = () => {
  const [initialHash, setInitialHash] = useState('')
  const [isCheckingEnabled, setIsCheckingEnabled] = useState(true)

  const fetchAndHashIndexHtml = async () => {
    try {
      const response = await fetch('/index.html', { cache: 'reload' })
      const text = await response.text()
      const encoder = new TextEncoder()
      const data = encoder.encode(text)
      const hashBuffer = await crypto.subtle.digest('SHA-256', data)
      const hashArray = Array.from(new Uint8Array(hashBuffer))
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
      return hashHex
    } catch (error) {
      console.error('Failed to fetch or hash index.html:', error)
      setIsCheckingEnabled(false)
      return null
    }
  }

  useEffect(() => {
    // Fetch and set the initial hash
    fetchAndHashIndexHtml().then(hash => {
      if (hash) {
        setInitialHash(hash)
      } else {
        console.error('Unable to set initial hash for app version check.')
        setIsCheckingEnabled(false)
      }
    });
  }, [])

  useEffect(() => {
    if (isCheckingEnabled) {
      const intervalId = setInterval(async () => {
        const currentHash = await fetchAndHashIndexHtml()
        if (currentHash && initialHash && currentHash !== initialHash) {
          alert('A new version of the app is available. Please refresh the page.');
          setIsCheckingEnabled(false)
        }
      }, 3600*1000) // check every hour

      return () => clearInterval(intervalId)
    }
  }, [isCheckingEnabled, initialHash])

  return null
}

export default useAppVersionCheck