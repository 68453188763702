import { ReactNode } from "react"
import "./Popover.css"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Close from "../../../../components/Modal/assets/close.svg?react"

const Popover = ({
  children,
  position,
  content,
  onClose,
  active = false,
  style
}: {
  position: "top" | "right" | "bottom" | "left"
  content?: ReactNode
  children?: ReactNode
  onClose?: () => void
  active?: boolean
  style?: {
    [x: string]: string
  }
}) => {
  return (
    <>
      {active ? (
        <div className="relative flex">
          {children}
          <div
            className={`popover absolute w-[300px] rounded-1 left-1/2 -translate-x-1/2 px-5 pt-8 pb-5 text-main-500 bg-highlight-200 z-40 ${
              position || "top"
            }`}
            style={style}
          >
            <div className="absolute right-0 top-0 py-2 px-2">
              <div className="cursor-pointer" tabIndex={1}>
                <Close className="h-6 w-6" onClick={onClose} />
              </div>
            </div>
            {content}
            <div className="arrow">
              <div className="arrow-back" />
              <div className="arrow-front" />
            </div>
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default Popover
