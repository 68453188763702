import { useContext, useEffect, useState } from "react"
import { AppContext } from "../contexts/AppContext"
import { Advisor } from "../models/Advisor"

const useMyAdvisor = (advisors?: Advisor[]) => {
  const { userProfile } = useContext(AppContext)
  const [advisor, setAdvisor] = useState<Advisor>()

  useEffect(() => {
    if (advisors?.length === 1) {
      setAdvisor(advisors[0])
    } else {
      // pre-select the logged in advisor
      const loggedInAdvisor = advisors?.find((adv) => adv.email === userProfile?.email)
      setAdvisor(loggedInAdvisor)
    }
  }, [advisors, userProfile?.email])

  const selectAdvisor = (advisor: Advisor) => {
    setAdvisor(advisor)
  }
  return { advisor, selectAdvisor }
}

export default useMyAdvisor
