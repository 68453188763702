const colour: { [key: number]: string[] } = {
  3: ["#A7CDD2", "#90CBCE", "#1C3236"],
  4: ["#A7CDD2", "#90CBCE", "#427880", "#1C3236"],
  5: ["#A7CDD2", "#90CBCE", "#5DA1AC", "#427880", "#1C3236"],
  6: ["#C5E1E4", "#90CBCE", "#5DA1AC", "#427880", "#31585E", "#1C3236"],
  7: ["#C5E1E4", "#A7CDD2", "#90CBCE", "#5DA1AC", "#427880", "#31585E", "#1C3236"],
  8: ["#CEF1F4", "#ACDCDF", "#69C8CD", "#28A4AC", "#008091", "#006171", "#0D4355", "#1C3236"]
}

export const pickPortfolioColours = (noOfPortfolios: number) => {
  return colour[noOfPortfolios]
}