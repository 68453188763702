import { Trans } from "@lingui/macro"
import { clsx } from "clsx"
import React, { useCallback, useMemo } from "react"
import { ASSET_TYPES } from "../../../../../config/assetClasses"
import { useTheme } from "../../../../../contexts/ThemeContext"
import { Client } from "../../../../../models/Client"
import { DoughnutChart } from "../../../../rmjourney/components/DoughnutChart/DoughnutChart"
import pieIcon from "./PortfolioCompare/assets/pie.svg"
import PortfolioMatchCardMenu from "./PortfolioMatchCardMenu"
import { customDateFormat } from "../../../../../lib/date"
import { latest } from "../../../../../lib/clients"

interface CardProps {
  clickable?: boolean
  client?: Client
  type: "current" | "proposed" | "disabled"
  forReport?: boolean
}

const PortfolioMatchCard: React.FC<CardProps> = ({ clickable = false, client, type, forReport = false }) => {
  const game = latest(client!, "esg")
  const score = useMemo(() => {
    return type === "current"
      ? client?.portfolio?.current?.smScore
      : type === "proposed"
      ? client?.portfolio?.proposed?.smScore ?? game?.esg.results?.portfolioMatch?.smScore
      : 0
  }, [game?.esg.results?.portfolioMatch?.smScore, client?.portfolio, type])

  const theme = useTheme()
  const assetClasses = theme.colors.assetClassConfigurations

  const riskIndicator = useCallback((allocation: number) => {
    return allocation < 10
      ? 1
      : allocation >= 10 && allocation < 25
      ? 2
      : allocation >= 25 && allocation < 40
      ? 3
      : allocation >= 40 && allocation < 60
      ? 4
      : allocation >= 60 && allocation < 75
      ? 5
      : allocation >= 75 && allocation < 90
      ? 6
      : 7
  }, [])
  const currentPortfolioRiskIndicator = useMemo(() => riskIndicator(client?.portfolio?.current?.growthAllocationSum ?? 0), [client?.portfolio, riskIndicator])
  const proposedPortfolioRiskIndicator = useMemo(
    () => riskIndicator(game?.esg.results?.portfolioMatch?.growthAllocationSum ?? 0),
    [game?.esg.results?.portfolioMatch?.growthAllocationSum, riskIndicator]
  )

  const currentPortfolioAssetTotal = useMemo(() => {
    return [
      Math.round(client?.portfolio?.current?.growthAllocationSum ?? 0),
      Math.round(client?.portfolio?.current?.incomeAllocationSum ?? 0),
      Math.round(client?.portfolio?.current?.cashAllocationSum ?? 0)
    ]
  }, [client?.portfolio])

  const currentPortfolioMissingAssetTotal = useMemo(
    () => 100 - currentPortfolioAssetTotal.reduce((acc, current) => acc + current, 0),
    [currentPortfolioAssetTotal]
  )

  const proposedPortfolioAssetTotal = useMemo(() => {
    return [
      Math.round(client?.portfolio?.proposed?.growthAllocationSum ?? game?.esg.results?.portfolioMatch?.growthAllocationSum ?? 0),
      Math.round(client?.portfolio?.proposed?.incomeAllocationSum ?? game?.esg.results?.portfolioMatch?.incomeAllocationSum ?? 0),
      Math.round(client?.portfolio?.proposed?.cashAllocationSum ?? game?.esg.results?.portfolioMatch?.cashAllocationSum ?? 0)
    ]
  }, [
    game?.esg.results?.portfolioMatch?.cashAllocationSum,
    game?.esg.results?.portfolioMatch?.growthAllocationSum,
    game?.esg.results?.portfolioMatch?.incomeAllocationSum,
    client?.portfolio?.proposed?.cashAllocationSum,
    client?.portfolio?.proposed?.growthAllocationSum,
    client?.portfolio?.proposed?.incomeAllocationSum
  ])

  const proposedPortfolioMissingAssetTotal = useMemo(
    () => 100 - proposedPortfolioAssetTotal.reduce((acc, current) => acc + current, 0),
    [proposedPortfolioAssetTotal]
  )

  return (
    <div
      className={clsx("relative border-t-2 rounded-2 shadow w-full lg-max:w-2/3 xl-max:w-1/2 py-5", {
        "border-neutral-400 text-surface-400": type === "disabled",
        "border-neutral-400": type === "current",
        "border-data-olive-500": type === "proposed",
        "px-3": forReport,
        "px-6": !forReport
      })}
    >
      {type !== "disabled" && clickable && (
        <a href={`${client?._id}/portfolio-analyser`} aria-label="Go to Portfolio analyser" className="absolute top-0 left-0 w-full h-full" />
      )}
      {!forReport && type === "current" && (
        <div className="absolute top-0 right-0 m-2">
          <PortfolioMatchCardMenu type={type} />
        </div>
      )}
      <div className="mb-3.5">
        <p className={clsx("text-sec", { "font-bold": type === "proposed" })}>
          {type === "disabled" || type === "current" ? "Current portfolio" : "Proposed portfolio"}
        </p>
      </div>
      <div className="flex items-center gap-3 justify-between">
        <div className="flex gap-2 items-center text-sm">
          {type === "disabled" ? (
            <>
              <img className="w-15" src={pieIcon} alt="Greyed out portfolio pie chart" />
              <ul>
                <li className="flex gap-1 items-center">
                  <span className="inline-block w-1.5 h-1.5 rounded-full bg-neutral-100" />
                  Growth
                </li>
                <li className="flex gap-1 items-center">
                  <span className="inline-block w-1.5 h-1.5 rounded-full bg-neutral-100" />
                  Income
                </li>
                <li className="flex gap-1 items-center">
                  <span className="inline-block w-1.5 h-1.5 rounded-full bg-neutral-100" />
                  Cash
                </li>
              </ul>
            </>
          ) : type === "current" ? (
            <div className={clsx("flex items-center", forReport ? "gap-2" : "gap-3.5")}>
              <div className="w-15">
                <DoughnutChart data={currentPortfolioAssetTotal} colors={ASSET_TYPES.map((assetClass) => assetClasses[assetClass].color)} />
              </div>
              <ul>
                {ASSET_TYPES.map((assetClass, i) => (
                  <li key={assetClass} className="flex gap-1 items-center">
                    <span className="inline-block w-1.5 h-1.5 rounded-full" style={{ backgroundColor: assetClasses[assetClass].color }} />
                    {assetClasses[assetClass].label}
                    <span>{currentPortfolioAssetTotal[i]}%</span>
                  </li>
                ))}
                {currentPortfolioMissingAssetTotal > 0 && (
                  <li key="current_missing" className="flex gap-1 items-center">
                    <span className="w-1.5 h-1.5 rounded-full bg-neutral-400" />
                    <span>Undefined {currentPortfolioMissingAssetTotal}%</span>
                  </li>
                )}
              </ul>
            </div>
          ) : type === "proposed" ? (
            <div className={clsx("flex items-center", forReport ? "gap-2" : "gap-3.5")}>
              <div className="w-15">
                <DoughnutChart data={proposedPortfolioAssetTotal} colors={ASSET_TYPES.map((assetClass) => assetClasses[assetClass].color)} />
              </div>
              <ul>
                {ASSET_TYPES.map((assetClass, i) => (
                  <li key={assetClass} className="flex gap-1 items-center">
                    <span className="inline-block w-1.5 h-1.5 rounded-full" style={{ backgroundColor: assetClasses[assetClass].color }} />
                    {assetClasses[assetClass].label}
                    <span className="font-bold">{proposedPortfolioAssetTotal[i]}%</span>
                  </li>
                ))}
                {proposedPortfolioMissingAssetTotal > 0 && (
                  <li key="proposed_missing" className="flex gap-1 items-center">
                    <span className="w-1.5 h-1.5 rounded-full bg-neutral-400" />
                    <span>Undefined {proposedPortfolioMissingAssetTotal}%</span>
                  </li>
                )}
              </ul>
            </div>
          ) : null}
        </div>
        <div className="w-1/2">
          <div className="text-sec font-bold">
            <Trans id="portfolio-match-card-bar-title">Sustainability match</Trans>
            <div className="flex">
              <div className="portfolio-card-bar-container relative w-full h-2 mt-1 flex">
                <div className="portfolio-card-bar-bg absolute left-0 top-0 bg-surface-400 h-full w-full" />
                <div className="portfolio-card-bar-fill absolute left-0 top-0 h-full" style={{ width: `${score}%`, backgroundColor: "#849E42" }} />
              </div>
              <div className="w-5">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.8052 2.75781C11.8052 2.75781 10.735 3.76891 9.57756 4.17655C1.00643 7.19554 4.62124 13.0787 4.73917 13.0981C4.73917 13.0981 5.2198 12.283 5.86905 11.8181C9.98895 8.86876 10.8263 5.47389 10.8263 5.47389C10.8263 5.47389 9.90181 9.55901 6.23654 12.1009C5.42702 12.662 4.87887 14.0436 4.64684 15.4245C4.64684 15.4245 5.22067 15.1987 5.46743 15.1376C5.56365 14.5261 5.76483 13.9406 6.10495 13.4175C11.2276 14.0114 12.9054 9.99183 13.1245 8.59023C13.6419 5.27993 11.8052 2.75781 11.8052 2.75781Z"
                    fill={type === "disabled" ? "#E2E4E5" : "#849E42"}
                  />
                </svg>
              </div>
              {type !== "disabled" && <span className={clsx({ "font-normal": type === "current" })}>{score}%</span>}
            </div>
          </div>
        </div>
      </div>
      {type === "current" && client?.portfolio?.current && (
        <p className="text-sm text-neutral-500 mt-2.5">Last updated {customDateFormat(client.portfolio.current.lastUpdatedAt!, "d MMM yyyy")}</p>
      )}
      {type === "proposed" && (client?.portfolio?.proposed ?? game?.esg.results?.portfolioMatch)?.lastUpdatedAt && (
        <p className="text-sm text-neutral-500 mt-2.5">
          Last calculated {customDateFormat((client?.portfolio?.proposed ?? game?.esg.results?.portfolioMatch)!.lastUpdatedAt!, "d MMM yyyy")}
        </p>
      )}
    </div>
  )
}

export default PortfolioMatchCard
