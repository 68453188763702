import clsx from "clsx"
import { Client } from "../../models/Client"

interface Props {
  clients: Client[]
  colorByStatus?: boolean
  colorStartIndex?: number
  size?: "xs" | "small" | "medium" | "large"
}

const AVATAR_CLASSES = ["bg-avatar-0-500", "bg-avatar-1-500"]

const AvatarBadges = ({ clients, colorByStatus, colorStartIndex = 0, size = "large" }: Props) => {
  return (
    <div className="flex">
      {clients.map(({ _id, firstName, status }, i) => {
        return (
          <div
            className={clsx(
              "text-sm rounded-full flex items-center justify-center font-semibold uppercase",
              colorByStatus
                ? `text-black/100 status-badge-${status ? status.replaceAll(/\s+/g, "-").toLowerCase() : "none"}`
                : AVATAR_CLASSES[colorStartIndex + i] + " text-white",
              {
                "w-4 h-4 text-xs": size === "xs",
                "w-6 h-6": size === "small",
                "w-7 h-7": size === "medium",
                "w-9 h-9": size === "large",
                "-ml-2": size === "small" || (size === "medium" && i > 0),
                "-ml-3": size === "large" && i > 0
              }
            )}
            key={_id}
          >
            {firstName.charAt(0)}
          </div>
        )
      })}
    </div>
  )
}

export default AvatarBadges
