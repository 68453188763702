import { ReactNode } from "react"
import styled from "styled-components"

interface ContainerProps {
  bgColor?: string
  bgImage?: string
}

const Container = styled.div<ContainerProps>`
  height: 1122px;
  width: 794px;
  ${(props) => props.bgImage && `background-image: url('${props.bgImage}');`}
  ${(props) => props.bgColor && `background-color: ${props.bgColor};`}
  @media print {
    page-break-before: always;
  }
`
const CoverPageContainer = ({ bgColor, bgImage, className, children }: { bgColor?: string; bgImage?: string; className?: string; children?: ReactNode }) => (
  <Container bgColor={bgColor} bgImage={bgImage} className={className}>
    {children}
  </Container>
)

export default CoverPageContainer
