import vector from "../images/Vector.svg"

const DeleteModelPortfolioModal: React.FunctionComponent<{ handleClose: () => void; onDelete: () => void }> = ({ handleClose, onDelete }) => {

  return (
    <div className="modal-container w-[600px]">
      <div className="m-auto pb-10 gap-5">
        <img src={vector} alt="vector" />
      </div>
      <h1 className="modal-title">Are you sure you want to delete this portfolio?</h1>
      <p className="text-center p-4">
        Any clients currently mapped to this portfolio will have a note added to their record that the portfolio has been deleted. Please remind advisers to
        update the portfolio for these clients
      </p>
      <div className="flex gap-x-4 w-full max-w-[340px] m-auto">
        <button className="btn btn-secondary btn-medium text-sec flex-1" onClick={handleClose}>
          Cancel
        </button>
        <button
          className="btn btn-primary btn-medium text-sec flex-1 hover:bg-interactive-600 hover:cursor-pointer"
          onClick={() => {
            onDelete()
          }}
        >
          Delete
        </button>
      </div>
    </div>
  )
}

export default DeleteModelPortfolioModal
