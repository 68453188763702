import { Outlet, Route, Routes } from "react-router"
import RMJRiskAttitude from "./riskAttitude/RMJRiskAttitude"
import RMJYourDecisions from "./yourDecisions/RMJYourDecisions"
import RMJPortfolioComfort from "./portfolioComfort/RMJPortfolioComfort"
import RMJOverviewPage from "./overview/RMJOverviewPage"
import RMJAdviceOverviewPage from "./adviceOverview/RMJAdviceOverviewPage"
import RMJGoalExplorerPage from "./goalExplorer/RMJGoalExplorerPage"
import RMJLossSensitivity from "./lossSensitivity/RMJLossSensitivity"
import RMJSummaryPage from "./summary/RMJSummaryPage"

const RMJourney = () => (
  <div className="relative pg-ctr w-full h-full p-10">
    <Outlet />
    <Routes>
      <Route path="overview" element={<RMJOverviewPage />} />
      <Route path="advice-overview" element={<RMJAdviceOverviewPage />} />
      <Route path="your-decisions" element={<RMJYourDecisions />} />
      <Route path="risk-attitude" element={<RMJRiskAttitude />} />
      <Route path="loss-sensitivity" element={<RMJLossSensitivity />} />
      <Route path="portfolio-comfort" element={<RMJPortfolioComfort />} />
      <Route path="goal-explorer" element={<RMJGoalExplorerPage />} />
      <Route path="summary" element={<RMJSummaryPage />} />
    </Routes>
  </div>
)

export default RMJourney
