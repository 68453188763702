import React, { useEffect, useState } from "react"
import InputCode from "../../components/InputCode/InputCode"
import errorImage from "./images/plus-cross.svg"
import { useMutation } from "react-query"
import axios, { AxiosError } from "axios"

type Props = {
  onNextClick: (token: string) => void
  onTryAnotherEmail: () => void
  token: string
}

export default function VerifyCode({ token, onNextClick, onTryAnotherEmail }: Props) {
  const [verifyCodeError, setVerifyCodeError] = useState("")
  
  const {
    error, mutate: verifyCode, data: verifyCodeResponse
  } = useMutation<{ token: string}, AxiosError<{ message?: string } | string | undefined>, { otp: string, token: string }>(
      (payload) => axios.post(`${import.meta.env.VITE_APP_API_BASE}/api/auth/verify-otp`, payload)
        .then(res => res.data)
  )
  
  useEffect(() => {
    if (error) {
      setVerifyCodeError((error.response?.data as any)?.message ?? error.response?.data ?? error.response?.statusText)
    }
  }, [error])
  
  useEffect(() => {
    if (verifyCodeResponse) {
      onNextClick(verifyCodeResponse.token)
    }
  }, [onNextClick, verifyCodeResponse])
  
  return (
    <div className=" mx-auto my-auto">
      <div className="modal-container flex flex-col gap-6">
        <h1 className="modal-title text-left p-0">Verify your email</h1>
        <p className="modal-description text-left p-0">
          If the email you’ve entered matches an account, you’ll receive an email shortly. Please enter the six-digit code from the email.
        </p>
        <div className="input-wrapper">
          <InputCode length={6} onComplete={(otp) => {
            verifyCode({ token, otp })
          }} />
        </div>
        {verifyCodeError && (
          <div className="flex items-center gap-1 bg-white border border-neutral-300 p-2">
            <img src={errorImage} alt="" aria-hidden />
            <p className="text-sm">The verification code you entered is invalid. Please enter the correct code.</p>
          </div>
        )}
        <p className="text-main-500 text-left">
          If you didn't receive an email, please check your spam folder or{" "}
          <span role="button" onClick={onTryAnotherEmail} className="font-bold text-interactive-500">
            try another email address
          </span>
        </p>
      </div>
    </div>
  )
}
