import clsx from "clsx"
import { useMemo } from "react"

interface Props {
  activeIndex: number
  numSteps: number
}

const StepDisplay = ({ activeIndex, numSteps }: Props) => {
  const steps = useMemo(() => {
    return Array(numSteps)
      .fill(null)
      .map((element, i) => {
        return {
          id: `step-${i + 1}`,
          value: i + 1
        }
      })
  }, [numSteps])

  return (
    <div aria-label="" className="step-display w-full flex justify-center items-center">
      {steps.map(({ id, value }) => {
        const isActive = value === activeIndex
        return (
          <div
            aria-describedby={isActive ? "current" : "unavailable"}
            className={clsx("h-[0.4rem] w-[0.4rem] mx-1 bg-main-400 rounded-full", {
              "opacity-100": isActive,
              "opacity-30": !isActive
            })}
            key={id}
          />
        )
      })}
    </div>
  )
}

export default StepDisplay
