import React, { ReactNode, useState } from "react"

import TextInput from "../../components/TextInput/TextInput"
import eyeOff from "./images/eye-off.svg"
import eye from "./images/eye.svg"

/*
  THIS IS NOT a COMPONENT LIBRARY to be used in general. It only serves the needs of the Auth screens. 
  Actual components to be used throughout the app are in src/components
*/

export const Email: React.FunctionComponent<{ emailIsValid: boolean; setEmail: (_: string) => void; email: string; label: string | ReactNode; error?: string; }> = ({ setEmail, email, label, error }) => {
  return (
    <TextInput
      id="email-input"
      error={error ? true : false}
      name="email"
      type="text"
      label={label}
      className="email-input"
      onChange={(value) => {
        setEmail(value)
      }}
      placeholder="Email"
      value={email}
      autoComplete="email"
    />
  )
}

export const Password: React.FC<{
  id?: string
  label?: string
  passwordIsValid: boolean
  setPassword: (_: string) => void
  password?: string
  error?: string
}> = ({ id = "password", label, setPassword, password, error }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  return (
    <div className="password-input-wrapper">
      <TextInput
        id={`${id}-input`}
        error={error ? true : false}
        name={id}
        type={showPassword ? "text" : "password"}
        label={label}
        className="password-input"
        onChange={(value) => {
          setPassword(value)
        }}
        placeholder="Password"
        value={password}
        suffix={
          <button type="button" className="outline-none" tabIndex={-1} onClick={() => setShowPassword(!showPassword)}>
            <img src={showPassword ? eye : eyeOff} alt="eye" className="inline-block" />
          </button>
        }
        autoComplete="current-password"
      />
    </div>
  )
}

export const Username: React.FunctionComponent<{ usernameIsValid: boolean; setUsername: (_: string) => void; username: string; error?: string; }> = ({ setUsername, username, error }) => {
  return (
    <TextInput
      id="username-input"
      error={error ? true : false}
      name="username"
      type="text"
      label="Email address"
      className="username-input"
      onChange={(value) => {
        setUsername(value)
      }}
      placeholder="Email"
      value={username}
      autoComplete="username"
    />
  )
}

