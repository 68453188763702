export default `
## CAPITAL PREFERENCES TERMS OF SERVICE

Important: **By using any element of Capital Preferences’ Solutions offerings, you indicate that you have read, understood, and agree to be bound by these Capital Preferences Terms of Service (“Terms”).** These Terms govern your use of the Capital Preferences Solutions and form a legal contract between you and Capital Preferences Ltd. (“**Capital Preferences**”, “**we**” or “**us**”). If you are an individual accepting these Terms on behalf of a company or entity, then you are binding the company or entity to these Terms and represent and warrant that you have full power and authority to do so. Capital Preferences may modify these Terms from time to time as described in Section 13 (Modifications to Terms) below.

**1. Introduction**.

Thank you for using the Capital Preferences Solutions! Capital Preferences Solutions are designed for use by qualified financial professionals such as you (“**Firm**” or “**you**”) to better understand the preferences and investment goals of their clients (“**Clients**”). By using any Capital Preferences Solutions, you certify that you are a licensed investment advisor, and that if you choose to make investment decisions for your Clients in reliance on any Client Reports, you do so at your own risk.

**2. The Capital Preferences Solutions**.

1. Client Access to Decision Games. By purchasing a subscription to the Capital Preferences Solutions, subject to the Order Terms (as defined below), you will be able to provision a designated number of your Clients with access to Decision Games. “**Decision Games**” are interactive games designed to measure individuals’ estimated risk, time, ambiguity, distributional and other preferences through hypothetical tradeoff scenarios and similar exercises. The Decision Games will be hosted by Capital Preferences, and their use by Clients will be governed by the Capital Preferences Terms of Service and [Privacy Policy](https://capitalpreferences.com.au/privacy-policy) (“**Capital Preferences** **Terms**”). The Capital Preferences Terms will likewise govern any data collected by Capital Preferences through Decision Games.

2. Client Reports. Once a Client has completed the Decision Game, Capital Preferences will score that Client’s preferences to create an individual Client profile, or “Economic Fingerprint”, which Capital Preferences uses to generate a Client report (each, a “**Client Report**”). Client Reports will be delivered to you through our Reporting Tools (as described below) and may include non-Capital Preferences content that you have provided to us (e.g., your own content). You may use the Client Reports for your own business purposes, subject to Section 5(d) (Firm Obligations) below. You may not alter or remove any Capital Preferences or other branding or proprietary notices on the Client Reports (including without limitation any logos or notices associated with providers of Third Party Data, as defined below) without our prior written consent. You acknowledge and agree that, in the course of a Client’s interaction with the Decision Games, Capital Preferences may also provide information included in a Client Report and other feedback directly to the applicable Client on your behalf.

3. Reporting Tools. During your subscription term, you will receive access to certain Reporting Tools to administer Client access to Decision Games, retrieve Client Reports and manage your account. “**Reporting Tools**” consist of Capital Preferences’ online administrator and advisor dashboard, APIs and other reporting tools that we may make available to you from time to time. You may only use the Reporting Tools in accordance with these Terms and any user documentation provided by Capital Preferences. You will not (and will not allow anyone else to): (a) use Reporting Tools except as permitted hereunder; (b) decompile, disassemble, or reverse engineer the underlying software to any Reporting Tools (unless this restriction is not permitted under applicable law); (c) sell, rent, lease or use any Reporting Tools for time sharing purposes; or (d) remove any copyright or proprietary notices contained in any Reporting Tools.

**3. Your Account**.

Use of the Capital Preferences Solutions may require you to create an account and register your email address and a password. You are solely responsible for any and all actions taken using your account and you must keep your passwords secure.

**4. Subscription Plans and Fees**.

1. Subscription Plans. Your permitted scope of use – such as which Decision Games and types of Client Reports will be available, the designated number of Clients who can access Decision Games, and which features of the Capital Preferences Solutions are available to you – depends on the subscription plan you select. Current plans are described at www.capitalpreferences.com.au and your specific subscription plan, and the applicable subscription term (e.g, monthly or annual), will be identified in the terms presented when you register, order or pay for the Capital Preferences Solutions (“**Order Terms**”). These Terms incorporate the Order Terms and any referenced URLs and Capital Preferences policies. If you receive access to the Capital Preferences Solutions as part of a trial or free subscription, you will automatically become enrolled as a full subscriber upon expiration of the trial subscription term unless you provide us notice that you wish to opt out of a full subscription. Any free trial access to Decision Games will be subject to these Terms.

2. Fees. You will be required to provide Capital Preferences and its payment vendors with information regarding your credit card, debit card or other accepted payment instrument as specified in the Order Terms. You represent and warrant to us that such information is true and current and that you are authorized to use the payment instrument. Capital Preferences will bill your payment instrument on file in accordance with the Order Terms and the subscription plan you select. Renewals and cancellations are governed by our Billing Policy. All fees are non-refundable except as expressly specified in Section 13 (Modifications to Terms), the Order Terms or our Billing Policy.

**5. Firm Materials**

1. Use of Firm Materials. As between the parties, Firm retains all of its rights to any Client contact or profile information or other business materials that Firm submits to the Capital Preferences Solutions (“**Firm Materials**”). Firm hereby grants to Capital Preferences a non-exclusive, worldwide, royalty-free right to use, copy, store, transmit, modify, create derivative works of and publicly perform and display the Firm Materials in order to provide the Capital Preferences Solutions to Firm and otherwise to operate, improve and maintain the Capital Preferences Solutions. Capital Preferences may use the services of subcontractors to provide the Capital Preferences Solutions and permit them to exercise our rights to Firm Materials.

2. Rights in Firm Materials. Firm is solely responsible for the accuracy, content and legality of all Firm Materials. Firm represents and warrants to Capital Preferences that Firm has sufficient rights in the Firm Materials relating to Clients and other third parties to grant the rights granted to Capital Preferences in Section 5(a) (Use of Firm Materials) above and that the Firm Materials do not infringe or violate the intellectual property, publicity, privacy or other rights of any third party. As between Firm and Capital Preferences, Firm will be solely responsible for obtaining the necessary clearances, consents and approvals from Clients and other third parties to provide the Firm Materials under all applicable laws.

3. Storage of Firm Materials. Capital Preferences does not provide an archiving service. Capital Preferences agrees only that it will not intentionally delete Firm Materials during your subscription term. Capital Preferences expressly disclaims all other obligations with respect to storage.

4. Firm Obligations. Firm will ensure that its provision of Firm Materials and its use of the Capital Preferences Solutions is at all times compliant with Firm’s privacy policies and other terms applicable to Clients and all applicable local, state, federal and international laws, regulations and conventions (“**Laws**”), including, without limitation, the Investment Firms Act of 1940 (in the US), the Corporations Act 2001 and the Australian Securities and Investments Commission Act 2001 and any similar acts or laws applicable to financial services providers and investment advisers and any laws related to data privacy and data transfer. Firm acknowledges that Capital Preferences is not an investment adviser or broker/dealer and is not providing any investment advice to Firm or any Clients under these Terms.

5. Prohibited Data. Firm will ensure that Firm Materials do not include (and that Firm does not otherwise submit to Capital Preferences) any sensitive personal information of Clients or others, including any government issued identification, individual medical or health information (including personal health information regulated under HIPAA (in the US) or similar laws in other countries), individual financial information, credit or debit card numbers, security codes, or passwords.

6. Indemnification by Firm. Firm will indemnify, defend and hold harmless Capital Preferences from and against any and all claims, costs, damages, losses, liabilities and expenses (including reasonable attorneys’ fees and costs) arising out of or in connection with any claim (from Clients or third parties) arising from or relating to (a) any Firm Materials or breach or alleged breach by Firm of this Section 5, (b) any investment, advisory or other services provided by Firm to Clients, or (c) use of or reliance by Firm or any Client on Client Reports provided by Capital Preferences in connection with these Terms.

7. Compelled Disclosures. Capital Preferences may share Firm Materials when we have a good faith belief that access, use, preservation or disclosure of such information is reasonably necessary to: (i) satisfy any Law, legal process or governmental request; (ii) enforce these Terms, including investigation of potential violations hereof; or (iii) protect against imminent harm to the Capital Preferences Solutions or the rights, property or safety of Capital Preferences, its users or the public as required or permitted by law.

8. Other Data Collection. Capital Preferences uses your account information (as described in Section 2) and certain other information about your use of the Capital Preferences Solutions in accordance with our [Privacy Policy](https://capitalpreferences.com.au/privacy-policy). Nothing in the Privacy Policy limits our rights to Firm Materials described above in this Section 5 or elsewhere in these Terms.

**6. Ownership**

1. **Capital Preferences Technology**. Firm acknowledges that it is obtaining only a limited right to the Capital Preferences Solutions and that irrespective of any use of the words “purchase”, “sale” or like terms in these Terms no ownership rights are being conveyed to Firm under these Terms. Firm agrees that Capital Preferences or its suppliers retain all right, title and interest (including all patent, copyright, trademark, trade secret and other intellectual property rights) in and to the Capital Preferences Solutions and any and all related and underlying technology, methodologies and documentation; and any derivative works, modifications or improvements of any of the foregoing, including as may incorporate Feedback. Except as expressly set forth in these Terms, no rights in any Capital Preferences Solutions or Capital Preferences technology are granted to Firm. Firm acknowledges that it has no right to obtain a copy of any Capital Preferences technology underlying the Capital Preferences Solutions.

2. **Feedback**. Firm, from time to time, may submit comments, questions, suggestions or other feedback relating to the Capital Preferences Solutions to Capital Preferences (“**Feedback**”). Capital Preferences may freely use or exploit Feedback in connection with any of its products or services.

3. **Insights and Aggregate Data**. Firm agrees that (i) Capital Preferences may gain general learnings, deductions and other insights as a result of its access to Firm Materials, and (ii) Capital Preferences may aggregate Firm Materials with other data so that the results are not identifiable to Firm. Notwithstanding anything in these Terms to the contrary, Capital Preferences may use such insights and aggregate data to improve, support and operate the Capital Preferences Solutions and for its lawful business purposes during and after the term of these Terms, including without limitation to create and distribute reports and other materials.

**7. Acknowledgement of Intellectual Property Rights**

You acknowledge that all copyrights, patent rights, trademarks, services marks, trade secrets and other intellectual property rights relating to the use of all Capital Preferences products are the sole and exclusive property of Capital Preferences and may be covered by one or more Patent see Capital Preferences IP Listing (https://capitalpreferences.com/intellectual-property-filings).

**8. Termination and Suspension**

1. Termination; Suspension. Either party may terminate these Terms if the other party fails to cure any material breach of these Terms within thirty (30) days after written notice. In addition, Capital Preferences may terminate or suspend these Terms immediately if we cease to offer the Capital Preferences Solutions, in order to prevent harm to the Capital Preferences Solutions or other customers, or if our right or ability to offer the Capital Preferences Solutions is restricted, suspended or terminated (whether pursuant to Laws or otherwise). Further, Capital Preferences may suspend these Terms immediately for your failure to pay fees when due, and may terminate these Terms ten (10) days after such suspension if you do not pay all fees due within such ten (10) day period.

2. Effect of Termination. Upon any expiration or termination of your subscription term or these Terms, your right to use the Capital Preferences Solutions will immediately cease and (in Capital Preferences’ discretion) your account will be deleted or reverted to limited functionality. Following any such expiration or termination, Capital Preferences may delete or limit your access to any of your Firm Materials or other data input into the Capital Preferences Solutions and require you to delete (or at our request, return) any Capital Preferences passwords, access codes or other Confidential Information. Except where an exclusive remedy is specified, the exercise of either party of any remedy under these Terms, including termination, will be without prejudice to any other remedies it may have under these Terms, by law or otherwise.

3. Survival. The following Sections will survive any expiration or termination of these Terms: 3(b) (Use of Reporting Tools), 5 (Firm Materials), 6 (Ownership), 7 (Termination and Suspension), 8 (Warranty Disclaimer), 10 (Limitation of Remedies and Damages), 11 (Confidential Information), 13 (Modifications to Terms) and 14 (General Terms).

**9. Warranty Disclaimer**

THE CAPITAL PREFERENCES SOLUTIONS AND related CAPITAL PREFERENCES services ARE PROVIDED “AS IS”. NEITHER CAPITAL PREFERENCES NOR ITS SUPPLIERS MAKES ANY WARRANTIES, EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE OR NONINFRINGEMENT. Capital Preferences does not warrant that FIRM’S use of THE CAPITAL PREFERENCES SOLUTIONS will be uninterrupted or error-free, nor does Capital Preferences warrant that it will review the FIRM MATERIALS for accuracy or that it will preserve or maintain the FIRM MATERIALS without loss. Capital Preferences WILL NOT BE LIABLE FOR DELAYS, INTERRUPTIONS, SERVICE FAILURES OR OTHER PROBLEMS INHERENT IN USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS OR OTHER SYSTEMS OUTSIDE THE REASONABLE CONTROL OF Capital Preferences. FIRM MAY HAVE OTHER STATUTORY RIGHTS, BUT THE DURATION OF STATUTORILY REQUIRED WARRANTIES, IF ANY, WILL BE LIMITED TO THE SHORTEST PERIOD PERMITTED BY LAW.

**FIRM understands that Client Reports depend on the individual Client inputs and other factors outside the control of Capital Preferences, and are generated using statistical models and methodologies that have inherent limitations. As such, without limiting the above disclaimers, FIRM agrees that Capital Preferences makes no representations as to the suitability of any Client Report or any other information derived from use of ANY Capital Preferences Solution for any use, including to make investment, compliance or marketing decisions. FIRM and each Client must independently determine the suitability of use of any Client Report or the Capital Preferences Solution for any purpose. in any event, FIRM should not rely on the CAPITAL PREFERENCES SOLUTIONS or client reports to determine a client’s overall attitude toward RISK OR RISK PROFILE without considering other client variables and personal circumstances.**

**Capital Preferences does not offer any advice or recommendations regarding a Client’s investment objectives, financial situation or specific needs, AND FIRM ACKNOWLEDGES THAT THE CAPITAL PREFERENCES SOLUTIONS AND CLIENT REPORTS DO NOT CONSTITUTE FINANCIAL PRODUCT OR INVESTMENT ADVICE OF ANY KIND. FIRM ACKNOWLEDGES THAT AN INVESTMENT IN ANY SECURITY IS SUBJECT TO A NUMBER OF RISKS AND THAT DISCUSSIONS OF ANY SECURITY INCLUDED IN THE CAPITAL PREFERENCES SOLUTIONS OR CLIENT REPORTS WILL NOT CONTAIN A LIST OR DESCRIPTION OF RELEVANT RISK FACTORS. FIRM FURTHER ACKNOWLEDGES THAT CAPITAL PREFERENCES IS NOT REGISTERED WITH THE SECURITIES AND EXCHANGE COMMISSION AS AN INVESTMENT ADVISOR OR A BROKER/DEALER AND DOES NOT OWE A FIDUCIARY DUTY TO FIRM OR ANY CLIENTS.**

**10. Support**

Capital Preferences uses reasonable efforts to provide email and online self-help. All support is governed by Capital Preferences’ then-current support policies.

**11. Limitation of Remedies and Damages**.

IN NO EVENT WILL CAPITAL PREFERENCES or its suppliers BE LIABLE TO YOU or to any third party (I) FOR ANY LOST PROFITS, LOSS OF USE, LOST DATA, INTERRUPTION OF BUSINESS, FAILURE OF SECURITY MECHANISMS OR ANY SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGES OR (II) IN ANY EVENT, FOR amounts exceeding THE GREATER OF (A) THE AMOUNT PAID OR PAYABLE BY FIRM TO capital preferences IN THE PRIOR six (6) month PERIOD OR (B) one hundred dollars (US$100). THIS SECTIon will survive and apply even if any limited remedy in thESE TERMS is found to have failed of its essential purpose. Firm acknowledges that the foregoing limitations are an essential element of these Terms and a reasonable allocation of risk between the parties, and that in the absence of such limitations the pricing and other provisions in these Terms would be substantially different.

**12. Confidential Information**

Capital Preferences Solutions. Confidential Information excludes information that Firm already lawfully knew or that becomes public through no fault of Firm. Firm will (a) use a reasonable degree of care to protect all Confidential Information, (b) not use Confidential Information except in support of its authorized use of the Capital Preferences Solutions and (c) not disclose Confidential Information except to employees and agents with a legitimate need to know and who have agreed in writing to keep it confidential. Firm may also disclose Confidential Information to the extent required by law after reasonable notice to Capital Preferences and cooperation to obtain confidential treatment. Unauthorized disclosure of Confidential Information may cause harm not compensable by damages, and Capital Preferences may seek injunctive or equitable relief in a court of competent jurisdiction, without posting a bond, to protect its Confidential Information.

**13. Publicity**

Capital Preferences may use Firm’s name, logo, and marks to identify Firm as a Capital Preferences customer on Capital Preferences’ website and other marketing materials.

**14. Modifications to Terms**

Capital Preferences may modify these Terms (including pricing and plans for Capital Preferences Solutions) from time to time, with notice given to you by email or through the Capital Preferences Solutions. If you disagree with the modifications, you must notify Capital Preferences within fifteen (15) days of notice of the modifications, and Capital Preferences (at its option and as your exclusive remedy) will allow you to terminate these Terms and receive a pro-rated refund for the terminated portion of your subscription. In either case, you may be required to click to agree to the modified Terms in order to continue using the Capital Preferences Solutions, and in any event continued use of the Capital Preferences Solutions after the modifications take effect constitutes your acceptance of the modifications.

**15. General Terms**

a. Assignment. You may not assign or transfer these Terms or any rights hereunder, and any attempted assignment or transfer in violation of the foregoing will be void. Capital Preferences may freely assign or transfer its rights or obligations hereunder at its sole discretion. These Terms will bind and inure to the benefit of each party’s permitted successors and assigns.

b. Contract Mechanics and Interpretation. These Terms constitute the entire agreement between you and Capital Preferences related to the Capital Preferences Solutions and supersede any prior or contemporaneous agreements relating to the Capital Preferences Solutions. If any provision of these Terms is held unenforceable, that provision will be enforced to the extent permissible by law and the remaining provisions will remain in full force. No provision of these Terms will be deemed waived unless the waiver is in writing and signed by Capital Preferences. Except as set forth in Section 13 (Modifications to Terms), all amendments to these Terms must be in writing and signed by both parties. In these Terms headings are for convenience only and “including” and similar terms will be construed without limitation.

c. Governing Law; Dispute Resolution.

i) Direct Dispute Resolution. In the event of any dispute, claim, question, or disagreement arising from or relating to this Agreement, whether arising in contract, tort or otherwise, (“**Dispute**”), the parties will first use their best efforts to resolve the Dispute. If a Dispute arises, the complaining party will provide written notice to the other party in a document specifically entitled “Initial Notice of Dispute,” specifically setting forth the precise nature of the dispute (“**Initial Notice of Dispute**”). If an Initial Notice of Dispute is being sent to Capital Preferences it must be emailed to legal@capitalpreferences.com and sent via mail to:

Capital Preferences, 148 New Dorp Lane, New York, NY 10306.

Following receipt of the Initial Notice of Dispute, the parties will consult and negotiate with each other in good faith and, recognizing their mutual interest, attempt to reach a just and equitable solution of the Dispute that is satisfactory to both parties (“**Direct Dispute Resolution**”). If the parties are unable to reach a resolution of the Dispute through Direct Dispute Resolution within thirty (30) days of the receipt of the Initial Notice of Dispute, then the Dispute will subsequently be resolved by arbitration as set forth below.

ii) Arbitration. IN THE EVENT THAT A DISPUTE BETWEEN THE PARTIES CANNOT BE SETTLED THROUGH DIRECT DISPUTE RESOLUTION, AS DESCRIBED ABOVE, THE PARTIES AGREE TO SUBMIT THE DISPUTE TO BINDING ARBITRATION. BY AGREEING TO ARBITRATE, THE PARTIES AGREE TO WAIVE THEIR RIGHT TO A JURY TRIAL. The arbitration will be conducted before a single neutral arbitrator, before JAMS in San Francisco, California. The arbitration will be administered by JAMS in accordance with this document and the JAMS Streamlined Rules and Procedures for the Arbitration, with one addition: The limitation of one discovery deposition per side will be applied by the arbitrator, unless it is determined, based on all relevant circumstances, that more depositions are warranted. The arbitrator will consider the amount in controversy, the complexity of the factual issues, the number of parties and the diversity of their interests and whether any or all of the claims appear, on the basis of the pleadings, to have sufficient merit to justify the time and expense associated with the requested discovery.

The arbitration will occur in San Francisco, California, but the parties may choose to appear by person, by phone, by another virtual means, or through the submission of documents.

The arbitrator will issue a ruling in writing. Any issue concerning the extent to which any dispute is subject to arbitration, the applicability, interpretation, or enforceability of this agreement will be resolved by the arbitrator. To the extent state law is applicable, the arbitrator will apply the substantive law of California.

All aspects of the arbitration will be treated as confidential and neither the parties nor the arbitrators may disclose the content or results of the arbitration, except as necessary to comply with legal or regulatory requirements. The result of the arbitration will be binding on the parties and judgment on the arbitrator’s award may be entered in any court having jurisdiction. The arbitrator will award to the prevailing party, if any, the costs and attorneys’ fees reasonably incurred by the prevailing party in connection with the arbitration.

iii) Choice of Law and Jurisdiction. FOR ANY CLAIM WHICH IS NOT SUBJECT TO THIS DISPUTE RESOLUTION PROVISION, YOU AGREE TO SUBMIT AND CONSENT TO THE PERSONAL AND EXCLUSIVE JURISDICTION IN, AND THE EXCLUSIVE VENUE OF, THE STATE AND FEDERAL COURTS LOCATED WITHIN SAN FRANCISCO COUNTY, CALIFORNIA. IN ANY SUCH DISPUTE, CALIFORNIA LAW WILL APPLY.

iv) Construction and Joinder. THIS AGREEMENT MUST BE CONSTRUED AS IF IT WAS JOINTLY WRITTEN BY BOTH PARTIES. BOTH YOU AND CAPITAL PREFERENCES AGREE THAT EACH MAY BRING OR PARTICIPATE IN CLAIMS AGAINST THE OTHER ONLY IN THEIR RESPECTIVE INDIVIDUAL CAPACITIES, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS. NO ARBITRATION OR CLAIM UNDER THIS AGREEMENT WILL BE JOINED TO ANY OTHER ARBITRATION OR CLAIM, INCLUDING ANY ARBITRATION OR CLAIM INVOLVING ANY OTHER CURRENT OR FORMER USER OF THE SERVICES, AND NO CLASS ARBITRATION PROCEEDINGS WILL BE PERMITTED. IN THE EVENT OF ANY DISPUTE CONCERNING THE VALIDITY OR ENFORCEABILITY OF THIS PROVISION, SUCH CLAIM MUST BE ADJUDICATED BY A COURT AND NOT BY AN ARBITRATOR.

v) Injunctive Relief; Intellectual Property Claims. Notwithstanding the above provisions, (a) Capital Preferences may apply for injunctive remedies (or an equivalent type of urgent legal relief) in any jurisdiction and (b) Capital Preferences may, in its sole discretion, bring any claim related to its intellectual property rights in the state and federal courts located within San Francisco County, California (and you agree to submit and consent to the personal and exclusive jurisdiction thereof), in which case California law will apply.

d. Notices. We may send notices to your email on file or through the Capital Preferences Solutions. You may send notices to us at legal@capitalpreferences.com. All notices are effective upon delivery.

e. Force Majeure. Capital Preferences will not be liable to Firm in any way whatsoever for any failure or delay in performance of any of its obligations under these terms arising out of any event or circumstance beyond the reasonable control of Capital Preferences.

f. Independent Contractors. The parties to these Terms are independent contractors. There is no relationship of partnership, joint venture, employment, franchise or agency created hereby between the parties. Neither party will have the power to bind the other or incur obligations on the other party’s behalf without the other party’s prior written consent.

g. Export Control. In its use of the Capital Preferences Solutions, Firm agrees to comply with all export and import laws and regulations of the United States and other applicable jurisdictions. Without limiting the foregoing, (i) Firm represents and warrants that it is not listed on any U.S. government list of prohibited or restricted parties or located in (or a national of) a country that is subject to a U.S. government embargo or that has been designated by the U.S. government as a “terrorist supporting” country, (ii) Firm will not (and will not permit any of its users to) access or use the Capital Preferences Solutions in violation of any U.S. export embargo, prohibition or restriction, and (iii) Firm will not submit to the Capital Preferences Solutions any information that is controlled under the U.S. International Traffic in Arms Regulations.

h. Government End-Users. Elements of the Capital Preferences Solutions are commercial computer software. If the user or licensee of the Capital Preferences Solutions is an agency, department, or other entity of the United States Government, the use, duplication, reproduction, release, modification, disclosure, or transfer of the Capital Preferences Solutions, or any related documentation of any kind, including technical data and manuals, is restricted by a license agreement or by the terms of these Terms in accordance with Federal Acquisition Regulation 12.212 for civilian purposes and Defense Federal Acquisition Regulation Supplement 227.7202 for military purposes. All Capital Preferences Solutions were developed fully at private expense. All other use is prohibited.

**16. Third Party Data**.

If, as part of the Capital Preferences Solutions, you are receiving any data or model portfolios provided by third party suppliers (collectively, “**Third Party Data**”), the following terms apply:

1. The Third Party Data is deemed to be part of the Capital Preferences Solutions and subject to all applicable disclaimers and other provisions of these Terms.

2. You will use the Third Party Data solely for internal purposes and will not redistribute the Third Party Data in any form or manner to any third party, except that you may further disseminate and display the Third Party Data to your Clients via an internal website or through soft-copy or hard-copy documents.

3. You may not use, or permit any third party to use, the Third Party Data (i) in connection with (A) creating any index-linked securities or financial instruments that are based on the performance or composition of any index included in the Third Party Data, including, but not limited to index funds and index derivatives (e.g., index options, warrants, swaps, and futures), whether listed on an exchange or traded over the counter or on a private-placement basis or otherwise, or (B) managing, advising, writing, trading, marketing or promoting any such securities or financial instruments, or (ii) to create any indexes (custom or otherwise).

4. You will not alter, modify or adapt any component of the Third Party Data, including without limitation by creating derivative works (excluding any reformatting or repackaging of the Third Party Data in your permitted reports and applications).

5. You will reproduce all copyright, proprietary rights and restrictive legends appearing on the Third Party Data on all permitted copies you make of the Third Party Data.

6. You acknowledge that each supplier of Third Party Data may, in its sole and absolute discretion at any time, terminate your right to receive and/or use a portion or all of such supplier’s Third Party Data. Any such termination will not affect any other provision of these Terms (including without limitation pricing contained in the Order Terms).`
