import { Trans } from "@lingui/macro"
import { format } from "date-fns"
import styled from "styled-components"
import { useTheme } from "../../../contexts/ThemeContext"
import { Client } from "../../../models/Client"
import { FirmContext } from "../../../contexts/FirmContext"
import { useContext, useMemo } from "react"
import { clientTopCause } from "../components/Profile/causeScores"
import { clsx } from "clsx"

const CoverPageContainer = styled.div`
  height: 1122px;
  width: 794px;
`

const SustainablePersonaCoverPage = ({ client }: { client: Client }) => {
  const theme = useTheme()
  const { firm } = useContext(FirmContext)
  const topCause = useMemo(() => clientTopCause(client, theme), [client, theme])
  const persona = topCause?.persona

  return (
    <CoverPageContainer className="flex flex-col justify-end justify-items-end">
      {persona?.image && <img src={persona.image} className="print-cover-banner-img w-full h-2/3 object-cover" alt="" aria-hidden />}
      <div className="py-8 flex-auto mx-9 text-black/100">
        <h3 className="font-normal mb-8 text-display">
          <Trans id="print-report-cover-title">Your Sustainable persona:</Trans>
          <br aria-hidden />
          {persona?.name}
        </h3>
        <p className="text-h2 leading-7 mb-2">{`${client.firstName} ${client.lastName}`}</p>
        <p>{format(new Date(), "dd-MM-yyyy")}</p>
      </div>
      <div
        className={clsx("footer flex flex-row mx-9 border-t-2", theme.reports?.coverPage?.disclaimer?.show ? "justify-between" : "justify-end")}
      >
        {theme.reports?.coverPage?.disclaimer?.show && (
          <p className="cover-page-disclaimer py-6 w-full text-xxs text-balance">
            These results generated should not be taken as advice or recommendation to buy or sell any investments. Activity and results may be covered by one
            or more patents, see{" "}
            <a className="underline" target="_blank" rel="noreferrer" href="https://capitalpreferences.com/intellectual-property-filings">
              Capital Preferences IP Listings.
            </a>{" "}
            For Internal Use Only. Not for Distribution.
          </p>
        )}
        <img src={theme.clientLogo} alt={`${firm?.name} logo`} className="mt-6 mb-9 h-8" />
      </div>
    </CoverPageContainer>
  )
}

export default SustainablePersonaCoverPage
