import { useContext, useMemo } from 'react'
import { AppContext } from "../../contexts/AppContext"
import SelectPage from "./SelectPage"

const SelectRoute = () => {

  const { userProfile: user } = useContext(AppContext)
  const firmsPerms = useMemo(() => user?.permissions?.filter(p => p.firmId), [user?.permissions])

  return (
    firmsPerms?.length
      ? <SelectPage permissions={firmsPerms} />
      : <div className="full-flex-content-center">User not configured to access anything.</div>
  )
}

export default SelectRoute