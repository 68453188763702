export type ActionItemKey =
  | "review-your-current-investments"
  | "review-investment-factsheets"
  | "create-strategy-for-volatile-times"
  | "comfortable-with-investment-risk"
  | "increase-goal-achieving-likelihood"
  | "increase-goal-achieving-likelihood-of-investment-drawdown"
  | "freetext"

export interface ActionItem {
  key: ActionItemKey
  value: boolean | string
}

export const ActionItemLabels: { [key in ActionItemKey]?: string } = {
  "review-your-current-investments": "Review your current investments",
  "create-strategy-for-volatile-times": "Create a strategy for volatile times",
  "review-investment-factsheets": "Review investment factsheets",
  "comfortable-with-investment-risk": "I’m comfortable with the investment risk",
  "increase-goal-achieving-likelihood": "I want to increase the likelihood of achieving my goal",
  "increase-goal-achieving-likelihood-of-investment-drawdown": "I want to increase the likelihood of my investment drawdown continuing through my retirement"
}
