import { AxiosError } from "axios"
import { useEffect, useState } from "react"
import { useMutation } from "react-query"
import axiosInstance from "../../../../api/axiosInstance"
import Checkbox from "../../../../components/Checkbox/Checkbox"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import { useTheme } from "../../../../contexts/ThemeContext"
import { tt } from "../../../../lib/translations"
import { GameType } from "../../../../models/Client"
import { Firm } from "../../../../models/Firm"

type FirmUpdate = Partial<Omit<Firm, "gameTypes"> & { gameTypes?: GameType[] }>

const ActivitySettings = ({ firm, whenDone = () => {} }: { firm: Firm; whenDone?: () => any }) => {
  const theme = useTheme()
  const [selectedGames, setSelectedGames] = useState<GameType[]>([])

  useEffect(() => {
    setSelectedGames(firm.config?.supportedGames?.map((g) => g.type) ?? [])
  }, [firm.config?.supportedGames])

  const updateFirm = useMutation<unknown, AxiosError<{ message?: string } | string | undefined>, FirmUpdate>(
    (payload) => axiosInstance.patch(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/firm/${firm._id}`, payload).then((res) => res.data),
    {
      onSuccess: () => {
        whenDone()
      }
    }
  )

  const onChange = (gameType: GameType) => {
    setSelectedGames((prevSelected) => (prevSelected.includes(gameType) ? prevSelected.filter((game) => game !== gameType) : [...prevSelected, gameType]))
  }

  return (
    <div className="max-w-md pl-10 flex flex-col gap-y-6">
      <div className="w-full bg-surface-100 py-3 pl-4">
        {theme.games?.map((game) => {
          return (
            <Checkbox
              key={game.type}
              name={game.type}
              checked={selectedGames?.includes(game.type)}
              onChange={() => onChange(game.type)}
              className="flex-row-reverse justify-between w-full p-1"
              label={tt({ id: `game-${game.type}-title` })}
            />
          )
        })}
      </div>

      <button className="btn btn-primary btn-medium w-48" disabled={updateFirm.isLoading} onClick={() => updateFirm.mutate({ gameTypes: selectedGames })}>
        {updateFirm.isLoading ? <Loading type="dots" /> : <>Update</>}
      </button>
    </div>
  )
}

export default ActivitySettings
