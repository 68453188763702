import { t, Trans } from "@lingui/macro"
import { useTheme } from "../../../contexts/ThemeContext"
import Page from "./Page"
import { PrintCauseDetail } from "./PrintCauseDetail"

const AppendixPage1 = ({ page }: { page: number }) => {
  const theme = useTheme()

  return (
    <Page page={page} title={t({ id: "print-report-page-header-title" })}>
      <div className="mx-9">
        <h1 className="font-semibold mb-8 text-[22px]">
        Appendix: Understanding the sustainability themes and the UN SDGs
        </h1>
        <h2 className="text-sec mb-4">
          <Trans id="print-appendix-1-section1-text">To create your Sustainable persona, we evaluated your attitude to five themes aligned with the UN SDGs. Use this full list of themes to help you reflect on what matters to you as you navigate the sustainable investing landscape.</Trans>
        </h2>
        {Object.values(theme.causes!.S1!).slice(1, 4).map((cause) => (
          <PrintCauseDetail cause={cause} key={cause.id} />
        ))}
      </div>
    </Page>
  )
}

export default AppendixPage1
