import { useMemo } from "react"
import { Client, Game } from "../../../../../../models/Client"
import { clientReports } from "../../../../../../lib/regulatoryReports"
import ReportsList, { ReportsListItem } from "../../../../../../components/ReportsList/ReportsList"

const RegulatoryReporting = ({ client, game }: { client: Client, game?: Game }) => {
  const reportsListItems: ReportsListItem[] = useMemo(() => {
    const reports = game?.gameType === "risk" ? clientReports(client, "Risk activity data") : []

    return reports.map(({ date, csvContent, fileName, id, message }) => ({
      date,
      id,
      link: (
        <a className="text-riskComfort-500 underline" href={csvContent} download={fileName}>
          Export data
        </a>
      ),
      title: message
    }))
  }, [client, game?.gameType])
  const anyPlayedGame = client.games?.some((game) => game.played)

  return <ReportsList reports={anyPlayedGame ? reportsListItems : []} />
}

export default RegulatoryReporting
