export const ALTRUISM_DATA = [
  { id: "0", value: 7 },
  { id: "1", value: 7.13546543 },
  { id: "2", value: 7.30439828 },
  { id: "3", value: 7.50559841 },
  { id: "4", value: 7.7378657 },
  { id: "5", value: 8 },
  { id: "6", value: 8.29080119 },
  { id: "7", value: 8.60906913 },
  { id: "8", value: 8.9536037 },
  { id: "9", value: 9.32320476 },
  { id: "10", value: 9.71667217 },
  { id: "11", value: 10.13280582 },
  { id: "12", value: 10.57040555 },
  { id: "13", value: 11.02827125 },
  { id: "14", value: 11.50520278 },
  { id: "15", value: 12 },
  { id: "16", value: 12.51079623 },
  { id: "17", value: 13.03305856 },
  { id: "18", value: 13.56158751 },
  { id: "19", value: 14.09118361 },
  { id: "20", value: 14.61664739 },
  { id: "21", value: 15.13277938 },
  { id: "22", value: 15.63438011 },
  { id: "23", value: 16.1162501 },
  { id: "24", value: 16.57318989 },
  { id: "25", value: 17 },
  { id: "26", value: 17.39201388 },
  { id: "27", value: 17.74669663 },
  { id: "28", value: 18.06204626 },
  { id: "29", value: 18.3360608 },
  { id: "30", value: 18.56673826 },
  { id: "31", value: 18.75207666 },
  { id: "32", value: 18.89007402 },
  { id: "33", value: 18.97872835 },
  { id: "34", value: 19.01603767 },
  { id: "35", value: 19 },
  { id: "36", value: 18.92964825 },
  { id: "37", value: 18.80815494 },
  { id: "38", value: 18.63972745 },
  { id: "39", value: 18.42857319 },
  { id: "40", value: 18.17889957 },
  { id: "41", value: 17.89491398 },
  { id: "42", value: 17.58082382 },
  { id: "43", value: 17.24083651 },
  { id: "44", value: 16.87915943 },
  { id: "45", value: 16.5 },
  { id: "46", value: 16.10689311 },
  { id: "47", value: 15.70068363 },
  { id: "48", value: 15.28154395 },
  { id: "49", value: 14.84964644 },
  { id: "50", value: 14.40516348 },
  { id: "51", value: 13.94826744 },
  { id: "52", value: 13.47913069 },
  { id: "53", value: 12.99792562 },
  { id: "54", value: 12.5048246 },
  { id: "55", value: 12 },
  { id: "56", value: 11.48427932 },
  { id: "57", value: 10.96111054 },
  { id: "58", value: 10.43459675 },
  { id: "59", value: 9.90884105 },
  { id: "60", value: 9.38794653 },
  { id: "61", value: 8.87601628 },
  { id: "62", value: 8.37715341 },
  { id: "63", value: 7.89546101 },
  { id: "64", value: 7.43504218 },
  { id: "65", value: 7 },
  { id: "66", value: 6.5934896 },
  { id: "67", value: 6.2148742 },
  { id: "68", value: 5.86256904 },
  { id: "69", value: 5.53498937 },
  { id: "70", value: 5.23055042 },
  { id: "71", value: 4.94766743 },
  { id: "72", value: 4.68475565 },
  { id: "73", value: 4.44023033 },
  { id: "74", value: 4.2125067 },
  { id: "75", value: 4 },
  { id: "76", value: 3.80126226 },
  { id: "77", value: 3.61539264 },
  { id: "78", value: 3.44162707 },
  { id: "79", value: 3.27920148 },
  { id: "80", value: 3.12735181 },
  { id: "81", value: 2.985314 },
  { id: "82", value: 2.85232397 },
  { id: "83", value: 2.72761768 },
  { id: "84", value: 2.61043104 },
  { id: "85", value: 2.5 },
  { id: "86", value: 2.39546134 },
  { id: "87", value: 2.29555523 },
  { id: "88", value: 2.19892268 },
  { id: "89", value: 2.10420471 },
  { id: "90", value: 2.01004234 },
  { id: "91", value: 1.91507658 },
  { id: "92", value: 1.81794845 },
  { id: "93", value: 1.71729897 },
  { id: "94", value: 1.61176915 },
  { id: "95", value: 1.5 },
  { id: "96", value: 1.38063255 },
  { id: "97", value: 1.25230781 },
  { id: "98", value: 1.11366679 },
  { id: "99", value: 0.96335052 },
  { id: "100", value: 0.8 }
]
