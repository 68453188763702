import clsx from "clsx"
import { AnimatePresence } from "framer-motion"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { ClientUpdateRequest } from "../../../api/clients"
import { HouseholdUpdateRequest } from "../../../api/households"
import infoAlert from "../../../assets/icons/info-alert-dark.svg"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import RadioButton from "../../../components/RadioButton/RadioButton"
import { ClientHouseholdCacheContext } from "../../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../../contexts/FirmContext"
import useClient from "../../../hooks/useClient"
import useHousehold from "../../../hooks/useHousehold"
import arrowLeft from "../assets/images/arrow-left.svg"
import RMJFinishModal from "../components/RMJFinishModal/RMJFinishModal"
import SidebarHeader from "../components/SidebarHeader/SidebarHeader"
import RMJInvestmentGoalModal from "./RMJInvestmentGoalModal/RMJInvestmentGoalModal"
import { latest } from "../../../lib/clients"

const RMJPortfolioComfortSideBar = () => {
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client } = useClient(clientId)
  const { household } = useHousehold(householdId)
  const clientOrHousehold = useMemo(() => client ?? household, [client, household])
  const { firm } = useContext(FirmContext)
  const navigate = useNavigate()
  const [isFinishModalOpen, setIsFinishModalOpen] = useState(false)
  const [isInvestmentGoalModalOpen, setIsInvestmentGoalModalOpen] = useState(false)
  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const [reviewPortfolioFactsheets, setReviewPortfolioFactsheets] = useState<boolean>(false)
  const [status, setStatus] = useState<"updating" | "error">()
  const [portfolio, setPortfolio] = useState<string>()
  const [portfolioError, setPortfolioError] = useState<boolean>()
  const [shouldValidate, setShouldValidate] = useState<boolean>()
  const game = latest((client ?? household)!, "risk")

  const existingPortfolioFactsheetsItem = useMemo(
    () => game?.actionItems?.find((item) => item.key === "review-investment-factsheets"),
    [game?.actionItems]
  )
  useEffect(() => {
    setReviewPortfolioFactsheets(!!(existingPortfolioFactsheetsItem && Boolean(existingPortfolioFactsheetsItem.value)))
  }, [existingPortfolioFactsheetsItem])

  useEffect(() => setPortfolio(clientOrHousehold?.currentPortfolio), [clientOrHousehold?.currentPortfolio])

  const onFinish = () => {
    if (reviewPortfolioFactsheets !== !!(existingPortfolioFactsheetsItem && Boolean(existingPortfolioFactsheetsItem.value))) {
      const actionItems = game?.actionItems
      const req: ClientUpdateRequest | HouseholdUpdateRequest = {
        actionItems: [
          existingPortfolioFactsheetsItem
            ? { ...existingPortfolioFactsheetsItem, value: reviewPortfolioFactsheets }
            : {
                key: "review-investment-factsheets",
                value: reviewPortfolioFactsheets
              },
          ...(actionItems || []).filter((x) => x.key !== "review-investment-factsheets")
        ]
      }
      setStatus("updating")
      return client ? updateClient(client._id, req) : updateHousehold(household!._id, [], req)
    } else {
      return Promise.resolve(null)
    }
  }

  const validate = useCallback(() => {
    setPortfolioError(!portfolio)
  }, [portfolio])

  useEffect(() => {
    if (shouldValidate) {
      validate()
    }
  }, [shouldValidate, validate])

  return (
    <div className="w-80 h-full bg-interactive-600 flex flex-1 flex-col justify-between">
      {(client || household) && (
        <>
          <div className="gap-x-2.5 overflow-y-auto no-scrollbar">
            <div className="flex flex-col">
              {client && <SidebarHeader client={client} />}
              {household && <SidebarHeader household={household} />}
              <div className="sidebar-section">
                <h3 className="sidebar-section-title">Risk Comfort</h3>
                <p className="sidebar-section-text text-sec">
                  Your Risk Comfort expresses how comfortable you’d be in each of our investment options, given the risk and return expectations.
                </p>
              </div>
              <div className="sidebar-section text-white">
                <fieldset>
                  <legend className="sidebar-section-title mb-2">
                    When you’re ready to proceed, either select an investment risk level and finish meeting, or launch Goal Projector
                  </legend>
                  {firm?.modelPortfolios?.map((modelPortfolio) => (
                    <RadioButton
                      key={modelPortfolio.id}
                      checked={portfolio === modelPortfolio.id}
                      id={modelPortfolio.id}
                      invertColors={true}
                      label={
                        <div className="flex flex-row items-center text-sec">
                          <div className={clsx("mr-2", { "text-negative-600": portfolioError, "text-white": !portfolioError })}>{modelPortfolio.name}</div>
                          <div className="flex flex-col">
                            {clientOrHousehold?.currentPortfolio === modelPortfolio.id && (
                              <div className="flex flex-row items-center">
                                <div className="rounded-full h-1 w-1 mr-2 bg-alt-link-600"></div>
                                <div className="text-xs text-alt-link-600 uppercase">Current investment</div>
                              </div>
                            )}
                          </div>
                        </div>
                      }
                      isDark={true}
                      error={portfolioError}
                      name="portfolio"
                      value={modelPortfolio.name}
                      onChange={() => setPortfolio(() => modelPortfolio.id)}
                      className="mb-2 text-white items-center"
                    />
                  ))}
                </fieldset>

                {portfolioError && (
                  <div className="flex items-center mt-1">
                    <img alt="Info alert" className="mr-1" src={infoAlert} />
                    <p className="text-sm text-negative-600">Please select an investment risk</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="px-4 pb-4 w-full flex">
            <div onClick={() => navigate(-1)} className="cursor-pointer w-10">
              <img className="mt-8" src={arrowLeft} alt="arrow left icon" />
            </div>
            <div className="flex flex-col items-center grow">
              <button
                className="btn btn-medium btn-primary-alt mt-4 w-full h-14"
                type="button"
                disabled={status === "updating"}
                onClick={() => {
                  onFinish()
                    .then(() => {
                      setStatus(undefined)
                      if (clientOrHousehold?.primaryInvestmentGoal) {
                        navigate(
                          `/${client ? "clients" : "households"}/${clientOrHousehold?._id}/rm/goal-explorer?goal=${
                            clientOrHousehold.primaryInvestmentGoal === "retirementIncome"
                              ? "retirement-income"
                              : clientOrHousehold.primaryInvestmentGoal === "retirementDrawdown"
                              ? "retirement-drawdown"
                              : "wealth-accumulation"
                          }`
                        )
                      } else {
                        setIsInvestmentGoalModalOpen(true)
                      }
                    })
                    .catch(() => setStatus("error"))
                }}
              >
                Launch Goal Projector
              </button>
              <button
                className="mt-3 w-full py-1 border border-alt-interactive-300"
                type="button"
                disabled={status === "updating"}
                onClick={() => {
                  setShouldValidate(true)
                  if (portfolio) {
                    onFinish()
                      .then(() => {
                        setStatus(undefined)
                        setIsFinishModalOpen(true)
                      })
                      .catch(() => setStatus("error"))
                  }
                }}
              >
                {status === "updating" ? <Loading type="dots" /> : <span className="text-sec text-alt-interactive-300 font-bold">Finish meeting</span>}
              </button>
            </div>
          </div>
        </>
      )}
      <AnimatePresence>
        {isFinishModalOpen && <RMJFinishModal portfolio={portfolio} onClose={() => setIsFinishModalOpen(false)} />}
        {isInvestmentGoalModalOpen && <RMJInvestmentGoalModal client={client} household={household} onClose={() => setIsInvestmentGoalModalOpen(false)} />}
      </AnimatePresence>
    </div>
  )
}

export default RMJPortfolioComfortSideBar
