import { Advisor } from "../models/Advisor"
import { UserProfile } from "../models/UserProfile"
import { DeactivateTeamMember } from "../pages/firm/components/DeactivateTeamMemberModal"
import { SessionInfo } from "../views/auth/AuthContext"
import axiosInstance from "./axiosInstance"

export type UserProfileUpdateRequest = Partial<UserProfile & {
  password?: string // needs to be sent with the request to change sensitive informaiton like email
}>

export const update = async (fields: UserProfileUpdateRequest) => {
  const res = await axiosInstance
    .patch(`${import.meta.env.VITE_APP_API_BASE || ""}/api/user/profile`, fields)
    .then((res) => res.data)
  // console.log("res in ts", res)
  return res as UserProfile
}


export type NewUserProfile = {
  firstName?: string
  lastName?: string
  email?: string
  advisor?: Advisor
  allAdvisors?: boolean
  isAdmin?: boolean
}

export const createUserProfile = async (sessionInfo: SessionInfo, firmId: string, associate: NewUserProfile) => {
  const res = await axiosInstance
    .post<UserProfile>(
      `${import.meta.env.VITE_APP_API_BASE || ""}/api/firm/${firmId}/team`,
            {
        firstName: associate.firstName,
        lastName: associate.lastName,
        email: associate.email,
        advisorId: associate.advisor?._id,
        allAdvisors: associate.allAdvisors,
        isAdmin: associate.isAdmin
      }
    )
    .then((res) => res.data)
  console.log("res in ts", res)
  return res
}

export const deactivate = (deactivateAssociate: DeactivateTeamMember, deactiveDate: string): Promise<UserProfile> => {
  const transfer = deactivateAssociate.newAdvisorId ? `&transferClientsTo=${deactivateAssociate.newAdvisorId}` : ""
  return axiosInstance
    .delete(`${import.meta.env.VITE_APP_API_BASE || ""}/api/user/profile?deactiveDate=${deactiveDate}&email=${encodeURIComponent(deactivateAssociate.email)}${transfer}`)
    .then((res) => res.data)
}
