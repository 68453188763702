import { Suspense, lazy, useContext } from "react"
import { FirmContext } from "../../../contexts/FirmContext"
import { Client } from "../../../models/Client"
import { Household } from "../../../models/Household"
import Container from "./components/Container"
import Footer from "./components/Footer"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import { Trans } from "@lingui/macro"
import { format } from "date-fns"
import { tt } from "../../../lib/translations"
import Header from "./Header"
import { useTheme } from "../../../contexts/ThemeContext"

const MarkdownContent = lazy(() => import("../../../components/MarkdownContent/MarkdownContent"))

const DisclaimerPage = ({ page, client, household, content }: { page: number; client: Client; household: Household; content: string }) => {
  const { firm } = useContext(FirmContext)
  const theme = useTheme()
  const contact = theme.reports?.footer?.clientContactInfo
  return (
    <Container className="flex flex-col justify-between pt-10 relative">
      {theme.reports?.header?.showOnDisclaimerPage && <Header title={<Trans id="report-header-title">Your Goal Plan</Trans>} page={page} />}
      <div className="flex-auto mx-10">
        <h3 className="report-disclaimer-title font-semibold text-main-600 text-h4">
          <Trans id="report-disclaimer-title">Disclaimers</Trans>
        </h3>
        <div className="disclaimers-markdown-content mt-8 columns-2 gap-5 text-sec">
          <Suspense fallback={<Loading />}>{content !== null && <MarkdownContent className="report-markdown-content">{content}</MarkdownContent>}</Suspense>
        </div>
      </div>
      <div className="relative mx-10">
        {theme.reports?.footer?.showOnDisclaimerPage ? (
          <div className="disclaimer-footer-content flex text-xxs w-full mb-5">
            <div className="flex-1 flex flex-col">
              <a href={`mailto:${contact?.email}`}>{contact?.email}</a>
              <a href={contact?.url}>{contact?.url}</a>
            </div>
            <div className="flex-1 self-end">
              <p>{tt({ id: "disclaimer-footer-text-1", values: { 0: format(new Date(), "MMMM yyyy") } })}</p>
              <p>{tt({ id: "disclaimer-footer-text-2" })}</p>
            </div>
          </div>
        ) : (
          <Footer pageNum={page} client={client} household={household} firm={firm} />
        )}
      </div>
    </Container>
  )
}

export default DisclaimerPage
