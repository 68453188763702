import { Client, GameType } from "../models/Client"
import { SessionInfo } from "../views/auth/AuthContext"
import axiosInstance from "./axiosInstance"

export const createGame = async (_: SessionInfo, clientId: string, gameType: GameType) => {
  return axiosInstance
    .post(
      `${import.meta.env.VITE_APP_API_BASE || ""}/api/client/${clientId}/game?gameType=${gameType}`,
      {}
    )
    .then((res) => res.data) as Promise<Client>
}