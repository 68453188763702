import clsx from "clsx"
import { ReactNode } from "react"

interface Props {
  checked?: boolean
  id: string
  label?: string | ReactNode
  name?: string
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void
}

const Switch = ({ checked, id, label, name, onChange }: Props) => {
  return (
    <div className="switch flex items-center relative">
      <div className="switch-input-container flex relative mr-2">
        <input
          checked={checked}
          className={clsx(
            "switch-input w-9 h-6 appearance-none rounded-l-full rounded-r-full transition-all duration-300 outline-none focus:shadow-focus cursor-pointer",
            "after:absolute after:w-4 after:h-4 after:top-0 after:left-0 after:mt-1 after:content-[''] after:bg-white after:rounded-full after:ease-out after:duration-300",
            {
              "bg-interactive-500 after:translate-x-full": checked,
              "bg-interactive-300 after:ml-1": !checked
            }
          )}
          id={id}
          name={name}
          onChange={onChange}
          type="checkbox"
        />
      </div>
      <label htmlFor={id} className="flex-1 text-sec text-main-600 cursor-pointer">
        {label}
      </label>
    </div>
  )
}

export default Switch
