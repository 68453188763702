import React, { useMemo } from "react"
import { Client } from "../../../../../../models/Client"
import { Household } from "../../../../../../models/Household"

interface TooltipProps {
  x?: number
  y?: number
  index?: number
  chartWidth: number
  client: Client
  household: Household
  planningHorizonYear: number
}
const Tooltip = ({ x, y, planningHorizonYear, client, household, chartWidth }: TooltipProps) => {
  const width = 200
  const height = 70
  const offsetX = x! + width / 2 >= chartWidth ? 100 : 60
  const offsetY = 33
  const currentYear = new Date().getFullYear()

  const householdMember = useMemo(() => {
    return household
      ? household.members?.map(({ client }) => {
          const yearOfBirth = new Date(client.dob).getFullYear()
          const age = currentYear - yearOfBirth
          const firstName = client.firstName
          const planningHorizonAge = currentYear === planningHorizonYear ? age : age + (planningHorizonYear - currentYear)
          return { firstName, planningHorizonAge }
        })
      : undefined
  }, [currentYear, household, planningHorizonYear])

  const individualClient = useMemo(() => {
    const yearOfBirth = new Date(client?.dob).getFullYear()
    const age = currentYear - yearOfBirth
    const planningHorizonAge = currentYear === planningHorizonYear ? age : age + (planningHorizonYear - currentYear)
    return client
      ? {
          planningHorizonAge,
          firstName: client.firstName
        }
      : undefined
  }, [client, currentYear, planningHorizonYear])

  return (
    <g>
      <foreignObject width={width} height={height} x={x! - offsetX} y={y! + offsetY}>
        <div className="bg-white fixed px-3 py-3 text-sm text-main-500 shadow border border-interactive-200">
          <div className="flex flex-col">
            {household &&
              householdMember?.map(({ firstName, planningHorizonAge }, i) => (
                <div className="flex justify-between gap-x-2" key={i}>
                  <p>{firstName}'s age</p>
                  <p className="font-semibold">{planningHorizonAge} yrs</p>
                </div>
              ))}
          </div>

          {client && (
            <div className="flex gap-x-2">
              <p>{individualClient?.firstName}'s age</p>
              <p className="font-semibold">{individualClient?.planningHorizonAge} yrs</p>
            </div>
          )}
        </div>
      </foreignObject>
    </g>
  )
}

export default Tooltip
