import { useRef, useState } from "react"
import Dropdown from "../../../components/Dropdown/Dropdown"
import { AnimatePresence } from "framer-motion"
import Modal from "../../../components/Modal/Modal"
import DeactivateTeamMemberModal from "./DeactivateTeamMemberModal"
import { FEATURE_EDIT_TEAM_MEMBER } from "../../../config/features"
import edit from "../images/Edit.svg"
import remove from "../images/person-remove.svg"
import moreIcon from "../../../assets/images/icons/dots.svg"
import { TeamMember } from "../../../hooks/useTeamMemberSearch"

const TeamMenuItemList = ({ alignVertical, noOfClients, member }: { alignVertical?: "bottom" | "top"; noOfClients?: number | "-"; member: TeamMember }) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const menuBtn = useRef<HTMLButtonElement>(null)
  const [deactivateTeamMemberModal, setDeactivateTeamMemberModal] = useState<boolean>(false)

  const toggleMenu = () => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen)
  }

  const handleDeactivate = () => {
    setIsMenuOpen(false)
    setDeactivateTeamMemberModal(true)
  }

  return (
    <div className="relative flex items-center">
      <button className="p-1 border-2 border-transparent hover:border-interactive-200" onClick={toggleMenu} ref={menuBtn}>
        <img alt="Actions" src={moreIcon} />
      </button>
      {isMenuOpen && (
        <Dropdown overlayClassName="w-[220px]" align="left" alignVertical={alignVertical} handleClose={() => setIsMenuOpen(false)} trigger={menuBtn}>
          <ul className="list-none p-0 text-main-500">
            {FEATURE_EDIT_TEAM_MEMBER && (
              <li>
                <button
                  className="btn text-p p-4 hover:bg-interactive-100 cursor-pointer border-0 block w-full text-left font-normal"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Edit member details
                  <img src={edit} className="pl-1" aria-hidden alt="" />
                </button>
              </li>
            )}
            <li>
              <button
                className="btn text-p p-4 hover:bg-interactive-100 cursor-pointer border-0 flex justify-between items-center w-full text-left font-normal"
                onClick={handleDeactivate}
              >
                De-activate member
                <img src={remove} className="pl-1" aria-hidden alt="" />
              </button>
            </li>
          </ul>
        </Dropdown>
      )}
      <AnimatePresence>
        {deactivateTeamMemberModal && (
          <Modal handleClose={() => setDeactivateTeamMemberModal(false)}>
            <DeactivateTeamMemberModal
              handleClose={() => setDeactivateTeamMemberModal(false)}
              member={member}
              noOfClients={!member.isAssociate ? noOfClients! : 0}
            />
          </Modal>
        )}
      </AnimatePresence>
    </div>
  )
}

export default TeamMenuItemList
