import React, { useState, useContext, useEffect, Suspense, useRef } from "react"
import { AuthContext } from "../../views/auth/AuthContext"
import { UserPoliciesContext } from "../../contexts/UserPoliciesContext"
import Checkbox from "../Checkbox/Checkbox"
import Loading from "../ClientProfile/Loading/Loading"
import MarkdownContent from "../MarkdownContent/MarkdownContent"
import errorIcon from "../../pages/advisor/assets/images/error.svg"
import bulbIcon from "../../../src/assets/icons/bulb-beige.svg"
import { useTheme } from "../../contexts/ThemeContext"
import { Trans } from "@lingui/react"
import clsx from "clsx"
import { t } from "@lingui/macro"

const UserPolicies: React.FunctionComponent<{ handleClose: () => void; interactive?: boolean; modalType?: "billing" | "tos" | "privacy" }> = ({
  handleClose,
  interactive = true,
  modalType
}) => {
  const theme = useTheme()
  const { firmHasBilling, isBillingAccepted, isNewUser, isPrivacyPolicyAccepted, isTosAccepted, acceptPolicy } = useContext(UserPoliciesContext)
  const { signOut } = useContext(AuthContext)
  const [isChecked, setIsChecked] = useState({
    billing: false,
    tos: false,
    privacy: false
  })
  const [error, setError] = useState<boolean>(false)
  const [currentModal, setCurrentModal] = useState<"billing" | "tos" | "privacy" | undefined>(modalType)
  const contentRef = useRef<HTMLDivElement>(null)

  const modalContent = {
    billing: theme.policies.billing.markdown,
    tos: theme.policies.tos.markdown,
    privacy: theme.policies.privacy.markdown
  }

  const tosLink = t({ id: "terms-of-service-link" })
  const privacyLink = t({ id: "privacy-policy-link" })

  const onNext = () => {
    if (currentModal === "billing") {
      if (!isTosAccepted) {
        setCurrentModal("tos")
      } else if (!isPrivacyPolicyAccepted) {
        setCurrentModal("privacy")
      } else {
        handleClose()
      }
    } else if (currentModal === "tos") {
      if (!isPrivacyPolicyAccepted) {
        setCurrentModal("privacy")
      } else {
        handleClose()
      }
    } else if (currentModal === "privacy") {
      handleClose()
    }
  }

  const onContinue = () => {
    if (currentModal === "billing") {
      if (!isChecked.billing) {
        setError(true)
      } else {
        setError(false)
        acceptPolicy("billing")
        onNext()
      }
    } else if (currentModal === "tos") {
      if (!isChecked.tos) {
        setError(true)
      } else {
        setError(false)
        acceptPolicy("tos")
        onNext()
      }
    } else if (currentModal === "privacy") {
      if (!isChecked.privacy) {
        setError(true)
      } else {
        setError(false)
        acceptPolicy("privacy")
        onNext()
      }
    }
  }

  const onDecline = () => {
    signOut()
  }

  useEffect(() => {
    if (firmHasBilling && !isBillingAccepted) {
      setCurrentModal("billing")
    } else if (!isTosAccepted) {
      setCurrentModal("tos")
    } else if (!isPrivacyPolicyAccepted) {
      setCurrentModal("privacy")
    }
  }, [firmHasBilling, isBillingAccepted, isPrivacyPolicyAccepted, isTosAccepted])

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
    }
  }, [currentModal])

  return (
    <div className="p-0">
      <div className={clsx("mt-6 flex flex-col relative", { "mb-10": !interactive })}>
        <div className="mx-10">
          <h2 className="modal-title text-h4 md:text-h2 py-6">
            <Trans id={`user-policies-heading-${currentModal}`} />
          </h2>
          {interactive && (
            <div className="border border-alt-interactive-500 bg-surface-200 p-4 mb-6 text-sec flex gap-2 items-start">
              <img alt="" src={bulbIcon} aria-hidden />
              <div className="flex flex-col gap-1">
                {currentModal === "billing" && (!isBillingAccepted && isNewUser) ? (
                  <Trans id="user-policies-billing-note" />
                ) : currentModal === "tos" && !isTosAccepted ? (
                  <Trans id="user-policies-tos-note" />
                ) : currentModal === "privacy" && !isPrivacyPolicyAccepted ? (
                  <Trans id="user-policies-privacy-note" />
                ) : (
                  <p className="font-semibold">
                    We've updated our{" "}
                    {currentModal === "tos"
                      ? "Terms of Service"
                      : currentModal === "privacy"
                      ? "Privacy Policy"
                      : currentModal === "billing"
                      ? "subscription details"
                      : "policy"}
                    . Please read and accept to continue.
                  </p>
                )}
              </div>
            </div>
          )}
          {currentModal && (
            <div ref={contentRef} className="p-4 overflow-auto bg-surface-100" style={{ maxHeight: `calc(100vh - ${interactive ? "400px" : "200px"})` }}>
              <div className="flex flex-col gap-4 text-sm">
                {currentModal === "billing" && (
                  <div className="flex flex-col gap-5 text-sec">
                    <ul className="list-disc ml-4 font-normal">
                      <li>
                        <strong>
                          <Trans id="billing-policy-list-item-1">Monthly subscription fee is $99 + GST per adviser.</Trans>
                        </strong>
                      </li>
                      <li>
                        <Trans id="billing-policy-list-item-2">Unlimited number of client profiles can be created and managed.</Trans>
                      </li>
                      <li>
                        <Trans id="billing-policy-list-item-3">One additional associate user login provided for every adviser user, at no extra cost.</Trans>
                      </li>
                    </ul>
                    {isNewUser && (
                      <div className="flex flex-col gap-2 font-normal">
                        <h2 className="text-sec leading-4 font-bold">
                          <Trans id="billing-policy-new-user-heading">One month free trial</Trans>
                        </h2>
                        <p>
                          <Trans id="billing-policy-new-user-description">
                            As a new user you receive a one month free trial of the Risk Profiling Suite. If you do not wish to continue after the trial period,
                            please notify us within 30 days of receiving the login details to avoid being charged.
                          </Trans>
                        </p>
                      </div>
                    )}
                  </div>
                )}
                <Suspense fallback={<Loading />}>
                  <MarkdownContent>{modalContent[currentModal].content}</MarkdownContent>
                </Suspense>
                {currentModal === "billing" && (
                  <div className="font-normal text-main-400 text-sm flex flex-col gap-2 mb-4">
                    <p>
                      Capital Preferences reserves the right to change pricing at any time. For more information, please refer to our{" "}
                      <a className="link text-main-400" href={tosLink} target="_blank" rel="noreferrer">
                        Terms of Service
                      </a>{" "}
                      and{" "}
                      <a className="link" href={privacyLink} target="_blank" rel="noreferrer">
                        Privacy Policy
                      </a>
                      .
                    </p>
                    <p>
                      Contact us at{" "}
                      <a className="link text-main-400" href="mailto:support@capitalpreferences.com" target="_blank" rel="noreferrer">
                        support@capitalpreferences.com
                      </a>
                      .
                    </p>
                  </div>
                )}
              </div>
              {interactive && (
                <ul className="mb-6 list-none">
                  <li className="cursor-pointer">
                    <Checkbox
                      name={currentModal}
                      label={<Trans id={`user-policies-${currentModal}-checkbox`}>I have read and accept the policy</Trans>}
                      onChange={() => setIsChecked({ ...isChecked, [currentModal]: !isChecked[currentModal] })}
                      className="py-1 text-sec"
                      checked={isChecked[currentModal]}
                    />
                  </li>
                </ul>
              )}
            </div>
          )}
          <div role="alert">
            {error && (
              <p className="sr-only">
                <Trans id={`user-policies-error-${currentModal}`} />
              </p>
            )}
          </div>
          {error && (
            <div className="absolute bottom-24 left-0 flex justify-center w-full">
              <div className="toast-notification bg-white border border-interactive-200 flex flex-row gap-x-1 items-center shadow-sm px-2 py-1">
                <img src={errorIcon} alt="" aria-hidden />
                <p className="text-sm">
                  <Trans id={`user-policies-error-${currentModal}`} />
                </p>
              </div>
            </div>
          )}
        </div>
        {interactive && (
          <div className="flex justify-center gap-4 pt-4 pb-6 shadow-md">
            <button className="w-44 btn btn-secondary btn-medium" onClick={onDecline}>
              Decline
            </button>
            <button className="w-44 btn btn-primary btn-medium" onClick={onContinue}>
              Continue
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default UserPolicies
