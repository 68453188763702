import { Trans } from "@lingui/macro"
import { Client } from "../../../../../../models/Client"
import { useEffect, useState } from "react"
import { useTheme } from "../../../../../../contexts/ThemeContext"
import PortfolioMatchCard from "../PortfolioMatchCard"
import { AnimatePresence } from "framer-motion"
import Modal from "../../../../../../components/Modal/Modal"
import { Link } from "react-router-dom"
import ImportPortfolio from "../../../../../advisor/components/ImportModal/ImportPortfolio"
import { latest } from "../../../../../../lib/clients"

const PortfolioCompare = ({ client }: { client: Client }) => {
  const theme = useTheme()
  const [portfolioUploaded, setPortfolioUploaded] = useState(!!client.portfolio)
  const [showImportModal, setShowImportModal] = useState<boolean>(false)

  useEffect(() => {
    setPortfolioUploaded(!!client.portfolio)
  }, [client.portfolio])

  return (
    <>
      {portfolioUploaded ? (
        <>
          <div className="flex flex-col gap-6 mb-10">
            <div className="flex flex-col lg:flex-row gap-5 ">
              <PortfolioMatchCard clickable={true} client={client} type="current" />
              <PortfolioMatchCard clickable={true} client={client} type="proposed" />
            </div>
            <span className="text-xxs">
              <Trans id="funds-match-source-citation">Source: Calculated on MSCI Analytics</Trans>
            </span>
          </div>
          <div className="bg-interactive-100 rounded-2 w-full flex gap-3 justify-between items-center px-4 py-3">
            <div className="w-2/3 flex gap-3">
              {theme.pages.profile.sections.portfolioCompare.uploadNote?.icon && (
                <img src={theme.pages.profile.sections.portfolioCompare.uploadNote.icon} alt="" aria-hidden />
              )}
              <div>
                <h4 className="font-bold">
                  <Trans id="portfolio-compare-analyser-note-heading">
                    Improve {client.firstName}'s Sustainability match to{" "}
                    {client.portfolio?.proposed?.smScore ?? latest(client, "esg")?.esg.results?.portfolioMatch?.smScore}%
                  </Trans>
                </h4>
                <span>
                  <Trans id="portfolio-compare-analyser-note">
                    The Portfolio Analyser will provide recommendations on which funds will improve {client.firstName}'s Sustainability match score.
                  </Trans>
                </span>
              </div>
            </div>
            <Link to={`/clients/${client?._id}/portfolio-analyser`} className="btn btn-primary btn-medium" onClick={() => setPortfolioUploaded(true)}>
              <Trans id="portfolio-compare-analyser-note-btn">Portfolio analyser</Trans>
            </Link>
          </div>
        </>
      ) : (
        <div className="flex flex-col xl-max:flex-row xl-max:items-center justify-between gap-4">
          <PortfolioMatchCard type="disabled" />
          <div className="bg-interactive-100 rounded-2 w-full xl-max:w-1/2 flex gap-3 items-center px-4 py-3">
            {theme.pages.profile.sections.portfolioCompare.uploadNote?.icon && (
              <img src={theme.pages.profile.sections.portfolioCompare.uploadNote.icon} alt="" aria-hidden></img>
            )}
            <span>
              <Trans id="portfolio-compare-upload-note">
                Upload {client.firstName}'s current portfolio to see how it measures up to their Sustainability match
              </Trans>
            </span>
            <button className="btn btn-primary btn-medium" onClick={() => setShowImportModal(true)}>
              <Trans id="portfolio-compare-upload-note-btn">Upload</Trans>
            </button>
          </div>
        </div>
      )}
      <AnimatePresence>
        {showImportModal && (
          <Modal handleClose={() => setShowImportModal(false)}>
            <ImportPortfolio handleClose={() => setShowImportModal(false)} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  )
}

export default PortfolioCompare
