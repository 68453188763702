import clsx from "clsx"
import { useEffect, useRef, useState } from "react"
import { Household } from "../../../../../models/Household"
import riskComfortIcon from "../../../assets/images/riskComfort.svg"
import pieChartFillIcon from "../../assets/pie-chart-fill.svg"
import { portfolioComfortMatch } from "../../comfortMatch"
import { Datum } from "../RMJPortfolioComfortChart"
import RMJPortfolioComfortTooltip from "./ChartTooltip"
import ClientRiskComfortIcon from "./ClientRiskComfortIcon"
import { AVATAR_ICON_FILL_CLASSES } from "../../RMJPortfolioComfort"
import { useTheme } from "../../../../../contexts/ThemeContext"
import AvatarBadges from "../../../../../components/AvatarBadges/AvatarBadges"

interface Props {
  datum?: Datum
  x?: number
  y?: number
  onComfortMatchMouseOver: (x: number, y: number, clientName?: string) => void
  onComfortMatchMouseOut: () => void
  onCurrentInvestmentMouseOver: (x: number, y: number) => void
  onCurrentInvestmentMouseOut: () => void
  household?: Household
  forReport: boolean
  isPostMeeting: boolean
  setCoordinates?: (coordinates: { x: number; y: number; width: number, comfortScore: number }) => void
}

const height = 64
const offsetY = -58
const width = 20

const ChartPoint = ({
  x,
  y,
  datum,
  household,
  forReport,
  isPostMeeting,
  onComfortMatchMouseOver,
  onComfortMatchMouseOut,
  onCurrentInvestmentMouseOver,
  onCurrentInvestmentMouseOut,
  setCoordinates
}: Props) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const textRef = useRef<SVGTextElement>(null)
  const theme = useTheme()
  const matchingMembers = household?.members?.filter(({ client }) => datum?.portfolioMapping.portfolio.id === portfolioComfortMatch(client)?.portfolio.id)
  useEffect(() => {
    if (setCoordinates && textRef?.current) setCoordinates({ x: x!, y: y!, width: textRef.current.getBoundingClientRect().width, comfortScore: datum!.portfolioMapping.riskComfort! })
  }, [datum, datum?.portfolioMapping.riskComfort, setCoordinates, x, y])

  return (
    <g>
      <foreignObject x={x ? x - width / 2 : undefined} y={y ? y + offsetY : undefined} width={width} height={height} className="overflow-visible">
        {datum && (
          <div className="h-full flex flex-col items-center justify-end chart-point">
            {datum.isCurrentInvestment && (
              <div
                className={clsx("flex flex-col items-center", {
                  "h-14": forReport && theme.charts?.comfortMatch?.useAvatarIcons === false,
                  "h-full":
                    (theme.charts?.comfortMatch?.useAvatarIcons === false && !forReport) ||
                    (theme.charts?.comfortMatch?.useAvatarIcons === true && (matchingMembers?.length === 0 || !household))
                })}
              >
                <div className="cursor-pointer" onMouseOver={() => onCurrentInvestmentMouseOver(x!, y! + offsetY)} onMouseOut={onCurrentInvestmentMouseOut}>
                  <img alt="" className="w-4 shrink-0 mb-px" src={pieChartFillIcon} />
                </div>
                {theme.charts?.comfortMatch?.useAvatarIcons === false ||
                (theme.charts?.comfortMatch?.useAvatarIcons === true && (matchingMembers?.length === 0 || !household)) ? (
                  <div className="w-px grow bg-avatar-0-400 -mt-1" />
                ) : (
                  null
                )}
              </div>
            )}
            {!isPostMeeting && datum.isComfortMatch && (
              <div
                className={clsx("flex flex-col items-center", {
                  "h-14": forReport && theme.charts?.comfortMatch?.useAvatarIcons === false,
                  "h-full":
                    (!forReport && theme.charts?.comfortMatch?.useAvatarIcons === false) ||
                    (theme.charts?.comfortMatch?.useAvatarIcons === true && (matchingMembers?.length === 0 || !household))
                })}
              >
                <div
                  className="cursor-pointer w-[17px] risk-comfort-img-container"
                  onMouseOver={() => onComfortMatchMouseOver(x!, y! - (datum.isCurrentInvestment ? height / 2 : height))}
                  onMouseOut={onComfortMatchMouseOut}
                >
                  <img alt="" className="risk-comfort-img shrink-0 mx-auto mb-px" src={riskComfortIcon} />
                </div>
                {theme.charts?.comfortMatch?.useAvatarIcons === false ||
                (theme.charts?.comfortMatch?.useAvatarIcons && (matchingMembers?.length === 0 || !household)) ? (
                  <div className="w-px grow bg-riskComfort-400 -mt-1" />
                ) : (
                  null
                )}
              </div>
            )}
            {(!isPostMeeting || theme.charts?.comfortMatch?.useAvatarIcons) &&
              household?.members?.map(
                ({ client }, i) =>
                  datum.portfolioMapping.portfolio.id === portfolioComfortMatch(client)?.portfolio.id && (
                    <div className="flex flex-col items-center" key={client._id}>
                      <div className="cursor-pointer" onMouseOver={() => onComfortMatchMouseOver(x!, y!, client.firstName)} onMouseOut={onComfortMatchMouseOut}>
                        {theme.charts?.comfortMatch?.useAvatarIcons ? (
                          <div className="mb-0.5">
                            <AvatarBadges clients={[client]} size="xs" colorStartIndex={i} />
                          </div>
                        ) : (
                          <ClientRiskComfortIcon className={AVATAR_ICON_FILL_CLASSES[i]} />
                        )}
                      </div>
                    </div>
                  )
              )}

            <div
              className="w-3.5 h-3.5 rounded-full shrink-0 cursor-pointer"
              onMouseOver={() => setIsTooltipVisible(true)}
              onMouseOut={() => setIsTooltipVisible(false)}
              style={{ backgroundColor: datum.fill }}
            />
          </div>
        )}
      </foreignObject>
      <text
        className={clsx("text-main-600 font-semibold pointer-events-none", { "text-sm": forReport, "text-sec": !forReport })}
        textAnchor="middle"
        x={x ? x : undefined}
        ref={textRef}
      >
        {datum?.label.split(/\s+/).map((l, index) => (
          <tspan key={`${x},${index}`} x={x ? x : undefined} y={y! + 24 + index * 16}>
            {l}
          </tspan>
        ))}
      </text>
      {isTooltipVisible && <RMJPortfolioComfortTooltip x={x} y={y} datum={datum} />}
    </g>
  )
}

export default ChartPoint
