import clsx from "clsx"
import { ReactNode } from "react"

type Props = {
  toggle1: ReactNode
  toggle2: ReactNode
  onClick: (valueDisplayType: "list" | "chart") => void
  tabIndex?: number
  valueDisplayType: "list" | "chart"
}

const ChartDisplayToggle = ({ toggle1, toggle2, onClick: onClickProp, tabIndex, valueDisplayType }: Props) => {

  const onClick = () => {
    onClickProp(valueDisplayType === "list" ? "chart" : "list")
  }

  return (
    <button
      className="w-18 h-9 flex outline-none focus:border-focuscolor focus:shadow-focus p-1"
      style={{ backgroundColor: "#f2f0ed"}}
      // aria-label={valueDisplayType === "list" ? "%" : currency}
      onClick={onClick}
      tabIndex={tabIndex}
    >
      <span
        className={clsx("w-8 h-full flex items-center justify-center text-lg leading-5 cursor-pointer text-grape-400 transition-all duration-500 mr-1px", {
          "w-10 bg-white shadow": valueDisplayType === "chart"
        })}
      >
        {toggle1}
      </span>
      <span
        className={clsx("w-8 h-full flex items-center justify-center text-lg leading-5 cursor-pointer text-grape-400 transition-all duration-500", {
          "w-10 bg-white shadow": valueDisplayType === "list"
        })}
      >
        {toggle2}
      </span>
    </button>
  )
}

export default ChartDisplayToggle
