import { PortfolioReturn } from "../../../../api/rm/goals"
import { InvestmentGoal } from "../../../../models/Client"
import { isNullOrUndefined } from "./validation"

interface Props {
  goalType: InvestmentGoal
  portfolios?: PortfolioReturn[]
  otherSourcesIncome?: number
  retirementIncomeGoal?: number
  wealthAccumulationGoal?: number
}

export const calcGoalMatch = ({ goalType, portfolios, otherSourcesIncome, retirementIncomeGoal, wealthAccumulationGoal }: Props) => {
  if (portfolios) {
    if (goalType === "retirementIncome") {
      if (!isNullOrUndefined(otherSourcesIncome) && !isNullOrUndefined(retirementIncomeGoal)) {
        return portfolios
          .filter(({ percentiles: { 30: income } }) => {
            return (otherSourcesIncome ?? 0) + income >= retirementIncomeGoal!
          })
          .sort((valA, valB) => {
            return valA.sd! - valB.sd!
          })[0]
      } else {
        return undefined
      }
    } else {
      if (!isNullOrUndefined(wealthAccumulationGoal)) {
        return portfolios
          .filter(({ percentiles: { 30: income } }) => {
            return income >= wealthAccumulationGoal!
          })
          .sort((valA, valB) => {
            return valA.sd! - valB.sd!
          })[0]
      } else {
        return undefined
      }
    }
  }
}
