import { PortfolioReturn } from "../../../../api/rm/goals"
import { PortfolioMapping } from "../../../../models/Client"

interface Props {
  portfolioMappings?: PortfolioMapping[]
  portfolios?: PortfolioReturn[]
}

export const calcComfortMatch = ({ portfolioMappings, portfolios }: Props) => {
  const comfortMatches = [...(portfolioMappings || [])].sort((a, b) => {
    const d = b.riskComfort! - a.riskComfort!
    return d ? d : (a.portfolio!.sd || 0) - (b.portfolio!.sd || 0)
  })

  return portfolios?.filter((portfolio) => {
    return portfolio.id === comfortMatches?.[0]?.portfolio?.id
  })[0]
}
