import { useState } from "react"
import EnterNewPassword from "./EnterNewPassword"
import RequestCode from "./RequestCode"
import VerifyCode from "./EnterCode"

function ForgotPasswordFlow({ setupAccountSkin }: { setupAccountSkin?: boolean }) {
  const [screen, setScreen] = useState<"enterEmail" | "enterCode" | "enterPassword">("enterEmail")
  const [username, setUsername] = useState("")
  const [token, setToken] = useState("")
  // const [code, setCode] = useState("")
  const [verifyCodeError, setVerifyCodeError] = useState("")

  const screens = {
    enterEmail: (
      <RequestCode
        onNextClick={(username, token) => {
          setUsername(username)
          setToken(token)
          setScreen("enterCode")
        }}
        isSettingUp={setupAccountSkin}
      />
    ),
    enterCode: (
      <VerifyCode
        onNextClick={(token) => {
          setToken(token)
          setScreen("enterPassword")
        }}
        onTryAnotherEmail={() => {
          // setCode("")
          setToken("")
          setScreen("enterEmail")
        }}
        token={token}
      />
    ),
    enterPassword: (
      <EnterNewPassword
        token={token}
        onError={(err) => {
          setVerifyCodeError(err.message)
          setScreen("enterCode")
        }}
      />
    )
  }

  return <div className="max-w-screen-md">{screens[screen]}</div>
}

export default ForgotPasswordFlow
