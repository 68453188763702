import { useContext, useEffect, useMemo, useState } from "react"
import { update } from "../../api/firms"
import TextInput from "../../components/TextInput/TextInput"
import { FirmContext } from "../../contexts/FirmContext"
import { AuthContext } from "../../views/auth/AuthContext"
import { validateEmail } from "../../lib/email"
import Loading from "../../components/ClientProfile/Loading/Loading"

const FirmAdminPage = () => {
  const { sessionInfo } = useContext(AuthContext)
  const { firm, reloadFirm } = useContext(FirmContext)
  const [logo, setLogo] = useState<string | undefined>(firm?.logo)
  const [clientSupportEmail, setClientSupportEmail] = useState<string | undefined>(firm?.config?.clientSupportEmail)
  const [, setCounter] = useState(0)
  const [firmUpdateState, setFirmUpdateState] = useState<"updating" | "success" | "error" | undefined>()

  useEffect(() => {
    setFirmUpdateState(undefined)
    return () => clearTimeout(setTimeout(() => setCounter((prev) => prev + 1), 1000)) // force rerender
  }, [logo, setCounter])

  const logoSection = (
    <div className="w-1/2">
      <TextInput name="firmLogo" value={logo} onChange={setLogo} label="Update logo" />
      <div className="flex items-end justify-center gap-x-4">{logo && <img className="" src={logo} alt="logo" />}</div>
    </div>
  )

  const isEmailInvalid = useMemo(() => clientSupportEmail?.trim() && !validateEmail(clientSupportEmail.trim()), [clientSupportEmail])

  return (
    <div className="pg-ctr pg-ctr-py flex gap-y-8 px-10 flex-col w-full h-full overflow-y-auto no-scrollbar">
      <h1 className="text-h3">{firm?.name}</h1>
      {logoSection}
      <div className="w-1/2">
        <TextInput
          label="Client support email address"
          name="email"
          value={firm?.config?.clientSupportEmail}
          onChange={(value) => {
            setClientSupportEmail(value)
          }}
          disableAutoComplete
          error={isEmailInvalid ? "Please enter a valid email address" : undefined}
        />
      </div>
      <button
        className="btn btn-medium btn-primary w-44 self-start"
        disabled={firmUpdateState === "updating" || isEmailInvalid || (firm?.logo === logo && firm?.config?.clientSupportEmail === clientSupportEmail)}
        onClick={() => {
          setFirmUpdateState("updating")
          update(sessionInfo!, firm!._id, { logo, clientSupportEmail: clientSupportEmail?.trim() })
            .then(() => {
              reloadFirm()
              setFirmUpdateState("success")
            })
            .catch(() => setFirmUpdateState("error"))
        }}
      >
        {firmUpdateState === "updating" ? <Loading type="dots" /> : "Update"}
      </button>
      {firmUpdateState && firmUpdateState !== "updating" && (
        <p className={firmUpdateState === "success" ? "text-positive-600" : "text-error"}>
          {firmUpdateState === "success" ? "Success!!" : "Failed to update firm details"}
        </p>
      )}
    </div>
  )
}

export default FirmAdminPage
