import { useContext, useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"
import axiosInstance from "../api/axiosInstance"
import { AppContext } from "../contexts/AppContext"
import { Advisor } from "../models/Advisor"
import { UserProfile } from "../models/UserProfile"
import { AuthStatus, SessionInfo } from "../views/auth/AuthContext"

export type TeamMember = {
  firstName: string
  lastName: string
  email: string
  createdAt: string
  isAssociate: boolean
  noOfClients: number
  isFirmAdmin: boolean
  manageAllClients: boolean
  advisor: Advisor
  userProfile: UserProfile
  deactivatedAt?: boolean
}

const useTeamMemberSearch = (sessionInfo?: SessionInfo, authStatus?: AuthStatus) => {
  const [params, setParams] = useSearchParams()
  const [search, setSearch] = useState<string>(() => params.get("search") || "")
  const { firmId } = useContext(AppContext)

  const {
    data: teamMembers,
    isLoading: teamMembersLoading,
    error: teamMembersError,
    refetch
  } = useQuery<TeamMember[], { message?: string }>(
    ["teamMembers", firmId],
    () =>
      axiosInstance
        .get<TeamMember[]>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/firm/${firmId}/team`)
        .then((res) => res.data),
    {
      enabled: authStatus === AuthStatus.SignedIn && !!sessionInfo?.accessToken && !!firmId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 900,
      keepPreviousData: true
    }
  )

  const filteredMembers = useMemo(() => {
    return teamMembers?.filter((member) => {
      const { firstName, lastName, deactivatedAt } = member
      if (firstName && lastName && !deactivatedAt) {
        const fullName = `${firstName} ${lastName}`
        return fullName.toLowerCase().includes(search.toLowerCase())
      }
      return false
    })
  }, [teamMembers, search])

  useEffect(() => {
    setParams(search ? { search } : {})
  }, [search, setParams])

  return {
    search,
    setSearch,
    refetch,
    members: filteredMembers,
    isLoading: teamMembersLoading,
    error: teamMembersError
  }
}

export default useTeamMemberSearch
