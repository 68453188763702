import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import gfm from "remark-gfm"
import clsx from "clsx"

const Content = styled.div`
  text-align: left;

  a {
    color: #004AC8;
  }

  p {
    margin: 0 0 15px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
  }

  ul,
  ol {
    margin: 0 0 20px;
    padding: 0 0 0 30px;

    p {
      margin: 0 0 10px;
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: numeric;
  }

  li {
    margin: 0 0 20px;

    li {
      margin: 0 0 10px;
    }
  }
`

const MarkdownContent = ({ className, children }: { className?: string, children: string }) => {
  return (
    <Content className={clsx("markdown-content", className)}>
      <ReactMarkdown remarkPlugins={[gfm]}>{children}</ReactMarkdown>
    </Content>
  )
}

export default MarkdownContent