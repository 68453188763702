import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo } from "react"
import { AppContext } from "./AppContext"
import { useNavigate } from "react-router"
import { useTheme } from "./ThemeContext"
import { FirmContext } from "./FirmContext"

type Policy = "billing" | "privacy" | "tos"

type UserPoliciesContextType = {
  acceptPolicy: (policy: Policy) => void
  firmHasBilling: boolean
  isBillingAccepted: boolean
  isNewUser: boolean
  isPrivacyPolicyAccepted: boolean
  isTosAccepted: boolean
}

const defaults: UserPoliciesContextType = {
  acceptPolicy: () => {},
  firmHasBilling: false,
  isBillingAccepted: false,
  isNewUser: false,
  isPrivacyPolicyAccepted: false,
  isTosAccepted: false
}

export const UserPoliciesContext = createContext<UserPoliciesContextType>(defaults)

const UserPoliciesContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { userProfile, updateUserProfile } = useContext(AppContext)
  const { firm } = useContext(FirmContext)
  const navigate = useNavigate()
  const theme = useTheme()
  
  const billingVersion = theme.policies.billing.markdown.version
  const privacyVersion = theme.policies.privacy.markdown.version
  const tosVersion = theme.policies.tos.markdown.version

  const firmHasBilling = useMemo(() => {
    return !!(firm?.billingCode && firm?.billingEffective && new Date(firm.billingEffective) < new Date() && firm?.billingOptIn)
  }, [firm?.billingCode, firm?.billingEffective, firm?.billingOptIn])

  const isBillingAccepted = useMemo(
    () => !firmHasBilling || (userProfile?.policies?.billing?.version === billingVersion && !!userProfile.policies.billing.accepted),
    [billingVersion, firmHasBilling, userProfile?.policies?.billing?.accepted, userProfile?.policies?.billing?.version]
  )
  const isTosAccepted = useMemo(
    () => userProfile?.policies?.tos?.version === tosVersion && !!userProfile.policies.tos.accepted,
    [tosVersion, userProfile?.policies?.tos?.accepted, userProfile?.policies?.tos?.version]
  )
  const isPrivacyPolicyAccepted = useMemo(
    () => userProfile?.policies?.privacy?.version === privacyVersion && !!userProfile.policies.privacy.accepted,
    [privacyVersion, userProfile?.policies?.privacy?.accepted, userProfile?.policies?.privacy?.version]
  )

  const isNewUser = !userProfile?.policies?.tos?.accepted

  useEffect(() => {
    if (userProfile) {
      if (
        (theme.policies.privacy.enabled && !isPrivacyPolicyAccepted) ||
        (theme.policies.tos.enabled && !isTosAccepted) ||
        (theme.policies.billing.enabled && !isBillingAccepted)
      ) {
        navigate("/")
      }
    }
  }, [
    isPrivacyPolicyAccepted,
    isTosAccepted,
    navigate,
    userProfile,
    theme.policies.privacy.enabled,
    theme.policies.tos.enabled,
    theme.policies.billing.enabled,
    isBillingAccepted
  ])

  const acceptPolicy = useCallback(
    (policy: Policy) => {
      const policies: { [key in Policy]?: { version: string; accepted: Date; billingCode?: string; billingEffective?: Date | string } } = { ...userProfile?.policies }
      if (policy === "billing") {
        policies.billing = { version: billingVersion, billingCode: firm?.billingCode, billingEffective: firm?.billingEffective, accepted: new Date() }
      }
      if (policy === "privacy") {
        policies.privacy = { version: privacyVersion, accepted: new Date() }
      }
      if (policy === "tos") {
        policies.tos = { version: tosVersion, accepted: new Date() }
      }
      updateUserProfile({ policies })
    },
    [billingVersion, firm?.billingCode, firm?.billingEffective, privacyVersion, tosVersion, updateUserProfile, userProfile?.policies]
  )

  return (
    <UserPoliciesContext.Provider
      value={{
        acceptPolicy,
        firmHasBilling,
        isBillingAccepted,
        isNewUser,
        isPrivacyPolicyAccepted,
        isTosAccepted
      }}
    >
      {children}
    </UserPoliciesContext.Provider>
  )
}

export default UserPoliciesContextProvider
