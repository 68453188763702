import clsx from "clsx"
import { AnimatePresence, motion } from "framer-motion"
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useQuery } from "react-query"
import { useNavigate } from "react-router"
import axiosInstance from "../../api/axiosInstance"
import ScatterPlot from "../../components/AdvisorAnalytics/ScatterPlot"
import Checkbox from "../../components/Checkbox/Checkbox"
import Dropdown from "../../components/Dropdown/Dropdown"
import { ClientHouseholdCacheContext } from "../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../contexts/FirmContext"
import useTrackViewEvent from "../../hooks/useTrackViewEvent"
import { Client, RiskScores } from "../../models/Client"
import { AuthContext, AuthStatus } from "../../views/auth/AuthContext"
import closeIcon from "../advisor/assets/images/close.svg"
import searchIconBrown from "../advisor/assets/images/search-light-brown.svg"
import filterIcon from "./assets/images/chevron-down.svg"
import sortIcon from "./assets/images/sort-neutral.svg"
import sortDescIcon from "./assets/images/sort-up.svg"
import SearchBox from "./components/SearchBox"
import { tt } from "../../lib/translations"
import { Trans } from "@lingui/macro"

type AdvisorDesc = {
  _id: string
  firstName: string
  lastName: string
}

type FilterCriteria = {
  advisorIds?: string[]
  currentInvestments?: string[]
  atrCriteria?: string[]
  stlCriteria?: string[]
}

type FilterKeys = "advisorIds" | "currentInvestments" | "atrCriteria" | "stlCriteria"

type ScatterRecord = {
  advisors: AdvisorDesc[]
  clients: Partial<Client & RiskScores & { householdId?: string; riskComfort: number; atr: string; stl: string; performanceRating: number }>[]
}

type CountResult = {
  q1: number
  q2: number
  q3: number
  q4: number
}
export interface ScatterData {
  advisorId?: string
  atr?: string
  performanceRating?: number
  cceiScore?: number
  createdAt?: string
  currentPortfolio?: string
  firstName?: string
  householdId?: string
  lastName?: string
  lossAversion?: number
  riskComfort?: number
  riskTolerance?: number
  status?: string
  stl?: string
  _id?: string
}

type QUADRANT_KEYS = "q1" | "q2" | "q3" | "q4"

const AnalyticsPage = () => {
  const { sessionInfo, authStatus, isSuperAdmin } = useContext(AuthContext)
  const { lastUpdate } = useContext(ClientHouseholdCacheContext)
  const [previousData, setPreviousData] = useState<ScatterRecord | null>(null)
  const [activeArea, setActiveArea] = useState<QUADRANT_KEYS>()
  const [showSearchField, setShowSearchField] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [showTooltip, setShowTooltip] = useState(false)
  const [showInvestmentFilter, setShowInvestmentFilter] = useState(false)
  const [showAtrFilter, setShowAtrFilter] = useState(false)
  const [showStlFilter, setShowStlFilter] = useState(false)
  const [showAdvisorFilter, setShowAdvisorFilter] = useState(false)
  const [filterCriteria, setFilterCriteria] = useState<FilterCriteria>({})
  const [filteredClientList, setFilteredClientList] = useState<ScatterData[]>()
  const [sortOrders, setSortOrders] = useState({
    clientName: 0,
    riskComfort: 0,
    performanceRating: 0
  })
  const [sortBy, setSortBy] = useState<"clientName" | "riskComfort" | "performanceRating" | null>(null)
  const navigate = useNavigate()
  const trackViewEvent = useTrackViewEvent()
  const showPerformanceRatingColumn = import.meta.env.VITE_APP_FEATURE_PERFORMANCE_RATING_COLUMN !== "false"
  const investmentFilterBtn = useRef<HTMLButtonElement>(null)
  const atrFilterBtn = useRef<HTMLButtonElement>(null)
  const stlFilterBtn = useRef<HTMLButtonElement>(null)
  const advisorFilterBtn = useRef<HTMLButtonElement>(null)

  const { firm } = useContext(FirmContext)

  const {
    data: xScatterData,
    isLoading: isRefreshing,
    refetch,
    error
  } = useQuery<ScatterRecord, { message?: string }>(
    ["/analytics/scatter", sessionInfo?.accessToken, lastUpdate?._id, lastUpdate?.status, firm?._id],
    () =>
      axiosInstance
        .get<Omit<ScatterRecord, "clients"> & { clients: { [key: string]: any[] } }>(
          `${import.meta.env.VITE_APP_API_BASE || ""}/api/advisor/clients/analytics/scatter?firmId=${firm?._id}`
        )
        .then((res) => {
          return res.data
        })
        .then((newData) => {
          function reverseTranspose<T = { [key: string]: any }>(transposed: { [key: string]: any }): T[] {
            const keys = Object.keys(transposed)
            const length = transposed[keys[0]].length
            const result: T[] = []

            for (let i = 0; i < length; i++) {
              const obj: { [key: string]: any } = {}
              keys.forEach((key) => {
                obj[key] = transposed[key][i]
              })
              result.push(obj as T)
            }

            return result
          }

          return {
            advisors: newData.advisors,
            clients: reverseTranspose(newData.clients) as Partial<Client & RiskScores & { householdId?: string }>[]
          }
        }),
    {
      enabled: authStatus === AuthStatus.SignedIn && !!sessionInfo?.accessToken,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onSuccess: (newData) => setPreviousData(newData)
    }
  )

  const isLoading = isRefreshing && !previousData

  const scatterData = useMemo(() => xScatterData ?? previousData, [previousData, xScatterData])

  useEffect(() => {
    if (lastUpdate) {
      refetch()
    }
  }, [lastUpdate, refetch])

  const advisors = useMemo(
    () => scatterData?.advisors.reduce((acc, a) => ({ ...acc, [a._id]: a }), {} as { [key: string]: AdvisorDesc }),
    [scatterData?.advisors]
  )

  const atrStlAreaSelectionHandler = useCallback((chartData: ScatterData[]) => {
    const result: CountResult = {
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0
    }

    chartData?.forEach(({ lossAversion, riskTolerance }) => {
      const quadrant = riskTolerance! < 50 ? (lossAversion! < 20 ? "q1" : "q2") : lossAversion! < 20 ? "q3" : "q4"
      result[quadrant] += 1
    })

    return [
      { key: "q1", count: result.q1, color: "bg-interactive-200" },
      { key: "q2", count: result.q2, color: "bg-interactive-200" },
      { key: "q3", count: result.q3, color: "bg-data-olive-300" },
      { key: "q4", count: result.q4, color: "bg-red-200" }
    ]
  }, [])

  const clientList: ScatterData[] = useMemo(
    () =>
      scatterData?.clients
        .sort((a, b) => (!a.riskComfort && !b.riskComfort ? 0 : !a.riskComfort ? 1 : !b.riskComfort ? -1 : (a.riskComfort ?? 0) - (b.riskComfort ?? 0)))
        .map((data) => ({
          ...data,
          atr: data.riskTolerance! < 50 ? "Lower" : "Higher",
          stl: data.lossAversion! < 20 ? "More composed" : "More unsettled",
          performanceRating: Number((Math.random() * 20 - 10).toFixed(2))
        })) ?? [],
    [scatterData?.clients]
  )

  const handleFilterMatch = useCallback((value: string | string[], filterArray?: string[]) => {
    if (!filterArray || filterArray.length === 0) return true
    if (Array.isArray(value)) {
      return filterArray.some((filterValue) => value.includes(filterValue))
    }
    return filterArray.includes(value)
  }, [])

  const filterListHandler = useCallback(
    (filterCriteria: FilterCriteria, clientList: ScatterData[]) => {
      const { advisorIds, atrCriteria, stlCriteria, currentInvestments } = filterCriteria
      if (!advisorIds && !atrCriteria && !stlCriteria && !currentInvestments) {
        return clientList
      }
      return clientList?.filter(({ advisorId, atr, stl, currentPortfolio }) => {
        const advisorIdsMatch = handleFilterMatch(advisorId!, advisorIds)
        const atrCriteriaMatch = handleFilterMatch(atr!, atrCriteria)
        const stlCriteriaMatch = handleFilterMatch(stl!, stlCriteria)
        const currentInvestmentsMatch = handleFilterMatch(currentPortfolio!, currentInvestments)
        return advisorIdsMatch && atrCriteriaMatch && stlCriteriaMatch && currentInvestmentsMatch
      })
    },
    [handleFilterMatch]
  )

  const clearAtrStlFilter = useCallback(() => {
    setFilterCriteria((prev) => ({
      ...prev,
      atrCriteria: undefined,
      stlCriteria: undefined
    }))
  }, [])

  const handleFilterbyQuadrant = useCallback(
    (quadrant: QUADRANT_KEYS, clientList: ScatterData[]) =>
      clientList?.filter(({ lossAversion, riskTolerance }) =>
        quadrant === "q1"
          ? lossAversion! < 20 && riskTolerance! < 50
          : quadrant === "q2"
          ? riskTolerance! < 50 && lossAversion! >= 20
          : quadrant === "q3"
          ? riskTolerance! >= 50 && lossAversion! < 20
          : riskTolerance! >= 50 && lossAversion! >= 20
      ),
    []
  )

  const analyticsData = useMemo(() => {
    const chartData = scatterData?.clients.map((client) => {
      const {
        advisorId = "",
        cceiScore = 0,
        firstName = "",
        lastName = "",
        lossAversion = 0,
        riskTolerance = 0,
        _id = "",
        status = "",
        householdId,
        riskComfort = 0,
        atr = "",
        stl = "",
        performanceRating = 0
      } = client
      return {
        advisorId,
        cceiScore,
        firstName,
        lastName,
        lossAversion,
        riskTolerance,
        status,
        riskComfort,
        atr,
        stl,
        performanceRating,
        _id,
        householdId
      }
    })

    const stlAtrCardData = atrStlAreaSelectionHandler(chartData as ScatterData[])

    return { chartData, stlAtrCardData }
  }, [scatterData?.clients, atrStlAreaSelectionHandler])

  const currentPortfolios = useMemo(
    () =>
      scatterData?.clients
        .reduce((acc, a) => {
          if (a.currentPortfolio && !acc.includes(a.currentPortfolio)) {
            acc.push(a.currentPortfolio)
          }
          return acc
        }, [] as string[])
        .map((portfolio) => ({ name: portfolio })),
    [scatterData?.clients]
  )

  useEffect(() => {
    let filteredClients = clientList
    if (activeArea) {
      filteredClients = handleFilterbyQuadrant(activeArea, filteredClients)
    }

    if (Object.keys(filterCriteria).length > 0) {
      filteredClients = filterListHandler(filterCriteria, filteredClients)
    }

    if (sortBy) {
      const sortOrder = sortOrders[sortBy]
      filteredClients = [...filteredClients].sort((a, b) => {
        if (sortBy === "clientName") {
          const clientNameA = `${a.firstName ?? ""} ${a.lastName ?? ""}`
          const clientNameB = `${b.firstName ?? ""} ${b.lastName ?? ""}`
          return sortOrder * clientNameA.localeCompare(clientNameB)
        } else if (sortBy === "performanceRating") {
          return sortOrder * ((a.performanceRating ?? 0) - (b.performanceRating ?? 0))
        } else {
          if (a.riskComfort === undefined || a.riskComfort === null) return 1
          if (b.riskComfort === undefined || b.riskComfort === null) return -1
          return sortOrder * ((a.riskComfort ?? 0) - (b.riskComfort ?? 0))
        }
      })
    }
    if (searchValue) {
      filteredClients = (filteredClients ?? [])?.filter(({ firstName, lastName }) =>
        `${firstName} ${lastName}`.toLowerCase().includes(searchValue.toLowerCase())
      )
    }

    setFilteredClientList(filteredClients)
  }, [activeArea, clientList, filterCriteria, filterListHandler, handleFilterbyQuadrant, searchValue, sortBy, sortOrders])

  useEffect(() => {
    if (activeArea) {
      clearAtrStlFilter()
    }

    const filterCriteriaMap: { [key: string]: { atrCriteria: string[]; stlCriteria: string[] } } = {
      q1: { atrCriteria: ["Lower"], stlCriteria: ["More composed"] },
      q2: { atrCriteria: ["Lower"], stlCriteria: ["More unsettled"] },
      q3: { atrCriteria: ["Higher"], stlCriteria: ["More composed"] },
      q4: { atrCriteria: ["Higher"], stlCriteria: ["More unsettled"] }
    }
    setFilterCriteria((prev) => ({ ...prev, ...filterCriteriaMap[activeArea ?? ""] }))
  }, [activeArea, clearAtrStlFilter])

  const handleQuadrantSelection = useCallback(
    (quadrant: QUADRANT_KEYS) => {
      activeArea === quadrant ? (setActiveArea(undefined), clearAtrStlFilter()) : setActiveArea(quadrant)
    },
    [activeArea, clearAtrStlFilter]
  )

  const handleClientListSorting = useCallback(
    (sortBy: "clientName" | "riskComfort" | "performanceRating") => {
      const sortOrder = sortOrders[sortBy] === 1 ? -1 : 1
      setSortBy(sortBy)
      setSortOrders({
        clientName: sortBy === "clientName" ? sortOrder : 0,
        riskComfort: sortBy === "riskComfort" ? sortOrder : 0,
        performanceRating: sortBy === "performanceRating" ? sortOrder : 0
      })
    },
    [sortOrders]
  )

  const handleFilters = useCallback((filterKey: FilterKeys, matchId: string) => {
    if (filterKey === "atrCriteria" || filterKey === "stlCriteria") {
      setActiveArea(undefined)
    }
    setFilterCriteria((prev) => ({
      ...prev,
      [filterKey]: prev[filterKey]
        ? prev[filterKey]?.includes(matchId)
          ? prev[filterKey]?.filter((id) => id !== matchId)
          : [...(prev[filterKey] ?? []), matchId]
        : [matchId]
    }))
  }, [])

  const onRowClick = useCallback(
    (id: string, isHousehold: boolean) => {
      trackViewEvent({ action: "click", category: "client_selection", label: id })
      if (isHousehold) {
        navigate(`/households/${id}`)
      } else {
        navigate(`/clients/${id}`)
      }
    },
    [navigate, trackViewEvent]
  )

  return (
    <div className="p-10 pg-ctr h-full w-full overflow-auto no-scrollbar">
      <div role="alert">{error && <p className="text-error">{error.message}</p>}</div>

      <div className="flex justify-between">
        <h1 className="text-h2 text-main-600 font-semibold">Your clients’ risk preferences</h1>
      </div>
      <div className="flex items-center justify-end gap-x-1">
        <div className="bg-red-500 w-2 h-2 rounded-full" />
        <p className="text-sm text-main-500">Inconsistent clients</p>
      </div>
      <div className="mb-10 max-h-[550px] mt-2 h-full">
        <ScatterPlot
          chartData={analyticsData.chartData ?? []}
          handleQuadrantSelection={handleQuadrantSelection}
          activeArea={activeArea}
          isLoading={isLoading}
        />
      </div>
      <div className="flex w-full justify-between mt-10 gap-x-3">
        {analyticsData.stlAtrCardData.map(({ color, count, key }, i) => (
          <button
            key={i}
            className={clsx(
              "text-sm w-full text-center border border-interactive-200 p-3 flex items-center gap-x-3 hover:bg-interactive-100 focus-visible:bg-interactive-100 cursor-pointer",
              activeArea === key && "bg-interactive-200 border-interactive-500"
            )}
            aria-label={`Filter client list by ${tt({ id: `analytics-page-quadrant-${key}-button-text` })}`}
            onClick={() => handleQuadrantSelection(key as QUADRANT_KEYS)}
          >
            <span className={clsx("w-10 h-10 rounded-full flex items-center justify-center", color, isLoading && "animate-pulse")}>
              <span className={clsx("text-main-500 font-bold leading-6", count.toString().length > 4 ? "text-sm" : "text-p")}>{count}</span>
            </span>
            <span className="text-main-500">{tt({ id: `analytics-page-quadrant-${key}-button-text` })}</span>
          </button>
        ))}
      </div>
      {activeArea && (
        <div className="flex flex-col w-full gap-y-1 py-5 border-b border-b-surface-300">
          <span className="text-main-500 font-semibold">{tt({ id: `analytics-page-quadrant-${activeArea}-description1` })}</span>
          <span className="text-main-500">{tt({ id: `analytics-page-quadrant-${activeArea}-description2` })}</span>
        </div>
      )}
      <div className="flex items-center justify-end gap-x-2 relative h-12 mt-10">
        <AnimatePresence>
          {showSearchField && (
            <motion.div
              initial={{ width: "0%" }}
              animate={{ width: "100%" }}
              exit={{ width: "0%" }}
              transition={{ duration: 0.1, ease: "easeInOut" }}
              className="max-w-lg"
            >
              <SearchBox
                value={searchValue}
                className="w-full !static"
                id="search"
                placeholder="Search for client"
                onChange={setSearchValue}
                onClear={() => setSearchValue("")}
                isFocused={showSearchField}
              />
            </motion.div>
          )}
        </AnimatePresence>

        <button
          className="bg-interactive-600 w-11 h-11 text-white rounded-full flex items-center justify-center cursor-pointer"
          onClick={() => setShowSearchField(!showSearchField)}
          aria-label={showSearchField ? "Close search field" : "Open search field"}
        >
          <img src={showSearchField ? closeIcon : searchIconBrown} alt="" aria-hidden />
        </button>
      </div>

      <div role="table" className="w-full h-full mt-4.5 gap-x-4 gap-y-2 overflow-visible">
        <div role="rowgroup">
          <div
            role="row"
            className={clsx(
              "grid text-p text-main-600 font-semi leading-6 py-3.5 relative text-left place-items-start",
              !showPerformanceRatingColumn ? "grid-cols-12" : "grid-cols-14"
            )}
          >
            <div role="columnheader" className="col-span-2 my-auto">
              <button
                onClick={() => handleClientListSorting("clientName")}
                aria-label="Sort by client name"
                className="flex items-center gap-1 cursor-pointer pl-1"
              >
                Clients
                <img
                  className={clsx(sortOrders.clientName === -1 ? "rotate-0" : "rotate-180")}
                  src={sortOrders.clientName === -1 || sortOrders.clientName === 1 ? sortDescIcon : sortIcon}
                  alt=""
                  aria-hidden
                />
              </button>
            </div>

            <div role="columnheader" className="col-span-2 my-auto cursor-pointer relative ml-12">
              <button
                className="w-full flex items-center gap-1 text-left"
                onClick={() => setShowInvestmentFilter(!showInvestmentFilter)}
                aria-label="Filter by current investment"
                ref={investmentFilterBtn}
              >
                <span className="w-min lg:w-auto">Current investment</span>
                {filterCriteria?.currentInvestments && filterCriteria.currentInvestments.length > 0 && (
                  <span className="w-3.5 h-3.5 bg-highlight-600 rounded-full text-white text-xxs font-bold text-center">
                    {filterCriteria.currentInvestments?.length}
                  </span>
                )}
                <img src={filterIcon} alt="" aria-hidden />
              </button>

              {showInvestmentFilter && (
                <Dropdown overlayClassName="w-64 max-h-86 overflow-y-auto" trigger={investmentFilterBtn} handleClose={() => setShowInvestmentFilter(false)}>
                  <div className="text-sec font-normal">
                    <ul className="pt-2">
                      {(isSuperAdmin ? currentPortfolios : firm?.modelPortfolios)?.map(({ name }) => (
                        <li className="flex gap-x-2 p-3 px-4 hover:bg-interactive-100" key={name}>
                          <Checkbox
                            checked={!!filterCriteria.currentInvestments?.includes(name)}
                            label={name}
                            name={name}
                            onChange={() => handleFilters("currentInvestments", name)}
                          />
                        </li>
                      ))}
                    </ul>
                    <button
                      onClick={() => setFilterCriteria({ ...filterCriteria, currentInvestments: [] })}
                      className="w-full text-left pl-4 py-3 hover:bg-interactive-100 font-bold"
                    >
                      Clear filters
                    </button>
                  </div>
                </Dropdown>
              )}
            </div>

            <div className="col-span-2 m-auto" role="columnheader">
              <button
                onClick={() => handleClientListSorting("riskComfort")}
                aria-label="Sort by portfolio comfort"
                className="flex items-center gap-1 cursor-pointer"
              >
                Portfolio comfort
                <img
                  className={clsx(sortOrders.riskComfort === -1 ? "rotate-0" : "rotate-180")}
                  src={sortOrders.riskComfort === -1 || sortOrders.riskComfort === 1 ? sortDescIcon : sortIcon}
                  alt=""
                  aria-hidden
                />
              </button>
            </div>

            {showPerformanceRatingColumn && (
              <div role="columnheader" className="col-span-2 m-auto">
                <button
                  className="truncate flex items-center gap-1 cursor-pointer whitespace-break-spaces text-left"
                  aria-label="Sort by performance rating"
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  onClick={() => handleClientListSorting("performanceRating")}
                >
                  Australian 500 performance
                  <img
                    className={clsx("h-3 mr-10", sortOrders.performanceRating === -1 ? "rotate-0" : "rotate-180")}
                    src={sortOrders.performanceRating === -1 || sortOrders.performanceRating === 1 ? sortDescIcon : sortIcon}
                    alt=""
                    aria-hidden
                  />
                  {showTooltip && (
                    <span className="absolute top-3/4 text-sm text-left font-normal bg-white border border-highlight-200 whitespace-break-spaces w-60 p-1.5 cursor-none">
                      Change in the Australian 500 market index since you last profiled each client
                    </span>
                  )}
                </button>
              </div>
            )}

            <div role="columnheader" className="col-span-2 my-auto cursor-pointer relative">
              <button
                aria-label="Filter by lower or higher attitude to risk scores"
                className="flex items-center gap-1 text-left whitespace-break-spaces"
                onClick={() => setShowAtrFilter(!showAtrFilter)}
                ref={atrFilterBtn}
              >
                <span className="w-min xl-max:w-auto">Attitude to Risk</span>
                {filterCriteria?.atrCriteria && filterCriteria.atrCriteria.length > 0 && (
                  <span className="w-3.5 h-3.5 bg-highlight-600 rounded-full text-white text-xxs font-bold text-center">
                    {filterCriteria.atrCriteria?.length}
                  </span>
                )}
                <img src={filterIcon} alt="" aria-hidden />
              </button>
              {showAtrFilter && (
                <Dropdown overlayClassName="w-64" trigger={atrFilterBtn} handleClose={() => setShowAtrFilter(false)}>
                  <div className="text-sec font-normal">
                    <ul className="pt-2">
                      {["Lower", "Higher"].map((val) => (
                        <li key={val} className="flex gap-x-2 p-3 px-4 hover:bg-interactive-100">
                          <Checkbox
                            checked={!!filterCriteria.atrCriteria?.includes(val)}
                            label={val}
                            name={val}
                            onChange={() => handleFilters("atrCriteria", val)}
                          />
                        </li>
                      ))}
                    </ul>
                    <button
                      onClick={() => setFilterCriteria({ ...filterCriteria, atrCriteria: [] })}
                      className="w-full text-left pl-4 py-3 hover:bg-interactive-100 font-bold"
                    >
                      Clear filters
                    </button>
                  </div>
                </Dropdown>
              )}
            </div>

            <div role="columnheader" className="col-span-2 cursor-pointer relative my-auto">
              <button
                aria-label="Filter by more composed or unsettled sensitivity to loss score"
                className="flex items-center gap-1 text-left lg-fluid-max:w-auto"
                onClick={() => setShowStlFilter(!showStlFilter)}
                ref={stlFilterBtn}
              >
                <span className="w-min xl-max:w-auto">Sensitivity to Loss</span>
                {filterCriteria?.stlCriteria && filterCriteria.stlCriteria.length > 0 && (
                  <span className="w-3.5 h-3.5 bg-highlight-600 rounded-full text-white text-xxs font-bold text-center">
                    {filterCriteria.stlCriteria?.length}
                  </span>
                )}
                <img src={filterIcon} alt="" aria-hidden />
              </button>
              {showStlFilter && (
                <Dropdown overlayClassName="w-64" trigger={stlFilterBtn} handleClose={() => setShowStlFilter(false)}>
                  <div className="text-sec font-normal">
                    <ul className="pt-2">
                      {["More unsettled", "More composed"].map((val) => (
                        <li key={val} className="flex gap-x-2 p-3 px-4 hover:bg-interactive-100">
                          <Checkbox
                            checked={!!filterCriteria.stlCriteria?.includes(val)}
                            label={val}
                            name={val}
                            onChange={() => handleFilters("stlCriteria", val)}
                          />
                        </li>
                      ))}
                    </ul>
                    <button
                      onClick={() => setFilterCriteria({ ...filterCriteria, stlCriteria: [] })}
                      className="w-full text-left pl-4 py-3 hover:bg-interactive-100 font-bold"
                    >
                      Clear filters
                    </button>
                  </div>
                </Dropdown>
              )}
            </div>

            <div role="columnheader" className="cursor-pointer relative my-auto col-span-2">
              <button
                aria-label="Filter by primary adviser"
                className="whitespace-nowrap flex items-center gap-1 text-left"
                onClick={() => setShowAdvisorFilter(!showAdvisorFilter)}
                ref={advisorFilterBtn}
              >
                <Trans id="analytics-page-column-header-primary-adviser">Primary adviser</Trans>
                {filterCriteria?.advisorIds && filterCriteria.advisorIds.length > 0 && (
                  <span className="w-3.5 h-3.5 bg-highlight-600 rounded-full text-white text-xxs font-bold text-center">
                    {filterCriteria.advisorIds?.length}
                  </span>
                )}
                <img src={filterIcon} alt="" aria-hidden />
              </button>
              {showAdvisorFilter && (
                <Dropdown overlayClassName="w-64 max-h-86 overflow-y-auto" trigger={advisorFilterBtn} handleClose={() => setShowAdvisorFilter(false)}>
                  <div className="text-sec font-normal">
                    <ul className="pt-2">
                      {scatterData?.advisors.map(({ _id, firstName, lastName }) => (
                        <li key={_id} className="flex gap-x-2 p-3 px-4 hover:bg-interactive-100">
                          <Checkbox
                            checked={!!filterCriteria.advisorIds?.includes(_id)}
                            label={`${firstName} ${lastName}`}
                            name={_id}
                            onChange={() => handleFilters("advisorIds", _id)}
                          />
                        </li>
                      ))}
                    </ul>
                    <button
                      onClick={() => setFilterCriteria({ ...filterCriteria, advisorIds: [] })}
                      className="w-full text-left pl-4 py-3 hover:bg-interactive-100 font-bold"
                    >
                      Clear filters
                    </button>
                  </div>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
        {filteredClientList?.length === 0 && !isLoading && Object.keys(filterCriteria).length > 0 && (
          <div role="alert" aria-live="assertive" className="flex items-center justify-center my-10 text-main-500">
            <p className="font-medium">No clients found, please try adjusting your filters.</p>
          </div>
        )}
        <div role="rowgroup" className="text-p text-main-500 leading-6 pb-10">
          {isLoading && <div className="mt-16 text-center">Loading clients...</div>}

          {filteredClientList?.map((client) => (
            <div
              tabIndex={0}
              role="row button"
              className={clsx(
                "grid py-4.5 border-b cursor-pointer border-b-surface-300 hover:bg-interactive-100 focus:bg-interactive-100 text-left",
                !showPerformanceRatingColumn ? "grid-cols-12" : "grid-cols-14"
              )}
              key={client._id}
              onClick={() => onRowClick(client.householdId ?? client._id!, !!client.householdId)}
              onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) => {
                if (evt.key === "Enter" || evt.key === " ") {
                  onRowClick(client.householdId ?? client._id!, !!client.householdId)
                }
              }}
            >
              <p role="cell" className="col-span-2 pl-1 truncate">
                {client.firstName} {client.lastName}
              </p>
              <p role="cell" className="col-span-2 ml-12">
                {client.currentPortfolio ?? "-"}
              </p>
              <p role="cell" className="col-span-2 text-center">
                {client.riskComfort ?? "-"}
              </p>
              {showPerformanceRatingColumn && (
                <p
                  role="cell"
                  className={clsx("col-span-2 text-center", {
                    "text-positive-700": client.performanceRating! > 0,
                    "text-red-600": client.performanceRating! < 0
                  })}
                >
                  {client.performanceRating}%
                </p>
              )}
              <p role="cell" className="col-span-2">
                {client.atr}
              </p>
              <p role="cell" className="col-span-2">
                {client.stl}
              </p>
              <p role="cell" className="truncate col-span-2">
                {advisors && client.advisorId
                  ? advisors[client.advisorId as keyof AdvisorDesc].firstName + " " + advisors[client.advisorId as keyof AdvisorDesc].lastName
                  : undefined}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AnalyticsPage
