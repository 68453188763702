import React, { PropsWithChildren, ReactNode } from 'react'
import { Client } from "../../../../../../models/Client"
import { Household } from "../../../../../../models/Household"
import alertTriangle from "../../../../../../pages/advisor/assets/images/alter-triangle.svg"

const ProviderAlert: React.FC<PropsWithChildren<{ client?: Client, household?: Household, children?: ReactNode | string }>> = ({ client, household, children }) => {
  const ch = client || household
  console.log(ch?.externalId)
  return ch?.externalId && (
    <div className="shadow-100 w-full flex items-center text-sm text-main-500 p-2 gap-x-1.5 mt-2 mb-6">
      <img src={alertTriangle} />
      {children ?? <span>To ensure your data is kept in sync, some fields can only be adjusted in {ch.externalId.split(":")[0]}.</span>}
    </div>
  )
}

export default ProviderAlert