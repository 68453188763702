import { DoughnutChart } from "./DoughnutChart"

const PortfolioChartDetails = ({
  portfolioType,
  portfolioResults
}: {
  portfolioType: string
  portfolioResults: {
    assetName: string
    totalWeight: number
    totalWeightPercentage: number
  }[]
}) => {
  const chartData = portfolioResults?.map((portfolioData) => ({
    percentage: portfolioData.totalWeightPercentage,
    remainder: 100 - portfolioData.totalWeightPercentage
  }))
  const colors = ["#427880", "#57A2AC", "#90CBCE", "#B6E1E3"]
  return (
    <div className="flex items-center mr-10">
      {chartData && <DoughnutChart data={chartData} colors={colors} className="w-16" />}
      <div className="flex flex-col ml-2">
        <h3 className="text-main-500 font-semibold mb-1">{`${portfolioType} portfolio`}</h3>
        {portfolioResults?.map((val, i) => (
          <div key={i} className="flex items-center mb-1">
            <div className="bg-riskComfort-300 w-2 h-2 rounded-full mr-2" style={{ backgroundColor: colors[i] }}></div>
            <p className="text-main-500 text-sm" tabIndex={0}>{`${val.totalWeightPercentage}% ${val.assetName}`}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PortfolioChartDetails
