import { useContext, useEffect } from "react"
import { RMDetailContext } from "../contexts/RMDetailContext"

const useRMDetail = (clientId?: string) => {
  const { setClientId, ...rest } = useContext(RMDetailContext)

  useEffect(() => {
    if (clientId) {
      setClientId(clientId)
    }
  }, [clientId, setClientId])

  return { ...rest }
}

export default useRMDetail
