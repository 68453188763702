import clsx from "clsx"

const ClientRiskComfortIcon = ({ className }: { className: string }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className={clsx("w-3.5 shrink-0", className)}>
      <path d="M8.00003 14.0003C7.8227 14.0003 7.65336 13.9303 7.52803 13.8043L2.35003 8.61766C0.992698 7.25766 0.992698 5.04499 2.35003 3.68499C3.00536 3.02899 3.8807 2.66699 4.81336 2.66699C5.74603 2.66699 6.62136 3.02899 7.2767 3.68499L8.00003 4.40966L8.7227 3.68566C9.3787 3.02899 10.254 2.66699 11.1867 2.66699C12.1194 2.66699 12.9947 3.02899 13.65 3.68499C15.0074 5.04499 15.0074 7.25766 13.6507 8.61766L8.47203 13.805C8.3467 13.9303 8.17736 14.0003 8.00003 14.0003Z" />
    </svg>
  )
}
export default ClientRiskComfortIcon
