import { Client, Game } from "../models/Client"
import { sortArrayBy } from "./sort"

export const getClientCSVContent = (client: Client, game: Game) => {
  const header = `First Name,Last Name,Email,Phone Number,Goals,Investment Amount,Time Horizon,Risk Tolerance,Loss Aversion,Decision Consistency,Decision 1 - Gain,Decision 1 - Loss,Decision 2 - Gain,Decision 2 - Loss,Decision 3 - Gain,Decision 3 - Loss,Decision 4 - Gain,Decision 4 - Loss,Decision 5 - Gain,Decision 5 - Loss,Decision 6 - Gain,Decision 6 - Loss,Models,,,,\n`

  const clientDetails = (client: Client, game: Game) => {
    const cd = `${client.firstName},${client.lastName},${client.email || ""},-,-,${client.investmentAmount ? client.investmentAmount.toFixed(2) : ""},-,${
      game.risk.results?.R.riskTolerance
    },${game.risk.results?.R.lossAversion},${game.risk.results?.R.cceiScore}`

    return cd
  }

  const decisionDetails = (game: Game) =>
    sortArrayBy(game.risk?.decisions?.R ?? [], "seq", "asc").flatMap((result) => `${result.choiceGain},${result.choiceLoss}`)

  const models = (game: Game) => game.portfolioMappings?.map((modelPortfolio) => `${modelPortfolio.portfolio.name} ${modelPortfolio.riskComfort}`)

  const content = encodeURIComponent(`${header}${clientDetails(client, game)},${decisionDetails(game)},${models(game)}`)

  return `data:text/csv;charset=utf-8,${content}`
}

export const clientReports = (client: Client, message: string) =>
  client.games
    ?.filter((game) => game.gameType === "risk" && game.played && game.token)
    .map((game, i) => {
      return {
        csvContent: getClientCSVContent(client, game),
        fileName: `${client.firstName}-${game.played}.csv`,
        date: game.played,
        id: `${client._id}-report-${i}`,
        message
      }
    }) || []
