import { Route, Routes } from "react-router-dom"
import BillingSetup from "./components/billing/BillingSetup"
import CreateFirm from "./components/firm/CreateFirm"
import ListFirms from "./components/firm/ListFirms"
import ManageFirm from "./components/firm/ManageFirm"
import CreateLicensee from "./components/licensee/CreateLicensee"
import ListLicensees from "./components/licensee/ListLicensees"
import ManageLicensee from "./components/licensee/ManageLicensee"
import NotImplemented from "./components/NotImplemented"
import GlobalSearch from "./components/search/GlobalSearch"
import KafkaBrowser from "./kafka/KafkaBrowser"
import SiteNavigatorPage from "./SiteNavigatorPage"

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="search" element={<GlobalSearch />}/>
      <Route path="licensees" element={<ListLicensees />}/>
      <Route path="licensees/create" element={<CreateLicensee />}/>
      <Route path="licensee/:licenseeId/*" element={<ManageLicensee />}/>
      <Route path="firms" element={<ListFirms />}/>
      <Route path="firms/create" element={<CreateFirm />}/>
      <Route path="firm/:firmId/*" element={<ManageFirm />}/>
      <Route path="users" element={<NotImplemented />}/>
      <Route path="activities" element={<NotImplemented />}/>
      <Route path="analytics" element={<NotImplemented />}/>
      <Route path="billing/*" element={<BillingSetup />}/>
      <Route path="kafka/*" element={<KafkaBrowser />}/>
      <Route index element={<SiteNavigatorPage />} />
    </Routes>
  )
}

export default AdminRoutes