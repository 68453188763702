import ExampleDecisionTable from "./ExampleDecisionTable"

const PatternsModal: React.FunctionComponent<{ type: "loss" | "risk" }> = ({ type }) => {
  const lowRisk = [15, 14, 13, 12, 11, 10]
  const midRisk = [55, 54, 52, 51, 49, 48]
  const highRisk = [95, 94, 93, 91, 90, 89]
  const highLoss = [55, 40, 25, 10, 0, 0]
  const midLoss = [55, 51, 48, 40, 34, 28]
  const lowLoss = [55, 54, 52, 51, 49, 48]

  return (
    <div className="overflow-y-scroll no-scrollbar" style={{ maxHeight: "calc(100vh - 100px)" }}>
      <div className="modal-container w-[920px]">
        <div className="flex flex-col">
          <div className="modal-title pb-4 text-left">
            <h1>{type === "risk" ? "Attitude to Risk decision patterns" : "Sensitivity to Loss decision patterns"}</h1>
          </div>
          <h3 className="pb-2 text-h3">{type === "risk" ? "Cautious risk-taker (low Attitude to Risk)" : "Highly sensitive to loss (unsettled)"}</h3>
          <div className="flex justify-between items-center">
            <p className="pb-4">
              {type === "risk"
                ? "Cautious risk-takers risk relatively small amounts of their total investment in the activity."
                : "Investors who are highly sensitive to loss take a lot of risk at the highest opportunity, and quickly reduce their risk taking as the opportunity drops off."}
            </p>
            <h1 className="text-h3 whitespace-nowrap text-right text-interactive-400 font-semibold flex flex-col">
              {type === "risk" ? "9" : "80"}
              <span className="text-xs text-interactive-400">{type === "risk" ? "Risk score" : "Sensitivity score"}</span>
            </h1>
          </div>
          <div className="grow pb-5">
            <ExampleDecisionTable data={type === "risk" ? lowRisk : highLoss} />
          </div>
          <h3 className="pb-2 text-h3">{type === "risk" ? "Mid-level risk-taker (medium Attitude to Risk)" : "Somewhat sensitive to loss (somewhat unsettled)"}</h3>
          <div className="flex justify-between items-center">
            <p className="pb-4">
              {type === "risk"
                ? "Mid-level risk-takers risk a moderate amounts of their total investment in the activity."
                : "Investors who are somewhat sensitive to loss take a lot of risk at the highest opportunity, and reduce their risk taking considerably as the opportunity drops off."}
            </p>
            <div className="w-100">
              <h1 className="text-h3 text-right whitespace-nowrap text-interactive-400 font-semibold flex flex-col">
                {type === "risk" ? "46" : "30"}
                <span className="text-xs text-interactive-400">{type === "risk" ? "Risk score" : "Sensitivity score"}</span>
              </h1>
            </div>
          </div>
          <div className="grow pb-5">
            <ExampleDecisionTable data={type === "risk" ? midRisk : midLoss} />
          </div>
          <h3 className="pb-2 text-h3">{type === "risk" ? "Aggressive risk-taker (high Attitude to Risk)" : "Low sensitivity to loss (composed)"}</h3>
          <div className="flex justify-between items-center">
            <p className="pb-4">
              {type === "risk"
                ? "Aggressive risk-takers risk relatively high amounts of their total investment in the activity."
                : "Around 50% of investors have low sensitivity."}
            </p>
            <h1 className="text-h3 text-right whitespace-nowrap text-interactive-400 font-semibold flex flex-col">
              {type === "risk" ? "86" : "0"}
              <span className="text-xs text-interactive-400">{type === "risk" ? "Risk score" : "Sensitivity score"}</span>
            </h1>
          </div>
          <div className="grow pb-5">
            <ExampleDecisionTable data={type === "risk" ? highRisk : lowLoss} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PatternsModal
