type ChartData = {
  percentage: number
  remainder: number
}

export const DoughnutChart = ({ data, colors, centerLabel, className }: { data: ChartData[]; colors: string[]; centerLabel?: string; className?: string }) => {
  return (
    <div className={className}>
      <div className="relative">
        <svg width="100%" height="100%" viewBox="0 0 40 40">
          {data.map((d, index) => {
            const { percentage } = d
            return (
              <circle
                key={index}
                cx="20"
                cy="20"
                r="15.91549430918954"
                strokeDasharray={`${percentage} ${100 - percentage}`}
                strokeDashoffset={100 - data.slice(0, index).reduce((a, b) => a + b.percentage, 0) + 25}
                strokeWidth="6"
                fill="transparent"
                stroke={colors[index]}
              />
            )
          })}
        </svg>
        {centerLabel && (
          <div className="absolute top-1/2 left-1/2 text-center -translate-x-1/2 -translate-y-1/2">
            <div>{centerLabel}</div>
          </div>
        )}
      </div>
    </div>
  )
}
