export const SENSITIVITY_TO_LOSS_DATA = [
  { id: "0", value: 50 },
  { id: "1", value: 49.91570694 },
  { id: "2", value: 49.3183061 },
  { id: "3", value: 48.26305179 },
  { id: "4", value: 46.80519831 },
  { id: "5", value: 45 },
  { id: "6", value: 42.90271116 },
  { id: "7", value: 40.56858611 },
  { id: "8", value: 38.05287917 },
  { id: "9", value: 35.41084464 },
  { id: "10", value: 32.69773686 },
  { id: "11", value: 29.96881012 },
  { id: "12", value: 27.27931875 },
  { id: "13", value: 24.68451706 },
  { id: "14", value: 22.23965938 },
  { id: "15", value: 20 },
  { id: "16", value: 18.00874799 },
  { id: "17", value: 16.26093135 },
  { id: "18", value: 14.73953281 },
  { id: "19", value: 13.42753512 },
  { id: "20", value: 12.30792101 },
  { id: "21", value: 11.36367321 },
  { id: "22", value: 10.57777448 },
  { id: "23", value: 9.93320754 },
  { id: "24", value: 9.41295513 },
  { id: "25", value: 9 },
  { id: "26", value: 8.67829688 },
  { id: "27", value: 8.43568849 },
  { id: "28", value: 8.26098958 },
  { id: "29", value: 8.14301488 },
  { id: "30", value: 8.07057912 },
  { id: "31", value: 8.03249703 },
  { id: "32", value: 8.01758334 },
  { id: "33", value: 8.01465278 },
  { id: "34", value: 8.01252009 },
  { id: "35", value: 8 },
  { id: "36", value: 7.96806451 },
  { id: "37", value: 7.91631468 },
  { id: "38", value: 7.84650885 },
  { id: "39", value: 7.76040535 },
  { id: "40", value: 7.65976252 },
  { id: "41", value: 7.54633868 },
  { id: "42", value: 7.42189218 },
  { id: "43", value: 7.28818134 },
  { id: "44", value: 7.1469645 },
  { id: "45", value: 7 },
  { id: "46", value: 6.8484451 },
  { id: "47", value: 6.69105279 },
  { id: "48", value: 6.52597502 },
  { id: "49", value: 6.35136372 },
  { id: "50", value: 6.16537081 },
  { id: "51", value: 5.96614825 },
  { id: "52", value: 5.75184795 },
  { id: "53", value: 5.52062185 },
  { id: "54", value: 5.27062189 },
  { id: "55", value: 5 },
  { id: "56", value: 4.70815511 },
  { id: "57", value: 4.39947416 },
  { id: "58", value: 4.07959107 },
  { id: "59", value: 3.75413979 },
  { id: "60", value: 3.42875423 },
  { id: "61", value: 3.10906833 },
  { id: "62", value: 2.80071603 },
  { id: "63", value: 2.50933125 },
  { id: "64", value: 2.24054793 },
  { id: "65", value: 2 },
  { id: "66", value: 1.79193446 },
  { id: "67", value: 1.61505057 },
  { id: "68", value: 1.46666068 },
  { id: "69", value: 1.34407714 },
  { id: "70", value: 1.24461227 },
  { id: "71", value: 1.16557842 },
  { id: "72", value: 1.10428793 },
  { id: "73", value: 1.05805313 },
  { id: "74", value: 1.02418638 },
  { id: "75", value: 1 },
  { id: "76", value: 0.98310707 },
  { id: "77", value: 0.97232356 },
  { id: "78", value: 0.96676619 },
  { id: "79", value: 0.96555167 },
  { id: "80", value: 0.9677967 },
  { id: "81", value: 0.97261799 },
  { id: "82", value: 0.97913226 },
  { id: "83", value: 0.98645621 },
  { id: "84", value: 0.99370655 },
  { id: "85", value: 1 },
  { id: "86", value: 1.00463728 },
  { id: "87", value: 1.00765519 },
  { id: "88", value: 1.00927455 },
  { id: "89", value: 1.0097162 },
  { id: "90", value: 1.00920094 },
  { id: "91", value: 1.00794962 },
  { id: "92", value: 1.00618303 },
  { id: "93", value: 1.00412202 },
  { id: "94", value: 1.0019874 },
  { id: "95", value: 1 },
  { id: "96", value: 0.99838063 },
  { id: "97", value: 0.99735013 },
  { id: "98", value: 0.99712931 },
  { id: "99", value: 0.99793899 },
  { id: "100", value: 1 }
]
