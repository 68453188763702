import { motion } from "framer-motion"
import Opportunity from "../../../../rmjourney/yourDecisions/components/Opportunity/Opportunity"

const ExampleDecisionTable: React.FunctionComponent<{ data: number[] }> = ({ data }) => {
  return (
    <table>
      <thead>
        <tr className="h-10 text-left text-main-600 font-semibold text-sec">
          <th className="pr-10">Opportunity</th>
          <th className="w-3/4 px-2 ">Percentage risked in the activity</th>
        </tr>
      </thead>
      <tbody>
        {data.map((i, index) => {
          return (
            <tr key={index} className="border-b border-b-surface-300 text-sec h-13">
              <td className="pr-10 text-left">
                <Opportunity index={index} />
              </td>
              <td className="text-sec text-center px-2 w-full">
                <div className="flex flex-col justify-center h-full w-full gap-4">
                  <div key={i}>
                    <motion.div
                      initial={{ width: 0 }}
                      animate={{ width: `${i}%` }}
                      transition={{ duration: 0.8, delay: 0.5, ease: "easeInOut" }}
                      className="relative h-1 bg-avatar-0-400"
                    >
                      <div className="absolute top-0 right-0 translate-x-full -translate-y-1/3 w-9 text-main-600 text-left -mr-2">{Math.round(i)}%</div>
                    </motion.div>
                  </div>
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default ExampleDecisionTable
