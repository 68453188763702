import { useCallback, useMemo } from "react"

interface Props {
  setSort: (value: string) => void
  sort: string
}

export const useClientListSortColumn = ({ setSort, sort }: Props) => {
  const { sortCol, sortDirection } = useMemo(() => {
    if (sort) {
      return { sortCol: sort.charAt(0) === "-" ? sort.substring(1) : sort, sortDirection: sort.charAt(0) === "-" ? "DESC" : "ASC" }
    }
    return { sortCol: undefined, sortDirection: undefined }
  }, [sort])

  const setSortCol = useCallback(
    (col: string) => {
      const direction = (sortCol === col && sortDirection === "ASC" && "-") || ""
      setSort(`${direction}${col}`)
    },
    [setSort, sortCol, sortDirection]
  )

  return { setSortCol, sortCol, sortDirection }
}
