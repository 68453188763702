import { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import axiosInstance from "../api/axiosInstance"
import { ClientHouseholdCacheContext } from '../contexts/ClientHouseholdCacheContext'
import { Client } from '../models/Client'
import { AuthContext, AuthStatus } from '../views/auth/AuthContext'

const useClient = (desiredClientId?: string) => {

  const { authStatus, sessionInfo } = useContext(AuthContext)
  const [clientId, setClientId] = useState<string>()
  const { cache, replace } = useContext(ClientHouseholdCacheContext)


  const {
    isLoading,
    isSuccess,
    error,
    refetch
  } = useQuery<Client, { message?: string }>(
    ["client", clientId],
    () =>
      axiosInstance
        .get<Client>(`${import.meta.env.VITE_APP_API_BASE||""}/api/client/${clientId}`)
        .then(res => res.data),
    {
      enabled: authStatus === AuthStatus.SignedIn && !!sessionInfo?.accessToken && !!clientId && !cache[clientId],
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 900,
      onSuccess: (data) => {
        // assumption is that no household is in cache that uses this client (we can't load a householded client as we would get a 404)
        replace(data)
      }
    }
  )

  useEffect(() => {
    if (clientId !== desiredClientId) {
      setClientId(desiredClientId)
    }
  }, [clientId, desiredClientId, setClientId])

  
  return {
    clientId,
    setClientId,
    client: clientId ? cache[clientId] as Client : undefined,
    isClientLoading: isLoading && clientId && !cache[clientId],
    // eslint-disable-next-line no-mixed-operators
    isClientLoadedSuccessfully: isSuccess || clientId && !!cache[clientId],
    clientLoadError: error,
    reloadClient: refetch
  }
}

export default useClient