import { KeyboardEventHandler, PropsWithChildren, UIEventHandler, useCallback, useEffect } from "react"
import { createDomMotionComponent } from "framer-motion"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Close from "./assets/close.svg?react"
import clsx from "clsx"

export interface DrawerProps {
  className?: string
  contentClassName?: string
  handleClose?: UIEventHandler
  handleConfirm?: UIEventHandler
  slideOrigin?: "left" | "right"
}

const motion = {
  div: createDomMotionComponent("div")
}

const Drawer: React.FC<PropsWithChildren<DrawerProps>> = ({ children, className, contentClassName, handleClose, handleConfirm, slideOrigin }) => {
  const handleKeyClose: KeyboardEventHandler = useCallback(
    (e) => {
      if (e.keyCode === 27 && handleClose) {
        handleClose(e)
      } else if (e.keyCode === 13) {
        handleConfirm && handleConfirm(e)
      }
    },
    [handleClose, handleConfirm]
  )

  useEffect(() => {
    window.addEventListener<"keydown">("keydown", handleKeyClose as any) // TODO: I have code somewhere that does this properly in typescript
    return () => {
      window.removeEventListener("keydown", handleKeyClose as any)
    }
  }, [handleKeyClose])

  return (
    <>
      
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.25 } }}
        exit={{ opacity: 0, transition: { duration: 0.25 } }}
        className="fixed inset-0 z-40 bg-black/75"
        onClick={handleClose}
      />
      <div className={clsx("drawer w-full h-full fixed flex z-50 translate-x-1/4 translate-y-1/2 bottom-1/2", slideOrigin === "left" ? "left-0" : "right-0")}>
        <motion.div
          key="drawer-anim"
          initial={{ x: "160%" }}
          animate={{ x: 0, transition: { type: "tween" /* , duration: 0.3 */ } }}
          exit={{ x: "160%", transition: { type: "tween" /* , duration: 0.3 */ } }}
          aria-modal="true"
          role="dialog"
          className={clsx("relative flex flex-col max-h-full w-3/4 bg-white shadow-modal z-30 p-3", className)}
        >
          {handleClose && (
            <div className="absolute right-0 top-0 py-2.5 px-3 z-10">
              <div className="cursor-pointer" onClick={handleClose} tabIndex={1}>
                <Close className="h-6 w-6" />
              </div>
            </div>
          )}
          <main className={clsx("relative grow w-auto overflow-auto flex px-8 py-9", contentClassName)}>{children}</main>
        </motion.div>
      </div>
    </>
  )
}

export default Drawer
