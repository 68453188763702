import { Trans, t } from "@lingui/macro"
import { useMemo } from "react"
import { Client } from "../../../models/Client"
import { altruismChartData, getAltruismLevel } from "../components/Profile/components/AltruismScore/altruismScoreAttributes"
import Page from "./Page"
import AltruismLineChart from "../components/Profile/components/AltruismScore/AltruismLineChart"
import { useTheme } from "../../../contexts/ThemeContext"

const AltruismScoresPage = ({ page, client }: { page: number; client: Client }) => {
  const theme = useTheme()
  const altruismScore = useMemo(() => altruismChartData(theme, client), [theme, client])
  const altruismLevel: "low" | "medium" | "high" | null = getAltruismLevel(altruismScore.score[0])

  return (
    <Page page={page} title={t({ id: "print-report-page-header-title" })}>
      <div id="top-themes-page" className="relative mx-9 h-full">
        <div className="flex items-center justify-between mb-12">
          <div className="flex gap-2 items-center">
            <h1 className="font-normal text-[22px]">
              <Trans id={`report-results-altruism-${altruismLevel}-description`}>Reflecting core values is of high importance</Trans>
            </h1>
          </div>
          <p>
            <Trans id="altruism-score-text">
              Score: <span className="font-semibold">{altruismScore.score[0]}</span>
            </Trans>
          </p>
        </div>

        <AltruismLineChart client={client} altruismScore={altruismScore} enableAnimations={false} />
      </div>
    </Page>
  )
}

export default AltruismScoresPage
