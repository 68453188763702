import { NodeData } from "./LineChart"

export interface Props {
  formatter?: (node: NodeData) => string
  node: NodeData
}

const ToolTip = ({ formatter, node }: Props) => {
  const { value, x, y } = node
  return (
    <div className="absolute bg-white border-0.5 border-surface-400 drop-shadow-md -translate-y-1/2 ml-2 px-1" style={{ left: x, top: y }}>
      <p className="text-sm text-main-400">{formatter ? formatter(node) : value}</p>
    </div>
  )
}

export default ToolTip
