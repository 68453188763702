import clsx from "clsx"
import { DebounceInput } from "react-debounce-input"
import search from "../assets/images/Search.svg"
import clear from "../../../components/Modal/assets/close.svg"
import Dropdown from "../../../components/Dropdown/Dropdown"
import { ReactNode, useCallback, useEffect, useRef, useState } from "react"
import Loading from "../../../components/ClientProfile/Loading/Loading"

const SearchBox = ({
  id,
  ref: inRef,
  className,
  inputClassName,
  placeholder,
  results,
  onChange,
  value,
  isSearching = false,
  onClear,
  onSubmit,
  isFocused = false
}: {
  id?: string
  ref?: React.RefObject<HTMLInputElement>
  className?: string
  inputClassName?: string
  placeholder?: string
  results?: ReactNode
  onChange: (val: string) => void
  value?: string
  isSearching?: boolean
  onClear?: () => void
  onSubmit?: () => void
  isFocused?: boolean
}) => {
  const xref = useRef<HTMLInputElement>(null)
  const ref = inRef ?? xref

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(!!results)

  useEffect(() => {
    setIsMenuOpen(!!results)
  }, [results])

  const handleKeyDown = useCallback(
    (evt: React.KeyboardEvent<HTMLInputElement>) => {
      if (onSubmit && evt.key === "Enter") {
        onSubmit()
      }
    },
    [onSubmit]
  )

  return (
    <>
      <div className={clsx("flex flex-row border border-main-400 h-full items-center relative", className, { "shadow-focus": isFocused })}>
        <DebounceInput
          id={id}
          className={clsx("w-full px-4 py-2 border-transparent focus:outline-none", inputClassName)}
          placeholder={placeholder}
          debounceTimeout={500}
          value={value}
          inputRef={ref}
          autoComplete="off"
          onChange={(evt) => onChange(evt.target.value)}
          onKeyDown={handleKeyDown}
          autoFocus={isFocused}
        />
        {isSearching ? (
          <div className="h-10 mr-2">
            <Loading />
          </div>
        ) : value ? (
          <button type="button" aria-label="clear" onClick={onClear} className="cursor-pointer mr-4">
            <img src={clear} alt="" aria-hidden className="h-5 w-5" />
          </button>
        ) : (
          <img src={search} alt={"search"} className="h-5 w-5 mr-4" />
        )}
      </div>
      {isMenuOpen && (
        <div className="shadow">
          <Dropdown trigger={ref} align="left" overlayClassName="w-full" handleClose={() => setIsMenuOpen(false)}>
            {results}
          </Dropdown>
        </div>
      )}
    </>
  )
}

export default SearchBox
