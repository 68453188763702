import { clsx } from "clsx"
import { format } from "date-fns"
import { useContext } from "react"
import cpLogo from "../../../assets/images/logos/cp-logo.svg"
import { Reports } from "../../../config/theme"
import { FirmContext } from "../../../contexts/FirmContext"
import { useTheme } from "../../../contexts/ThemeContext"
import { Client } from "../../../models/Client"
import { Household } from "../../../models/Household"
import cover from "./assets/page-1.jpg"
import Container from "./components/Container"
import { tt } from "../../../lib/translations"
import { Trans } from "@lingui/macro"

const CoverPage = ({
  client,
  household,
  reportType,
  isPostMeeting = false,
  coverImage
}: {
  client: Client
  household: Household
  reportType: Reports
  isPostMeeting?: boolean
  coverImage?: string
}) => {
  const { firm } = useContext(FirmContext)
  const theme = useTheme()

  return (
    <Container className="reports-container flex relative flex-col w-full h-full cover-page">
      <div className={clsx("bg-white h-[77px] absolute w-full px-10", theme.reports?.coverPage?.logosBar?.position === "top" ? "top-0" : "bottom-0")}>
        {theme.reports?.coverPage?.logosBar?.position === "bottom" && <hr className="border-t border-interactive-500" />}
        <div className="cover-footer flex justify-between items-center h-[70px] w-full py-10 mx-auto">
          {(theme.reports?.coverPage?.showLogo ?? firm?.logo) && <img className="primary-logo h-8" src={firm?.logo} alt={`${firm?.name} logo`} />}
          <img className="secondary-logo h-4" src={cpLogo} alt="Powered by Capital Preferences" />
        </div>
      </div>
      <div className="cover-image-container px-10 pt-20">
        <img alt="cover page banner" src={coverImage ?? cover} className={`print-cover-banner-img-${reportType} object-cover object-left h-[560px]`} />
      </div>
      <div className="cover-page-text mx-10 mt-60 text-alt-decorative-600 max-w-[440px]">
        <h1 className="font-semibold mb-5 text-h1 leading-9">
          {client && `${client.firstName} ${client.lastName}`}
          {household && `${household.members.map((m) => `${m.client.lastName} ${m.client.firstName.charAt(0).toUpperCase()}`).join(" & ")}`}
        </h1>
        <p className="font-semibold leading-6 mb-6 text-h3">
          {isPostMeeting
            ? tt({ id: `report-cover-page-${reportType}-post-meeting-summary-label`, message: "Risk profile summary report" })
            : tt({ id: `report-cover-page-${reportType}-pre-meeting-summary-label`, message: "Risk profile preview report" })}
        </p>
        {theme.reports?.coverPage?.showDate === true && (
          <span className="text-sec uppercase tracking-tight report-date">{format(new Date(), "dd MMMM yyyy")}</span>
        )}
      </div>
      {theme.reports?.coverPage?.reportFlag?.show && (
        <div
          className={clsx(
            "report-flag flex gap-2 justify-between bg-alt-interactive-500 px-3 py-2 text-sec text-main-600 uppercase",
            theme.reports?.coverPage?.reportFlag.position === "top" ? "absolute top-5 left-0" : "mx-10"
          )}
        >
          <span>
            {isPostMeeting
              ? tt({ id: `report-cover-page-flag-${reportType}-summary-report`, message: "Summary report" })
              : tt({ id: `report-cover-page-flag-${reportType}-preview-report`, message: "Preview report" })}
          </span>
          {theme.reports?.coverPage?.reportFlag.showDate && (
            <div className="flex gap-2">
              <span>|</span>
              <span className="text-sec uppercase">{format(new Date(), "dd MMMM yyyy")}</span>
            </div>
          )}
        </div>
      )}
      {theme.reports?.coverPage?.disclaimer?.show && (
        <p className="cover-page-disclaimer text-main-400 px-10 py-6 w-full text-xs leading-3">
          <Trans id="cover-page-disclaimer-text">
            These results generated should not be taken as advice or recommendation to buy or sell any investments. Activity and results may be covered by one
            or more patents, see
            <a className="underline" target="_blank" rel="noreferrer" href="https://capitalpreferences.com/intellectual-property-filings">
              {" "}Capital Preferences IP Listings.{" "}
            </a>
            For Internal Use Only. Not for Distribution.
          </Trans>
        </p>
      )}
    </Container>
  )
}

export default CoverPage
