import { ReactNode } from "react"

const ChartIconTooltip = ({
  x,
  y,
  chartWidth,
  tooltipHeight = 72,
  tooltipWidth = 320,
  spaceBetweenIconAndTooltip = 15,
  children
}: {
  children: ReactNode
  x: number
  y: number
  chartWidth: number
  tooltipHeight?: number
  tooltipWidth?: number
  spaceBetweenIconAndTooltip?: number
}) => {
  return (
    <g style={{ pointerEvents: "none" }}>
      <foreignObject
        height={tooltipHeight}
        x={x + tooltipWidth + spaceBetweenIconAndTooltip > chartWidth ? x - (tooltipWidth + spaceBetweenIconAndTooltip) : x + spaceBetweenIconAndTooltip}
        y={y - tooltipHeight / 2}
        width={tooltipWidth}
      >
        <div className="h-full bg-white border border-interactive-200 z-10 shadow p-3">{children}</div>
      </foreignObject>
    </g>
  )
}

export default ChartIconTooltip
