import { Trans } from "@lingui/macro"
import { Client } from "../../../../../../models/Client"
import { Link } from "react-router-dom"
import chevron from "../../../../../../assets/icons/chevron-right.svg"
import chevDown from "../../../../../../assets/icons/chevron-down.svg"
import { useState } from "react"
import { clsx } from "clsx"
import { AnimatePresence, motion } from "framer-motion"
import { latest } from "../../../../../../lib/clients"

const FundsMatch = ({ client }: { client: Client }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const fundData =
    import.meta.env.VITE_APP_THEME_JPMAM === "true"
      ? latest(client, "esg")?.esg?.results?.sustainabilityMatchScores
          ?.sort((a, b) => b.score - a.score)
          .slice(0, 10)
          .map((fundMatchScore) => {
            return {
              ticker: fundMatchScore.fund?.ticker,
              fundName: fundMatchScore.fund?.name,
              assetClass: fundMatchScore.fund?.assetClass,
              matchScore: fundMatchScore.score
            }
          })
      : []
  const visibleFunds = isExpanded ? fundData : fundData?.slice(0, 3)

  return (
    <>
      <div className="flex gap-2 items-center justify-between pb-6">
        <div className="w-2/3 flex gap-2 items-center">
          <h2 className="text-h3 font-semibold">
            <Trans id="funds-match-heading">Funds that closely match {client.firstName}'s Sustainability profile</Trans>
          </h2>
        </div>
        <div className="w-1/3 flex justify-end">
          <Link
            to={`/clients/${client._id}/portfolio-analyser`}
            className="btn btn-primary btn-medium text-sec flex items-center justify-center text-center gap-1"
          >
            Portfolio analyser
            <img src={chevron} className="" aria-hidden />
          </Link>
        </div>
      </div>
      {visibleFunds && visibleFunds.length > 1 ? (
        <>
          <div role="table" className="text-sec mb-5">
            <div role="row" className="sr-only">
              <div role="columnheader">Ticker</div>
              <div role="columnheader">Fund name</div>
              <div role="columnheader">Asset class</div>
              <div role="columnheader">Sustainability match</div>
            </div>
            <AnimatePresence>
              {visibleFunds?.map((fund, index) => (
                <motion.div
                  key={index}
                  initial={{ height: 0 }}
                  animate={{
                    height: "auto",
                    transition: {
                      duration: 0.2,
                      ease: "easeInOut"
                    }
                  }}
                  exit={{ height: 0 }}
                  className="w-full overflow-hidden"
                >
                  <div role="row" className="funds-list flex items-center gap-5 px-5 py-4 bg-interactive-100 mb-2">
                    <div role="cell" className="text-left w-12">
                      {fund.ticker}
                    </div>
                    <div role="cell" className="flex-1 text-left truncate">
                      {fund.fundName}
                    </div>
                    <div role="cell" className="text-center w-20">
                      {fund.assetClass}
                    </div>
                    <div role="cell" className="flex-1 text-right flex gap-2 items-center justify-end">
                      <span>Sustainability match</span>
                      <span className="funds-list-score font-semibold bg-interactive-200 p-2">{fund.matchScore}%</span>
                    </div>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
          {fundData && fundData.length > 3 && (
            <div className="flex justify-center">
              <button className="btn btn-text text-primary text-sec font-semibold flex items-center gap-2 p-1" onClick={() => setIsExpanded(!isExpanded)}>
                <span>
                  {isExpanded ? (
                    <Trans id="funds-match-show-less-label">Show less</Trans>
                  ) : (
                    <Trans id="funds-match-show-more-label">Show more ({fundData.length - 3})</Trans>
                  )}
                </span>
                <img src={chevDown} alt="" className={clsx("mb-0.5", { "rotate-180": isExpanded })} aria-hidden />
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="w-full">No fund data to show</div>
      )}
      <div className="mt-5">
        <span className="text-xxs">
          <Trans id="funds-match-source-citation">Source: Calculated on MSCI Analytics</Trans>
        </span>
      </div>
    </>
  )
}

export default FundsMatch
