import { useState } from "react"
import TextInput from "../../../components/TextInput/TextInput"

const EditModelPortfolioModal: React.FunctionComponent<{ handleClose: () => void; onEdit: (name: string) => void; name: string }> = ({
  handleClose,
  onEdit,
  name
}) => {
  const [newName, setNewName] = useState<string>(name)
  const [error, setError] = useState<string>()

  return (
    <div className="modal-container w-[600px]">
      <h1 className="modal-title text-left">Edit model portfolio</h1>
      <p>Name of portfolio</p>
      <div>
        <TextInput name="editName" onChange={(val) => {
          setNewName(val)
          setError(val ? undefined : "Enter a valid name")
        }}
          value={newName} 
          error={error} />
      </div>
      <div className="flex gap-4 items-center justify-center pt-10">
        <button className="btn btn-secondary btn-medium text-sec flex-1" onClick={handleClose}>
          Cancel
        </button>
        <button
          className="btn btn-primary btn-medium text-sec flex-1 hover:bg-interactive-600 hover:cursor-pointer"
          disabled={!newName}
          onClick={() => {
            onEdit(newName)
          }}
        >
          Submit
        </button>
      </div>
    </div>
  )
}

export default EditModelPortfolioModal
