const Opportunity = ({ index }: { index: number }) => {
  return (
    <span className="risk-results-opportunity-text">
      {index === 0 && `Highest`}
      {index === 1 && `High`}
      {index === 2 && `Med-high`}
      {index === 3 && `Med-low`}
      {index === 4 && `Low`}
      {index === 5 && `Lowest`}
    </span>
  )
}

export default Opportunity
