import { useState } from "react"
import Loading from "../../components/ClientProfile/Loading/Loading"
import CreateIndividualClientPage from "./CreateIndividualClientPage"
import CreateHouseholdPage from "./CreateHouseholdPage"
import RadioButton from "../../components/RadioButton/RadioButton"
import useAdvisorSearch from "../../hooks/useAdvisorSearch"
import { useTheme } from "../../contexts/ThemeContext"

const CreateClientPage = () => {
  const [createClientInProgress, setCreateClientInProgress] = useState<boolean>(false)
  const [clientType, setClientType] = useState<"individual" | "household">("individual")
  const { isLoading, data: myAdvisors } = useAdvisorSearch()
  const theme = useTheme()
  return (
    <div className="pg-ctr pt-8 pb-0 w-full h-full absolute">
      {createClientInProgress || isLoading ? (
        <Loading />
      ) : (
        <div className="h-full flex flex-col">
          <div className="grid grid-cols-12 gap-x-4 gap-y-3 justify-center m-auto w-full">
            <h1 className="font-sans text-h2 mb-2 col-start-4 col-span-full">Create new client</h1>
             <div className="col-start-4 col-span-full mb-7">
              {theme.pages.createClient.showClientTypeSelection && 
                <fieldset className="flex gap-4">
                  <RadioButton
                    checked={clientType === "individual"}
                    id="individual"
                    label="Individual"
                    name="clientType"
                    onChange={() => setClientType("individual")}
                  />
                  <RadioButton
                    checked={clientType === "household"}
                    id="household"
                    label="Household"
                    name="clientType"
                    onChange={() => setClientType("household")}
                  />
                </fieldset>
              }
            </div>
          </div>
          {clientType === "individual" ? (
            <CreateIndividualClientPage onCreate={() => setCreateClientInProgress(true)} myAdvisors={myAdvisors} />
          ) : (
            <CreateHouseholdPage onCreate={() => setCreateClientInProgress(true)} myAdvisors={myAdvisors} />
          )}
        </div>
      )}
    </div>
  )
}

export default CreateClientPage
