import { t } from "@lingui/macro"
import clsx from "clsx"
import React, { UIEventHandler, useContext, useState } from "react"
import { ImportClientEntry, importClients } from "../../../../api/clients"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import SelectAdvisor from "../../../../components/SelectAdvisor/SelectAdvisor"
import { SupportedLocales } from "../../../../config/theme"
import { FirmContext } from "../../../../contexts/FirmContext"
import { useTheme } from "../../../../contexts/ThemeContext"
import useAdvisorSearch from "../../../../hooks/useAdvisorSearch"
import useClientHouseholdSearch from "../../../../hooks/useClientHouseholdSearch"
import { useImportModal } from "../../../../hooks/useImport"
import useMyAdvisor from "../../../../hooks/useMyAdvisor"
import { Advisor } from "../../../../models/Advisor"
import download from "../../assets/images/download.svg"
import remove from "../../assets/images/minus-circle.svg"
import selectFile from "../../assets/images/select-file.svg"

interface Props {
  handleClose: UIEventHandler
}

const ImportClients: React.FunctionComponent<Props> = ({ handleClose }) => {
  const { refetch } = useClientHouseholdSearch(false)
  const [invalidClients, setInvalidClients] = useState<ImportClientEntry[]>()
  const { data: myAdvisors } = useAdvisorSearch()
  const { advisor, selectAdvisor } = useMyAdvisor(myAdvisors)
  const theme = useTheme()
  const { firm } = useContext(FirmContext)
  const {
    selectedFile,
    setSelectedFile,
    dragActive,
    inputRef,
    importStatus,
    setImportStatus,
    isInvalidFile,
    handleDrag,
    handleDrop,
    handleChange,
    onButtonClick
  } = useImportModal()

  const handleImport = () => {
    setImportStatus("importing")
    const formData = new FormData()
    formData.append("File", selectedFile as Blob)
    importClients(advisor!._id, formData)
      .then((res) => {
        setSelectedFile(null)
        refetch()
        setInvalidClients(res.invalidData)
        setImportStatus(res.invalidData.length > 0 ? "error" : "success")
      })
      .catch((err) => {
        console.error("error importing", err)
        setSelectedFile(null)
        setImportStatus("error")
      })
  }

  const locale = firm?.locale ?? (import.meta.env.VITE_APP_DEFAULT_LOCALE as SupportedLocales) ?? theme.defaultLocale ?? "en"
  const clientsTemplateLink = `/templates/${theme.id}/${locale}/Import_clients_template.xlsx`

  return (
    <div className="import-clients-modal w-modal h-full py-1 px-2">
      {importStatus === "success" ? (
        <div className="flex flex-col gap-6 items-center justify-center text-center h-[478px]">
          {theme.pages.importModal?.images?.successImage && <img className="w-20" src={theme.pages.importModal?.images?.successImage} alt="" aria-hidden />}
          <h2 className="text-h2 text-main-600 font-semibold px-10">Clients successfully imported</h2>
        </div>
      ) : (
        <>
          <h2 className="text-h2 text-main-600 font-semibold pb-6">{importStatus === "error" ? "Import failed" : "Import your clients"}</h2>
          {importStatus === "error" ? (
            <p className="text-main-500 pb-6">We ran into some issues with the following clients:</p>
          ) : (
            <div className="flex flex-row items-center gap-x-2 my-2 pb-6 ">
              <img className="import-client-download-icon" src={theme.pages.importModal?.icons?.downloadImage ?? download} alt="" aria-hidden />
              <a href={clientsTemplateLink} download className="import-client-download-link text-interactive-500 font-bold text-sec">
                Download client import template
              </a>
            </div>
          )}
          {importStatus === "error" ? (
            <>
              <div className="max-h-96 overflow-y-auto">
                <table className="w-full border-separate mb-2">
                  <thead>
                    <tr className="mb-2 text-sec font-semibold">
                      <th className="p-2 text-left">Row</th>
                      <th className="p-2 text-left">Client name</th>
                      <th className="p-2 text-left">Error</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invalidClients?.map((invalid) => (
                      <tr className="bg-surface-100 mb-2" key={invalid.id}>
                        <td className="p-2 text-main-500">{invalid.id + 1}</td>
                        <td className="p-2 text-main-500">
                          {invalid.Firstname} {invalid.Lastname}
                        </td>
                        <td className="p-2 text-main-500">{invalid.errorMessage}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p className="text-sm text-main-400 pb-6">
                Please check that first and last names are filled, there are two clients per household, date of birth is in the correct format, and portfolio
                names match those defined in the system.
              </p>
            </>
          ) : importStatus === "importing" ? (
            <div className="w-full mx-auto border-0.5 border-dashed border-interactive-300 py-18 mb-6">
              <Loading />
            </div>
          ) : (
            <form
              id="form-file-upload"
              className="import-clients-form w-full relative text-center mb-1"
              onDragEnter={handleDrag}
              onSubmit={(e) => e.preventDefault()}
            >
              <input ref={inputRef} type="file" id="input-file-upload" className="hidden" multiple={true} onChange={handleChange} />
              <label
                id="label-file-upload"
                className={clsx(
                  "import-clients-label cursor-pointer flex items-center justify-center border-0.5 border-dashed border-interactive-300 bg-white p-5",
                  dragActive ? "bg-main-200" : ""
                )}
                htmlFor="input-file-upload"
              >
                <div className="flex flex-col justify-center">
                  <img
                    src={theme.pages.importModal?.icons?.uploadImage ?? selectFile}
                    alt=""
                    aria-hidden="true"
                    className="import-client-upload-icon h-24 pb-2.5"
                  />
                  <button
                    className="upload-button cursor-pointer border-0 hover:underline text-main-500 max-w-[200px] font-sans font-normal"
                    onClick={onButtonClick}
                  >
                    Select a file to upload or drag and drop it here
                  </button>
                </div>
              </label>
              {dragActive && (
                <div
                  id="drag-file-element"
                  className="absolute w-full h-full rounded-3 inset-0"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </form>
          )}
          {(!importStatus || importStatus === "validationError" || importStatus === "importing") && (
            <>
              <p className="text-sm text-main-400 pb-6">Allowed types are: xlsx, xls and csv.</p>
              {selectedFile && (
                <div className="import-clients__selected-file flex flex-row items-center justify-between bg-surface-100 p-2 mb-8">
                  <p>{selectedFile?.name}</p>
                  {!importStatus && (
                    <button aria-label={`Remove file ${selectedFile.name}`} onClick={() => setSelectedFile(null)}>
                      <img src={theme.pages.importModal?.icons?.removeImage ?? remove} alt="" aria-hidden />
                    </button>
                  )}
                </div>
              )}
              {myAdvisors && myAdvisors.length > 1 && (
                <div className="pb-6">
                  <SelectAdvisor
                    label={t({ id: "import-clients-select-adviser-text", message: "Select primary adviser to assign these clients to" })}
                    onSelect={(value: Advisor) => {
                      selectAdvisor(value)
                    }}
                    advisors={myAdvisors}
                    selectedAdvisor={advisor}
                    verticalAlign="top"
                  />
                </div>
              )}
            </>
          )}
          <div role="alert">{isInvalidFile && <p className="text-error mb-6">Invalid file type, allowed types are: xlsx, xls and csv</p>}</div>
          <div className="flex justify-center gap-x-5">
            <button type="button" className="btn btn-secondary btn-medium text-sec w-44" onClick={handleClose} disabled={importStatus === "importing"}>
              Cancel
            </button>
            {importStatus === "error" ? (
              <button type="button" className="btn btn-primary btn-medium text-sec w-44" onClick={() => setImportStatus(undefined)}>
                Try again
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary btn-medium text-sec w-44"
                onClick={handleImport}
                disabled={!selectedFile || isInvalidFile || !advisor}
              >
                {importStatus === "importing" ? <Loading type="dots" /> : "Import"}
              </button>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default ImportClients
