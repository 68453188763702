import { useTheme } from "../../../contexts/ThemeContext"

interface Props {
  page?: number
  title?: string | JSX.Element
}

const Header = ({ title, page }: Props) => {
  const theme = useTheme()
  return (
    <div className={title ? "mt-9 mx-9 border-b-2" : "mt-12 mx-9 border-b-2"}>
      <div className="flex flex-row justify-between font-semibold text-main-400 leading-6 text-xxs">
        <p>{title}</p>
        <p>{theme.reports?.header?.showPageNumber && page}</p>
      </div>
    </div>
  )
}

export default Header
