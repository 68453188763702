import { useSearchParams } from "react-router-dom"

const RMJGoalProjectorDisclaimer = () => {
  const [par] = useSearchParams()
  const goalType = par.get("goal")

  return (
    goalType && (
      <div className="text-xs text-main-400 max-w-md leading-2">
        <p>
          {goalType === "retirement-income"
            ? "The retirement income projector "
            : goalType === "retirement-drawdown"
            ? "The retirement income and legacy projector "
            : goalType === "wealth-accumulation"
            ? "The wealth accumulation projector "
            : null}
          is designed for illustrative purposes only, and does not represent a full financial plan.
        </p>
      </div>
    )
  )
}

export default RMJGoalProjectorDisclaimer
