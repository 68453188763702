import { Trans } from "@lingui/macro"
import clsx from "clsx"
import { useTheme } from "../../../contexts/ThemeContext"
import Page from "./Page"

const SustainableInvestingDisclaimerPage = ({ page }: { page: number }) => {
  const theme = useTheme()
  return (
    <Page page={page} title="Appendix">
      <div className="mx-9">
        <h1 className="font-semibold mb-8 text-[22px]">Disclaimers</h1>

        <div className={clsx("flex h-full", theme?.reports?.sustainableInvestingDisclaimers?.showMSCISection ? "text-xs leading-2 gap-5" : "text-sec leading-5")}>
          <div className={clsx("flex flex-1 gap-5", theme?.reports?.sustainableInvestingDisclaimers?.showMSCISection && "flex-col")}>
            {theme?.reports?.sustainableInvestingDisclaimers?.showMSCISection && (
              <h2 className="font-medium text-sec leading-1">
                <Trans id="print-sustainable-investing-disclaimers-client-section-heading">Capital Preferences</Trans>
              </h2>
            )}

            <div className="flex flex-col w-full text-left gap-5 font-normal">
              <p>
                <Trans id="print-sustainable-investing-disclaimers-text-1">Lorem ipsum</Trans>
              </p>
              <p>
                <Trans id="print-sustainable-investing-disclaimers-text-2">Lorem ipsum</Trans>
              </p>
              <p>
                <Trans id="print-sustainable-investing-disclaimers-text-3">Lorem ipsum</Trans>
              </p>
            </div>
            <div className="flex flex-col w-full text-left gap-5 font-normal">
              <p>
                <Trans id="print-sustainable-investing-disclaimers-text-4">Lorem ipsum</Trans>
              </p>
              <p>
                <Trans id="print-sustainable-investing-disclaimers-text-5">Lorem ipsum</Trans>
              </p>
              <p>
                <Trans id="print-sustainable-investing-disclaimers-text-6">Lorem ipsum</Trans>
              </p>
              <p>
                <Trans id="print-sustainable-investing-disclaimers-text-7">Lorem ipsum</Trans>
              </p>
              <p>
                <Trans id="print-sustainable-investing-disclaimers-text-8">Lorem ipsum</Trans>
              </p>
            </div>
          </div>

          {theme.reports?.sustainableInvestingDisclaimers?.showMSCISection && (
            <div className="flex-1">
              <h2 className="mb-5 font-medium text-sec leading-1">
                <Trans id="print-sustainable-investing-disclaimers-msci-section-heading">Lorem ipsum</Trans>
              </h2>

              <p className="mb-5 font-normal">
                <Trans id="print-sustainable-investing-disclaimers-msci-disclaimer-text">Lorem ipsum</Trans>
              </p>

              <p className="font-normal">
                <Trans id="print-sustainable-investing-disclaimers-msci-description">Lorem ipsum</Trans>
              </p>
            </div>
          )}
        </div>
      </div>
    </Page>
  )
}

export default SustainableInvestingDisclaimerPage
