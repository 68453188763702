import clsx from "clsx"
import { Trans, t } from "@lingui/macro"
import datapointIcon from "./assets/datapoint.svg"
import { Game } from "../../../../../models/Client"
import { useEffect, useMemo, useRef, useState } from "react"
import { useTheme } from "../../../../../contexts/ThemeContext"

const ValuesMindset = ({ className, game, altruismLevel }: { className?: string; game: Game; altruismLevel: "low" | "medium" | "high" }) => {
  const [graphWidth, setGraphWidth] = useState(0)
  const graphRef = useRef<HTMLDivElement>(null)
  const score = Object.values(game?.esg?.results?.S2 ?? {})?.[1]?.score
  const theme = useTheme()
  const description = useMemo(() => {
    if (altruismLevel === "low") {
      return t({
        id: "report-values-mindset-low-description",
        message:
          "Clients differ in how much importance they place on reflecting their values in their investments. Your decisions showed us that we should continue to prioritize generating returns as we develop your investment strategy."
      })
    } else if (altruismLevel === "medium") {
      return t({
        id: "report-values-mindset-medium-description",
        message:
          "Clients differ in how much importance they place on reflecting their interests in their investments. Your decisions show that you want to continue to prioritize returns while also looking for opportunities to reflect what you care about."
      })
    }
    if (altruismLevel === "high") {
      return t({
        id: "report-values-mindset-high-description",
        message:
          "Clients differ in how much importance they place on reflecting their interests in their investments. Your decisions show that it’s important to reflect what you care about as your investment strategy develops."
      })
    }
  }, [altruismLevel])

  useEffect(() => {
    const localRef = graphRef.current
    if (localRef) {
      const divResizeObserver = new ResizeObserver((e) => {
        setGraphWidth(e[0].contentRect.width)
      })

      divResizeObserver.observe(localRef)
      return () => {
        divResizeObserver.unobserve(localRef)
      }
    }
  }, [])

  const offset = (16 / graphWidth) * 100
  const displacement = ((graphWidth * (score / 100)) / graphWidth) * 100

  return (
    <div className={clsx("flex-auto", className)}>
      <h2 className="mb-2 text-main-600 text-sec tracking-wide uppercase">Investing Mindset</h2>
      <h3 className="font-semibold text-main-600 text-h4 mb-6">We’ve got a clear read on your investing mindset</h3>
      <p>{description}</p>
      <div className="w-full relative mt-18 px-4 max-w-xl">
        <div ref={graphRef} className="values-mindset-chart-line h-1 bg-neutral-400 w-full" />
        <div className="flex flex-col items-center relative">
          <div className="absolute top-0 h-12 flex flex-col items-center -translate-y-full w-max" style={{ left: `${displacement - offset}%` }}>
            <img className="w-8 h-8" src={theme.reports?.valuesMindset?.datapointIcon ?? datapointIcon} alt="" aria-hidden />
            <hr className="h-full border-0 border-l bg-interactive-600" />
            <div className="w-[5px] h-[5px] absolute bottom-0 rounded-full bg-interactive-600" />
          </div>
        </div>
        <div className="values-mindset-chart-labels mt-1 flex justify-between text-xs">
          <p className="text-left w-18">Generating higher returns</p>
          <p className="text-center w-12">Combines both</p>
          <p className="text-right w-18">
            <Trans id="report-values-mindset-chart-label-right">Aligning to areas of interest</Trans>
          </p>
        </div>
      </div>
    </div>
  )
}

export default ValuesMindset
