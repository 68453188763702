import { format, parse } from "date-fns"
import { useCallback, useContext, useState } from "react"
import { useNavigate } from "react-router"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import Modal from "../../../../components/Modal/Modal"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"
import { validateDob } from "../../../../lib/clients"
import { DATE_FORMAT } from "../../../../lib/date"
import { Client, InvestmentGoal } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import InvestmentGoalChooser from "../../../clients/components/InvestmentGoal/InvestmentGoalChooser"
import errorImage from "./assets/error.svg"

interface Props {
  client?: Client
  household?: Household
  onClose: () => void
  defaultGoal?: InvestmentGoal
}

type ClientDOB = {
  _id: string
  dob: Date | null
  dobRaw?: string
  name: string
}

const RMJInvestmentGoalModal = ({ client, household, onClose, defaultGoal }: Props) => {
  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const [status, setStatus] = useState<"updating" | "error">()
  const [goalType, setGoalType] = useState<InvestmentGoal | null>(() => defaultGoal || (client ?? household)?.primaryInvestmentGoal || null)
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [cDobs, setCDobs] = useState<ClientDOB[] | null>(() => {
    if (client) {
      return [{ _id: client._id, dob: client.dob ? new Date(client.dob) : null, name: client.firstName }]
    } else if (household) {
      return household.members.map(({ client }) => ({ _id: client._id, dob: client.dob ? new Date(client.dob) : null, name: client.firstName }))
    }
    return null
  })
  const navigate = useNavigate()

  const validate = useCallback(() => {
    if (goalType === "retirementIncome") {
      return cDobs?.map((c) => {
        if (!c.dob && !c.dobRaw) {
          return "Please select a date"
        } else {
          const parsed = c.dobRaw ? parse(c.dobRaw, DATE_FORMAT, new Date()) : c.dob
          return validateDob(parsed!)
        }
      })
    }
  }, [cDobs, goalType])

  const onContinue = () => {
    setShouldValidate(true)
    const errs = validate()
    if (goalType && (!errs || Object.values(errs).every((x) => !x))) {
      setStatus("updating")
      const updatePromise = client
        ? updateClient(client._id, {
            primaryInvestmentGoal: goalType,
            ...(goalType === "retirementIncome" ? { dob: format(cDobs![0].dob!, "yyyy-MM-dd") } : {})
          })
        : updateHousehold(household!._id, goalType === "retirementIncome" ? cDobs!.map((c) => ({ dob: format(c.dob!, "yyyy-MM-dd") })) : [], {
            primaryInvestmentGoal: goalType
          })
      updatePromise
        .then(() => {
          setStatus(undefined)
          onClose()
          navigate(
            `../goal-explorer?goal=${
              goalType === "retirementIncome" ? "retirement-income" : goalType === "retirementDrawdown" ? "retirement-drawdown" : "wealth-accumulation"
            }`
          )
        })
        .catch(() => {
          console.error("error updating the goal type")
          setStatus("error")
        })
    }
  }

  return (
    <Modal handleClose={onClose}>
      <div className="flex flex-col items-center">
        <h2 className="text-h2 text-main-600 font-semibold text-center mb-6">
          Now, let’s take a look at your primary <br />
          investment goal.
        </h2>
        <p className="text-sec text-main-600 mb-2">Please select a goal</p>
        <InvestmentGoalChooser
          client={client}
          household={household}
          goalType={goalType}
          cDobs={cDobs}
          onGoalChange={(goalType) => {
            setGoalType(goalType)
          }}
          onDobChange={(clientId, value) => {
            setCDobs(cDobs ? cDobs.map((item) => (item._id === clientId ? { ...item, dob: value, dobRaw: undefined } : item)) : null)
          }}
          onDobRawChange={(clientId, value) => {
            setCDobs(cDobs ? cDobs.map((item) => (item._id === clientId ? { ...item, dobRaw: value } : item)) : null)
          }}
          shouldValidate={shouldValidate}
        />
        {shouldValidate && !goalType && (
          <p className="flex items-center gap-1 text-sm text-error mt-2">
            <img alt="" src={errorImage} />
            <span className="mt-0.5">Please select a goal</span>
          </p>
        )}

        {status === "error" && (
          <p className="flex items-center gap-1 text-sm text-error mt-2">
            <img alt="" src={errorImage} />
            <span className="mt-0.5">Error updating the goal type, please try again</span>
          </p>
        )}
        <div className="flex justify-center gap-4 mt-6">
          <button onClick={onClose} className="btn btn-secondary w-44">
            Back
          </button>
          <button className="btn btn-primary btn-medium w-44" onClick={onContinue} disabled={status === "updating"}>
            {status === "updating" ? <Loading type="dots" /> : "Continue"}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default RMJInvestmentGoalModal
