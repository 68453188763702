import { ReactNode } from "react"
import CoverPageContainer from "./CoverPageContainer"
import Footer from "./Footer"
import Header from "./Header"
import clsx from "clsx"

interface Props {
  children: ReactNode
  page: number
  title?: string | JSX.Element
  footer?: ReactNode
  className?: string
}

const Page = ({ children, page, title, footer, className }: Props) => {
  return (
    <CoverPageContainer className={clsx("h-full flex flex-col", className)}>
      <Header title={title} page={page} />
      <div className="grow mt-8">{children}</div>
      {footer ? <div className="reports-footer relative h-full flex items-end">{footer}</div> : <Footer page={page} />}
    </CoverPageContainer>
  )
}

export default Page
