import React, { ReactNode, useEffect, useRef, useState } from "react"
import play from "./assets/play-button.svg"
import { clsx } from "clsx"

interface VideoPlayerProps {
  message?: ReactNode | string
  thumbnail: string
  videoSrc: string
  width?: number
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoSrc, message, thumbnail, width = 600 }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const handleStart = () => {
    setIsPlaying(true)
    videoRef.current?.play()
  }

  useEffect(() => {
    const videoElement = videoRef.current
    const handleVideoEnded = () => {
      videoElement?.load()
      setIsPlaying(false)
    }
    videoElement?.addEventListener("ended", handleVideoEnded)
    return () => videoElement?.removeEventListener("ended", handleVideoEnded)
  }, [])

  return (
    <div className="relative w-full" style={{ maxWidth: `${width}px` }}>
      {!isPlaying && (
        <button onClick={handleStart} aria-label="Play video" className="relative block w-full aspect-[16/9]">
          <img src={thumbnail} className="w-full h-auto" alt="" aria-hidden />
          <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/30">
            <img className="w-1/4" src={play} alt="" aria-hidden />
            {message && <p className="text-white font-semibold text-sec sm:text-p">{message}</p>}
          </div>
        </button>
      )}
      <video ref={videoRef} className={clsx("w-full h-auto aspect-video", isPlaying ? "block" : "hidden")} controls poster={thumbnail}>
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  )
}

export default VideoPlayer
