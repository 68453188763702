import { useState } from "react"
import clsx from "clsx"
import DollarIcon from "../../../../components/DolarIcon/DollarIcon"
import NumberInput from "../../../../components/NumberInput/NumberInput"
import { Client, InvestmentGoal } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import { RetirementIncomeDrawdownErrors, RetirementIncomeFormErrors, RetirementIncomeFormValues } from "./RetirementIncomeUtils"
import { GoalExplorerStatuses } from "../utils/validation"
import ToggleButton from "../components/ToggleButton/ToggleButton"
import editIcon from "../assets/editIcon.svg"
import RMJTextTooltip from "../components/RMJTextTooltip/RMJTextTooltip"
import infoIcon from "../../assets/images/info-icon.svg"
import { AnimatePresence } from "framer-motion"
import { FEATURE_TOOLTIP_WEALTH_AND_INCOME_FORMS, FEATURE_ALREADY_RETIRED } from "../../../../config/features"
import EditPlanningHorizonModal from "./EditPlanningHorizonModal"
import infoAlert from "../../../../assets/icons/info-alert.svg"
import { useNavigate } from "react-router"
interface Props {
  client?: Client
  household?: Household
  errors: RetirementIncomeFormErrors
  errorsDrawdown: RetirementIncomeDrawdownErrors
  outsideIM?: boolean
  onChange: (values: Partial<RetirementIncomeFormValues>) => void
  onSubmit: () => void
  setStatus: (status: GoalExplorerStatuses) => void
  status?: GoalExplorerStatuses
  values: RetirementIncomeFormValues
  goalType?: InvestmentGoal
  calculateResults?: (goalType: InvestmentGoal) => void
}

const RetirementIncomeForm = ({
  client,
  household,
  errors,
  onChange,
  onSubmit,
  setStatus,
  status,
  values,
  goalType,
  calculateResults,
  errorsDrawdown,
  outsideIM
}: Props) => {
  const [isPlanningHorizonOpen, setIsPlanningHorizonOpen] = useState(false)
  const [showInfoTooltip, setShowInfoTooltip] = useState(false)
  const navigate = useNavigate()
  return (
    <div className="flex flex-col grow overflow-hidden">
      <div
        className={clsx("grow overflow-auto no-scrollbar", {
          "pointer-events-none": status === "updating"
        })}
      >
        <div className="bg-surface-300 px-5 py-3 mb-3">
          <NumberInput
            inputFieldClassName="h-14"
            className="text-sec"
            disableAutoComplete
            name="retirementGoal"
            label={
              <span className="block text-p text-main-600 text-center font-semibold mb-1">
                {goalType === "retirementDrawdown" ? "Retirement drawdown goal" : "Retirement income goal"}
              </span>
            }
            value={values.retirementIncomeGoal}
            suffix={<span>p/y</span>}
            prefix={
              <div className="pl-3">
                <DollarIcon />
              </div>
            }
            onChange={(value) => onChange({ retirementIncomeGoal: value })}
            error={goalType === "retirementIncome" ? errors.retirementIncomeGoal : errorsDrawdown.retirementIncomeGoal}
          />
          {FEATURE_ALREADY_RETIRED && (
            <ToggleButton
              id="alreadyRetired"
              toggleState={!!values.alreadyRetired}
              onClick={() => {
                const retiredStatus = !values.alreadyRetired
                onChange({ alreadyRetired: retiredStatus })
                navigate(`../${outsideIM ? "goal" : "goal-explorer"}?goal=${retiredStatus ? "retirement-drawdown" : "retirement-income"}`, { relative: "path" })
                if (calculateResults) {
                  calculateResults(retiredStatus ? "retirementDrawdown" : "retirementIncome")
                }
              }}
              label="Already in retirement"
            />
          )}
        </div>
        <div className="px-5 mb-3">
          <NumberInput
            inputFieldClassName="h-9"
            className="text-sec"
            label="Investment amount"
            disableAutoComplete
            name="investmentAmount"
            value={values.investmentAmount}
            prefix={
              <div className="pl-3">
                <DollarIcon />
              </div>
            }
            onChange={(value) => onChange({ investmentAmount: value })}
            error={goalType === "retirementIncome" ? errors.investmentAmount : errorsDrawdown.investmentAmount}
          />
        </div>
        {goalType === "retirementIncome" && (
          <div className="px-5 mb-3">
            <NumberInput
              inputFieldClassName="h-9"
              className="text-sec"
              label="Annual investment contribution"
              disableAutoComplete
              name="annualInvestmentContribution"
              value={values.annualInvestmentContribution}
              suffix={<span>p/y</span>}
              prefix={
                <div className="pl-3">
                  <DollarIcon />
                </div>
              }
              onChange={(value) => onChange({ annualInvestmentContribution: value })}
              error={errors.annualInvestmentContribution}
            />
          </div>
        )}
        <div className="px-5 mb-3">
          <NumberInput
            inputFieldClassName="h-9"
            className="text-sec"
            label="Pension in retirement"
            disableAutoComplete
            name="pensionInRetirement"
            value={values.pensionInRetirement}
            prefix={
              <div className="pl-3">
                <DollarIcon />
              </div>
            }
            suffix={<span>p/y</span>}
            onChange={(value) => onChange({ pensionInRetirement: value })}
            error={goalType === "retirementIncome" ? errors.pensionInRetirement : errorsDrawdown.pensionInRetirement}
          />
        </div>
        <div className="px-5 mb-3">
          <NumberInput
            inputFieldClassName="h-9"
            className="text-sec"
            label="Other income in retirement"
            disableAutoComplete
            name="otherIncomeInRetirement"
            value={values.otherSourcesRetirementIncome}
            prefix={
              <div className="pl-3">
                <DollarIcon />
              </div>
            }
            suffix={<span>p/y</span>}
            onChange={(value) => onChange({ otherSourcesRetirementIncome: value })}
            error={goalType === "retirementIncome" ? errors.otherSourcesRetirementIncome : errorsDrawdown.otherSourcesRetirementIncome}
          />
          <small className="block text-sm text-main-600 mt-1">e.g. property income, other investments</small>
        </div>
        {goalType === "retirementDrawdown" && (
          <div className="px-5 mb-3">
            <NumberInput
              inputFieldClassName="h-9"
              className="text-sec"
              label="Legacy goal"
              disableAutoComplete
              name="estateGoal"
              value={values.estateGoal}
              prefix={
                <div className="pl-3">
                  <DollarIcon />
                </div>
              }
              onChange={(value) => onChange({ estateGoal: value })}
              error={errorsDrawdown.estateGoal}
            />
            <small className="block text-sm text-main-600 mt-1">e.g. money left for children or charities</small>
          </div>
        )}
        {goalType === "retirementIncome" && (
          <>
            <label className="text-sec px-5 text-main-600 font-semibold" htmlFor="retirementAge">
              {client ? `${client.firstName}'s retirement age` : "Retirement age"}
            </label>
            <div className="px-5 mt-1 mb-3 flex flex-row flex-wrap gap-x-2">
              {values.members.map(({ dob, retirementAge }, i) => {
                const member = household ? household?.members[i].client : client
                const yearOfBirth = new Date(dob!).getFullYear()
                const yearsLeftToRetire = retirementAge ? yearOfBirth + retirementAge - new Date().getFullYear() : 0
                return (
                  <div className="flex-1" key={member?._id}>
                    {values.members.length > 1 && household?.members[i] && <h4 className="text-sec text-main-600 font-semibold mb-1">{member?.firstName}</h4>}
                    <NumberInput
                      inputFieldClassName="h-9"
                      className="text-sec"
                      disableAutoComplete
                      name={household ? `retirementAge${i}` : "retirementAge"}
                      value={retirementAge}
                      suffix={"years"}
                      onChange={(value) => {
                        onChange({
                          members: values.members.map((member, idx) => {
                            if (idx === i) {
                              return {
                                ...member,
                                retirementAge: value ? value : undefined
                              }
                            }
                            return member
                          })
                        })
                      }}
                      error={errors.members[i]?.retirementAge}
                    />
                    {household && <label className="text-sm text-main-400">(in {yearsLeftToRetire} years)</label>}
                  </div>
                )
              })}
              {errors.planningHorizon && (
                <div className="flex">
                  <img alt="Info alert" className="text-input-error-icon mr-1" src={infoAlert} />
                  <p className="text-sm text-error mt-1">{errors.planningHorizon}</p>
                </div>
              )}
            </div>
            <div onClick={() => setIsPlanningHorizonOpen(true)} className="mx-5 mt-7 mb-5 flex gap-1 cursor-pointer w-max">
              <img aria-hidden src={editIcon} alt="" />
              <button className="text-xs text-interactive-500">Edit planning horizon</button>
            </div>
          </>
        )}
      </div>
      <div className="px-5 py-4 flex gap-x-4 items-center shadow-sm">
        <button
          className={clsx("btn btn-secondary btn-medium w-full h-12 flex items-center justify-center p-0", {
            "pointer-events-none": status === "updating"
          })}
          onClick={onSubmit}
        >
          {status === "updating" ? <Loading className="h-8 w-full" /> : "Update"}
        </button>
        {FEATURE_TOOLTIP_WEALTH_AND_INCOME_FORMS && (
          <div className="w-5 h-5" onMouseOver={() => setShowInfoTooltip(true)} onMouseOut={() => setShowInfoTooltip(false)}>
            <img className="cursor-pointer" src={infoIcon} alt="information icon" />
          </div>
        )}
        {showInfoTooltip && (
          <div className="absolute bottom-0 w-75 left-70 z-10">
            <RMJTextTooltip>
              <p className="text-sm leading-3">
                This projection assumes that you will stay invested in your current investment for at least the next 10 years, and then you will switch to our
                most conservative investment option
              </p>
            </RMJTextTooltip>
          </div>
        )}
      </div>
      <AnimatePresence>
        {isPlanningHorizonOpen && (
          <EditPlanningHorizonModal
            onClose={() => setIsPlanningHorizonOpen(false)}
            value={values.planningHorizon || 100}
            client={client}
            household={household}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default RetirementIncomeForm
