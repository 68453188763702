import React, { PropsWithChildren, useContext } from "react"
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom"
import { AuthContext, AuthStatus } from "./views/auth/AuthContext"
import AuthLayout from "./views/auth/AuthLayout"
import ForgotPasswordFlow from "./views/auth/ForgotPasswordFlow"
import RedirectToOAuthProvider from "./views/auth/RedirectToOAuthProvider"
import RespondToMFA from "./views/auth/RespondToMFA"
import SignIn from "./views/auth/SignIn"
import AccountNotFoundForProvider from "./views/auth/crm/AccountNotFoundForProvider"
import ProviderInsufficientPrivileges from "./views/auth/crm/ProviderInsufficientPrivileges"


const AppWithAuth: React.FC<PropsWithChildren<object>> = ({ children }) => {
  const { authStatus } = useContext(AuthContext)
  const [search] = useSearchParams()
  const modals: { [key: string]: JSX.Element } = {
    forgotpassword: <ForgotPasswordFlow />,
    setupaccount: <ForgotPasswordFlow setupAccountSkin />,
    mfaChallenge: <RespondToMFA onCodeComplete={() => {}} onResendCode={() => {}}/>
  }
  const _m = search.get("_m")
  const modal = _m ? modals[_m] : undefined
  const _auth = search.get("_auth")
  const _idp = search.get("_idp")

  return (
    <>
      {authStatus === AuthStatus.SignedIn ? children : null}
      {authStatus === AuthStatus.SignedOut || authStatus === AuthStatus.Loading ? (
        _auth === "oauth2" && _idp
          ? <RedirectToOAuthProvider idp={_idp} />
          : <Routes>
              <Route path="/auth/:provider/not-found" element={<AccountNotFoundForProvider />} />
              <Route path="/auth/:provider/insufficient-grants" element={<ProviderInsufficientPrivileges />} />
              <Route path="*" element={
                <AuthLayout modal={modal}>
                  <SignIn />
                </AuthLayout>
              } />
            </Routes>
      ) : (
        <></>
      )}
    </>
  )
}

export default AppWithAuth
