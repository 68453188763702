import clsx from "clsx"
import { ReactNode, useEffect, useMemo, useRef, useState } from "react"
import infoAlert from "../../../src/assets/icons/info-alert.svg"
import { Advisor } from "../../models/Advisor"
import chevUp from "../../pages/advisor/assets/images/chevron-up.svg"
import SearchBox from "../../pages/advisor/components/SearchBox"
import Dropdown from "../Dropdown/Dropdown"

type Props = {
  className?: string
  isAssociate?: boolean
  disableAutoComplete?: boolean
  advisors: Advisor[]
  error?: string | ReactNode
  label?: string | ReactNode
  onSelect: (v: any) => void
  selectedAdvisor?: Advisor | "all"
  verticalAlign?: "top" | "bottom"
}

const SelectAdvisor = ({ label, isAssociate, onSelect, selectedAdvisor, advisors: data, error, verticalAlign = "bottom" }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const menuTriggerEl = useRef<HTMLButtonElement>(null)
  const [searchStr, setSearchStr] = useState<string>()

  const advisors = useMemo(() => data?.filter((x) => String(x.firstName + " " + x.lastName).match(new RegExp(searchStr!, "i"))), [data, searchStr])

  useEffect(() => {
    if (!isMenuOpen) {
      setSearchStr("")
    }
  }, [isMenuOpen])

  return (
    <>
      <label className="select-adviser-label text-main-600 flex-shrink-0 text-sec mb-[5px]" htmlFor="select-adviser">
        {label}
      </label>
      <div className="w-full flex-col relative inline-block z-40 flex-shrink-0">
        <button
          className={clsx("px-4 py-2 select-adviser-wrapper w-full h-full flex justify-between border bg-white overflow-hidden", {
            "border-error": !!error,
            "border-main-400": !isMenuOpen && !error,
            "border-focuscolor": isMenuOpen && !error
          })}
          onClick={() => {
            setIsMenuOpen(!isMenuOpen)
          }}
          ref={menuTriggerEl}
          id="select-adviser"
        >
          {selectedAdvisor === "all"
            ? "All Advisers"
            : selectedAdvisor
            ? `${selectedAdvisor.firstName} ${selectedAdvisor.lastName}`
            : "Select"}
          <img src={chevUp} alt="" aria-hidden="true" className={clsx({ "rotate-180": !isMenuOpen })} />
        </button>
        {isMenuOpen && (
          <Dropdown overlayClassName="w-full h-48" handleClose={() => setIsMenuOpen(false)} trigger={menuTriggerEl} alignVertical={verticalAlign}>
            <ul
              className={clsx("list-none p-0 text-main-500 overflow-y-auto border h-48", {
                "border-focuscolor": isMenuOpen
              })}
            >
              <li key="start-typing">
                <SearchBox
                  className={clsx("border-none ", {
                    "border-focuscolor": isMenuOpen
                  })}
                  placeholder="Start typing ..."
                  onChange={(val) => {
                    setSearchStr(val)
                  }}
                  onClear={() => {
                    setSearchStr("")
                  }}
                />
              </li>
              {isAssociate && (
                <li key="all-advisers">
                  <button
                    className="btn p-4 hover:bg-interactive-100 cursor-pointer border-0 block w-full text-left font-normal"
                    onClick={() => {
                      onSelect("all")
                      setIsMenuOpen(false)
                    }}
                  >
                    All advisers
                  </button>
                </li>
              )}
              {advisors?.map((advisor) => (
                <li key={advisor._id}>
                  <button
                    className="btn p-4 hover:bg-interactive-100 cursor-pointer border-0 block w-full text-left font-normal"
                    onClick={() => {
                      onSelect(advisor)
                      setIsMenuOpen(false)
                    }}
                  >
                    {advisor.firstName} {advisor.lastName}
                  </button>
                </li>
              ))}
            </ul>
          </Dropdown>
        )}
      </div>
      {error && (
        <div className="text-input-error flex items-center mt-1">
          <img alt="" aria-hidden="true" className="text-input-error-icon mr-1" src={infoAlert} />
          <p className="text-input-error-text text-sm text-error font-normal" role="alert">
            {error}
          </p>
        </div>
      )}
    </>
  )
}

export default SelectAdvisor
