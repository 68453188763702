import vector from "../images/Vector.svg"

const DeleteAssetClassModal: React.FunctionComponent<{
  handleClose: () => void
}> = ({ handleClose }) => {
  return (
    <div className="modal-container w-[600px]">
      <div className="m-auto pb-10 gap-5">
        <img src={vector} alt="warning" aria-hidden />
      </div>
      <h1 className="modal-title">You cannot delete this asset class</h1>
      <p className="text-center p-4">This asset-class has been allocated to a model portfolio.</p>
      <div className="flex gap-x-4 w-full max-w-[340px] m-auto">
        <button className="btn btn-primary btn-medium text-primary flex-1 hover:bg-interactive-600 hover:cursor-pointer" onClick={handleClose}>
          Close
        </button>
      </div>
    </div>
  )
}

export default DeleteAssetClassModal
