import { i18n } from "@lingui/core"
import { Theme } from "../../../../config/theme"
import { Client, Game } from "../../../../models/Client"
import { latest } from "../../../../lib/clients"

export const causeScoresForProfile = (client: Client, theme: Theme) => {
  const game = latest(client, "esg")
  return Object.entries(game?.esg?.results?.S1 || {})
    .map(([causeId, s]) => {
      const cause = Object.values(theme.causes?.S1 ?? []).find((c) => c.id === s.id)
      const subOptions = game?.esg?.causes?.S1 && game?.esg?.causes?.S1[cause!.id!]
      return {
        causeId: causeId,
        title: `${cause?.title}${
          subOptions && subOptions.length > 0 ? ` (${/* lingui-extract-ignore */ i18n._({ id: `causes-${cause?.id}-${subOptions[0]}-label` })})` : ""
        }`,
        color: cause!.barColor!,
        icon: cause?.persona?.icon,
        secondaryIcon: cause!.secondaryIcon!,
        score: s.score,
      }
    })
    .sort((a, b) => (a.score === b.score ? 0 : a.score > b.score ? -1 : 1))
}

export const clientTopCause = (client: Client, theme: Theme) => {
  const game = latest(client, "esg")
  return clientTopCauseByGame(game!, theme)
}

export const clientTopCauseByGame = (game: Game, theme: Theme) => {
  const topCause = game?.esg?.results?.topCause
  const topCauseId = topCause && game.esg.results?.S1?.[topCause] ? game.esg.results?.S1?.[topCause]?.id : undefined
  return topCauseId
    ? Object.values(theme.causes?.S1 ?? []).find((c) => c.id === topCauseId)
    : Object.values(theme.causes?.S1 ?? []).find((cause) => cause.isDefault)
}
