import { useRef, useState } from "react"
import Dropdown from "../../../components/Dropdown/Dropdown"
import moreIcon from "../../../assets/images/icons/dots.svg"
import archiveIcon from "../assets/images/archive.svg"
import { useTheme } from "../../../contexts/ThemeContext"
import { isExternal } from "util/types"

interface Props {
  alignVertical?: "bottom" | "top"
  isHousehold: boolean
  isExternal?: boolean
  onArchiveClick: () => void
  onViewProfileClick: () => void
}

const ClientItemMenu = ({ alignVertical, isHousehold, isExternal, onArchiveClick, onViewProfileClick }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const menuBtn = useRef<HTMLButtonElement>(null)
  const theme = useTheme()

  return (
    <div className="relative flex items-center">
      <button aria-label="Actions" className="p-1 border-2 border-transparent hover:border-interactive-200" onClick={() => setIsMenuOpen(true)} ref={menuBtn}>
        <img alt="" src={moreIcon} aria-hidden />
      </button>
      {isMenuOpen && (
        <Dropdown overlayClassName="w-[200px]" align="left" alignVertical={alignVertical} handleClose={() => setIsMenuOpen(false)} trigger={menuBtn}>
          <ul className="list-none p-0 text-main-500">
            {theme.pages.clientsList?.clientActions.links.view &&
              <li>
                <button
                  className="btn text-p p-4 hover:bg-interactive-100 cursor-pointer border-0 block w-full text-left font-normal"
                  onClick={() => {
                    setIsMenuOpen(false)
                    onViewProfileClick()
                  }}
                >
                  View profile
                </button>
              </li>
            }
            {theme.pages.clientsList?.clientActions.links.archive && !isExternal &&
              <li>
                <button
                  className="btn text-p p-4 hover:bg-interactive-100 cursor-pointer border-0 flex justify-between items-center w-full text-left font-normal"
                  onClick={() => {
                    setIsMenuOpen(false)
                    onArchiveClick()
                  }}
                >
                  {isHousehold ? "Archive household" : "Archive client"}
                  <img alt="" src={archiveIcon} aria-hidden />
                </button>
              </li>
            }
          </ul>
        </Dropdown>
      )}
    </div>
  )
}

export default ClientItemMenu
