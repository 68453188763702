import { AxiosError } from 'axios'
import format from 'date-fns/format'
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import axiosInstance from '../../../api/axiosInstance'
import Loading from '../../../components/ClientProfile/Loading/Loading'
import CollapsibleMessage from './CollapsibleMessage'

export type Message = {
  _id: string
  offset: number
  timestamp: string
  headers: any
  key: string
  value: string
  json?: any
}

const ViewTopic: React.FC<PropsWithChildren<{ topic: string }>> = ({ topic }) => {

  const [copiedItem, setCopiedItem] = useState("")

  const query = useQuery(
    ['admin/kafka/topic', topic],
    () =>
      axiosInstance
        .get<{ messages: Message[] }>(
          `${import.meta.env.VITE_APP_API_BASE || ''}/api/admin/kafka/${topic}?limit=100`
        )
        .then((res) => res.data),
    { staleTime: 60 * 1000 }
  )

  const replay = useMutation<{}, AxiosError, { id: string }>((payload) =>
    axiosInstance
      .post(`${import.meta.env.VITE_APP_API_BASE || ''}/api/admin/kafka/${topic}`, payload)
      .then((r) => r.data)
  )

  const copyToClipboard = useCallback(
    (message: Message) => {
      navigator.clipboard.writeText(JSON.stringify(JSON.parse(message.value), null, 2))
      setCopiedItem(message._id)
    },
    [],
  )

  useEffect(() => {
    if (copiedItem) {
      const t = setTimeout(() => {
        setCopiedItem("")
      }, 3000);
      return () => clearTimeout(t)
    }
  
  }, [copiedItem])
  
  

  return (
    <>
      {query.isLoading && <Loading />}
      {query.isError && <div className="text-error">{String(query.error)}</div>}
      {query.isSuccess && (
        <>
          <div className="px-6 w-full h-full flex flex-col gap-y-4 overflow-y-hidden">
            <div className="h-full flex flex-col overflow-y-auto text-sm leading-1 gap-y-2">
              {query.data.messages.map((message) => (
                <div key={message._id} className="flex flex-col items-stretch rounded-2 border border-main-200">
                  <div className="w-full flex items-center justify-between px-3 py-2 bg-highlight-200 border-b border-main-200">
                    <div className="flex gap-x-1">
                      <div className="font-semibold text-main-500">{format(new Date(message.timestamp), 'yyyy-MM-dd HH:mm:ss.SSS')}</div>
                      <div className="text-main-300">{message._id}</div>
                    </div>
                    {message.key && <div className="flex gap-x-1">
                      <span className="font-semibold">Key:</span>
                      <span className="">{message.key}</span>
                    </div>}
                    <div className="flex items-end gap-x-1">
                      <button
                        className="btn btn-secondary btn-small px-2 py-1 bg-white"
                        onClick={() => copyToClipboard(message)}
                        disabled={message._id === copiedItem}
                      >
                        {message._id === copiedItem ? "Copied." : "Copy" }
                      </button>
                      <button
                        className="btn btn-primary btn-small px-2 py-1"
                        onClick={() => {
                          replay.mutate({ id: message._id })
                        }}
                      >
                        {replay.isLoading && replay.variables?.id === message._id ? (
                          <Loading />
                        ) : (
                          <>Replay</>
                        )}
                      </button>
                    </div>
                  </div>
                  <CollapsibleMessage message={message} />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ViewTopic

