import { Client } from "../../../models/Client"
import Container from "./components/Container"
import { Household } from "../../../models/Household"
import Footer from "./components/Footer"
import { useContext, useMemo } from "react"
import { FirmContext } from "../../../contexts/FirmContext"
import Target from "./assets/goals.svg"
import PortfolioChart from "../../rmjourney/goalExplorer/components/PortfolioChart/PortfolioChart"
import { Options } from "../../rmjourney/goalExplorer/components/SelectPortfolio/SelectPortfolio"
import { formatCurrencyLong } from "../../../lib/currency"
import RetirementDrawdownChart from "../../rmjourney/goalExplorer/components/RetirementDrawdownChart/RetirementDrawdownChart"
import RetirementDrawdownLegends from "../../rmjourney/goalExplorer/components/RetirementDrawdownChart/RetirementDrawdownLegends"
import clsx from "clsx"

interface Props {
  page: number
  client: Client
  household: Household
  options: Options
}

const ProjectorChartPage = ({ page, client, household, options }: Props) => {
  const { firm } = useContext(FirmContext)
  const clientOrHousehold = client || household
  const currentPortfolio = useMemo(
    () => firm?.modelPortfolios?.find((modelPortfolio) => modelPortfolio.id === clientOrHousehold.currentPortfolio),
    [firm?.modelPortfolios, clientOrHousehold.currentPortfolio]
  )
  return (
    <Container className="flex flex-col justify-between pt-10 relative">
      <div className="mx-10">
        <h2 className="mb-2 text-main-600 text-h4 font-semibold pb-5 tracking-wide">
          {clientOrHousehold.primaryInvestmentGoal === "retirementIncome"
            ? "Retirement income projector"
            : clientOrHousehold.primaryInvestmentGoal === "retirementDrawdown"
            ? "Retirement income and legacy projector"
            : "Wealth accumulation projector"}
        </h2>
        <div className="bg-surface-100 grow flex w-full px-6 py-6 items-center">
          <div className="flex-col pr-7 text-center border-r border-surface-400">
            <img className="mx-auto h-16 w-16" alt="target" src={Target} />
            <h3 className="text-h2 pt-3 font-semibold">
              {clientOrHousehold.primaryInvestmentGoal === "retirementIncome" || clientOrHousehold.primaryInvestmentGoal === "retirementDrawdown"
                ? formatCurrencyLong(clientOrHousehold.retirementIncomeGoal, "p/y")
                : formatCurrencyLong(clientOrHousehold.wealthAccumulationGoal)}
              <span className="block pt-2 font-semibold text-p">
                {clientOrHousehold.primaryInvestmentGoal === "retirementIncome"
                  ? "Retirement income goal"
                  : clientOrHousehold.primaryInvestmentGoal === "retirementDrawdown"
                  ? "Retirement drawdown goal"
                  : "Wealth accumulation goal"}
              </span>
            </h3>
          </div>
          <div className={clsx("flex flex-col flex-wrap w-86 gap-x-8 text-sm ml-6", clientOrHousehold.primaryInvestmentGoal === "wealthAccumulation" ? "max-h-32" : "max-h-44")}>
            <div>
              <h4>Investment amount</h4>
              <p className="font-bold">{formatCurrencyLong(clientOrHousehold.investmentAmount)}</p>
            </div>

            {clientOrHousehold.primaryInvestmentGoal !== "retirementDrawdown" && (
              <div>
                <h4 className="pt-2">Annual contribution</h4>
                <p className="font-bold">{formatCurrencyLong(clientOrHousehold.annualInvestmentContribution, "p/y")}</p>
              </div>
            )}

            {clientOrHousehold.primaryInvestmentGoal !== "wealthAccumulation" && (
              <>
                <div className="pb-2">
                  <h4 className="pt-2">Pension in retirement</h4>
                  <p className="font-bold">{formatCurrencyLong(clientOrHousehold.pensionInRetirement, "p/y")}</p>
                </div>

                <div>
                  <h4 className="">Other income in retirement</h4>
                  <p className="font-bold">{formatCurrencyLong(clientOrHousehold.otherSourcesRetirementIncome, "p/y")}</p>
                </div>
              </>
            )}

            {clientOrHousehold.primaryInvestmentGoal === "wealthAccumulation" && (
              <div className="flex flex-col pt-2">
                <h4>Goal target date</h4>
                <p className="font-bold">{clientOrHousehold.wealthAccumulationYear}</p>
              </div>
            )}

            {clientOrHousehold.primaryInvestmentGoal === "retirementDrawdown" && clientOrHousehold.estateGoal && (
              <div className="py-2">
                <h4>Legacy goal</h4>
                <p className="font-bold">{formatCurrencyLong(clientOrHousehold.estateGoal)}</p>
              </div>
            )}
            {clientOrHousehold.primaryInvestmentGoal === "retirementIncome" && (
              <div className="pt-2">
                <h4>Retirement age</h4>
                {client && (
                  <div className="flex">
                    <p className="font-bold pr-1">{clientOrHousehold.retirementAge} yrs</p>
                    <p>(retire in {clientOrHousehold.retirementAge! - (new Date().getFullYear() - new Date(client.dob).getFullYear())} yrs)</p>
                  </div>
                )}
                {household && (
                  <>
                    {household.members.map((member) => (
                      <div key={member.client?._id ?? member.id} className="flex">
                        <p className="font-bold pr-1">
                          {member.client.firstName}, {member.client.retirementAge} yrs
                        </p>
                        <p>(retire in {member.client.retirementAge! - (new Date().getFullYear() - new Date(member.client.dob).getFullYear())} yrs)</p>
                      </div>
                    ))}
                  </>
                )}
                <div className="pb-2">
                  <h4 className="pt-2">Planning horizon</h4>
                  <p className="font-bold">{clientOrHousehold?.planningHorizon || 100} yrs</p>
                </div>
              </div>
            )}
            <div>
              <h4>Selected investment</h4>
              <p className="font-bold">{currentPortfolio?.name}</p>
            </div>
          </div>
        </div>
        <div className="mt-18">
          <p className="text-sec text-main-500 pb-2">
            Our {clientOrHousehold.primaryInvestmentGoal === "retirementIncome"
              ? "Retirement Income"
              : clientOrHousehold.primaryInvestmentGoal === "retirementDrawdown"
              ? "Retirement Income and Legacy"
              : "Wealth Accumulation"} Projector illustrates how you might achieve your financial goal and stay comfortable with the level of investment risk.
          </p>
        </div>
        <div className="relative w-full h-full flex flex-col pt-5">
          {clientOrHousehold.primaryInvestmentGoal === "retirementDrawdown" ? (
            <div className="h-[500px] w-full flex flex-col">
              <div className="relative h-full w-full">
                <RetirementDrawdownChart client={client!} household={household!} options={options} forReport={true} />
              </div>
              <div className="mb-5">
                <RetirementDrawdownLegends forReport={true} clientOrHousehold={clientOrHousehold!} comfortMatch={options.comfortMatch!} />
              </div>
            </div>
          ) : (
            <div className="flex flex-col mt-4 grow mb-6 max-h-[450px]">
              {options && (
                <div className="mb-4 grow">
                  <PortfolioChart options={options.chartOptions} isRiskComfort={true} isRange={true} forReport={true} />
                </div>
              )}
              <div className="flex flex-row gap-x-2 justify-center items-center text-sm">
                <div className="border-x w-2.5 h-2 border-highlight-500">
                  <hr className="mx-auto rotate-90 border-l border-dashed h-full w-max border-highlight-500" />
                </div>
                <p>Wealth range</p>
                <div className="bg-interactive-200 rounded-full w-2 h-2" />
                <p>{clientOrHousehold.primaryInvestmentGoal === "wealthAccumulation" ? "Total projected investment balance" : "Total projected income"}</p>
                {clientOrHousehold.primaryInvestmentGoal === "retirementIncome" && (
                  <>
                    <div className="bg-interactive-300 rounded-full w-2 h-2"></div>
                    <p>Retirement income from other sources</p>
                  </>
                )}
              </div>
            </div>
          )}

          <p className="text-xxs text-main-500 pb-10 v-sm:pb-0">
            The {clientOrHousehold.primaryInvestmentGoal === "retirementIncome"
              ? "Retirement Income"
              : clientOrHousehold.primaryInvestmentGoal === "wealthAccumulation"
              ? "Wealth Accumulation"
              : "Retirement Income and Legacy"} Projector is designed for illustrative purposes only, and does not represent a full financial plan.
          </p>
        </div>
      </div>
      <div className="relative mx-10">
        <Footer pageNum={page} client={client} household={household} firm={firm} />
      </div>
    </Container>
  )
}

export default ProjectorChartPage
