import DatePicker from "../DatePicker/DatePicker"
import TextInput from "../TextInput/TextInput"
import { subYears } from "date-fns"
import { ClientErrors, NewClient } from "../../pages/advisor/CreateIndividualClientPage"
import { useTheme } from "../../contexts/ThemeContext"

const CreateClientForm = ({
  client,
  errors,
  onFirstNameChange,
  onLastNameChange,
  onEmailChange,
  onDobChange,
  onDobRawChange,
  children
}: {
  client?: NewClient
  errors?: ClientErrors
  onFirstNameChange: (v: string) => void
  onLastNameChange: (v: string) => void
  onEmailChange: (v: string) => void
  onDobChange: (v: Date | null) => void
  onDobRawChange: (v: string) => void
  children?: React.ReactNode
}) => {
  const theme = useTheme()
  return (
    <>
      <div className="col-start-4 col-span-3">
        <TextInput
          label="First name"
          mandatory={true}
          disableAutoComplete
          inputMode="text"
          name="firstName"
          value={client?.firstName}
          onChange={(value) => {
            onFirstNameChange(value)
          }}
          maxLength={64}
          error={errors?.firstName}
        />
      </div>
      <div className="col-start-7 col-span-3">
        <TextInput
          label="Last name"
          mandatory={true}
          disableAutoComplete
          name="lastName"
          value={client?.lastName}
          onChange={(value) => {
            onLastNameChange(value)
          }}
          maxLength={64}
          error={errors?.lastName}
        />
      </div>
      <div className="col-start-4 col-span-6">
        <TextInput
          label="Email address"
          type="text"
          name="email"
          value={client?.email}
          onChange={(value) => {
            onEmailChange(value)
          }}
          disableAutoComplete
          error={errors?.email}
        />
      </div>
      {theme.pages.createClient.individual?.formFields.showDOB && 
        <div className="col-start-4 col-span-6">
          <DatePicker
            id="dob"
            label="Date of birth"
            value={client?.dob || null}
            onChange={(value: Date | null) => {
              onDobChange(value)
            }}
            onChangeRaw={(value: string) => {
              onDobRawChange(value)
            }}
            yearsInAdvance={0}
            yearsInPast={125}
            defaultSelectedDate={subYears(new Date(), 40)}
            error={errors?.dob}
          />
        </div>
      }
      {children}
    </>
  )
}

export default CreateClientForm
