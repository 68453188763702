import { i18n } from "@lingui/core"
import { useContext, useMemo } from "react"
import { FirmContext } from "../../../../contexts/FirmContext"
import { useTheme } from "../../../../contexts/ThemeContext"
import { tt } from "../../../../lib/translations"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import { clientTopCause } from "../../components/Profile/causeScores"
import Container from "../components/Container"
import Footer from "../components/Footer"
import SustainabilityValues from "../components/SustainabilityValues/SustainabilityValues"
import ValuesMindset from "../components/ValuesMindset/ValuesMindset"
import leaves from "./assets/leaves.svg"
import { latest } from "../../../../lib/clients"

const SustainabilityFocusPage = ({ page, client, household }: { page: number; client: Client; household: Household }) => {
  const { firm } = useContext(FirmContext)
  const theme = useTheme()
  const topCause = clientTopCause(client, theme)
  const game = latest(client, "esg")
  const altruismScore = Object.values(game?.esg?.results?.S2 ?? {})?.[1]?.score
  const altruismLevel = altruismScore < 33 ? "low" : altruismScore < 67 ? "medium" : "high"
  const cause = useMemo(() => (altruismLevel === "low" ? theme.causes?.S2?.cause0 : topCause), [altruismLevel, theme.causes?.S2?.cause0, topCause])
  const subOptions = topCause && client.causes?.S1 && client.causes.S1[topCause!.id!]
  const topCauseSubOptionValue = tt({ id: `causes-${topCause?.id}-${subOptions?.[0]}-label` })
  return (
    <Container id="sustainability-focus-page" className="flex flex-col relative">
      <div className="relative flex items-start">
        <div className="sustainability-focus-top-cause w-full bg-neutral-50">
          <div className="flex gap-x-10 justify-between">
            <div className="flex flex-col justify-center self-start py-11 px-10">
              <h2 className="mb-7 text-main-600 text-sec tracking-wide uppercase">{altruismLevel === "low" ? "Your Focus" : "Your Interest"}</h2>
              <h3 className="mb-4 text-main-600 text-h2">{cause?.title}</h3>
              <p className="font-normal text-sec leading-5">
                {
                  /* lingui-extract-ignore */
                  i18n._({ id: `report-sustainability-focus-page-${topCause?.id}-${altruismLevel}-description`, values: { subOption: topCauseSubOptionValue } })
                }
              </p>
            </div>
            <div className="flex w-64 items-center justify-center" style={{ backgroundColor: topCause?.barColor }}>
              <img className="w-30 h-30" src={altruismLevel === "low" ? topCause?.returnsIcon : cause?.icon} alt="cause icon" aria-hidden />
            </div>
          </div>
        </div>
      </div>
      {Object.values(theme.causes?.S1 ?? {}).some((c) => c.id === cause?.id) ? (
        <SustainabilityValues client={client} className="p-10" altruismLevel={altruismLevel} />
      ) : (
        <ValuesMindset game={game!} altruismLevel={altruismLevel} className="mx-10 mt-10 max-w-xl" />
      )}

      <div className="relative h-full flex items-end">
        <div className="w-full h-60 bg-neutral-50">
          <div className="mx-9 h-full">
            <div className="flex mx-10 mt-8 mb-12">
              <img className="w-20 h-20 self-start" src={leaves} alt="science icon" aria-hidden />
              <div className="flex flex-col ml-6 justify-center self-start text-main-500">
                <p className="font-semibold text-sec leading-5">The science behind your results</p>
                <p className="font-normal text-sec leading-5">
                  This activity was developed by a decision science, technology and research team of leading economists. It is underpinned by internationally
                  validated decision science and behavioral economics research.
                </p>
              </div>
            </div>
            <Footer pageNum={page} client={client} household={household} firm={firm} className="relative" />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default SustainabilityFocusPage
