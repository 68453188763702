import { useRef, useState } from "react"
import Dropdown from "../../../components/Dropdown/Dropdown"
import moreIcon from "../../../assets/images/icons/dots.svg"
import unArchiveIcon from "../assets/images/un-archive.svg"

interface Props {
  alignVertical?: "bottom" | "top"
  isHousehold: boolean
  isExternal?: boolean
  onUnArchiveClick: () => void
  onViewProfileClick: () => void
}

const ArchivedItemMenu = ({ alignVertical, isHousehold, isExternal, onUnArchiveClick, onViewProfileClick }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const menuBtn = useRef<HTMLButtonElement>(null)

  return (
    <div className="relative flex items-center">
      <button className="p-1 border-2 border-transparent hover:border-interactive-200" onClick={() => setIsMenuOpen(true)} ref={menuBtn}>
        <img alt="Actions" src={moreIcon} />
      </button>
      {isMenuOpen && (
        <Dropdown overlayClassName="w-[230px]" align="left" alignVertical={alignVertical} handleClose={() => setIsMenuOpen(false)} trigger={menuBtn}>
          <ul className="list-none p-0 text-main-500">
            <li>
              <button
                className="btn text-p p-4 hover:bg-interactive-100 cursor-pointer border-0 block w-full text-left font-normal"
                onClick={() => {
                  setIsMenuOpen(false)
                  onViewProfileClick()
                }}
              >
                View profile
              </button>
            </li>
            {!isExternal && <li>
              <button
                className="btn text-p p-4 hover:bg-interactive-100 cursor-pointer border-0 flex justify-between items-center w-full text-left font-normal"
                onClick={() => {
                  setIsMenuOpen(false)
                  onUnArchiveClick()
                }}
              >
                {isHousehold ? "Un-archive household" : "Un-archive client"}
                <img alt="" src={unArchiveIcon} />
              </button>
            </li>}
          </ul>
        </Dropdown>
      )}
    </div>
  )
}

export default ArchivedItemMenu
