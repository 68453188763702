import clsx from "clsx"
import { useContext, useMemo } from "react"
import { useQueries } from "react-query"
import { useLocation, useNavigate } from "react-router-dom"
import { get as getFirm } from "../../api/firms"
import { AppContext } from "../../contexts/AppContext"
import { Firm } from "../../models/Firm"
import { Permission } from "../../models/Permission"
import { AuthContext, AuthStatus } from "../../views/auth/AuthContext"

const SelectPage = ({ permissions }: { permissions: Permission[] }) => {
  const { authStatus, sessionInfo } = useContext(AuthContext)
  const { firmId, setFirmId, updateUserProfile } = useContext(AppContext)
  const location = useLocation()
  const navigate = useNavigate()

  const firmsPermissions = useMemo(() => [...permissions.filter((perm) => perm.firmId)].sort(), [permissions])

  
  const firmsResults = useQueries(firmsPermissions.map((ap) => ({
    queryKey: ["firm", ap.firmId],
    queryFn: () => getFirm(ap.firmId!),
    enabled: authStatus === AuthStatus.SignedIn && !!sessionInfo && firmsPermissions.length > 0,
    staleTime: Infinity,
  })))
  
  const isLoading = firmsResults.some(result => result.isLoading)
  const error = firmsResults.find(result => result.error)?.error
  const loadingFirms = useMemo(() => firmsPermissions.filter((_, index) => firmsResults[index].isLoading), [firmsPermissions, firmsResults])
  

  const firms = firmsResults.reduce((acc, { data: firm }) => (firm ? { ...acc, [firm!._id as string]: firm! } : acc), {} as { [key: string]: Firm })

  const licensees = useMemo(() => {
    const uniqueLicensees = new Set(firmsPermissions.map(fp => firms[fp.firmId!]?.licensee).filter(Boolean))
    return Array.from(uniqueLicensees).sort()
  }, [firmsPermissions, firms])

  if (!permissions) return <></>

  return (
    <div className="p-10 full-flex-content-center overflow-hidden">
      <div className="w-full h-full overflow-hidden flex flex-col items-start justify-stretch">
        <div>
          <h1 className="text-h1">Select a firm</h1>
          <p>You are authorised for more than one firm. Please select one from the list below to set the context for this session.</p>
          {!!error && <div className="text-error">{String(error)}</div>}
        </div>
        <ul className="w-full space-y-5 pr-10 mt-5 pb-10 flex flex-col items-stretch overflow-y-auto">
          {licensees.map((licensee) => (
            <li key={String(licensee)} className="flex flex-col gap-y-4 px-8 py-3 bg-white">
              <h2 className="text-h4 font-bold w-full">
                {licensee ?? "-"} <span className="text-xs font-normal text-main-500">(licensee)</span>
              </h2>
              <ul className="space-y-5 flex flex-col items-stretch">
                {firmsPermissions
                  .filter((fp) => firms[fp.firmId!]?.licensee === licensee && (fp.permission === "ADMIN" || fp.permission.includes("ADMIN")))
                  .map((perm, i) => (
                    <li key={perm.firmId} className={clsx("p-4 pl-6 rounded flex items-center flex-wrap justify-between gap-x-4", firmId === perm.firmId ? "bg-interactive-300" : "bg-interactive-200")}>
                      <div className="">
                        <div className={clsx("text-h3 flex-grow")}>
                          {firms[perm.firmId!]?.name}
                        </div>
                        <div className="text-xs text-main-400">{perm.firmId}</div>
                      </div>
                      {firmId === perm.firmId
                      ? <button
                          className="btn btn-medium btn-primary uppercase"
                          onClick={() => {
                            navigate("/") // force leaving this page in case it's current firm
                          }}
                        >
                          Stay in this firm
                        </button>
                      :
                      <button
                        className="btn btn-medium btn-primary uppercase"
                        onClick={() => {
                          setFirmId(perm.firmId)
                          updateUserProfile({ selectedFirm: perm.firmId })
                          if (location.pathname === "/select") {
                            navigate("/") // force leaving this page in case it's current firm
                          }
                        }}
                      >
                        Select
                      </button>
                      }
                    </li>
                  ))}
                  {isLoading && <div className="w-full h-1 bg-interactive-400 animate-pulse shrink-0"></div>}

                  {loadingFirms.map((perm, i) => (
                <li key={perm.firmId} className="p-4 pl-6 rounded flex items-center flex-wrap justify-between gap-x-4 bg-interactive-200">
                  <div className="">
                    <div className="text-h3 flex-grow">Loading...</div>
                    <div className="text-xs text-main-400">{perm.firmId}</div>
                  </div>
                </li>
              ))}
              </ul>
            </li>
          ))
        }</ul> 
      </div>
    </div>
  )
}

export default SelectPage
