import { useContext, useState } from "react"
import Modal from "../../../../components/Modal/Modal"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import { Client, Game } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"
import heroImage from "./assets/questionMark.svg"
import errorIcon from "../../assets/images/error.svg"
import successIcon from "./assets/return.svg"
import { reprofile } from "../../../../api/clients"
import { AuthContext } from "../../../../views/auth/AuthContext"
import { useTheme } from "../../../../contexts/ThemeContext"

interface Props {
  client?: Client
  household?: Household
  game: Game
  onClose: () => void
  refetch?: () => void
}

const ReprofileConfirmationModal = ({ client, household, game, onClose }: Props) => {
  const { sessionInfo } = useContext(AuthContext)
  const { replace } = useContext(ClientHouseholdCacheContext)
  const [status, setStatus] = useState<"pending" | "saving" | "complete" | "reprofileError" | "refetchError">("pending")
  const theme = useTheme()

  const onConfirm = () => {
    setStatus("saving")
    if (client) {
      reprofile(sessionInfo!, client._id, game.experimentGroupId!)
        .then((updatedClient) => {
          replace(updatedClient)
          setStatus("complete")
          setTimeout(() => {
            onClose()
          }, 2000)
        })
        .catch((error) => {
          console.error("error updating client details", error)
          setStatus("reprofileError")
        })
    } else if (household) {
      Promise.all(household.members.map((member) => reprofile(sessionInfo!, member.client._id, game.experimentGroupId!)))
      .then((updatedClients) => {
        updatedClients.forEach((updatedClient) => replace(updatedClient))
        setStatus("complete")
        setTimeout(() => {
          onClose()
        }, 2000)
      })
      .catch((error) => {
        console.error("error updating household members", error)
        setStatus("reprofileError")
      })
    }
  }

  return (
    <Modal className="mx-0" contentClassName="w-modal !px-10" handleClose={onClose}>
      <div className="reprofile-modal-wrapper relative flex flex-col">
        <img className="reprofile-modal-img h-[100px] mb-6" src={theme.pages.profile.modals?.reprofile?.heroImage ??  heroImage} alt="" aria-hidden />
        {(status === "reprofileError" || status === "refetchError") && (
          <div className="border p-2 text-main-500 text-sm mb-6 flex flex-row gap-x-2 border-interactive-200 shadow items-center">
            <img src={errorIcon} alt="" aria-hidden />
            {status === "reprofileError" && "There was an error re-profiling the client. Please try again."}
            {status === "refetchError" && "There was an error loading clients. Please try again."}
          </div>
        )}
        <h2 className="text-h2 text-center font-semibold text-main-600 mb-6">Are you sure you want to re-profile?</h2>
        <p className="text-p text-center text-main-600 mb-6">
          You will need to re-invite your {`${household ? "household" : "client"}`} to complete the activity. Their current results will be recorded, but replaced with the new results for the
          provision of advice.
        </p>
        <div className="w-full flex justify-center gap-4">
          <button onClick={onClose} className="btn btn-secondary btn-medium w-44">
            Cancel
          </button>
          <button onClick={onConfirm} className="btn btn-primary btn-medium w-44">
            Confirm
          </button>
        </div>
        {(status === "saving" || status === "complete") && (
          <div className="absolute full-flex-content-center bg-white">
            {status === "saving" && <Loading />}
            {status === "complete" && (
              <>
                <img alt="" className="reprofile-modal-success-img h-24 mb-6" src={theme.pages.profile.modals?.reprofile?.successImage ?? successIcon} aria-hidden />
                <h3 className="text-h2 text-center font-semibold text-main-600 mb-6">Re-invite your {household ? "clients" : "client"} now ...</h3>
              </>
            )}
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ReprofileConfirmationModal
