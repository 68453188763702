import { useMemo } from "react"

const useDecimalNumberFormatter = (locale: string) => {
  const formatter = useMemo(() => {
    const options: Intl.NumberFormatOptions = {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    }
    return new Intl.NumberFormat(locale, options)
  }, [locale])

  return useMemo(() => {
    return {
      format: (value: number) => {
        if (String(value).endsWith(".")) {
          return String(value)
        }
        return formatter.format(value).replace("NaN", "")
      },
      parse: (value: string) => {
        if (value === "") {
          return
        }
        if (/[^0-9.]/.test(value) === false) {
          return value
        } else {
          return NaN
        }
      }
    }
  }, [formatter])
}

export default useDecimalNumberFormatter
