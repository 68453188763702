import React, { useEffect } from "react"

const AxisLabels = ({
  x,
  y,
  style,
  text,
  setTargetLineValues
}: {
  text?: string
  x?: number
  y?: number
  style?: {
    fill: string
    fontFamily: string
    fontSize: number
    fontWeight: number
    letterSpacing: string
    padding: number
    number: string
  }
  setTargetLineValues: React.Dispatch<
    React.SetStateAction<{
      x: number
      y: number
    }>
  >
}) => {
  const offsetY = 5
  const offsetX = 8

  useEffect(() => {
    setTargetLineValues({ x: x!, y: y! })
  }, [setTargetLineValues, x, y])

  return (
    <text dy={offsetY} x={x! - offsetX} y={y} style={{ ...style }}>
      {text}
    </text>
  )
}

export default AxisLabels
