import heroImage from "./assets/risk-dimensions-beige.png"

const RMJAdviceOverviewPage = () => {
  return (
    <div className="w-full h-full overflow-auto no-scrollbar">
      <div className="h-full flex flex-col">
        <h1 className="text-sec text-main-600 mb-1 uppercase">Risk dimensions</h1>
        <h2 className="text-h3 font-semibold text-main-600 mb-5">There are three dimensions of risk that will shape your financial strategy</h2>
        <p className="text-sec mb-8 max-w-2xl">Our planning process balances these three factors to come to a recommendation that offers the best path toward achieving your lifestyle goals.</p>
        <div className="flex justify-center items-start grow pb-10">
          <img alt="A triangle divided into three parts labeled Preferences, Constraints, and Goals" className="max-h-[370px]" src={heroImage} />
        </div>
      </div>
    </div>
  )
}

export default RMJAdviceOverviewPage
