export default `
## PRIVACY POLICY - AUSTRALIA

Effective May 2023

At Capital Preferences Ltd. (“**Capital Preferences**” or “**us**” or “**we**”), we respect the privacy rights of our users and recognize the importance of protecting the personal information we collect about you.
We are committed to protecting your privacy and complying with the Privacy Act 1988 (Cth) (Privacy Act) and other applicable privacy laws and regulations. Our Privacy Policy is designed to help you understand what information we collect and how we hold, use and share that information, and how we maintain the quality and security of your personal information.
This Privacy Policy applies to our websites, the Decision Games, and our other online properties, in whatever format they may be offered now or in the future (collectively, the “**Services**”).
The “**Decision Games**” means Capital Preferences’ proprietary interactive games designed to measure individuals’ estimated risk, time, ambiguity, distributional and other preferences through hypothetical tradeoff scenarios and similar exercises.

We have adopted the Australian Privacy Principles (APPs) contained in the Privacy Act. The APPs govern the way in which we collect, use, disclose, store, secure and dispose of your Personal Information.

A copy of the Australian Privacy Principles may be obtained from the website of The Office of the Australian Information Commissioner at [https://www.oaic.gov.au/](https://www.oaic.gov.au/).

By using or accessing the Services you are agreeing to this Privacy Policy and all other related policies or notices posted by us to the Services. If you use or access the Decision Games, then you also agree to our [Terms of Service](https://capitalpreferences.com.au/terms-of-service), which forms a binding contract between you and Capital Preferences.

**1. Information We Collect**

Personal information means any information or opinion, whether true or not, and whether recorded in a material form or not, about an identified individual or an individual who is reasonably identifiable. In general terms, this includes information or an opinion that personally identifies you either directly (e.g. your name) or indirectly. 

Information You Provide. We collect information about you when you (a) register for the Services and (b) contact us by email, mail, or through the Services. The types of information you provide may include your username, first and last name, email address, mailing address, phone number.

Information related to the Decision Games. You likely found us because your financial services firm would like you to play our Decision Games. We collect information about how you interact with the Decision Games, including your answers, responses, and other input submitted to the Decision Games, which may include personal information (collectively, “**Decision Games Information**”). Details on how we share Decision Games Information are included in Section 3 (Sharing Decision Games Information).

Technical, Usage and Location Information. We automatically collect information on how you interact with the Services, such as the IP address from which you access the Services, date and time, information about your browser, operating system and computer or device, pages viewed and items clicked. We may also collect location information, including location information automatically provided by your computer or device. We use tracking technologies as described below in Section 6 (Use of Cookies and other Tracking Technologies) to collect some of this information.

Other Information. We may collect other information from you that is not specifically listed here. We may use any such information in accordance with this Privacy Policy or as otherwise permitted by you.

Where reasonable and practicable to do so, we will collect your personal information only from you. However, in some circumstances we may be provided with information by third parties. In such a case we will take reasonable steps to ensure that you are made aware of the information provided to us by the third party.

Sensitive information is defined in the Privacy Act to include information or opinion about such things as an individual’s racial or ethnic origin, political opinions, membership of a political association, religious or philosophical beliefs, membership of a trade union or other professional body, criminal record or health information.

Sensitive information will be used by us only:

1. For the primary purpose for which it was obtained

2. For a secondary purpose that is directly related to the primary purpose

3. With your consent: or where required or authorised by law.

You do not have to provide us with your personal information. Where possible, we will give you the option to interact with us anonymously or by using a pseudonym. However, if you choose to deal with us in this way or choose not to provide us with your personal information, we may not be able to provide you with our services or otherwise interact with you.

**2 . How We Use the Information We Collect**

We use your information (including without limitation Decision Games Information) in the following ways:

- To provide, maintain and improve the Services and our other products and services, including to operate certain features and functionality of the Services (for example, by remembering your information so that you will not have to re-enter it during this or subsequent visits);

- To process your inquiries and otherwise deliver customer service

- To control unauthorized use or abuse of the Services and our products and services, or otherwise detect, investigate or prevent activities that may violate our policies or be illegal;

- To analyze and report on trends, administer or optimize the Services, monitor usage or traffic patterns (including to track users’ movements around the Services) and gather demographic and other information about our user base as a whole;

- To communicate directly with you, including by sending you newsletters, reminders and information about new products and services. Your opt-out options for promotional communications are described in Section 7 below (Your Controls and Choices);

- In the manner described to you at the time of collection or as otherwise described in this Privacy Policy.

**3. Sharing Decision Games Information**

If you access the Decision Games through or at the request of a financial services firm, media company or other third party (“**Partner**”), we may share your Decision Games Information with such Partner.

**4. Sharing Your Information with Third Parties**

We do not sell, trade, share or transfer your personal information or Decision Games Information to third parties except as set forth in Section 3 (Decision Games Information) and in the following other limited circumstances:

- We may share your personal information with our parent companies, subsidiaries and affiliates;

- We may share your personal information with third-party service providers to permit such parties to provide services that help us with our business activities, which may include assisting us with marketing, advertising our product/service offerings, or providing, maintaining and improving the features and functionality of the Services, among other things.

- We may share your personal information when we have a good faith belief that access, use, preservation or disclosure of such information is reasonably necessary to (a) satisfy any applicable law, regulation, legal process or enforceable governmental request, (b) enforce our [Terms of Service](https://capitalpreferences.com.au/terms-of-service), including investigation of potential violations thereof, or (c) protect against imminent harm to our rights, property or safety, or that of our users or the public as required or permitted by law;

- We may share your personal information with third parties (including our service providers and government entities) to detect, prevent, or otherwise address fraud or security or technical issues;

- We may share your personal information with our business partners who offer a service to you jointly with us, for example when running a cross-promotion;

- We may share and/or transfer your personally identifiable information if we become involved in a merger, acquisition, bankruptcy, or any form of sale of some or all of our assets; and

- We may share your personal information with a third party if we have your consent to do so.

We may also share aggregated or non-personally identifiable information with third parties for other purposes. Such information does not identify you individually, but may include usage, viewing and technical information we collected through our technology. If we are required under applicable law to treat such information as personally identifiable information, then we will only disclose it as described above. Otherwise we may disclose such information for any reason.

**5. Other Access to or Disclosure of Your Information**

The Services may also contain links to third party websites. This Privacy Policy applies solely to information collected by us. Even if the third party is affiliated with us through a business partnership or otherwise, we are not responsible for the privacy practices of such third party.

We encourage you to familiarize yourself with the privacy policies of such third parties to determine how they handle any information they separately collect from you. Please be aware that we do not warn you when you choose to click through to another website when using the Services.

**6. Use of Cookies and Other Tracking Technologies**

By using the Services or interacting with our advertisements or content on third-party sites, you agree that we and our advertisers, publishers and service providers may use cookies, web beacons and other tracking technologies on your computer or device.

Our Cookies. We use cookies to help make our site work, to provide the Services to you, and to improve the Services. For example, we use cookies to store your preferences, improve search results, to track and analyze user trends.

**7. Your Controls and Choices**

Opt-Outs. We may provide you with the opportunity to “opt-out” of having your personally identifiable information used for certain purposes when we ask for this information. If you decide to opt-out, we may not be able to provide all or certain features of the Services to you.

Communication Preferences. If you no longer wish to receive our newsletter and promotional communications, you may opt-out of receiving them by following the instructions included on such communications or on the Services. Please note, however, that you may be unable to opt-out of certain service-related communications.

Blocking Cookies. You can remove or block certain cookies using the settings in your browser but the Services may cease to function properly if you do so.

How We Respond to Do Not Track Signals. Your Web browser may have a “do not track” setting which, when enabled, causes your browser to send a do not track HTTP header file or “signal” to each site you visit. At present, the Services do not respond to this type of signal.

**8. Accessing and Updating Your Personal Information**

When you use the Services, we make good faith efforts to provide you with access to your personal information upon your request and either provide you the means to correct this information if it is inaccurate or to delete such information at your request if it is not otherwise required to be retained by law or for legitimate business purposes. You may access, review, correct, update, change or delete your information at any time. To do so, please contact us at [legal@capitalpreferences.com](mailto:legal@capitalpreferencs.com) with your name and the information requested to be accessed, corrected or removed. We may decline to process requests that are unreasonably repetitive or systematic, require disproportionate technical effort (for instance, requests concerning information residing on backup tapes), jeopardize the privacy of others, would be extremely impractical, or for which access is not otherwise required. In any case where we provide information access and correction, we perform this service free of charge, except if doing so would require a disproportionate effort.

Please note that if you cease using the Services or we terminate your access to the Services in accordance with our [Terms of Service](https://capitalpreferences.com.au/terms-of-service), you may no longer have the ability to access or update your information.

We may retain your information as necessary to support the Services, comply with our legal obligations or resolve disputes. Note that content you post may remain on the Services even if you cease using the Services, or we terminate your access to the Services.

We will not keep your personal information for longer than we need to. In most cases, this means that we will only retain your personal information for the duration of your relationship with us unless we are required to retain your personal information to comply with applicable laws, for example record-keeping obligations.

It is important to us that your personal information is up to date. We will take reasonable steps to make sure that your personal information is accurate, complete and up to date. If you find that the information we have is not up to date or is inaccurate, please advise us as soon as practicable so we can update our records and ensure we can continue to provide quality services to you.

**9. Privacy Policy**

We reserve the right to change our Privacy Policy at any time. If we make changes, we will post them and will indicate on this page the policy’s new effective date. If we make material changes to this policy, we will notify you by email or through notice on the Services.

**10. No Children Under Age 13**

The Services are not intended for use by anyone under the age of 13 nor does Capital Preferences knowingly collect or solicit personal information from anyone under the age of 13. If you are under 13, you may not attempt to register for the Services or send any information about yourself to us, including your name, address, telephone number, or email address. In the event that we confirm that we have collected personally identifiable information from someone under the age of 13 without verification of parental consent, we will delete that information promptly. If you are a parent or legal guardian of a child under 13 and believe that we might have any information from or about such child, please contact us at the email or mailing address provided at the end of this Privacy Policy.

**11. Your California Privacy Rights and Disclosures**

If you are a California resident and we disclose your personally identifiable information to third parties for such third parties’ direct marketing purposes, California’s Shine the Light Law (CA Civil Code Section 1798.83) allows you to request certain information from us about such disclosures. To make a request under the Shine the Light Law, please contact us at [privacy@capitalpreferences.com](mailto:privacy@capitalpreferences.com) Please note that under California law, businesses are not required to respond to such requests more than once during any calendar year.

**12. Security**

Your personal information is stored in a manner that reasonably protects it from misuse and loss and from unauthorized access, modification or disclosure.

When your personal information is no longer needed for the purpose for which it was obtained, we will take reasonable steps to destroy or permanently de-identify your personal information. However, most of the personal information is or will be stored in client files which will be kept by us for a minimum period of 7 years.This is to enable your financial services firm to comply with the document retention requirements for firms that give financial advice.

The security of your personal information is important to us. We have implemented measures designed to protect your personal information. However, no method of transmission over the Internet, method of electronic storage or other security methods are one hundred percent secure. Therefore, while we strive to use reasonable efforts to protect your personal information, we cannot guarantee its absolute security.

**13. International Transfer of Data**

We may from time to time transfer your personal information to other countries outside Australia, New Zealand and the United States and make it accessible to our parents, subsidiaries, affiliates and third party service providers internationally. By using the Services or otherwise providing personal information to us, you are consenting to have your personal information transferred to and processed in Australia, New Zealand and the United States or any other country in which we or our parents, subsidiaries, affiliates and service providers maintain facilities.

Some of the third-party service providers we disclose personal information to may be based in or have servers located outside of Australia, including in New Zealand and the United States.

Where we disclose your personal information to third parties overseas, we will take reasonable steps to ensure that data security and appropriate privacy practices are maintained. We will only disclose to overseas third parties if:

- We reasonably believe that:

    - the overseas recipient is subject to a law or binding scheme that is, overall, substantially similar to the Australian Privacy Principles in the Privacy Act; and

    - the law or binding scheme can be enforced; or

- the disclosure is required or authorised by an Australian law or court/ tribunal order.

**Contact Us**

If you have questions or need to contact us about this Privacy Policy, please email us at [privacy@capitalpreferences.com](mailto:privacy@capitalpreferences.com).
`
