const CauseBar = ({ title, score, color }: {title: string, score: number, color: string}) => (
  <div className="cause-bar grow">
    <h3 className="cause-bar-title text-sec mb-0.5">{title}</h3>
    <div className="cause-bar-container relative w-full h-1.5 flex">
      <div className="cause-bar-bg absolute left-0 top-0 bg-surface-400 h-full w-full" />
      <div className="cause-bar-fill absolute left-0 top-0 h-full" style={{ width: `${score}%`, backgroundColor: color }} />
    </div>
  </div>
)

export default CauseBar