import clsx from "clsx"
import { useTheme } from "../../../../../contexts/ThemeContext"
import { assetTypeWeights } from "../../../../clients/reports/ComfortMatchPage"
import { DoughnutChart } from "../../../components/DoughnutChart/DoughnutChart"
import { Datum } from "../RMJPortfolioComfortChart"

interface Props {
  datum?: Datum
  x?: number
  y?: number
}

const height = 170
const offsetY = 24
const width = 160

const ChartTooltip = ({ x, y, datum }: Props) => {
  const theme = useTheme()
  const assetWeights = datum ? assetTypeWeights(datum!.portfolioMapping.portfolio.components, datum!.allAssetClasses) : undefined
  // adjust positions if the tootip is off the bottom of the chart
  const alignY = y && (y + (height + offsetY) > (datum?.chartSize?.height ?? 0) ? "top" : "bottom")
  const nextX = x ? x - width / 2 : undefined
  const nextY = y && (alignY === "top" ? y - height : y + offsetY)

  return (
    <g style={{ pointerEvents: "none" }}>
      <foreignObject height={height} x={nextX} y={nextY} width={width}>
        <div
          className={clsx("h-full flex flex-col", {
            "justify-start": alignY === "bottom",
            "justify-end": alignY === "top"
          })}
        >
          <div className="flex flex-col items-center bg-white border border-interactive-200 shadow-sm m-3 p-3">
            <tspan x={x ? x : undefined} y={y! + 24 + datum!.label.split(/\s+/).length * 16} className="text-sm m-2">
              {datum?.portfolioMapping.riskComfort}% Comfort
            </tspan>
            {assetWeights && (
              <>
                <div className="w-10 h-10 mb-2">
                  <DoughnutChart
                    data={assetWeights.map(({ total }) => total)}
                    colors={assetWeights.map(({ assetClass }) => theme.colors.assetClassConfigurations[assetClass].color)}
                  />
                </div>
                {assetWeights.map(({ assetClass, total }, i) => {
                  return (
                    <div className="w-full flex flex-row justify-start mb-1" key={i}>
                      <div
                        className="rounded-full shrink-0 h-1.5 w-1.5 my-auto mr-1"
                        style={{ backgroundColor: theme.colors.assetClassConfigurations[assetClass].color }}
                      ></div>
                      <p className="w-full flex justify-between text-sm text-main-500">
                        <span>{theme.colors.assetClassConfigurations[assetClass].label}</span>
                        <span className="font-semibold">{total}%</span>
                      </p>
                    </div>
                  )
                })}
              </>
            )}
          </div>
        </div>
      </foreignObject>
    </g>
  )
}

export default ChartTooltip
