import React, { PropsWithChildren, useContext } from 'react'
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router"
import { Link } from "react-router-dom"
import axiosInstance from "../../../../api/axiosInstance"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import { AppContext } from "../../../../contexts/AppContext"
import { Firm } from "../../../../models/Firm"

type Stats = {
  stats: {
    advisors: {
      active: number
      archived: number
    }
    clients: {
      active: number
      archived: number
    }
  }
}

const ViewLicensee: React.FC<PropsWithChildren<{ licensee: {_id: string, name: string } }>> = () => {
  const { licenseeId } = useParams()

  const view = useQuery(["licensee", "reports", "default", licenseeId], () => axiosInstance.get<{ firms: (Firm & Stats)[] }>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/licensees/${licenseeId}`)
    .then(res => res.data), { staleTime: 1000 })

  const navigate = useNavigate()
  const { setFirmId } = useContext(AppContext)

  return (
    <div className="px-10 w-full h-full flex flex-col gap-y-4 overflow-y-hidden">
      
      {view.isLoading && <Loading />}
      {view.isError && <div className="text-error">{String(view.error)}</div>}
      {view.isSuccess && <>
        <div className="grid grid-cols-[4fr_1fr_2fr_2fr_2fr_6rem]">
          <div className="text-h5 font-semibold border-b border-interactive-300">Firm Name</div>
          <div className="text-h5 font-semibold border-b border-interactive-300 text-right">Advisors</div>
          <div className="text-h5 font-semibold border-b border-interactive-300 text-right">Active Clients</div>
          <div className="text-h5 font-semibold border-b border-interactive-300 text-right">Archived Clients</div>
          <div className="text-h5 font-semibold border-b border-interactive-300 pl-2">Billing Model</div>
          <div className="text-h5 font-semibold border-b border-interactive-300 text-right">Actions</div>
        </div>
        <div className="grid grid-cols-[4fr_1fr_2fr_2fr_2fr_6rem] overflow-y-auto">
          {view.data.firms?.map(firm => (
            <React.Fragment key={firm._id}>
              <div className="border-b border-neutral-200 py-1"><Link className="underline text-interactive-500" to={`../firm/${firm._id}`}>{firm.name}</Link></div>
              <div className="border-b border-neutral-200 py-1 text-right">{firm.stats.advisors.active || 0}</div>
              <div className="border-b border-neutral-200 py-1 text-right">{firm.stats.clients.active || 0}</div>
              <div className="border-b border-neutral-200 py-1 text-right">{firm.stats.clients.archived || 0}</div>
              <div className="border-b border-neutral-200 py-1 pl-6">
                {firm.billingCode && <span className="rounded-2 bg-surface-300 px-3 py-1 text-sec">{firm.billingCode}</span>}
              </div>
              <div className="border-b border-neutral-200 py-1 text-right">
                <button
                  className="btn btn-xs btn-secondary"
                  onClick={() => {
                    setFirmId(firm._id)
                    navigate("/firm/model-portfolios")
                  }}>
                  Admin
                </button>
              </div>
          </React.Fragment>
          ))}
        </div>
      </>}
    </div>
  )
}

export default ViewLicensee