import { Trans } from "@lingui/macro"
import Page from "./Page"

const SustainableInvestingDisclaimerPage2 = ({ page }: { page: number }) => {
  return (
    <Page page={page} title="Appendix">
      <div className="max-w-md mx-9">
        <div className="mt-8 flex h-full text-xs leading-2 gap-5">
          <div className="flex flex-1 gap-5 flex-col">
            <h2 className="font-medium text-sec leading-1">
              <Trans id="print-sustainable-investing-disclaimers-2-client-section-heading">Capital Preferences</Trans>
            </h2>

            <div className="flex flex-col w-full text-left gap-5 font-normal">
              <p>
                <Trans id="print-sustainable-investing-disclaimers-2-text-1"><span className="font-semibold">Lorem</span> ipsum</Trans>
              </p>
              <p>
                <Trans id="print-sustainable-investing-disclaimers-2-text-2"><span className="font-semibold">Lorem</span> ipsum</Trans>
              </p>
              <p>
                <Trans id="print-sustainable-investing-disclaimers-2-text-3"><span className="font-semibold">Lorem</span> ipsum</Trans>
              </p>
            </div>
            <div className="flex flex-col w-full text-left gap-5 font-normal">
              <p>
                <Trans id="print-sustainable-investing-disclaimers-2-text-4"><span className="font-semibold">Lorem</span> ipsum</Trans>
              </p>
              <p>
                <Trans id="print-sustainable-investing-disclaimers-2-text-5"><span className="font-semibold">Lorem</span> ipsum</Trans>
              </p>
              <p>
                <Trans id="print-sustainable-investing-disclaimers-2-text-6"><span className="font-semibold">Lorem</span> ipsum</Trans>
              </p>
              <p>
                <Trans id="print-sustainable-investing-disclaimers-2-text-7"><span className="font-semibold">Lorem</span> ipsum</Trans>
              </p>
              <p>
                <Trans id="print-sustainable-investing-disclaimers-2-text-8"><span className="font-semibold">Lorem</span> ipsum</Trans>
              </p>
              <p>
                <Trans id="print-sustainable-investing-disclaimers-2-text-9"><span className="font-semibold">Lorem</span> ipsum</Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default SustainableInvestingDisclaimerPage2
