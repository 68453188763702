import { Trans, t } from "@lingui/macro"
import { useMemo } from "react"
import { useTheme } from "../../../contexts/ThemeContext"
import { Client } from "../../../models/Client"
import { causeScoresForProfile, clientTopCause } from "../components/Profile/causeScores"
import CauseScores, { BarScore } from "../components/Profile/components/PersonaSummary/CauseScores"
import Page from "./Page"

const TopThemes = ({ page, client }: { page: number; client: Client }) => {
  const theme = useTheme()
  const cause = useMemo(() => clientTopCause(client, theme), [client, theme])
  const persona = cause?.persona
  const scores: BarScore[] = useMemo(() => causeScoresForProfile(client, theme), [client, theme])

  return (
    <Page
      page={page}
      title={t({ id: "print-report-page-header-title" })}
      footer={
        <div className="w-full" style={{ backgroundColor: "#F5F7F8", height: "216px" }}>
          <div className="mx-9 h-full">
            <div className="flex mx-10 mt-8 mb-12">
              <img className="w-20 h-20 self-start" src={theme.reports?.topThemes?.leavesIcon} alt="science icon" />
              <div className="flex flex-col ml-6 justify-center self-start">
                <p className="font-semibold text-sec leading-5">
                  <Trans id="print-topthemes-footer-title">The science behind your results</Trans>
                </p>
                <p className="font-normal text-sec leading-5">
                  <Trans id="print-topthemes-footer-text">
                    Sustainable Persona was developed by a decision science, technology and research team of leading economists. It is underpinned by
                    internationally validated decision science and behavioral economics research.
                  </Trans>
                </p>
              </div>
            </div>
            <div className="w-full border-t-2 flex items-center justify-between text-xxs">
              <p className="mt-2">
                <Trans id="print-footer-text">Capital Preferences</Trans>
              </p>
              <p className="mt-2">© {new Date().getFullYear()} Capital Preferences Ltd | For Internal Use Only. Not for Distribution | {page}</p>
            </div>
          </div>
        </div>
      }
    >
      <div id="top-themes-page" className="relative mx-9 h-full">
        <h1 className="font-normal text-[22px]">
          <Trans id="print-topthemes-title">Your sustainable investing persona</Trans>
        </h1>
        <div className={"flex h-auto p-5 mt-8 items-start"} style={{ backgroundColor: cause?.barColor }}>
          <div className="mr-4">
            {cause?.report?.icon && <img className="w-20" src={cause.report.icon} alt="cause champion icon" />}
          </div>
          <div className={`flex-1 text-sec report-page2-default-text-color`}>
            <p className="mb-1 font-semibold">{persona?.title}</p>
            <p className="font-normal leading-5">{cause?.report?.topThemeDescription}</p>
          </div>
        </div>
        <h2 className="mt-13 text-sec font-semibold">
          <Trans id="print-topthemes-chart-title">Themes that matter to you</Trans>
        </h2>
        <p className="mt-2 mb-5 text-sec font-normal">
          <Trans id="print-topthemes-chart-text">This is what's important to you when you're choosing sustainable investments:</Trans>
        </p>
        {scores && (
          <div className="max-w-[537px]">
            <CauseScores scores={scores} />
          </div>
        )}
      </div>
    </Page>
  )
}

export default TopThemes
