import React, { useContext, useEffect, useState } from "react"
import { PortfolioReturn } from "../../../../../api/rm/goals"
import { Client, PortfolioMapping } from "../../../../../models/Client"
import { Household } from "../../../../../models/Household"
import { pickPortfolioColours } from "../../../utils/portfolioColour"
import clsx from "clsx"
import { FirmContext } from "../../../../../contexts/FirmContext"
import { ModelPortfolio } from "../../../../../models/PortfolioModel"
import { latest } from "../../../../../lib/clients"

const RetirementDrawdownLegends = ({
  clientOrHousehold,
  comfortMatch,
  forReport = false
}: {
  clientOrHousehold: Client | Household
  comfortMatch: PortfolioReturn
  forReport?: boolean
}) => {
  const [portfolioColours, setPortfolioColours] = useState<string[]>([])
  const { firm } = useContext(FirmContext)
  const game = latest(clientOrHousehold, "risk")

  useEffect(() => {
    setPortfolioColours(pickPortfolioColours((game?.portfolioMappings ?? firm?.modelPortfolios)!.length))
  }, [firm?.modelPortfolios, game, game?.portfolioMappings])
  return (
    <div className={clsx("flex flex-wrap justify-center gap-x-3", forReport ? "w-3/4 m-auto" : "w-auto")}>
      {(game?.portfolioMappings ?? firm?.modelPortfolios)?.map((portfolioMapping: PortfolioMapping | ModelPortfolio, i) => {
        const portfolio = game?.portfolioMappings ? (portfolioMapping as PortfolioMapping).portfolio : portfolioMapping as ModelPortfolio
        return (
          <div className="text-sm text-main-500 flex items-center gap-x-1" key={i}>
            {comfortMatch?.id === portfolio.id ? (
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.00002 10.5C5.86702 10.5 5.74002 10.4475 5.64602 10.353L1.76252 6.463C0.744523 5.443 0.744523 3.7835 1.76252 2.7635C2.25402 2.2715 2.91052 2 3.61002 2C4.30952 2 4.96602 2.2715 5.45752 2.7635L6.00002 3.307L6.54202 2.764C7.03402 2.2715 7.69052 2 8.39002 2C9.08952 2 9.74602 2.2715 10.2375 2.7635C11.2555 3.7835 11.2555 5.443 10.238 6.463L6.35402 10.3535C6.26002 10.4475 6.13302 10.5 6.00002 10.5Z"
                  fill={portfolioColours[i]}
                />
              </svg>
            ) : (
              <div className="rounded-full w-2 h-2" style={{ backgroundColor: portfolioColours[i] }} />
            )}
            <p>{portfolio.name}</p>
          </div>
        )
      })}
    </div>
  )
}

export default RetirementDrawdownLegends
