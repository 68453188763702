import errorImage from "../../layout/images/plug-unplugged.svg"

const ErrorMessage = () => {
  return (
    <div className="w-full h-full bg-surface-100 flex flex-col items-center justify-center gap-11" role="alert">
      <img src={errorImage} alt="A plug that's been unplugged" />
      <div className="flex flex-col items-center justify-center gap-8 text-center">
        <h1 className="text-display font-semibold">Sorry, something went wrong</h1>
        <a href="mailto:customer-support@capitalpreferences.com" className="btn btn-primary btn-large">
          Contact support
        </a>
      </div>
    </div>
  )
}

export default ErrorMessage
