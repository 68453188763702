import { Advisor } from "../models/Advisor"
import { Client } from "../models/Client"
import { Household } from "../models/Household"
import { SessionInfo } from "../views/auth/AuthContext"
import axiosInstance from "./axiosInstance"

export const get = (_: SessionInfo, advisorId: string) => {
  return axiosInstance.get(`${import.meta.env.VITE_APP_API_BASE || ""}/api/advisor/${advisorId}`).then((res) => res.data as Advisor)
}

export const investmentMeetingDone = async (
  advisorId: string,
  clientType: string,
  id: string,
  params?: {
    goalExplorerCompleted?: boolean
    nextMeetingMonths?: number
    generateReport?: boolean
    markAsReviewed?: boolean
    gameId?: string
  }
): Promise<Client | Household> =>
  axiosInstance
    .post(`${import.meta.env.VITE_APP_API_BASE || ""}/api/advisor/${advisorId}/${clientType}/${id}/rmj/investment-meeting-done`, {
      ...(params?.nextMeetingMonths ? { nextMeetingMonths: params.nextMeetingMonths } : {}),
      ...(params?.goalExplorerCompleted ? { goalExplorerCompleted: params.goalExplorerCompleted } : {}),
      ...(params?.generateReport ? { generateReport: params.generateReport } : {}),
      ...(params?.markAsReviewed ? { markAsReviewed: params.markAsReviewed } : {}),
      ...(params?.gameId ? { gameId: params.gameId } : {})
    })
    .then((res) => res.data)

export const markAsReviewed = async (
  advisorId: string,
  clientType: string,
  id: string,
  params?: {
    gameId?: string
  }
): Promise<Client | Household> =>
  axiosInstance
    .post(`${import.meta.env.VITE_APP_API_BASE || ""}/api/advisor/${advisorId}/${clientType}/${id}/rmj/mark-as-reviewed`, {
      ...(params?.gameId ? { gameId: params.gameId } : {})
    })
    .then((res) => res.data)
