import { useCallback, useRef, useState } from "react"
import questionMark from "../images/Question-mark.svg"

const AdminToolTip = () => {
  const tooltipRef = useRef<HTMLButtonElement>(null)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === "Enter") {
        event.preventDefault()
        setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen)
      }
    },
    [setIsMenuOpen]
  )

  return (
    <div className="relative">
      <button
        ref={tooltipRef}
        onMouseEnter={() => setIsMenuOpen(true)}
        onMouseLeave={() => setIsMenuOpen(false)}
        onKeyDown={handleKeyPress}
        className="cursor-pointer"
        aria-expanded={isMenuOpen}
        aria-label="More info about admin rights"
      >
        <img src={questionMark} className="pl-1" alt="" aria-hidden />
      </button>
      {isMenuOpen && (
        <div className="absolute left-0 top-full p-2 text-sm bg-white border border-interactive-200 shadow-sm z-50 w-75" role="tooltip" aria-hidden={!isMenuOpen}>
          <p className="mb-2 font-semiboldbold">Admin rights give access to:</p>
          <ul className="list-disc pl-4">
            <li>Invite new team members</li>
            <li>Manage existing team members and their permissions</li>
            <li>De-activate team members</li>
          </ul>
        </div>
      )}
    </div>
  )
}

export default AdminToolTip
