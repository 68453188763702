import MainMenu from "../MainMenu"

const PortalNavBar = () => {
  return (
    <nav className="w-full px-8 bg-white font-bold text-sec flex items-center">
      <div className="ml-auto flex">
        <MainMenu />
      </div>
    </nav>
  )
}

export default PortalNavBar
