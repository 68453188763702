import { ReactNode, useState } from "react"
import ToolTip, { Props as ToolTipProps } from "./ToolTip"

export interface Label {
  id: string
  text: string
}

export interface XLabel extends Label {
  x: string
  width: string
}

export interface YLabel extends Label {
  y: string
}
export interface GridLine {
  id: string
}

export interface XGridLine extends GridLine {
  x: string
}

export interface XAxisConfig {
  minLabels: number
  labels: Label[]
  title: string
}

export interface YAxisConfig {
  labels: Label[]
  title: string
}

export interface Value {
  id: string
  code: string
  raw?: any
  x: number
  y: number
}

export interface Series {
  id: string
  color: string
  title: string
  toolTipFormatter?: (node: NodeData) => ReactNode
  values: Array<Value | null>
}

export interface NodeData {
  code: string
  color: string
  id: string
  raw?: any
  value: number
  x: string
  y: string
}
export interface SeriesData {
  nodes: Array<NodeData | null>
  toolTipFormatter?: (node: NodeData) => ReactNode
}

export interface Props {
  series: Series[]
  title: string
  xAxis: XAxisConfig
  yAxis: YAxisConfig
}

const createSeries = ({ seriesData }: { seriesData: Series; xLabels: XLabel[] }): SeriesData => {
  return {
    nodes: seriesData.values.map((node) => {
      return node
        ? {
            code: node.code,
            color: seriesData.color,
            id: node.id,
            raw: node.raw,
            value: node.y,
            x: `${node.x}%`,
            y: `${100 - node.y}%`
          }
        : null
    }),
    toolTipFormatter: seriesData.toolTipFormatter
  }
}

const LineChart = ({ series, title, xAxis, yAxis }: Props) => {
  const [toolTip, setToolTip] = useState<ToolTipProps | null>(null)

  const xLabelsData: Label[] =
    xAxis.labels.length > xAxis.minLabels
      ? xAxis.labels
      : Array(xAxis.minLabels)
          .fill("")
          .map((val, i) => {
            return xAxis.labels[i] ?? { id: `label-${i}`, text: "-" }
          }) // fill remaining labels

  const xLabels: XLabel[] = [...xLabelsData].map(({ id, text }, i) => {
    return {
      id,
      text,
      x: `${i * (100 / xLabelsData.length)}%`,
      width: `${100 / xLabelsData.length}%`
    }
  })

  const xGridLines: XGridLine[] = [...xLabelsData].map(({ id }, i) => {
    return {
      id,
      x: `${(i + 1) * (100 / xLabelsData.length)}%`
    }
  })

  const yLabels: YLabel[] = [...yAxis.labels].reverse().map(({ id, text }, i) => {
    return {
      id,
      text,
      y: `${i * (100 / (yAxis.labels.length - 1))}%`
    }
  })

  const chartSeries: SeriesData[] = series.map((seriesData) => {
    return createSeries({ seriesData, xLabels })
  })

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full h-10 flex">
        <div className="w-18"></div>
        <div className="flex flex-grow justify-center">
          <p className="text-main-500">{title}</p>
        </div>
      </div>
      <div className="w-full flex flex-grow">
        <div className="w-6 flex flex-col items-center">
          <div className="flex flex-grow items-center">
            <p className="text-main-500 block -rotate-90">{yAxis.title}</p>
          </div>
          <div className="h-10"></div>
        </div>
        <div className="flex flex-col flex-grow">
          <div className="flex flex-grow">
            {/* Y Axis labels */}
            <div className="relative w-10 flex flex-col items-end">
              {yLabels.map(({ id, text, y }) => {
                return (
                  <div className="absolute text-sm text-main-400 text-right leading-1 pr-2 -translate-y-1/2" key={id} style={{ top: y }}>
                    {text}
                  </div>
                )
              })}
            </div>
            <div className="relative flex-grow rounded-lg">
              <div className="absolute w-full h-full flex flex-col">
                {yLabels.map(({ id, y }, i) => {
                  return i < yLabels.length ? <div className="absolute w-full border-t-0.5 border-surface-400 border-dashed" key={id} style={{ top: y }} /> : null
                })}
              </div>
              <div className="absolute w-full h-full flex flex-col">
                <div className="absolute h-[105%] border-l-0.5 border-surface-400 border-dashed" style={{ left: "0%" }} />
                {xGridLines.map(({ id, x }, i) => {
                  return i < xGridLines.length ? (
                    <div className="absolute h-[105%] border-l-0.5 border-surface-400 border-dashed" key={id} style={{ left: x }} />
                  ) : null
                })}
              </div>
              <svg className="absolute w-full h-full overflow-visible">
                {chartSeries.map(({ nodes, toolTipFormatter }) => {
                  return nodes.map((node) => {
                    if (node === null) {
                      return null
                    }

                    const { color, id, x, y } = node
                    return (
                      <g key={id}>
                        <circle
                          className="cursor-pointer opacity-0 drop-shadow-md hover:opacity-50"
                          cx={x}
                          cy={y}
                          fill={color}
                          r="6"
                          onMouseOver={
                            toolTipFormatter
                              ? () =>
                                  setToolTip({
                                    formatter: toolTipFormatter,
                                    node
                                  })
                              : undefined
                          }
                          onMouseOut={toolTipFormatter ? () => setToolTip(null) : undefined}
                        />
                        <circle className="pointer-events-none" cx={x} cy={y} fill={color} r="4" />
                      </g>
                    )
                  })
                })}
                {chartSeries.map(({ nodes }) => {
                  // remove nulls so we can connect the nodes even when there are gaps
                  const filteredNodes = nodes.filter((val) => val !== null)

                  return filteredNodes.map((node, i) => {
                    const { id, x, y } = node!
                    const nextNode = filteredNodes[i + 1]

                    return nextNode ? (
                      <line key={`line-${id}`} x1={x} y1={y} x2={nextNode.x} y2={nextNode.y} stroke={nextNode.color} strokeDasharray="2" />
                    ) : null
                  })
                })}
              </svg>
              {toolTip && <ToolTip formatter={toolTip.formatter} node={toolTip.node} />}
            </div>
          </div>
          {/* X Axis labels */}
          <div className="h-10 flex px-1px mt-1">
            <div className="w-10 flex-shrink-0"></div>
            <div className="relative flex-grow flex items-center">
              {xLabels.map(({ id, text, x, width }) => {
                return (
                  <div
                    className="absolute text-main-400 text-center text-sm"
                    key={id}
                    style={{
                      left: x,
                      width
                    }}
                  >
                    {text}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="h-16 flex flex-col">
        {/* X Axis title */}
        <div className="h-10 flex justify-center">
          <div className="w-18"></div>
          <div className="flex flex-grow justify-center">
            <p className="text-main-500">{xAxis.title}</p>
          </div>
        </div>
        {/* Legend */}
        <div className="h-4 flex">
          <div className="w-18"></div>
          <div className="flex flex-grow justify-center">
            {series.map(({ color, id, title }) => {
              return (
                <div className="flex items-center mr-8 last-of-type:mr-0" key={id}>
                  <span className="w-2 h-2 rounded-full mr-2" style={{ backgroundColor: color }}></span>
                  <p className="text-sm text-main-400">{title}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LineChart
