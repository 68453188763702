import clsx from "clsx"
import "./Loading.styles.css"
import bouncyDots from "./assets/bouncy-dots.gif"

type Props = {
  className?: string
  color?: string
  type?: "spinner" | "dots" | "spinner-var"
}

const Loading = ({ className, color, type = "spinner" }: Props) => {
  return (type === "spinner-var") ? (
    <div className={clsx("loading-spinner-var", className)} />
  ) : (
    <div className={clsx("w-full h-full flex items-center justify-center text-highlight-600", className)} style={{ color }}>
      {type === "spinner" && <div className="loading-spinner" />}
      {type === "dots" && (
        <div className={clsx("w-auto flex items-center", className ?? "h-4")}>
          <img className="loading-dots w-14" alt="" src={bouncyDots} aria-hidden />
        </div>
      )}
      <span className="sr-only">Loading...</span>
    </div>
  )
}

export default Loading
