import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { AxiosError } from "axios"
import RadioButton from "../../../components/RadioButton/RadioButton"
import TextInput from "../../../components/TextInput/TextInput"
import Switch from "../../../components/Switch/Switch"
import AdminToolTip from "./AdminToolTip"
import SelectAdvisor from "../../../components/SelectAdvisor/SelectAdvisor"
import { NewUserProfile, createUserProfile } from "../../../api/userProfiles"
import { AuthContext } from "../../../views/auth/AuthContext"
import { AppContext } from "../../../contexts/AppContext"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import { validateEmail } from "../../../lib/email"
import { NewAdvisor, createAdvisor } from "../../../api/firm/createAdvisor"
import { Advisor } from "../../../models/Advisor"
import useFirmAdvisors from "../../../hooks/useFirmAdvisors"
import infoAlert from "../../../assets/icons/info-alert.svg"
import useTeamMembersSearch from "../../../hooks/useTeamMemberSearch"
import complete from "../images/Fancy-icon-confirm.svg"
import { FirmContext } from "../../../contexts/FirmContext"
import AlertIcon from "../../../assets/icons/AlertIcon"

export type Errors = {
  firstName?: string
  lastName?: string
  email?: string
  advisor?: string
  memberType?: string
  portfolioSet?: string
  creation?: string
}

export type NewTeamMember = {
  firstName?: string
  lastName?: string
  email?: string
  notifyUser?: boolean
  manageAllClients?: boolean
  advisor?: Advisor
  allAdvisors?: boolean
  isAdmin?: boolean
}

const InviteTeamMember: React.FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const [memberType, setMemberType] = useState<"adviser" | "associate">()
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [newAssociate, setNewAssociate] = useState<NewUserProfile>()
  const [newAdviser, setNewAdviser] = useState<NewAdvisor>()
  const [newTeamMember, setNewTeamMember] = useState<NewTeamMember>({
    firstName: "",
    lastName: "",
    email: "",
    notifyUser: false,
    manageAllClients: false,
    advisor: undefined,
    allAdvisors: false,
    isAdmin: false
  })
  const { sessionInfo, authStatus } = useContext(AuthContext)
  const { firmId } = useContext(AppContext)
  const { firm } = useContext(FirmContext)
  const [errors, setErrors] = useState<Errors>({})
  const { data: advisors } = useFirmAdvisors()
  const { refetch } = useTeamMembersSearch(sessionInfo, authStatus!)
  const activeAdvisors = useMemo(() => advisors?.filter((advisor) => !advisor.deletedBy), [advisors])
  const [screen, setScreen] = useState<"form" | "success">("form")
  const [createInProgress, setCreateInProgress] = useState<boolean>(false)
  const modelPortfolios = useMemo(() => firm?.modelPortfolios, [firm])

  const createTeamMember = () => {
    setShouldValidate(true)
    const errs = validateForm()
    if (Object.values(errs).every((x) => x === null || x === undefined || x === "")) {
      setCreateInProgress(true)
      ;(memberType === "adviser" ? createAdvisor(sessionInfo!, firmId!, newAdviser!) : createUserProfile(sessionInfo!, firmId!, newAssociate!))
        .then(() => setErrors((old) => ({ ...old, creation: undefined })))
        .then(() => refetch())
        .then(() => setScreen("success"))
        .catch((err: AxiosError) => {
          setCreateInProgress(false)
          setErrors((old) => ({ ...old, creation: err.response?.status === 409 ? "Email already linked to an account" : "Error creating team member, please try again" }))
        })
    }
  }

  useEffect(() => {
    setNewAdviser({
      firstName: newTeamMember.firstName,
      lastName: newTeamMember.lastName,
      email: newTeamMember.email,
      manageAllClients: newTeamMember.manageAllClients,
      isAdmin: newTeamMember.isAdmin,
      notifyUser: false
    })
  }, [newTeamMember.email, newTeamMember.firstName, newTeamMember.lastName, newTeamMember.manageAllClients, newTeamMember.isAdmin])

  useEffect(() => {
    setNewAssociate({
      firstName: newTeamMember.firstName,
      lastName: newTeamMember.lastName,
      email: newTeamMember.email,
      advisor: newTeamMember.advisor,
      allAdvisors: newTeamMember.allAdvisors ? true : false,
      isAdmin: newTeamMember.isAdmin
    })
  }, [newTeamMember.advisor, newTeamMember.allAdvisors, newTeamMember.email, newTeamMember.firstName, newTeamMember.lastName, newTeamMember.isAdmin])

  const validateForm = useCallback(() => {
    const { firstName, lastName, email, advisor, allAdvisors } = newTeamMember
    const errs: Errors = {
      firstName: !firstName?.trim() ? "First name cannot be empty" : undefined,
      lastName: !lastName?.trim() ? "Last name cannot be empty" : undefined,
      email: email ? (email?.trim() && !validateEmail(email.trim()) ? "Please enter a valid email address" : undefined) : "Please enter an email address",
      advisor: !advisor && !allAdvisors && memberType === "associate" ? "Adviser cannot be empty" : undefined,
      memberType: !memberType ? "Please select a member type" : undefined,
      portfolioSet: (modelPortfolios?.length ?? 0) < 3 ? "Please create at least 3 model portfolios before inviting team members" : undefined
    }
    return errs
  }, [newTeamMember, memberType, modelPortfolios?.length])

  useEffect(() => {
    if (shouldValidate) {
      const nextErrors = validateForm()
      setErrors(nextErrors)
    }
  }, [shouldValidate, validateForm])

  useEffect(() => {
    modelPortfolios && modelPortfolios.length >= 3
      ? setErrors((old) => ({ ...old, portfolioSet: undefined }))
      : setErrors((old) => ({ ...old, portfolioSet: "Please create at least 3 model portfolios before inviting team members" }))
  }, [modelPortfolios])

  const screens = {
    form: (
      <>
        {createInProgress ? (
          <Loading />
        ) : (
          <>
            <h1 className="modal-title text-left">Invite team member</h1>
            <div className="flex gap-x-5">
              <div className="w-1/2">
                <TextInput
                  value={newTeamMember.firstName}
                  onChange={(value) => setNewTeamMember((old) => ({ ...old, firstName: value }))}
                  error={errors.firstName}
                  label={<span>First name</span>}
                  maxLength={64}
                  name="firstName"
                  focusOnLoad
                />
              </div>
              <div className="w-1/2">
                <TextInput
                  value={newTeamMember.lastName}
                  onChange={(value) => setNewTeamMember((old) => ({ ...old, lastName: value }))}
                  error={errors.lastName}
                  disableAutoComplete
                  maxLength={64}
                  label={<span>Last name</span>}
                  name="lastName"
                />
              </div>
            </div>
            <TextInput
              value={newTeamMember.email}
              onChange={(value) => setNewTeamMember((old) => ({ ...old, email: value }))}
              error={errors.email}
              disableAutoComplete
              label={<span>Email address</span>}
              name="email"
            />
            <fieldset>
              <legend>Member type</legend>
              <RadioButton
                checked={memberType === "adviser"}
                id="adviser"
                label={<span>Adviser</span>}
                name="memberType"
                className="mt-2"
                onChange={() => setMemberType("adviser")}
                error={errors.memberType ? true : false}
              />
              <RadioButton
                checked={memberType === "associate"}
                id="associate"
                className="mt-2"
                label={<span>Associate</span>}
                name="memberType"
                onChange={() => setMemberType("associate")}
                error={errors.memberType ? true : false}
              />
              <div className="text-input-error flex items-center mt-1" role="alert">
                {errors.memberType && (
                  <>
                    <img alt="" aria-hidden className="text-input-error-icon mr-1" src={infoAlert} />
                    <p className="text-input-error-text text-sm text-error font-normal">{errors.memberType}</p>
                  </>
                )}
              </div>
            </fieldset>
            <div className="pt-3">
              {memberType === "adviser" && (
                <div className="flex pb-2 gap-x-1">
                  <Switch
                    checked={newTeamMember.manageAllClients}
                    id="can-manage-all-clients"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const value = event.target.checked
                      setNewTeamMember((old) => ({ ...old, manageAllClients: value }))
                    }}
                    label="Give this member rights to manage all clients within the firm"
                  />
                </div>
              )}
              {memberType === "associate" && (
                <div className="pb-4">
                  <SelectAdvisor
                    error={errors.advisor}
                    label={<span>Allow this associate to manage clients on behalf of ...</span>}
                    isAssociate={true}
                    onSelect={(value) =>
                      setNewTeamMember((old) => ({
                        ...old,
                        allAdvisors: value === "all",
                        advisor: value !== "all" ? value : undefined
                      }))
                    }
                    selectedAdvisor={newTeamMember.allAdvisors ? "all" : newTeamMember.advisor}
                    verticalAlign="top"
                    advisors={activeAdvisors!}
                  />
                </div>
              )}
              <div className="pb-2 flex items-center gap-x-1">
                <Switch
                  checked={newTeamMember.isAdmin}
                  id="admin-rights"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.checked
                    setNewTeamMember((old) => ({ ...old, isAdmin: value }))
                  }}
                  label="Give this member admin rights"
                />
                <AdminToolTip />
              </div>
            </div>
            <div className="flex gap-x-4 w-full max-w-[340px] m-auto">
              <button className="btn btn-secondary btn-medium text-sec flex-1" onClick={handleClose}>
                Cancel
              </button>
              <button className="btn btn-primary btn-medium text-sec flex-1 hover:bg-interactive-600 hover:cursor-pointer" onClick={createTeamMember}>
                Send invite
              </button>
            </div>
          </>
        )}
      </>
    ),
    success: (
      <>
        <img className="h-20" src={complete} alt="" aria-hidden />
        <h1 className="modal-title text-center">Invite sent</h1>
      </>
    )
  }

  return (
    <div className="modal-container gap-y-3 modal-small min-h-[400px]">
      {screens[screen]}
      <div className="text-error col-span-12 text-center" role="alert">
        {errors.portfolioSet || errors.creation ? (
          <div className="flex gap-2 items-center justify-center">
            <AlertIcon />
            <span>{errors.portfolioSet || errors.creation}</span>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default InviteTeamMember
