import vector from "../images/Vector.svg"

const DeleteAssetClassModal: React.FunctionComponent<{
  handleClose: () => void
  onDelete: () => void
}> = ({ handleClose, onDelete }) => {
  return (
    <div className="modal-container w-[600px]">
      <div className="m-auto pb-10 gap-5">
        <img src={vector} alt="warning" aria-hidden />
      </div>
      <h1 className="modal-title">Are you sure you want to delete this asset class?</h1>
      <div className="flex gap-x-4 w-full max-w-[340px] m-auto">
        <button className="btn btn-secondary btn-medium text-sec flex-1" onClick={handleClose}>
          Cancel
        </button>
        <button
          className="btn btn-primary btn-medium text-sec flex-1 hover:bg-interactive-600 hover:cursor-pointer"
          onClick={() => {
            onDelete()
            handleClose()
          }}
        >
          Delete
        </button>
      </div>
    </div>
  )
}

export default DeleteAssetClassModal
