import { Trans } from "@lingui/macro"
import { useMemo } from "react"
import { useTheme } from "../../../../contexts/ThemeContext"
import { latest } from "../../../../lib/clients"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import LineChart from "../../../rmjourney/components/LineChart/LineChart"
import { riskAttitudeAxisValues, riskAttitudeDetails } from "../../../rmjourney/riskAttitude/riskAttitudeAttributes"
import peopleIcon from "../assets/icon-people.svg"

const AttitudeToRisk = ({ client, household }: { page: number; client: Client; household: Household }) => {
  const theme = useTheme()
  const riskAttitudeMessages = riskAttitudeDetails(client, household)

  const attitudeToRisk = useMemo(() => {
    const axisValues = riskAttitudeAxisValues(theme, client, household)
    if (axisValues) {
      return {
        ...axisValues,
        highlightDataPoints: axisValues.highlightDataPoints.map((dataPoint) => {
          return {
            ...dataPoint,
            text: theme.charts?.attitudeToRisk?.showScoreAsDataPoint ? dataPoint.x : dataPoint.text,
            textColor: theme.charts?.attitudeToRisk?.showScoreAsDataPoint ? theme.colors.avatarColors[0] : dataPoint.textColor
          }
        })
      }
    }
    return axisValues
  }, [client, household, theme])

  const attitudeToRiskDescription = useMemo(() => {
    if (riskAttitudeMessages && riskAttitudeMessages.description && riskAttitudeMessages.note) {
      return theme.reports?.riskChartsPage?.attitudeToRisk?.showPersonalisedDescription && riskAttitudeMessages.description ? (
        riskAttitudeMessages.description
      ) : (
        <Trans id="reports-attitude-to-risk-description">
          Your Attitude to Risk score is an expression of your naturally occurring risk preferences, and reflects the level of risk you’re most comfortable
          taking.
        </Trans>
      )
    }
    return (
      <Trans id="reports-attitude-to-risk-description">
        Your Attitude to Risk score is an expression of your naturally occurring risk preferences, and reflects the level of risk you’re most comfortable
        taking.
      </Trans>
    )
  }, [riskAttitudeMessages, theme.reports?.riskChartsPage?.attitudeToRisk?.showPersonalisedDescription])

  const attitudeToRiskNote = useMemo(() => {
    if (riskAttitudeMessages && riskAttitudeMessages.description && riskAttitudeMessages.note) {
      return theme.reports?.riskChartsPage?.attitudeToRisk?.note?.show && riskAttitudeMessages.note ? riskAttitudeMessages.note : null
    }
    return null
  }, [riskAttitudeMessages, theme.reports?.riskChartsPage?.attitudeToRisk?.note?.show])

  return (
    <div>
      <div className="w-full flex justify-between items-center gap-x-2">
        <div className="w-full flex flex-col">
          <div className="w-full flex justify-between">
            <div>
              <h2 className="attitude-risk-header text-main-600 font-semibold text-h4 tracking-wide">
                <Trans id="attitude-risk-header">Attitude to Risk</Trans>
              </h2>
              {theme.reports?.riskChartsPage?.attitudeToRisk?.showSubtitle && riskAttitudeMessages?.title && (
                <h3 className="attitude-risk-subtitle text-main-600 text-h4 tracking-wide">{riskAttitudeMessages.title}</h3>
              )}
            </div>
            <div className="flex gap-x-8 score-label-container">
              {client ? (
                <h4 className="risk-score text-h3 text-right text-interactive-400 font-semibold flex flex-row items-center gap-x-2">
                  <span className="text-xs text-interactive-400">Risk score</span>
                  {latest(client, "risk")?.risk?.results?.R?.riskTolerance}
                </h4>
              ) : (
                household?.members.map(({ client }, i) => (
                  <h4 className="text-h3 text-right text-interactive-400 font-semibold flex flex-col items-end w-max" key={client._id}>
                    <span className="flex items-center gap-x-2">
                      {attitudeToRisk?.legend && <div className="w-2 h-2 rounded-full" style={{ backgroundColor: attitudeToRisk.legend[i].color }} />}
                      {latest(client, "risk")?.risk?.results?.R.riskTolerance}
                    </span>
                    <span className="text-xs text-interactive-400">Risk score</span>
                  </h4>
                ))
              )}
            </div>
          </div>
          {!theme.reports?.riskChartsPage?.attitudeToRisk?.showSubtitle && riskAttitudeMessages?.title && (
            <h3 className="font-bold text-main-600 text-sec">{riskAttitudeMessages.title}</h3>
          )}
        </div>
      </div>
      <p className="attitude-risk-description text-sec leading-tight pt-2 text-main-500">{attitudeToRiskDescription}</p>
      {theme.reports?.riskChartsPage?.attitudeToRisk?.note?.show && attitudeToRiskNote && (
        <div className="attitude-to-risk-note flex items-center gap-1 bg-surface-100 border border-surface-500 mt-6 text-sm p-3">
          {theme.reports?.riskChartsPage?.attitudeToRisk?.note?.icon && (
            <img src={theme.reports?.riskChartsPage?.attitudeToRisk?.note?.icon} alt="" aria-hidden />
          )}
          {attitudeToRiskNote}
        </div>
      )}
      {theme.reports?.riskChartsPage?.lineChart?.showTitle && (
        <p className="report-attitude-to-risk-chart-title text-sec font-bold text-center pt-6">
          <Trans id="report-attitude-to-risk-chart-title">Your Attitude to Risk compared to other investors</Trans>
        </p>
      )}
      <div className="w-full self-center mt-12 h-75 risk-attitude-line-chart">
        {(client || household) && attitudeToRisk && (
          <LineChart
            enableAnimations={false}
            xAxis={attitudeToRisk.xAxis}
            yAxis={attitudeToRisk.yAxis}
            gameType={theme.gameType}
            icon={theme.reports?.riskChartsPage?.lineChart?.showIcon ? peopleIcon : undefined}
            legend={theme.reports?.riskChartsPage?.lineChart?.showLegend ? attitudeToRisk.legend : []}
            series={[
              {
                id: "1",
                title: client?.firstName,
                values: attitudeToRisk.attitudeToRiskScore,
                color: theme.colors.scoreChartColor
              }
            ]}
            type={"risk"}
            highlightDataPoints={attitudeToRisk.highlightDataPoints}
            showScoreAsDataPoint={theme.charts?.attitudeToRisk?.showScoreAsDataPoint}
            highlightAreas={[
              {
                endXPercent: 24,
                id: "low",
                label: "Low",
                startXPercent: 0
              },
              {
                endXPercent: 59,
                id: "mid",
                label: "Mid",
                startXPercent: 24
              },
              {
                endXPercent: 100,
                id: "high",
                label: "High",
                startXPercent: 59
              }
            ]}
          />
        )}
      </div>
    </div>
  )
}

export default AttitudeToRisk
