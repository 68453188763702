import clsx from "clsx"
import { useQuery } from "react-query"
import { NavLink } from "react-router-dom"
import axiosInstance from "../../../../api/axiosInstance"
import RenderQueryResults from "../../components/RenderQueryResults"


const ListLicensees = () => {
  const licensees = useQuery(["licensees"], () => axiosInstance.get<{_id: string, name: string}[]>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/licensees`)
    .then(res => res.data))

  const linkStyle = ({ isActive }: { isActive: boolean }) => clsx("block px-4 py-2 rounded-3 border border-neutral-200 transition-all", isActive ? "bg-surface-500 text-white" : "bg-highlight-100 hover:bg-interactive-100 ")

  return (
      <div className="pg-ctr pg-ctr-py-lg h-full flex-0 px-10 flex flex-col gap-y-4">
        <h1 className="text-h1">Licensees</h1>
        <RenderQueryResults queryResult={licensees} renderData={(data) =>
          <nav className="flex items-start justify-start flex-row flex-wrap mt-2 gap-x-2 gap-y-2">
            {data.map((l, i) => (
                <NavLink key={i} to={`../licensee/${l._id}`} className={linkStyle}>
                  <div className="text-xs">{l._id}</div>
                  <div className="text-sec font-semibold">{l.name}</div>
                </NavLink>
            ))}
            <NavLink to="create" className="ml-auto btn btn-primary btn-medium">+ Add new</NavLink>
          </nav>}
        />
      </div>
  )
}

export default ListLicensees