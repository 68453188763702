import { AnimatePresence } from "framer-motion"
import { useContext, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router"
import useClient from "../../../hooks/useClient"
import arrowLeft from "../assets/images/arrow-left.svg"
import menuIcon from "./assets/menu.svg"
import useHousehold from "../../../hooks/useHousehold"
import SidebarHeader from "../components/SidebarHeader/SidebarHeader"
import RMJFinishModal from "../components/RMJFinishModal/RMJFinishModal"
import { FirmContext } from "../../../contexts/FirmContext"
import { portfolioComfortMatch } from "../portfolioComfort/comfortMatch"
import RadioButton from "../../../components/RadioButton/RadioButton"
import infoAlert from "../../../assets/icons/info-alert-dark.svg"
import clsx from "clsx"
import { useSearchParams } from "react-router-dom"
import { InvestmentGoal } from "../../../models/Client"
import RMJPortfolioComfortMoreInfoModal from "../portfolioComfort/RMJPortfolioComfortMoreInfoModal/RMJPortfolioComfortMoreInfoModal"
import { latest } from "../../../lib/clients"

const RMJGoalExplorerSideBar = () => {
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client } = useClient(clientId)
  const { household } = useHousehold(householdId)
  const clientOrHousehold = client ?? household
  const { firm } = useContext(FirmContext)
  const navigate = useNavigate()
  const [isFinishModalOpen, setIsFinishModalOpen] = useState(false)
  const [shouldValidate, setShouldValidate] = useState<boolean>()
  const [portfolio, setPortfolio] = useState<string>()
  const [isMoreInfoModalOpen, setIsMoreInfoModalOpen] = useState(false)
  const existingPortfolio = useMemo(() => clientOrHousehold?.currentPortfolio, [clientOrHousehold?.currentPortfolio])
  const comfortMatch = useMemo(() => portfolioComfortMatch(clientOrHousehold), [clientOrHousehold])
  useEffect(() => setPortfolio(clientOrHousehold?.currentPortfolio), [clientOrHousehold?.currentPortfolio])
  const query = useSearchParams()[0].get("goal")
  const goalType = useMemo<InvestmentGoal>(() => {
    return query === "wealth-accumulation" ? "wealthAccumulation" : query === "retirement-income" ? "retirementIncome" : "retirementDrawdown"
  }, [query])
  const game = latest(clientOrHousehold!, "risk")

  const isFinishButtonDisabled = useMemo(() => {
    return (
      (goalType === "wealthAccumulation" && !clientOrHousehold?.wealthAccumulationGoal) ||
      (goalType === "retirementIncome" && (!clientOrHousehold?.retirementIncomeGoal || !clientOrHousehold?.retirementAge)) ||
      (goalType === "retirementDrawdown" && !clientOrHousehold?.retirementIncomeGoal)
    )
  }, [goalType, clientOrHousehold?.wealthAccumulationGoal, clientOrHousehold?.retirementIncomeGoal, clientOrHousehold?.retirementAge])

  return (
    <div className="w-80 shrink-0 h-full bg-interactive-600 flex flex-1 flex-col justify-between">
      {(client || household) && (
        <>
          <div className="flex gap-x-2.5 overflow-y-auto no-scrollbar">
            <div className="flex flex-col w-full">
              {client && <SidebarHeader client={client} />}
              {household && <SidebarHeader household={household} />}
              <div className="sidebar-section">
                <h3 className="sidebar-section-title text-sec">Discuss</h3>
                <p className="sidebar-section-text text-sec">What are your financial goals and investment timeframe?</p>
              </div>
              <div className="sidebar-section text-white">
                <h3 className="sidebar-section-title text-sec">Select investment risk level</h3>
                {firm?.modelPortfolios?.map((modelPortfolio) => (
                  <RadioButton
                    key={modelPortfolio.id}
                    checked={portfolio === modelPortfolio.id}
                    id={modelPortfolio.id}
                    invertColors={true}
                    label={
                      <div className="flex flex-row items-center text-sec">
                        <div className={clsx("mr-2", { "text-alt-critical": shouldValidate && !portfolio, "text-white": !(shouldValidate && !portfolio) })}>
                          {modelPortfolio.name}
                        </div>
                        <div className="flex flex-col">
                          {existingPortfolio === modelPortfolio.id && (
                            <div className="flex flex-row items-center">
                              <div className="rounded-full h-1 w-1 mr-2 bg-alt-link-600"></div>
                              <div className="text-xs text-alt-link-600 uppercase">Current investment</div>
                            </div>
                          )}
                        </div>
                      </div>
                    }
                    isDark={true}
                    error={shouldValidate && !portfolio}
                    name={modelPortfolio.id}
                    onChange={() => setPortfolio(() => modelPortfolio.id)}
                    className="mb-2 text-white items-center"
                  />
                ))}
                {shouldValidate && !portfolio && (
                  <div className="flex items-center mt-1">
                    <img alt="Info alert" className="mr-1" src={infoAlert} />
                    <p className="text-sm text-alt-critical">Please select an investment risk</p>
                  </div>
                )}
                <button
                  className="btn btn-text btn-small btn-secondary btn-alt-text text-white text-xs px-3 flex gap-x-1 items-center"
                  onClick={() => setIsMoreInfoModalOpen(true)}
                >
                  <img src={menuIcon} aria-hidden />
                  View strategic asset allocation
                </button>
              </div>
            </div>
          </div>
          <div className="px-4 pb-4 w-full flex justify-between items-center">
            <div onClick={() => navigate(-1)} className="cursor-pointer w-10">
              <img className="mt-3" src={arrowLeft} alt="arrow left icon" />
            </div>
            <button
              disabled={isFinishButtonDisabled}
              className="btn btn-medium btn-primary-alt mt-4 w-full h-14"
              type="button"
              onClick={() => {
                setShouldValidate(true)
                if (portfolio) {
                  setIsFinishModalOpen(true)
                }
              }}
            >
              Finish
            </button>
          </div>
        </>
      )}
      <AnimatePresence>
        {isFinishModalOpen && (
          <RMJFinishModal
            infoAlert="Please note, content from Goal Explorer page will not be included in the PDF report"
            onClose={() => setIsFinishModalOpen(false)}
            portfolio={portfolio}
            goalExplorerCompleted={true}
            goalType={goalType}
          />
        )}
        {isMoreInfoModalOpen && (
          <RMJPortfolioComfortMoreInfoModal
            assetClasses={game?.assetClasses}
            comfortMatch={comfortMatch}
            onClose={() => setIsMoreInfoModalOpen(false)}
            portfolioMappings={game?.portfolioMappings}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default RMJGoalExplorerSideBar
