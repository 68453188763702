import { useContext } from "react"
import { FirmContext } from "../../../../contexts/FirmContext"
import { useTheme } from "../../../../contexts/ThemeContext"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import DecisionsTable from "../../../rmjourney/yourDecisions/components/DecisionsTable/DecisionsTable"
import Container from "../components/Container"
import Footer from "../components/Footer"
import leaves from "./assets/leaves.svg"

const DecisionPatternPage = ({ page, client, household }: { page: number; client: Client; household: Household }) => {
  const theme = useTheme()
  const { firm } = useContext(FirmContext)

  return (
    <Container className="flex flex-col justify-between pt-10 relative">
      <div className="flex-auto mx-10">
        <h2 className="report-decision-pattern-title mb-2 text-main-600 text-sec tracking-wide uppercase">Decision Pattern</h2>
        <div className="flex flex-col gap-6">
          <h3 className="font-semibold text-main-600 text-h4">You have a strong intuition when managing risk and reward</h3>
          {theme.reports?.decisionPatternPage?.description?.show && (
            <div className="flex flex-col gap-2">
              <p>
                Your decision pattern reflects the percentage of your investible amount you put at risk in each round of the activity given the hypothetical
                returns available. Decisions are sorted from highest potential return available to lowest.
              </p>
              <p>The green numbers are the potential gain and the red numbers are the potential loss you selected in each round.</p>
            </div>
          )}
        </div>
        <div className="flex flex-col w-full text-main-600 mt-7">
          {household && <DecisionsTable enableAnimations={false} forReport={true} clients={household.members.map(({ client }) => client)} />}
          {client && <DecisionsTable enableAnimations={false} forReport={true} clients={[client]} />}
        </div>
      </div>
      <div className="relative h-full flex items-end">
        <div className="w-full h-[252px] bg-neutral-50">
          <div className="mx-9 h-full">
            <div className="flex mx-10 my-12">
              <img className="w-20 h-20 self-start" src={theme.reports?.decisionPatternPage?.scienceIcon ?? leaves} alt="science icon" aria-hidden />
              <div className="flex flex-col ml-6 justify-center self-start">
                <p className="font-semibold text-sec leading-5">The science behind your results</p>
                <p className="font-normal text-sec leading-5">
                  This activity was developed by a decision science, technology and research team of leading economists. It is underpinned by internationally
                  validated decision science and behavioral economics research.
                </p>
              </div>
            </div>
            <Footer pageNum={page} client={client} household={household} firm={firm} className="relative" />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default DecisionPatternPage
