import AvatarBadges from "../../../../components/AvatarBadges/AvatarBadges"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"

interface Props {
  client?: Client
  household?: Household
}

const SidebarHeader = ({ client, household }: Props) => {
  if (client) {
    return (
      <div className="flex items-center border-b border-b-interactive-500 p-5">
        <AvatarBadges clients={[client]} size="small" />
        <h3 className="text-white mr-3 ml-2">{`${client.firstName} ${client.lastName}`}</h3>
      </div>
    )
  } else if (household) {
    const clientNames = household?.members
      .filter(({ client: { lastName } }) => !!lastName)
      .map(({ client: { firstName, lastName } }) => {
        return `${firstName ? `${firstName.charAt(0).toUpperCase()} ` : ""}${lastName}`
      })

    return (
      <div className="flex items-center border-b border-b-interactive-500 p-5">
        <AvatarBadges clients={household.members.map(({ client }) => client)} size="small" />
        <h3 className="text-white ml-3">{clientNames?.join(" & ")}</h3>
      </div>
    )
  } else {
    return null
  }
}

export default SidebarHeader
