import { Trans } from "@lingui/macro"
import Container from "./components/Container"
import Footer from "./components/Footer"
import { Household } from "../../../models/Household"
import { Client } from "../../../models/Client"
import { Firm } from "../../../models/Firm"
import { latest } from "../../../lib/clients"

const AppendixPage3 = ({ page, client, household, firm }: { page: number; client: Client; household: Household; firm: Firm }) => {
  const clientOrHousehold = client ?? household
  const game = latest(clientOrHousehold, "risk")

  return (
    <Container className="flex flex-col justify-between pt-10 relative">
      <div className="mx-9">
        <h1 className="report-appendix-title text-sec text-main-600 mb-1 uppercase">Appendix</h1>
        <h2 className="text-h4 mb-4">
          <Trans id="print-appendix-3-title">Our allocation profiles are defined as follows:</Trans>
        </h2>
        <ul className="flex flex-col gap-6">
          {game?.portfolioMappings.map((cause) => (
            <li key={cause.portfolio.id}>
              <p>
                <strong>{cause.portfolio.name}: </strong>
                <span>
                  <Trans id={`print-appendix-3-${cause.portfolio.translationKey}`}>Information about this portfolio.</Trans>
                </span>
              </p>
            </li>
          ))}
        </ul>
      </div>
      <div className="relative mx-10">
        <Footer pageNum={page} client={client} household={household} firm={firm} />
      </div>
    </Container>
  )
}

export default AppendixPage3
