import { Trans, t } from "@lingui/macro"
import { useMemo } from "react"
import { useTheme } from "../../../contexts/ThemeContext"
import { Client } from "../../../models/Client"
import FundsTable, { FundData } from "../../advisor/components/PortfolioAnalyser/FundsTable"
import PortfolioMatchCard from "../components/Profile/components/PortfolioMatchCard"
import Page from "./Page"
import { customSort } from "../../advisor/components/PortfolioAnalyser/FundAllocation"
import { Firm } from "../../../models/Firm"
import { latest } from "../../../lib/clients"

export function splitFundArrays(input: any[]) {
  const splitArrays = []
  let index = 0
  splitArrays.push(input.slice(index, index + 6))
  index += 6
  while (index < input.length) {
    splitArrays.push(input.slice(index, index + 15))
    index += 14
  }
  return splitArrays
}

const PortfolioAnalyserReportPage = ({ page, client, firm }: { page: number; client: Client; firm?: Firm }) => {
  const theme = useTheme()
  const game = latest(client, "esg")
  const funds: FundData[] | undefined = useMemo(() => {
    const currentFunds = client?.portfolio?.current?.fundAllocations
    const proposedFunds = client?.portfolio?.proposed?.fundAllocations
    const missingFunds = currentFunds?.filter(
      (f) => !(proposedFunds ?? game?.esg?.results?.portfolioMatch?.fundMatches)?.find((a) => a.fund?.id === f.fund?.id)
    )
    return (
      (proposedFunds ?? game?.esg?.results?.portfolioMatch?.fundMatches)?.concat(missingFunds ?? []).map((fundAllocation) => {
        const fundId = fundAllocation.fund?.id
        const match = (client?.portfolio?.proposed?.fundAllocations ?? game?.esg?.results?.portfolioMatch?.fundMatches)?.find(
          (match) => match.fund?.id === fundId
        )
        const currentFund = currentFunds?.find((f) => f.fund?.id === fundId)
        const fund = currentFund?.fund ?? firm?.funds.find((f) => f.id === fundId)
        return {
          id: fundAllocation.fund?.id,
          ticker: fund?.ticker,
          fundName: fund?.name,
          assetClass: fund?.assetClass,
          sustainabilityMatchScore: match?.smScore,
          currentAllocation: currentFund?.allocation,
          proposedAllocation: match?.allocation ?? currentFund?.allocation,
          status: !fund?.name
            ? ""
            : match?.allocation === 0
            ? "Removed"
            : !currentFund
            ? "Added"
            : currentFund.allocation !== match?.allocation
            ? "Updated"
            : ""
        }
      }) ?? []
    )
  }, [game?.esg?.results?.portfolioMatch?.fundMatches, client?.portfolio, firm?.funds])

  const sortedFunds = useMemo(() => [...funds].sort(customSort), [funds])

  const pageFunds = splitFundArrays(sortedFunds ?? [])

  const constraints = useMemo(() => {
    const currentPortfolioGrowthAsset = client?.portfolio?.current?.growthAllocationSum ?? 0
    const proposedPortfolioGrowthAsset =
      client?.portfolio?.proposed?.growthAllocationSum ?? game?.esg.results?.portfolioMatch?.growthAllocationSum ?? 0
    const growthAssetDiff = Math.abs(currentPortfolioGrowthAsset - proposedPortfolioGrowthAsset)

    const portfolioChurn = funds.reduce((acc, f) => {
      if (f.status === "Removed") {
        acc += f.currentAllocation || 0
      } else if (f.status === "Updated" && f.proposedAllocation! < f.currentAllocation!) {
        acc += f.currentAllocation! - f.proposedAllocation! || 0
      }
      return acc
    }, 0)

    return { growthAssetDiff, portfolioChurn }
  }, [game?.esg.results?.portfolioMatch?.growthAllocationSum, client?.portfolio, funds])

  return (
    <>
      <Page page={page} title={t({ id: "print-report-page-header-title" })}>
        <div id="top-themes-page" className="relative mx-9 h-full">
          <div className="flex items-center justify-between">
            <div className="flex gap-2 items-center">
              <h1 className="font-normal text-[22px]">
                <Trans id="print-portfolio-analyser-title">Portfolio analysis</Trans>
              </h1>
            </div>
          </div>

          <div className="pt-6">
            <h2 className="text-p font-medium mb-6">
              <Trans id="portfolio-analyser-fund-allocation-heading">Fund allocation</Trans>
            </h2>
            <div className="flex flex-col gap-2 mb-8 mx-1">
              <div className="flex gap-5">
                <PortfolioMatchCard client={client} type="current" forReport={true} />
                <PortfolioMatchCard client={client} type="proposed" forReport={true} />
              </div>
              <div className="text-xxs">
                <span><Trans id="funds-match-source-citation">Source: Calculated on MSCI Analytics</Trans></span>
              </div>
            </div>
            <div className="mb-6 bg-neutral-50 p-4 w-full">
              <h3 className="text-p font-medium">
                <Trans id="print-portfolio-analyser-constraint-title">Constraints</Trans>
              </h3>
              <p className="text-sec font-normal">
                <Trans id="print-portfolio-analyser-constraint-description">
                  The proposed portfolio switches out X% of holdings from the current portfolio.
                </Trans>
              </p>
              <div className="border w-full mt-3 mb-2" />
              <ul className="flex justify-between flex-wrap w-full text-sec leading-5 font-normal ">
                <li className="flex-grow">
                  <p className="pt-2">Growth assets (+/-2%)</p>
                  <p className="flex">
                    <span className="flex items-center gap-x-1 italic">
                      {constraints.growthAssetDiff === 0 ? "No change" : `Proposed +${parseFloat(constraints.growthAssetDiff.toString()).toFixed(2)}%`}
                    </span>
                  </p>
                </li>
                <li className="flex-grow">
                  <p className="pt-2">Portfolio churn (max 50%)</p>
                  <p className="flex">
                    <span className="flex items-center gap-x-1 italic">
                      Proposed {constraints!.portfolioChurn ? `${parseFloat(Math.abs(constraints!.portfolioChurn).toString()).toFixed(2)}%` : ""}
                    </span>
                  </p>
                </li>
                <li className="flex-grow">
                  <p className="pt-2">Fund allocation</p>
                  <p className="flex items-center">
                    (0.25% - 25%)
                    <img src={theme.pages.portfolioAnalyser?.fundAllocation.icons.successCheckmark} className="ml-1" alt="success" />
                  </p>
                </li>
                <li className="flex-grow">
                  <p className="pt-2">Total allocation</p>
                  <p className="flex items-center">
                    (100%)
                    <img src={theme.pages.portfolioAnalyser?.fundAllocation.icons.successCheckmark} className="ml-1" alt="success" />
                  </p>
                </li>
              </ul>
            </div>
            <FundsTable fundTableData={pageFunds.shift()} forReport={true} showDisclaimer={sortedFunds.length <= 6} />
          </div>
        </div>
      </Page>
      {pageFunds.map((funds, i) => {
        return (
          <Page key={i} page={++page} title={t({ id: "print-report-page-header-title" })}>
            <div id="top-themes-page" className="relative mx-9 h-full">
              <FundsTable fundTableData={funds} forReport={true} showDisclaimer={true} />
            </div>
          </Page>
        )
      })}
    </>
  )
}

export default PortfolioAnalyserReportPage
