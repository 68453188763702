import clsx from "clsx"
import { ClientStatus } from "../../models/Client"
import { HouseholdStatus } from "../../models/Household"
import { useTheme } from "../../contexts/ThemeContext"

interface Props {
  status: ClientStatus | HouseholdStatus
}

const StatusBadge = ({ status }: Props) => {
  const theme = useTheme()
  return (  
    <div className={clsx(`status-badge status-badge-${status.replaceAll(/\s+/g, "-").toLowerCase()} text-xs font-bold text-center uppercase px-3 py-1 tracking-extrawide`, theme.statuses && theme.statuses[status].classNames)}>
      {status}
    </div>
  )
}

export default StatusBadge
