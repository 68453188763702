import { longRound, shortRound } from "./numbers"

type Map = {
  [key: string]: any
}

const parseCurrency =
  (isEuro: boolean) =>
  (value: string): string => {
    const { group, decimal } = isEuro ? { group: ".", decimal: "," } : { group: ",", decimal: "." }
    const num = value.split(decimal)
    const integer = num[0].split(group)
    const fraction = num.length > 1 && num[1]
    return (fraction ? [...integer, decimal, fraction] : integer).join("")
  }
let formatter: Intl.NumberFormat
export const currencyNumberFormat = {
  create(locale: string = "en-US", currency: string = "USD", opts?: Map | undefined) {
    const options =
      typeof currency === "string"
        ? {
            style: "currency",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            currency,
            ...opts
          }
        : opts

    // cache a instance of NumberFormat
    formatter = new Intl.NumberFormat(locale, options)
    return this
  },
  format(amount: number): string {
    return formatter && formatter.format(amount).replace("NaN", "")
  },
  parse(value: string, currency: Map): string {
    const options = (formatter && formatter.resolvedOptions()) || { currency }
    return parseCurrency(this.isEuro(options.currency))(value)
  },
  isEuro(currency = "USD") {
    const currencyMap: Map = {
      USD: false,
      EUR: true
    }
    return currencyMap[currency]
  }
}

export const getCurrency =
  (locale: string = "en-US", currency: string = "USD", opts: Map) =>
  (amount: number, digit: number = 0) => {
    return currencyNumberFormat
      .create(
        locale,
        currency,
        (opts = {
          ...opts,
          maximumFractionDigits: digit,
          minimumFractionDigits: digit
        })
      )
      .format(amount)
  }

export const formatCurrencyShort = (amount: number | undefined) => {
  const rounded = amount !== undefined ? shortRound(amount) : null
  return rounded ? `$${rounded.value}${rounded.unit}` : null
}

export const formatCurrencyLong = (amount: number | null | undefined, postfix: string = "") => {
  return amount === undefined || amount === null ? null : `$${longRound(amount).toLocaleString()} ${postfix}`
}
