import clsx from "clsx"

type Props = {
  className?: string,
  message?: string
}

const LoadingError = ({ className, message = "Could not load" }: Props) => {
  return (
    <p className={clsx("w-full h-full flex items-center justify-center text-main-400", className)}>
      { message }
    </p>
  )
}

export default LoadingError
