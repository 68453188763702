import { Trans } from "@lingui/macro"
import React from "react"
import { useTheme } from "../../../../../../contexts/ThemeContext"
import { Client } from "../../../../../../models/Client"
import LineChart from "../../../../../rmjourney/components/LineChart/LineChart"
import { AltruismScoreData } from "./altruismScoreAttributes"

interface Props {
  client: Client
  altruismScore: AltruismScoreData
  enableAnimations?: boolean
}

const AltruismLineChart: React.FC<Props> = ({ client, altruismScore, enableAnimations = true }: Props) => {
  const theme = useTheme()

  return (
    <div className="h-80">
      <LineChart
        xAxis={altruismScore!.xAxis}
        yAxis={altruismScore!.yAxis}
        enableAnimations={enableAnimations}
        xAxisLabel={{
          show: true,
          text: (
            <div className="results-altruism-line-chart-labels flex justify-between text-xs w-full leading-1 font-normal mt-8">
              <p className="pr-1">
                <Trans id="results-altruism-x-left-label">Generating highest returns</Trans>
              </p>
              <p className="text-right">
                <Trans id="results-altruism-x-right-label">Reflecting core values</Trans>
              </p>
            </div>
          )
        }}
        gameType={theme.gameType}
        series={[
          {
            id: "1",
            title: client?.firstName,
            values: altruismScore!.altruismData,
            color: theme.colors.scoreChartColor
          }
        ]}
        highlightDataPoints={altruismScore!.highlightDataPoints}
      />
    </div>
  )
}

export default AltruismLineChart
