import clsx from "clsx"
import { AnimatePresence, motion } from "framer-motion"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { useTheme } from "../../../../contexts/ThemeContext"
import useHousehold from "../../../../hooks/useHousehold"
import HouseholdProfileOverview from "../../../households/components/HouseholdProfileOverview/HouseholdProfileOverview"

const HouseholdStatusSideBar = () => {
  const { householdId } = useParams<{ householdId: string }>()
  const { household } = useHousehold(householdId)
  const [isExpanded, setIsExpanded] = useState(true)
  const [isAnimating, setIsAnimating] = useState(true)
  const theme = useTheme()

  return (
    <AnimatePresence>
      <motion.div
        initial={false}
        animate={{ width: isExpanded ? "320px" : "76px", transition: { duration: 0.3, ease: "easeInOut" } }}
        onAnimationStart={() => setIsAnimating(isAnimating)}
        onAnimationComplete={() => setIsAnimating(!isAnimating)}
        className={clsx("client-sidebar-wrapper relative h-full bg-interactive-600", isExpanded ? "w-80" : "w-20")}
      >
        {theme.pages.profile.sidebar.isCollapsible && (
          <button
            className="absolute w-7 h-7 -right-2 top-13.5 z-10 border border-interactive-400 bg-interactive-500 flex justify-center items-center"
            onClick={() => setIsExpanded(!isExpanded)}
            aria-label={`${isExpanded ? "Collapse" : "Expand"} client sidebar`}
          >
            <img className={clsx("w-3 h-3", { "rotate-180": isExpanded })} src={theme.pages.profile.sidebar.expandCollapseIcon} alt="" aria-hidden />
          </button>
        )}
        {household && (
          <div className="client-sidebar h-full flex items-center">
            <HouseholdProfileOverview isExpanded={isExpanded} household={household} isAnimating={isAnimating} />
          </div>
        )}
      </motion.div>
    </AnimatePresence>
  )
}

export default HouseholdStatusSideBar
