import React from "react"

export default function DollarIcon() {
  return (
    <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.84677 17.3334V15.4837C3.28305 15.4221 2.71319 15.2741 2.13721 15.0398C1.57349 14.7932 1.08329 14.4726 0.666626 14.078L1.62251 12.6352C2.05143 12.9805 2.48648 13.2579 2.92766 13.4676C3.36883 13.6772 3.84065 13.782 4.3431 13.782C4.94359 13.782 5.39089 13.6464 5.68501 13.3751C5.97913 13.0915 6.12619 12.6969 6.12619 12.1913C6.12619 11.7844 6.00364 11.4452 5.75854 11.174C5.51344 10.9027 5.20094 10.656 4.82104 10.4341C4.45339 10.2121 4.05511 10.0025 3.62619 9.80518C3.19726 9.59555 2.79285 9.35509 2.41295 9.08381C2.0453 8.80019 1.73893 8.46724 1.49383 8.08497C1.24873 7.69037 1.12618 7.20329 1.12618 6.62372C1.12618 5.76053 1.37128 5.04532 1.86148 4.47808C2.35168 3.89851 3.01344 3.53474 3.84677 3.38676V0.666748H5.31736V3.34977C5.9056 3.42376 6.40805 3.5964 6.82472 3.86768C7.25364 4.12664 7.63967 4.43492 7.9828 4.79253L6.89824 6.03182C6.55511 5.71121 6.22423 5.47075 5.9056 5.31044C5.58697 5.1378 5.20707 5.05148 4.76589 5.05148C4.26344 5.05148 3.87128 5.18096 3.58942 5.43992C3.30756 5.68654 3.16663 6.05032 3.16663 6.53123C3.16663 6.90117 3.28918 7.20945 3.53427 7.45608C3.77937 7.69037 4.08574 7.91234 4.45339 8.12197C4.83329 8.31927 5.23771 8.52273 5.66663 8.73236C6.09555 8.92966 6.49383 9.17012 6.86148 9.45374C7.24138 9.73736 7.55388 10.0888 7.79898 10.5081C8.04408 10.915 8.16663 11.4267 8.16663 12.0433C8.16663 12.9312 7.92153 13.6772 7.43133 14.2814C6.94114 14.8733 6.23648 15.2556 5.31736 15.4282V17.3334H3.84677Z"
        fill="#31366B"
      />
    </svg>
  )
}
