import { t } from "@lingui/macro"
import { useContext, useMemo } from "react"
import { useQuery } from "react-query"
import axiosInstance from "../../../../../../api/axiosInstance"
import NotesList, { NotesListItem } from "../../../../../../components/NotesList/NotesList"
import { AppContext } from "../../../../../../contexts/AppContext"
import { Client, Note } from "../../../../../../models/Client"
import { Household, HouseholdEventType } from "../../../../../../models/Household"
import { AuthContext, AuthStatus } from "../../../../../../views/auth/AuthContext"
import { clientActivityNotes } from "../../../../../clients/components/Profile/components/Notes/Notes"

interface NoteItem {
  client?: Client
  id: string
  note: Note
  warningAlert?: {
    message: string
  }
}

interface Props {
  isDisabled: boolean
  isNoteSaving: boolean
  household: Household
  onAddNote: (note: string) => void
}

const householdEventMessages = (name: string): { [key in HouseholdEventType]: string } => ({
  created: t({ id: `household-event-created-note`, message: `${name}'s record was created` }),
  "marked as reviewed": t({ id: `household-event-risk-mark-as-reviewed-note`, message: `${name}'s risk profile was marked as reviewed` })
})

const HouseholdNotes = ({ household, isDisabled, isNoteSaving, onAddNote }: Props) => {
  const { authStatus, sessionInfo } = useContext(AuthContext)
  const { userProfile } = useContext(AppContext)

  const notes: NoteItem[] = useMemo(() => {
    const memberNotes: NoteItem[] = []

    household.members.forEach(({ client }) => {
      const clientNotes = clientActivityNotes(client)
      memberNotes.push(...clientNotes)
    })

    const householdNotes =
      household.notes?.map((note) => ({
        id: `note-${household._id}-${note.created}`,
        note
      })) ?? []

    const householdEvents =
      household.events?.map((event) => ({
        id: `event-${event.created}`,
        note: {
          message: householdEventMessages(household.name)[event.type],
          created: event.created,
          createdBy: event.createdBy,
          custom: false
        }
      })) ?? []

    return [...householdNotes, ...memberNotes, ...householdEvents]
  }, [household._id, household.members, household.notes, household.name, household.events])

  const subs = useMemo(
    () =>
      Array.from(
        new Set(
          notes
            ?.filter((n) => n.note.custom)
            .map((n) => n.note.createdBy?.sub)
            .filter((s) => s)
        )
      ),
    [notes]
  )

  const { isLoading, data: usersBySub } = useQuery<{ [sub: string]: { firstName: string; lastName: string } }>(
    ["usersBySub", ...notes.map((n) => n.note.createdBy?.sub)],
    () => {
      if (subs && subs.length === 1 && subs[0] === userProfile?.sub) {
        return {
          [subs[0]]: { firstName: userProfile.firstName, lastName: userProfile.lastName }
        }
      } else {
        const queryParams = new URLSearchParams({})
        subs?.forEach((sub) => {
          if (sub) queryParams.append("sub", sub)
        })
        return axiosInstance
          .get(`${import.meta.env.VITE_APP_API_BASE || ""}/api/user/subs?householdId=${household._id}&${queryParams}`)
          .then((res) => res.data)
      }
    },
    {
      enabled: authStatus === AuthStatus.SignedIn && !!sessionInfo?.accessToken && subs && subs.length > 0,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  )

  const notesListItems: NotesListItem[] = useMemo(() => {
    return [...notes]
      .sort((a, b) => new Date(b.note.created).getTime() - new Date(a.note.created).getTime())
      .map(
        (noteItem: NoteItem) =>
          ({
            date: noteItem.note.created,
            id: noteItem.id,
            message: noteItem.note.message,
            userName:
              noteItem.note.custom && usersBySub && noteItem.note.createdBy?.sub && usersBySub[noteItem.note.createdBy?.sub]
                ? `${usersBySub[noteItem.note.createdBy?.sub].firstName}: `
                : null,
            warningAlert: noteItem.warningAlert
          } as NotesListItem)
      )
  }, [notes, usersBySub])

  return <NotesList isDisabled={isDisabled} isLoading={isLoading} isNoteSaving={isNoteSaving} items={notesListItems} onAddNote={onAddNote} />
}

export default HouseholdNotes
