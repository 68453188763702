import { Route, Routes } from "react-router-dom"
import RMJYourDecisionsSideBar from "./yourDecisions/RMJYourDecisionsSideBar"
import RMJPortfolioComfortSideBar from "./portfolioComfort/RMJPortfolioComfortSideBar"
import RMJAdviceOverviewSideBar from "./adviceOverview/RMJAdviceOverviewSideBar"
import RMJGoalExplorerSideBar from "./goalExplorer/RMJGoalExplorerSideBar"
import RMJRiskAttitudeSideBar from "./riskAttitude/RMJRiskAttitudeSideBar"
import RMJLossSensitivitySideBar from "./lossSensitivity/RMJLossSensitivitySideBar"

const RMJourneySidebar = () => {
  return (
    <Routes>
      <Route path="advice-overview" element={<RMJAdviceOverviewSideBar />} />
      <Route path="your-decisions" element={<RMJYourDecisionsSideBar />} />
      <Route path="risk-attitude" element={<RMJRiskAttitudeSideBar />} />
      <Route path="loss-sensitivity" element={<RMJLossSensitivitySideBar />} />
      <Route path="portfolio-comfort" element={<RMJPortfolioComfortSideBar />} />
      <Route path="goal-explorer" element={<RMJGoalExplorerSideBar />} />
    </Routes>
  )
}

export default RMJourneySidebar
