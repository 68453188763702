import React from "react"
import PrintReportText from "./PrintReportText"
import { Cause } from "../../../config/theme"
import clsx from "clsx"

export const PrintCauseDetail = ({ cause, className }: { cause: Cause; className?: string }) => (
  <div className={clsx("mb-10 cause-detail", className)}>
    <div className="flex">
      <div>
        <img alt={`${cause.title} icon`} src={cause.persona?.icon} className="w-6 mr-2 print-cause-detail-icon" />
      </div>
      <div className="w-full">
        <PrintReportText className="font-semibold cause-title">{cause.title}</PrintReportText>
        <PrintReportText>{cause.description}</PrintReportText>
        <div className="flex mt-3">
          <div className="pr-8 w-1/2">
            <div className="flex items-start">
              <div>
                <PrintReportText className="mb-1">Includes companies that:</PrintReportText>
                <ul className="list-disc ml-4 text-sec leading-5">
                  {cause.items?.map((item, i) => (
                    <React.Fragment key={i}>
                      <li className="tracking-tight">{item.title}</li>
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-5/12">
            <PrintReportText className="pb-2">{cause.learn?.devGoals?.description}</PrintReportText>
            <ul
              className={clsx("mb-2 flex flex-col gap-2 text-sec leading-5", {
                "list-disc ml-4": !cause.learn?.devGoals?.items.some((item) => item.icon)
              })}
            >
              {cause.learn?.devGoals?.items.map((item, i) => (
                <li className={clsx({ "flex p-0": !!item.icon })} key={i}>
                  {item.icon && <img alt="" src={item.icon} className="h-5 w-5 mr-2" aria-hidden />}
                  {item.description}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
)
