import React, { useState, useContext, useCallback } from "react"
import { FirmContext } from "../../../contexts/FirmContext"
import { update } from "../../../api/firms"
import { AuthContext } from "../../../views/auth/AuthContext"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import DecimalInput from "./DecimalInput"

const InflationRate: React.FunctionComponent<{ handleClose: () => void, disableSave?: boolean }> = ({ handleClose, disableSave }) => {
  const { firm, reloadFirm } = useContext(FirmContext)
  const [inflationRate, setInflationRate] = useState<number | undefined>(firm?.inflationRate)
  const [error, setError] = useState<string>()
  const [valueInProgress, setValueInProgress] = useState<boolean>(false)
  const { sessionInfo } = useContext(AuthContext)

  const saveChanges = useCallback(() => {
    setValueInProgress(true)
    if (inflationRate) {
      setError(undefined)
      update(sessionInfo!, firm!._id, {
        inflationRate: inflationRate
      })
        .then(() => {
          reloadFirm()
          handleClose()
        })
        .catch((err) => {
          setValueInProgress(false)
          setError(err)
        })
    }
  }, [reloadFirm, handleClose, firm, sessionInfo, inflationRate])

  return (
    <div className="modal-container w-[600px]">
      <h1 className="modal-title ph-10 text-left">Inflation rate</h1>
      <label htmlFor="inflation">Expected inflation rate</label>
      <div className="w-30">
        <DecimalInput
          name="inflation"
          onChange={(val) => {
            setInflationRate(val)
          }}
          value={inflationRate}
          error={error}
          max={4}
          suffix={"%"}
        />
      </div>
      <div className="flex gap-4 w-[340px] m-auto pt-10">
        <button className="btn btn-secondary btn-medium text-sec flex-1" onClick={handleClose}>
          Cancel
        </button>
        <button
          className="btn btn-primary btn-medium text-sec flex-1 hover:bg-interactive-600 hover:cursor-pointer"
          disabled={!!error || valueInProgress || disableSave}
          onClick={saveChanges}
        >
          {valueInProgress === true ? <Loading type="dots" /> : "Save"}
        </button>
      </div>
    </div>
  )
}

export default InflationRate
