import clsx from "clsx"
import CauseBar from "./CauseBar"

export interface BarScore {
  causeId: string
  title: string
  color: string
  icon: any
  score: number
}

const CauseScores = ({ scores }: { scores?: BarScore[] }) => {
  return (
    <ul
      className={clsx("cause-scores w-full bg-white py-3")}
    >
      {scores?.map(({ title, score, color, icon }, i) => (
        <li
          key={i}
          className="cause-score flex items-center w-full py-2.5"
        >
          <img className="cause-score-image w-6 mr-3" src={icon} alt="" aria-hidden />
          <CauseBar title={title} score={score} color={color} />
          <div className="cause-score-text w-14 shrink-0 text-sec font-medium tracking-wide whitespace-no-wrap overflow-hidden ml-5">
            {score} pts
          </div>
        </li>
      ))}
    </ul>
  )
}

export default CauseScores