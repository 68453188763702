import { useCallback, useContext, useMemo, useState } from "react"
import { update } from "../../api/firms"
import { AppContext } from "../../contexts/AppContext"
import { FirmContext } from "../../contexts/FirmContext"
import { AuthContext } from "../../views/auth/AuthContext"
import { AnimatePresence } from "framer-motion"
import Modal from "../../components/Modal/Modal"
import target from "./images/Target.svg"
import { AssetClass } from "../../models/InvestmentUniverse"
import AssetClassItemMenu from "./components/AssetClassItemMenu"
import DeleteAssetClassModal from "./components/DeleteAssetClassModal"
import AssetClassModal from "./components/AssetClassModal"
import ErrorDeleteAssetClassModal from "./components/ErrorDeleteAssetClassModal"
import Drawer from "../../components/Drawer/Drawer"

const AssetClassesPage = () => {
  const { sessionInfo } = useContext(AuthContext)
  const { firmId } = useContext(AppContext)
  const { firm, reloadFirm } = useContext(FirmContext)
  const [createAssetClassModal, setCreateAssetClassModal] = useState<boolean>(false)
  const [editAssetClassModal, setEditAssetClassModal] = useState<boolean>(false)
  const [deleteAssetClassModal, setDeleteAssetClassModal] = useState<boolean>(false)
  const assetLibrary = useMemo(() => (firm!.assetClasses ? firm!.assetClasses : []), [firm])
  const [currentAssetClass, setCurrentAssetClass] = useState<AssetClass>()
  const [index, setIndex] = useState<number>()
  const [errorDeleteAssetClassModal, setErrorDeleteAssetClassModal] = useState<boolean>(false)

  const updateFirm = useCallback(
    (assetClasses: AssetClass[]) => {
      update(sessionInfo!, firmId!, {
        assetClasses: assetClasses
      }).then(() => {
        reloadFirm()
      })
    },
    [firmId, reloadFirm, sessionInfo]
  )

  const createAssetClass = useCallback(
    (assetClass: AssetClass) => {
      if (assetClass.name) {
        const newAssetClasses = [
          ...assetLibrary,
          {
            name: assetClass.name,
            type: assetClass.type,
            subAssetClasses: assetClass.subAssetClasses
          }
        ]
        updateFirm(newAssetClasses)
      }
    },
    [assetLibrary, updateFirm]
  )

  const editAssetClass = useCallback(
    (assetClass: AssetClass, index: number) => {
      if (assetClass.name) {
        const updatedAssetClasses = [...assetLibrary!]
        updatedAssetClasses[index] = {
          ...updatedAssetClasses[index],
          name: assetClass.name,
          type: assetClass.type,
          subAssetClasses: assetClass.subAssetClasses
        }
        updateFirm(updatedAssetClasses)
      }
    },
    [updateFirm, assetLibrary]
  )

  const deleteAssetClass = useCallback(
    (index: number) => {
      const updatedSubAssetClassList = firm?.assetClasses.filter((_, i) => i !== index)
      updateFirm(updatedSubAssetClassList!)
    },
    [firm?.assetClasses, updateFirm]
  )

  return (
    <div className="pg-ctr w-full h-full text-main-600 px-10 flex flex-col items-stretch overflow-y-auto no-scrollbar">
      <div className="flex items-stretch w-full h-full">
        <div className="w-full h-full flex flex-col items-stretch py-12">
          <div className="flex pb-10">
            <h2 className="text-h4 w-full leading-tight">Asset classes</h2>
            <button
              className="btn btn-primary btn-medium h-full w-75 flex flex-col items-center justify-center text-center px-10"
              onClick={() => setCreateAssetClassModal(true)}
            >
              Create asset class
            </button>
          </div>
          {assetLibrary === undefined || assetLibrary.length === 0 ? (
            <div className="bg-surface-100 w-full h-full text-center p-10">
              <img className="mx-auto" alt="" src={target} aria-hidden />
              <h2 className="p-5">Start by creating an asset class</h2>
              <button onClick={() => setCreateAssetClassModal(true)} className="btn btn-secondary p-2">
                Create new asset class
              </button>
            </div>
          ) : (
            <div className="pb-10">
              {firm?.assetClasses.map((ac, index) => {
                const subAssetClassesInUse = firm.modelPortfolios?.some((pf) =>
                  pf.components.some((comp) => ac.subAssetClasses?.some((sac) => sac.id === comp.id && comp.weight !== 0))
                )
                const assetClassesInUse = firm.modelPortfolios?.some((pf) => pf.components.some((comp) => comp.id === ac.id && comp.weight !== 0))
                return (
                  <div key={ac.id || `assetClass-${index}-${ac.name}`} className="w-full bg-surface-100 my-3 p-3">
                    <div className="flex justify-between pr-5">
                      <p className="font-semibold">{ac.name}</p>
                      <div className="items-end flex justify-end">
                        <div className="pr-1 text-p">
                          {(ac.subAssetClasses?.length === 0 || !ac.subAssetClasses) && (
                            <p>{ac.type === "growth" ? "Growth" : ac.type === "income" ? "Income" : ac.type === "cash" ? "Cash" : ""}</p>
                          )}
                        </div>
                        <AssetClassItemMenu
                          alignVertical={"bottom"}
                          onEditClick={() => {
                            setEditAssetClassModal(true)
                            setCurrentAssetClass(ac)
                            setIndex(index)
                          }}
                          onDeleteClick={() => {
                            assetClassesInUse || subAssetClassesInUse ? setErrorDeleteAssetClassModal(true) : setDeleteAssetClassModal(true)
                            setCurrentAssetClass(ac)
                            setIndex(index)
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex-col">
                      {ac.subAssetClasses?.map((sac, sidx) => (
                        <div key={sac.id || `subAssetClass-${sidx}-${sac.name}`} className="pr-10">
                          {sidx !== 0 && <hr className="border-surface-300" />}
                          <div className="flex font-base py-2 items-center">
                            <p>{sac.name}</p>
                            <div className="ml-auto text-p">
                              <p>{sac.type === "growth" ? "Growth" : sac.type === "income" ? "Income" : sac.type === "cash" ? "Cash" : ""}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
      <AnimatePresence>
        {createAssetClassModal && (
          <Drawer handleClose={() => setCreateAssetClassModal(false)}>
            <AssetClassModal
              handleClose={() => setCreateAssetClassModal(false)}
              onCreate={(value) => createAssetClass(value)}
              onEdit={(value) => editAssetClass(value, index!)}
            />
          </Drawer>
        )}
        {editAssetClassModal && (
          <Drawer handleClose={() => setEditAssetClassModal(false)}>
            <AssetClassModal
              handleClose={() => setEditAssetClassModal(false)}
              onCreate={(value) => createAssetClass(value)}
              onEdit={(value) => editAssetClass(value, index!)}
              currentAssetClass={currentAssetClass}
              isEdit={true}
            />
          </Drawer>
        )}
        {deleteAssetClassModal && (
          <Modal handleClose={() => setDeleteAssetClassModal(false)}>
            <DeleteAssetClassModal handleClose={() => setDeleteAssetClassModal(false)} onDelete={() => deleteAssetClass(index!)} />
          </Modal>
        )}
        {errorDeleteAssetClassModal && (
          <Modal handleClose={() => setErrorDeleteAssetClassModal(false)}>
            <ErrorDeleteAssetClassModal handleClose={() => setErrorDeleteAssetClassModal(false)} />
          </Modal>
        )}
      </AnimatePresence>
    </div>
  )
}

export default AssetClassesPage
